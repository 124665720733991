import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    VideoItemWrapper,
    VideoItemContainer,
    InfoContainer,
    VisitTitle,
    VisitDescription,
    ItemIConWrapper
} from './VideoItem.styles';
import { UserHistoryVideo } from '../../../../../../models/UserHistory';
import { RightSquareOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatNumber } from '../../../../../../utils/FormatUtils';

interface Props {
    data: UserHistoryVideo;
}

export const VideoItem: ComponentType<Props> = pure(({ data }) => {
    return (
        <VideoItemWrapper>
            <VideoItemContainer>
                <ItemIConWrapper>
                    <RightSquareOutlined
                        style={{ fontSize: 30, color: 'red' }}
                    />
                </ItemIConWrapper>
                <InfoContainer>
                    <VisitTitle>{data.title}</VisitTitle>
                    <VisitDescription>
                        Điểm thưởng: {formatNumber(data.point)}
                    </VisitDescription>
                    <VisitDescription>
                        {moment(data.date).format('MM/DD/YYYY HH:mm:ss')}
                    </VisitDescription>
                </InfoContainer>
            </VideoItemContainer>
        </VideoItemWrapper>
    );
});
