import { ComponentType, useState } from 'react';
import pure from 'recompose/pure';
import { useNavigate } from 'react-router-dom';
//components

//styles
import {
    VideoItemWrapper,
    VideoItemContainer,
    VideoBannerWrapper,
    VideoBannerImage,
    InfoContainer,
    VideoName,
    VideoDesc,
    FooterWrapper,
    PointWrapper,
    PointText,
    PointVal,
    ViewButton,
    VideoNameWrapper
} from './VideoItem.styles';
import Video from '../../../models/Video';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { PlayDialog } from '../PlayDialog/PlayDialog';
import { observer } from 'mobx-react-lite';
import LinesEllipsis from 'react-lines-ellipsis';
import { formatNumber } from '../../../utils/FormatUtils';

interface Props {
    data: Video;
}

export const VideoItem: ComponentType<Props> = observer(({ data }) => {
    const navigation = useNavigate();
    const [openDialogPlayVideo, setOpenDialogPlayVideo] = useState(false);

    const onViewVideo = () => {
        setOpenDialogPlayVideo(true);
    };

    const onStopVideo = () => {
        setOpenDialogPlayVideo(false);
    };

    return (
        <VideoItemWrapper xl={8} md={8} sm={24} xs={24}>
            <VideoItemContainer>
                <VideoBannerWrapper>
                    <VideoBannerImage src={data?.thumbPath} />
                </VideoBannerWrapper>
                <InfoContainer>
                    <VideoNameWrapper title={data?.videoName}>
                        {/* <VideoName
                            line={2}
                            element="span"
                            truncateText="…"
                            text={data?.videoName}
                        /> */}

                        <LinesEllipsis
                            text={data?.videoName}
                            maxLine="2"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                            style={{ paddingRight: 2 }}
                        />
                    </VideoNameWrapper>

                    <FooterWrapper>
                        <PointWrapper>
                            <PointText>Điểm thưởng</PointText>
                            <PointVal>{formatNumber(data?.point)}</PointVal>
                        </PointWrapper>

                        <ViewButton
                            typeButton={APP_BUTTON_TYPE.BORDER}
                            onClick={() => onViewVideo()}>
                            Xem
                        </ViewButton>
                    </FooterWrapper>
                </InfoContainer>
                {openDialogPlayVideo ? (
                    <PlayDialog
                        video={data}
                        onClose={onStopVideo}
                        open={openDialogPlayVideo}
                    />
                ) : null}
            </VideoItemContainer>
        </VideoItemWrapper>
    );
});
