import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';

export const CategoryItemWrapper = styled.div`
    position: relative;
    cursor: pointer;
    margin: 10px;
    padding: 10px;
    :not(:last-child) {
        border-bottom: solid 1px #313435;
    }
`;

export const CategoryItemContainer = styled.div`
    display: flex;
`;

export const CategoryThumbnailContainer = styled.div`
    /* width: 500px; */
    max-height: 100px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
`;

export const CategoryThumbnail = styled.img`
    object-fit: cover;
    width: 100px;
    height: 60px;
    margin-top: 8px;
    border-radius: 10px;
`;

export const InfoContainer = styled.div`
    height: 80px;
    width: 100%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const CategoryName = styled.h1`
    color: #fff;
    margin: 0;
    font-weight: 700;

    &:hover {
        color: #fc1c03;
    }
    @media ${device.mobileS} {
        font-size: 16px;
    }

    @media ${device.tablet} {
        font-size: 18px;
    }
`;

export const CategoryDesc = styled.h1`
    color: #fff;
    margin: 0;
    font-weight: 500;
    @media ${device.mobileS} {
        font-size: 14px;
    }

    @media ${device.tablet} {
        font-size: 16px;
    }
`;
