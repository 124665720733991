import styled from 'styled-components';

export const ShopWrapper = styled.div`
    min-height: 300px;
`;

export const GiftCategoryWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const GiftCategoryText = styled.p`
    margin: 0;
    margin-right: 10px;
    font-size: 18px;
    color: #fff;
`;
