import { action, makeObservable, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { APIResponseList } from '../models/APIResponse';
import { ListMiniGame } from '../models/MiniGame';
import { IGetListMiniGameRequest, IMiniGame } from '../types/IMiniGame';
import { MiniGameService } from '../services/MiniGameService';

class MiniGameStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable listMiniGame: ListMiniGame | null = null;

    @action getListMiniGame = async (
        params: IGetListMiniGameRequest,
        loadMore: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listMiniGame)
            runInAction(() => {
                this.listMiniGame = new ListMiniGame();
            });
        else if (loadMore) {
            this.listMiniGame.pageIndex += 1;
            this.listMiniGame.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listMiniGame.pageIndex
                : this.listMiniGame.pageSize * this.listMiniGame.pageIndex;
        }

        //request api service
        const result = (await MiniGameService.getInstance().getListMiniGame(
            params
        )) as APIResponseList<Array<IMiniGame>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listMiniGame) {
                    this.listMiniGame.list = this.listMiniGame.list?.concat(
                        ListMiniGame.fromJson(result.data?.list!)
                    )!;
                    this.listMiniGame.totalRecord = result.totalRecord();
                    this.listMiniGame.isLoading = false;
                }
            });
        }
        return result;
    };
}

export default MiniGameStore;
