import { observer } from 'mobx-react-lite';
import { ComponentType, useEffect, useRef, useState } from 'react';
import pure from 'recompose/pure';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { animateScroll as scroll, scrollSpy } from 'react-scroll';

import {
    GiftBagActionType,
    IBagReceiveItemRequest,
    IGetListGiftBag,
    IGetListGiftBagType
} from '../../../types/IGiftBag';
import { Col, Modal, Row, Select, Spin } from 'antd';

//components

//styles
import {
    GiftTypeText,
    GiftTypeWrapper,
    LineDivider,
    TitleWrapper
} from './BagView.styles';
import React from 'react';
import Gift from '../../../models/Gift';
import {
    AppCard,
    AppContentContainer,
    AppContentWrapper,
    AppTitle
} from '../../../styles/App.styles';
import { GiftBagItem } from './GiftBagItem/GiftBagItem';
import { FConfirmDialog } from '../../../components/FConfirmDialog/FConfirmDialog';
import GiftBag from '../../../models/GiftBag';
import { GIFT_VALUE_TYPE } from '../../../types/IGift';
import { delay, showMessage } from '../../../utils';

interface Props {}

const { Option } = Select;

export const BagView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const {
        scrollToList,
        listGiftBag,
        listGiftBagType,
        getListGiftBagType,
        getListGiftBag,
        receiveBagItem
    } = store.giftBagStore;
    const { currentUser } = store.authStore;

    const [giftTypeId, setGiftTypeId] = React.useState('');
    const [showDialogConfirm, setShowDialogConfirm] = useState(false);
    const [giftData, setGiftData] = useState<GiftBag>();

    const refList = React.useRef<any>(null);

    useEffect(() => {
        onGetListGiftBagType();
    }, []);

    useEffect(() => {
        autoScroll();
    }, [scrollToList]);

    const autoScroll = async () => {
        scroll.scrollTo(400);
        // if (refList.current) {
        //     await delay(200);
        //     refList.current.scrollIntoView({
        //         behavior: 'smooth',
        //         block: 'start'
        //     });
        // }
    };

    const onGetListGiftBagType = async () => {
        const params: IGetListGiftBagType = {};
        const result = await getListGiftBagType(params);
        if (result && result.isSuccess()) {
            if (result.data?.list && result.data?.list.length > 0)
                setGiftTypeId(result.data?.list[0].type.toString()!);
            onGetListGiftBag(result.data?.list[0].type);
        }
    };

    const onGetListGiftBag = async (giftType: any) => {
        const params: IGetListGiftBag = {
            data: {
                type: giftType
            }
        };
        const result = await getListGiftBag(params);
    };

    const onChangeGiftType = (value: any) => {
        setGiftTypeId(value);
        onGetListGiftBag(value);
    };

    const renderExtra = () => {
        return (
            <>
                {listGiftBagType && listGiftBagType?.list.length > 0 ? (
                    <GiftTypeWrapper>
                        <GiftTypeText>Chọn loại quà </GiftTypeText>
                        <Select
                            defaultValue={giftTypeId}
                            value={giftTypeId}
                            style={{ width: 200 }}
                            onChange={onChangeGiftType}>
                            {listGiftBagType?.list.map((item) => {
                                return (
                                    <Option
                                        key={item.giftTypeId}
                                        value={item.giftTypeId}>
                                        {item.giftTypeName}
                                    </Option>
                                );
                            })}
                        </Select>
                    </GiftTypeWrapper>
                ) : null}
            </>
        );
    };

    const buildDescription = () => {
        const description = `Bạn có chắc muốn nhận quà <b>${giftData?.giftName}</b>?`;
        return description;
    };

    const onConfirm = async () => {
        const params: IBagReceiveItemRequest = {
            data: {
                giftId: giftData?.giftId!,
                action: GiftBagActionType.RECEIVE,
                bagId: giftData?.bagId!
            }
        };
        console.log(params);
        const result: any = await receiveBagItem(params);
        setShowDialogConfirm(false);
        let message = '';
        if (result.isSuccess()) {
            if (result.data.giftType === GIFT_VALUE_TYPE.POINT) {
                message = 'Chúc mừng bạn đã nhận quà thành công.';
                //update point user
                currentUser?.updateTotalPoint(result.data.point, true);
            } else if (result.data.giftType === GIFT_VALUE_TYPE.SPIN) {
                message = 'Chúc mừng bạn đã nhận quà thành công.';
                //update total spin user
                currentUser?.updateTotalSpin(result.data.point, true);
            } else
                message =
                    'Chúc mừng bạn đã nhận quà thành công. Thông tin quà đã được gửi qua email đăng ký của bạn';
        }
        showMessage(result, message);
    };

    const onReceiveGift = async (gift: GiftBag) => {
        setGiftData(gift);
        setShowDialogConfirm(true);
    };

    return (
        <AppContentWrapper>
            <AppContentContainer>
                <TitleWrapper ref={refList}>
                    <LineDivider />
                    <AppTitle>TÚI ĐỒ CỦA BẠN</AppTitle>
                </TitleWrapper>
                {listGiftBag?.isLoading ? <Spin /> : null}
                <Row>
                    {listGiftBag &&
                        listGiftBag.list.map((item) => {
                            return (
                                <Col
                                    key={item.giftId}
                                    xl={8}
                                    md={8}
                                    sm={24}
                                    xs={24}>
                                    <GiftBagItem
                                        onReceiveGift={onReceiveGift}
                                        key={item.giftId}
                                        data={item}
                                    />
                                </Col>
                            );
                        })}
                </Row>
            </AppContentContainer>
            {showDialogConfirm && giftData ? (
                <FConfirmDialog
                    visible={showDialogConfirm}
                    title="Hoàn tất nhận quà"
                    description={buildDescription()}
                    onConfirm={onConfirm}
                    image={giftData.image}
                    onClose={() =>
                        setShowDialogConfirm(false)
                    }></FConfirmDialog>
            ) : null}
        </AppContentWrapper>
    );
});
