import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components
import { UserLogin } from './UserLogin';
//styles
import {
    FuDrawer,
    MenuContainer,
    MenuItem,
    MenuLink
} from './MenuDrawer.styles';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { MAIN_MENU } from '../../../../types/IGlobal';

interface Props {
    open: boolean;
    onClose: () => void;
}

export const MenuDrawer: ComponentType<Props> = pure(({ open, onClose }) => {
    const store = useRootStore();
    const { currentUser, logout } = store.authStore;
    const { indexMainMenu, changeMainMenuIndex } = store.globalStore;

    const onChangeMainMenuIndex = (index: MAIN_MENU) => {
        onClose();
        changeMainMenuIndex(index);
    };

    return (
        <FuDrawer title="" placement="right" onClose={onClose} open={open}>
            <UserLogin />
            <MenuContainer>
                <MenuLink
                    onClick={() => onChangeMainMenuIndex(MAIN_MENU.INTRO)}
                    isActive={indexMainMenu == MAIN_MENU.INTRO ? 'active' : ''}
                    to="/intro">
                    <MenuItem
                        isActive={
                            indexMainMenu == MAIN_MENU.INTRO ? 'active' : ''
                        }>
                        GIỚI THIỆU
                    </MenuItem>
                </MenuLink>
                <MenuLink
                    onClick={() => onChangeMainMenuIndex(MAIN_MENU.MISSION)}
                    isActive={
                        indexMainMenu == MAIN_MENU.MISSION ? 'active' : ''
                    }
                    to="/mission">
                    <MenuItem
                        isActive={
                            indexMainMenu == MAIN_MENU.MISSION ? 'active' : ''
                        }>
                        NHIỆM VỤ
                    </MenuItem>
                </MenuLink>
                <MenuLink
                    onClick={() => onChangeMainMenuIndex(MAIN_MENU.SPIN)}
                    isActive={indexMainMenu == MAIN_MENU.SPIN ? 'active' : ''}
                    to="/spin">
                    <MenuItem
                        isActive={
                            indexMainMenu == MAIN_MENU.SPIN ? 'active' : ''
                        }>
                        VÒNG QUAY MAY MẮN
                    </MenuItem>
                </MenuLink>
                {/* <MenuLink
                    onClick={() => onChangeMainMenuIndex(MAIN_MENU.TOURNAMENT)}
                    isActive={
                        indexMainMenu == MAIN_MENU.TOURNAMENT ? 'active' : ''
                    }
                    to="/tournament">
                    <MenuItem
                        isActive={
                            indexMainMenu == MAIN_MENU.TOURNAMENT
                                ? 'active'
                                : ''
                        }>
                        GIẢI ĐẤU
                    </MenuItem>
                </MenuLink> */}
                <MenuLink
                    onClick={() => onChangeMainMenuIndex(MAIN_MENU.GIFT)}
                    isActive={indexMainMenu == MAIN_MENU.GIFT ? 'active' : ''}
                    to="/gift">
                    <MenuItem
                        isActive={
                            indexMainMenu == MAIN_MENU.GIFT ? 'active' : ''
                        }>
                        ĐỔI QUÀ
                    </MenuItem>
                </MenuLink>

                <MenuLink
                    onClick={() => onChangeMainMenuIndex(MAIN_MENU.CONTACT)}
                    isActive={
                        indexMainMenu == MAIN_MENU.CONTACT ? 'active' : ''
                    }
                    to="/contact">
                    <MenuItem
                        isActive={
                            indexMainMenu == MAIN_MENU.CONTACT ? 'active' : ''
                        }>
                        LIÊN HỆ
                    </MenuItem>
                </MenuLink>

                <MenuLink
                    onClick={() => onChangeMainMenuIndex(MAIN_MENU.PROFILE)}
                    isActive={
                        indexMainMenu == MAIN_MENU.PROFILE ? 'active' : ''
                    }
                    to="/profile">
                    <MenuItem
                        isActive={
                            indexMainMenu == MAIN_MENU.PROFILE ? 'active' : ''
                        }>
                        TRANG CÁ NHÂN
                    </MenuItem>
                </MenuLink>
            </MenuContainer>
        </FuDrawer>
    );
});
