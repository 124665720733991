import { ComponentType, useEffect, useState } from 'react';

//components
import { InstallAppItem } from './InstallAppItem/InstallAppItem';
//styles
import {
    InstallAppHistoryWrapper,
    InstallAppHistoryContainer,
    TotalInstallApp
} from './InstallAppHistory.styles';
import { useRootStore } from '../../../../../providers/RootStoreProvider';
import { observer } from 'mobx-react-lite';
import { PaginationWrapper } from '../SpinHistory/SpinHistory.styles';
import { Pagination, PaginationProps } from 'antd';
import { IInstallApp } from '../../../../../types/IUser';

interface Props {
    data: Array<IInstallApp>;
}

export const InstallAppHistory: ComponentType<Props> = observer(({ data }) => {
    const store = useRootStore();
    const [pageIndex, setPageIndex] = useState(1);

    useEffect(() => {}, []);

    const onChange: PaginationProps['onChange'] = (page, pageSize) => {
        console.log(pageSize);
        setPageIndex(page);
    };

    const renderExtra = () => {
        return <TotalInstallApp></TotalInstallApp>;
    };

    return (
        <InstallAppHistoryWrapper>
            <InstallAppHistoryContainer
                title={`Lịch sử cài đặt app (${data.length})`}
                extra={renderExtra()}>
                {data &&
                    data
                        .filter((item) => item.status === 1)
                        .map((item) => {
                            return (
                                <InstallAppItem data={item} key={item.appId} />
                            );
                        })}
            </InstallAppHistoryContainer>
        </InstallAppHistoryWrapper>
    );
});
