import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../providers/RootStoreProvider';

//components
import { TopHeader } from './TopHeader/TopHeader';
import { ActivityView } from './ActivityView/ActivityView';
import { ProfileInfo } from './ProfileInfo/ProfileInfo';
import { PROFILE_SCREEN } from '../../configs/ScreenType';
import { MAIN_MENU } from '../../types/IGlobal';
import { TokenInvalid } from '../CommonView/TokenInvalid/TokenInvalid';
import { BagView } from './BagView/BagView';
//styles

interface Props {}

export const UserProfile: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { currentUser } = store.authStore;
    const { profileScreen, changeMainMenuIndex } = store.globalStore;

    useEffect(() => {
        changeMainMenuIndex(MAIN_MENU.PROFILE);
    }, []);

    const renderContent = () => {
        switch (profileScreen) {
            case PROFILE_SCREEN.ACTIVITY:
                return renderActivityView();
            case PROFILE_SCREEN.INFO:
                return renderProfileView();
            case PROFILE_SCREEN.BAG:
                return renderBagView();
        }
    };

    const renderProfileView = () => {
        return <ProfileInfo />;
    };

    const renderActivityView = () => {
        return <ActivityView />;
    };

    const renderBagView = () => {
        return <BagView />;
    };

    const checkValidProfile = () => {
        if (!currentUser?.isLogin()) {
            return <TokenInvalid />;
        } else
            return (
                <>
                    <TopHeader />
                    {renderContent()}
                </>
            );
    };

    return <>{checkValidProfile()}</>;
});
