import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
    account: Yup.string().required('Account is required'),
    //.email('Email is invalid'),
    passwd: Yup.string()
        .trim()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters')
        .max(40, 'Password must not exceed 40 characters')
});
