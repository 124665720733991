import { ComponentType, useState } from 'react';
import pure from 'recompose/pure';
import { Checkbox, Modal } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

//styles
import {
    RegisterWrapper,
    RegisterContainer,
    RegisterModal,
    TitleContainer,
    RegisterTitle,
    LogoApp,
    RegisterButton,
    RuleWrapper,
    RuleText,
    InputWrapper,
    ErrorText,
    InputText
} from './Register.styles';
import { AppInputText } from '../../styles/AppInput.styles';
import { AppButton } from '../../styles/AppButton.styles';
import { APP_BUTTON_TYPE, JSON_CONTENT_TYPE } from '../../types/IGlobal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegistrationUserSchema } from '../../validation/RegistrationUserSchema';
import { IRegisterUserInfo, IUserRegisterRequest } from '../../types/IUser';
import { useRootStore } from '../../providers/RootStoreProvider';
import { IResponseBase } from '../../types/ITypeBase';
import { showMessage } from '../../utils';
import { observer } from 'mobx-react-lite';

interface Props {
    visible: boolean;
    refId?: string;
    onClose: () => void;
}

export const Register: ComponentType<Props> = observer(
    ({ visible, refId, onClose }) => {
        const store = useRootStore();
        const { registerUser } = store.userStore;
        const { toggleShowDialogRegister } = store.globalStore;
        const {
            toggleShowDialogLogin,
            setJsonContent,
            toggleShowDialogJsonContent
        } = store.globalStore;

        const {
            register,
            handleSubmit,
            reset,
            resetField,
            formState: { errors }
        } = useForm<IRegisterUserInfo>({
            resolver: yupResolver(RegistrationUserSchema)
        });

        const [acceptTerm, setAcceptTerm] = useState(false);
        const [acceptPolicyData, setAcceptPolicyData] = useState(false);

        const onSubmit = async (data: IRegisterUserInfo) => {
            if (!acceptPolicyData) {
                Modal.error({
                    title: 'Đăng ký không thành công',
                    content:
                        'Vui lòng đồng ý với chính sách bảo vệ dữ liệu cá nhân',
                    centered: true
                });
                return;
            }
            // if (!acceptTerm) {
            //     Modal.error({
            //         title: 'Đăng ký không thành công',
            //         content:
            //             'Vui lòng xác nhận trên 18 tuổi và đồng ý các Điều khoản & Điều kiện',
            //         centered: true
            //     });
            //     return;
            // }
            const params: IUserRegisterRequest = {
                data: {
                    account: data.account,
                    passwd: data.passwd,
                    phone: data.phone,
                    refid: data.refid
                }
            };
            const result: IResponseBase = await registerUser(params);
            const msgSuccess = 'Chúc mừng bạn đã đăng ký tài khoản thành công';
            showMessage(result, msgSuccess);
            if (result.isSuccess()) {
                reset();
                handleCloseDialog();
                //show dialog login...
                toggleShowDialogLogin(true);

                return;
            }
        };

        const handleCloseDialog = () => {
            toggleShowDialogRegister(false);
            onClose();
        };

        const onAcceptTerms = (e: CheckboxChangeEvent) => {
            setAcceptTerm(e.target.checked);
        };

        const onAcceptPolicyData = (e: CheckboxChangeEvent) => {
            setAcceptPolicyData(e.target.checked);
        };

        const onShowPolicy = () => {
            setJsonContent(JSON_CONTENT_TYPE.POLICY);
            toggleShowDialogJsonContent(true);
        };

        return (
            <RegisterWrapper>
                <RegisterContainer>
                    <RegisterModal
                        open={visible}
                        centered
                        onCancel={handleCloseDialog}
                        footer={null}>
                        <TitleContainer>
                            <RegisterTitle>Đăng ký tài khoản</RegisterTitle>
                            <LogoApp src="../../../../images/app_logo_footer.png"></LogoApp>
                        </TitleContainer>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <InputWrapper>
                                <InputText
                                    placeholder="Email..."
                                    {...register('account')}
                                    isError={errors.account ? 'error' : ''}
                                />
                                {errors.account ? (
                                    <ErrorText>
                                        {errors.account.message}
                                    </ErrorText>
                                ) : null}
                            </InputWrapper>
                            <InputWrapper>
                                <InputText
                                    placeholder="Mật khẩu"
                                    type="password"
                                    {...register('passwd')}
                                    isError={errors.passwd ? 'error' : ''}
                                />
                                {errors.passwd ? (
                                    <ErrorText>
                                        {errors.passwd.message}
                                    </ErrorText>
                                ) : null}
                            </InputWrapper>
                            <InputWrapper>
                                <InputText
                                    placeholder="Xác nhận mật khẩu"
                                    type="password"
                                    {...register('confirmPassword')}
                                    isError={
                                        errors.confirmPassword ? 'error' : ''
                                    }
                                />
                                {errors.confirmPassword ? (
                                    <ErrorText>
                                        {errors.confirmPassword.message}
                                    </ErrorText>
                                ) : null}
                            </InputWrapper>
                            <InputWrapper>
                                <InputText
                                    placeholder="Số điện thoại"
                                    {...register('phone')}
                                    isError={errors.phone ? 'error' : ''}
                                />
                                {errors.phone ? (
                                    <ErrorText>
                                        {errors.phone.message}
                                    </ErrorText>
                                ) : null}
                            </InputWrapper>
                            <AppInputText
                                placeholder="Mã giới thiệu"
                                {...register('refid')}
                                readOnly={refId && refId.length > 0}
                                defaultValue={refId}
                            />

                            <RuleWrapper>
                                <Checkbox
                                    onChange={onAcceptPolicyData}
                                    style={{ marginRight: 10 }}></Checkbox>
                                <RuleText
                                    onClick={() => onShowPolicy()}
                                    dangerouslySetInnerHTML={{
                                        __html: "Tôi xác nhận rằng tôi trên 18 tuổi và đồng ý với <a href='#'> Chính Sách bảo vệ dữ liệu cá nhân</a>"
                                    }}></RuleText>
                            </RuleWrapper>

                            {/* <RuleWrapper>
                                <Checkbox onChange={onAcceptTerms}></Checkbox>
                                <RuleText
                                    dangerouslySetInnerHTML={{
                                        __html: "Tôi xác nhận rằng tôi trên 18 tuổi và đồng ý với các <a href='http://news.zing.vn'> Điều khoản & Điều kiện!</a>"
                                    }}></RuleText>
                            </RuleWrapper> */}

                            <RegisterButton
                                type="submit"
                                typeButton={APP_BUTTON_TYPE.PRIMARY}>
                                Đăng ký tài khoản
                            </RegisterButton>
                        </form>
                    </RegisterModal>
                </RegisterContainer>
            </RegisterWrapper>
        );
    }
);
