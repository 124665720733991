import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { PROFILE_ACTIVITY_SCREEN } from '../../../../configs/ScreenType';
import { VisitHistory } from './VisitHistory/VisitHistory';
import { InvitedHistory } from './InvitedHistory/InvitedHistory';
import { observer } from 'mobx-react-lite';
import { VideoHistory } from './VideoHistory/VideoHistory';
import { SpinHistory } from './SpinHistory/SpinHistory';
import { BuyGiftHistory } from './BuyGiftHistory/BuyGiftHistory';
import { InstallAppHistory } from './InstallAppHistory/InstallAppHistory';

interface Props {}

export const RightContent: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { currentUser } = store.authStore;
    const { profileActivityScreen } = store.globalStore;

    const onRenderContent = () => {
        if (profileActivityScreen === PROFILE_ACTIVITY_SCREEN.HISTORY_VISIT)
            return <VisitHistory />;
        else if (
            profileActivityScreen === PROFILE_ACTIVITY_SCREEN.HISTORY_INVITED
        )
            return <InvitedHistory />;
        else if (
            profileActivityScreen === PROFILE_ACTIVITY_SCREEN.HISTORY_VIDEO_VIEW
        )
            return <VideoHistory />;
        else if (profileActivityScreen === PROFILE_ACTIVITY_SCREEN.HISTORY_SPIN)
            return <SpinHistory />;
        else if (
            profileActivityScreen === PROFILE_ACTIVITY_SCREEN.HISTORY_BUY_GIFT
        )
            return <BuyGiftHistory />;
        else if (
            profileActivityScreen ===
            PROFILE_ACTIVITY_SCREEN.HISTORY_INSTALL_APP
        )
            return <InstallAppHistory data={currentUser?.installApp!} />;
    };

    return <>{onRenderContent()}</>;
});
