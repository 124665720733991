import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';
import { HELP_CONTENT } from '../../../types/IGlobal';
import { useRootStore } from '../../../providers/RootStoreProvider';
import {
    IGetInfoHelpRequest,
    IGetInfoIntroRequest,
    IIntro
} from '../../../types/IIntro';
import { APIResponse } from '../../../models/APIResponse';
import { Modal, Spin } from 'antd';

//components

//styles
import {
    HelpDialogContainer,
    HelpDialogTitle,
    HelpDialogTitleWrapper,
    HelpDialogWrapper,
    HelpModal
} from './HelpDialog.styles';
import { observer } from 'mobx-react-lite';
interface Props {}

export const HelpDialog: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { helpType, showDialogHelp, toggleShowDialogHelp } =
        store.globalStore;
    const { helpInfo, getInfoHelp } = store.commonStore;

    useEffect(() => {
        onGetIntroInfo();
    }, []);

    const onGetIntroInfo = async () => {
        const params: IGetInfoHelpRequest = {
            data: {
                type: helpType
            }
        };
        let result: APIResponse<IIntro> = await getInfoHelp(params);
    };

    return (
        <HelpDialogWrapper>
            <HelpModal
                title={helpInfo?.title}
                open={showDialogHelp}
                centered={true}
                onCancel={() => toggleShowDialogHelp(false)}
                width={800}
                footer={null}>
                {!helpInfo ? (
                    <HelpDialogContainer>
                        <Spin />
                    </HelpDialogContainer>
                ) : (
                    <>
                        {/* <HelpDialogTitleWrapper>
                            <HelpDialogTitle>{helpInfo?.title}</HelpDialogTitle>
                        </HelpDialogTitleWrapper> */}

                        <HelpDialogContainer
                            dangerouslySetInnerHTML={{
                                __html: helpInfo?.content!
                            }}></HelpDialogContainer>
                    </>
                )}
            </HelpModal>
        </HelpDialogWrapper>
    );
});
