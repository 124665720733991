import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import { IGetListMiniGameRequest } from '../types/IMiniGame';
import BaseAuthService from './BaseAuthService';

export class MiniGameService extends BaseAuthService {
    private static instance: MiniGameService;

    public static getInstance(): MiniGameService {
        if (!MiniGameService.instance) {
            MiniGameService.instance = new MiniGameService();
        }
        return MiniGameService.instance;
    }

    getListMiniGame = async (params: IGetListMiniGameRequest) => {
        try {
            const response = await this.postData(API.MINIGAME.LIST, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}
