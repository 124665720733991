import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';

export const GameContainer = styled.div`
    @media ${device.mobileS} {
        margin-left: 20px;
        margin-right: 20px;
    }
    @media ${device.laptop} {
        width: 1100px;
        margin: 0 auto;
    }
`;

export const GameWrapper = styled.div`
    width: 100%;
    min-height: 600px;

    @media ${device.mobileS} {
        padding-top: 0px;
        background-color: #19181c;
        padding-top: 42px;
    }
    @media ${device.laptop} {
        background-image: url('../../../images/minigame_bg.png');
        background-color: #19181c;
        background-position: center;
        background-size: cover;
    }
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ViewMoreWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media ${device.mobileS} {
        margin-top: 0px;
    }
    @media ${device.laptop} {
        margin-top: 0px;
        margin-left: 20px;
        height: 100%;
    }
`;

export const ViewMoreTitle = styled.h1`
    color: #fff;
    margin: 0;
    margin-right: 10px;
    cursor: pointer;
    @media ${device.mobileS} {
        font-size: 14px;
        font-weight: 700;
    }
    @media ${device.laptop} {
        font-size: 16px;
        font-weight: 700;
    }
    :hover {
        color: #ff2423;
    }
`;

export const ViewMoreArrow = styled.div`
    @media ${device.mobileS} {
        width: 12px;
        height: 16px;
    }
    @media ${device.laptop} {
        width: 14px;
        height: 19px;
    }
`;
