import { ComponentType } from 'react';
import pure from 'recompose/pure';
//styles
import {
    ItemWrapper,
    ItemContainer,
    ItemImageWrapper,
    ItemImage,
    CheckImageWrapper,
    TextCount
} from './MonthlyItem.styles';
import { CheckIcon } from '../../../../components/SvgIcon';
import { observer } from 'mobx-react-lite';

interface Props {
    id: number;
    total: number;
}

export const MonthlyItem: ComponentType<Props> = observer(({ id, total }) => {
    const inactive: boolean = id <= total;

    const renderImage = () => {
        return (
            <ItemImage
                showBorder={inactive ? 'inactive' : 'active'}
                src={`../../../../images/visit/${id}.jpg`}></ItemImage>
        );
    };

    return (
        <ItemWrapper>
            <ItemContainer>
                <TextCount>{id} Ngày</TextCount>
                <ItemImageWrapper>{renderImage()}</ItemImageWrapper>
                {inactive ? (
                    <CheckImageWrapper>
                        <CheckIcon />
                    </CheckImageWrapper>
                ) : null}
            </ItemContainer>
        </ItemWrapper>
    );
});
