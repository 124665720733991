import { Modal } from 'antd';
import styled from 'styled-components';
import { AppButton } from '../../styles/AppButton.styles';

export const CropImageWrapper = styled.div``;

export const CropImageModal = styled(Modal)`
    .ant-modal-header {
        background-color: red;
        border: 0 !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .ant-modal-title {
        color: #fff !important;
        font-weight: 700 !important;
    }
    .ant-modal-close-x {
        color: #fff !important;
        font-weight: 700 !important;
    }
    .ant-modal-body {
        margin: 0 !important;
        padding: 0 !important;
        //height: 400px !important;
    }
`;

export const CropWrapper = styled.div`
    position: relative;
    width: '100%';
    height: 400px;
    background-color: #333;
`;

export const ActionWrapper = styled.div`
    display: flex;
    justify-content: end;
    padding: 20px;
    background-color: #333;
`;

export const PreviewImage = styled.img`
    width: 200px;
    height: 200px;
    object-fit: contain;
`;

export const OKButton = styled(AppButton)`
    height: 40px;
    width: 150px;
`;
