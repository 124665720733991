import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';

export const ItemWrapper = styled.div`
    @media ${device.mobileS} {
        padding: 30px;
    }
    @media ${device.laptop} {
        padding: 30px;
        padding-bottom: 50px;
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ItemImageWrapper = styled.div`
    position: relative;
    @media ${device.mobileS} {
        width: 100%;
        height: 100%;
    }
    @media ${device.laptop} {
        width: 100%;
        height: 100%;
    }
`;

export const CheckImageWrapper = styled.div`
    position: absolute;
    top: 70px;
    @media ${device.mobileS} {
        width: 50%;
        height: 50%;
    }
    @media ${device.laptop} {
        width: 50%;
        height: 50%;
    }
`;

export const TextCount = styled.p`
    margin: 0;
    font-weight: 700;
    color: #fff;
    margin-bottom: 10px;

    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
        font-size: 16px;
    }
`;

interface ItemImageProps {
    showBorder: boolean;
}

export const ItemImage = styled.img.attrs((props: ItemImageProps) => ({
    className: props.showBorder
}))`
    object-fit: cover;
    border-radius: 50%;

    &.active {
        border: solid 3px #8b8b8b;
        filter: grayscale(100%);
    }

    &.inactive {
        border: solid 3px red;
    }

    @media ${device.mobileS} {
        width: 100%;
        height: 100%;
    }
    @media ${device.laptop} {
        width: 100%;
        height: 100%;
    }
`;
