import styled from 'styled-components';
import { AppCard } from '../../styles/App.styles';

export const ResetPassContainer = styled(AppCard)`
    .ant-card-body {
        padding: 20px;
    }
`;

export const ContentTitle = styled.h2`
    font-weight: 700;
    color: #fff;
    font-size: 18px;
`;

export const ContentDescription = styled.h2`
    font-weight: 500;
    color: #fff;
    font-size: 16px;
`;
