import { Route, Routes, Navigate } from 'react-router-dom';

//import views
import User from '../views/User/User';
import { Home } from '../views/Home/Home';
import { DailyAttendance } from '../views/DailyAttendance/DailyAttendance';
import { ContactView } from '../views/ContactView/ContactView';
import { IntroView } from '../views/IntroView/IntroView';
import { SpinView } from '../views/SpinView/SpinView';
import { MiniGameView } from '../views/MiniGameView/MiniGameView';
import { UserProfile } from '../views/UserProfile/UserProfile';
import { InvitedView } from '../views/InvitedView/InvitedView';
import { VideoView } from '../views/VideoView/VideoView';
import { GiftExchange } from '../views/GiftExchange/GiftExchange';
import { ErrorView } from '../views/CommonView/CommonView';
import { MissionView } from '../views/MissionView/MissionView';
import { TournamentView } from '../views/TournamentView/TournamentView';
import { Maintenance } from '../views/Maintenance/Maintenance';
import { ResetPass } from '../views/ResetPass/ResetPass';
import { InstallApp } from '../views/InstallAppMission/InstallApp';

//import layouts
import MainLayout from '../layouts/MainLayout/MainLayout';

//protected router with private resources must be login
import ProtectedRoute from '../components/ProtectedRoute';
import ScrollToTop from '../components/ScrollTop';

const AppRouters = () => {
    return (
        <ScrollToTop>
            <Routes>
                <Route element={<MainLayout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Home />} />
                    <Route path="/visit" element={<DailyAttendance />} />
                    <Route path="/contact" element={<ContactView />} />
                    <Route path="/intro" element={<IntroView />} />
                    <Route path="/game" element={<MiniGameView />} />
                    <Route path="/profile" element={<UserProfile />} />
                    <Route path="/invite-friend" element={<InvitedView />} />
                    <Route path="/spin" element={<SpinView />} />
                    <Route path="/video" element={<VideoView />} />
                    <Route path="/error" element={<ErrorView />} />
                    <Route path="/gift" element={<GiftExchange />} />
                    <Route path="/mission" element={<MissionView />} />
                    <Route path="/tournament" element={<TournamentView />} />
                    <Route path="/reset-pass" element={<ResetPass />} />
                    <Route path="/install-app" element={<InstallApp />} />
                </Route>
                <Route path="/maintenance" element={<Maintenance />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </ScrollToTop>
    );

    return (
        <MainLayout>
            <Routes>
                <Route
                    path="/book"
                    element={
                        <ProtectedRoute
                            isAuthenticated={false}
                            authenticationPath={'/login'}
                        />
                    }
                />
            </Routes>
        </MainLayout>
    );
};

export default AppRouters;
