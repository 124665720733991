import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import { IGetListMatchRequest } from '../types/IMatch';
import { IGetListTournamentRequest } from '../types/ITournament';
import BaseAuthService from './BaseAuthService';

export class TournamentService extends BaseAuthService {
    private static instance: TournamentService;

    public static getInstance(): TournamentService {
        if (!TournamentService.instance) {
            TournamentService.instance = new TournamentService();
        }
        return TournamentService.instance;
    }

    getListTournament = async (params: IGetListTournamentRequest) => {
        try {
            const response = await this.postData(API.TOURNAMENT.LIST, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListMatch = async (params: IGetListMatchRequest) => {
        try {
            const response = await this.postData(API.MATCH.LIST, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}
