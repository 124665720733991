import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRouters from './configs/AppRouters';
import { useRootStore } from './providers/RootStoreProvider';
import { LOCAL_KEY } from './configs/AppKey';

//css import
import 'antd/dist/antd.min.css';
import './styles/AntdDesign.css';
import './styles/GlobalStyle.css';
import GlobalStyle from './styles/GlobalStyle';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { TopBoardContainer, TopBoardImage } from './views/Home/Home.styles';
import { LeaderboardDrawer } from './views/Home/LeaderboardDrawer/LeaderboardDrawer';

const App: React.FC = () => {
    const store = useRootStore();
    const { changeLanguage } = store.globalStore;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        onInitAppLanguage();
    }, []);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    //init default app language
    const onInitAppLanguage = () => {
        const key = localStorage.getItem(LOCAL_KEY.USER_LANGUAGE);
        if (key && key == 'en') changeLanguage(key);
        else changeLanguage('vi');
    };

    //disable console logging when build production
    if (process.env.NODE_ENV === 'production') {
        console.log = () => {};
        console.error = () => {};
        console.debug = () => {};
    }

    return (
        <BrowserRouter>
            <GlobalStyle />
            <AppRouters />
            {/* <TopBoardContainer onClick={() => showDrawer()}>
                <TopBoardImage src="../../../../images/top_bxh.png" />
            </TopBoardContainer>
            <LeaderboardDrawer open={open} onClose={() => onClose()} /> */}
        </BrowserRouter>
    );
};

export default App;
