import { IGift } from '../types/IGift';
import { DataList } from './DataList';

export default class Gift {
    public catId: number;
    public giftId: string;
    public giftName: string;
    public image: string;
    public giftCode: string;
    public point: number;
    public totalBuy: number;
    public order: number;

    constructor(
        catId: number,
        giftId: string,
        giftName: string,
        image: string,
        giftCode: string,
        point: number,
        totalBuy: number,
        order: number
    ) {
        this.catId = catId;
        this.giftId = giftId;
        this.giftName = giftName;
        this.image = image;
        this.giftCode = giftCode;
        this.point = point;
        this.totalBuy = totalBuy;
        this.order = order;
    }

    static fromJson = (json: IGift): Gift => {
        return new Gift(
            json.catId,
            json.giftId,
            json.giftName,
            json.giftImg,
            json.code,
            json.point,
            json.totalBuy,
            json.order
        );
    };
}

export class ListGift extends DataList<Gift> {
    static fromJson = (jsonArray: Array<IGift>): Array<Gift> => {
        if (!jsonArray) return [];
        let data = new Array<Gift>();
        jsonArray.forEach((item) => {
            data.push(Gift.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
