import { Card } from 'antd';
import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';

export const LeftMenuWrapper = styled.div``;

export const LeftMenuContainer = styled(Card)`
    background-color: #282829;
    border-color: #3a3b3d;
    color: #fff;

    .ant-card-head {
        border-bottom: 3px solid #3a3b3d;
        font-weight: 700;
        font-size: 20px;
        color: #ff2423;
    }

    ant-card-body {
        padding: 0;
        margin: 0;
    }
`;

export const MenuItemWrapper = styled.div`
    display: flex;
    cursor: pointer;
    margin: 20px;
    :not(:last-child) {
        border-bottom: 2px solid #3a3b3d;
    }
`;

interface MenuItemProps {
    isActive: boolean;
}

export const MenuItemText = styled.p.attrs((props: MenuItemProps) => ({
    className: props.isActive
}))`
    &.active {
        color: #ff2423;
    }

    @media ${device.mobileS} {
        font-weight: 700;
        font-size: 18px;
    }
    @media ${device.laptop} {
        font-weight: 700;
        font-size: 18px;
    }
`;
