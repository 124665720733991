import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    LeftMenuWrapper,
    LeftMenuContainer,
    MenuItemWrapper,
    MenuItemText
} from './LeftMenu.styles';
import { UserAddOutlined } from '@ant-design/icons';
import { PROFILE_INFO_SCREEN } from '../../../../configs/ScreenType';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../../providers/RootStoreProvider';

interface Props {
    onChangeMenuItem: (item: PROFILE_INFO_SCREEN) => void;
}

export const LeftMenu: ComponentType<Props> = observer(
    ({ onChangeMenuItem }) => {
        const store = useRootStore();
        const { currentUser } = store.authStore;
        const { profileInfoScreen } = store.globalStore;
        return (
            <LeftMenuWrapper>
                <LeftMenuContainer title={`User ID : ${currentUser?.refId}`}>
                    <MenuItemWrapper
                        onClick={() =>
                            onChangeMenuItem(PROFILE_INFO_SCREEN.INFO)
                        }>
                        <MenuItemText
                            isActive={
                                profileInfoScreen === PROFILE_INFO_SCREEN.INFO
                                    ? 'active'
                                    : ''
                            }>
                            Thông tin cá nhân
                        </MenuItemText>
                    </MenuItemWrapper>
                    <MenuItemWrapper
                        onClick={() =>
                            onChangeMenuItem(PROFILE_INFO_SCREEN.VERIFY)
                        }>
                        <MenuItemText
                            isActive={
                                profileInfoScreen === PROFILE_INFO_SCREEN.VERIFY
                                    ? 'active'
                                    : ''
                            }>
                            Xác thực tài khoản
                        </MenuItemText>
                    </MenuItemWrapper>
                    <MenuItemWrapper
                        onClick={() =>
                            onChangeMenuItem(
                                PROFILE_INFO_SCREEN.CHANGE_PASSWORD
                            )
                        }>
                        <MenuItemText
                            isActive={
                                profileInfoScreen ===
                                PROFILE_INFO_SCREEN.CHANGE_PASSWORD
                                    ? 'active'
                                    : ''
                            }>
                            Thay đổi mật khẩu
                        </MenuItemText>
                    </MenuItemWrapper>
                </LeftMenuContainer>
            </LeftMenuWrapper>
        );
    }
);
