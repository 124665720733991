import styled from 'styled-components';

export const SpinItemWrapper = styled.div`
    :not(:last-child) {
        border-bottom: 1px solid #d9d9d9;
    }
    padding: 10px;
`;

export const SpinItemContainer = styled.div`
    display: flex;
`;

export const ItemIConWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const VisitTitle = styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
`;

export const WinDescription = styled.p`
    margin: 0;
    color: #73daff;
    font-size: 14px;
    font-weight: 400;
`;

export const VisitDescription = styled.p`
    margin: 0;
    color: #d9d9d9;
    font-size: 12px;
    font-weight: 400;
`;
