import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    BuyGiftItemWrapper,
    BuyGiftItemContainer,
    InfoContainer,
    VisitTitle,
    VisitDescription,
    ItemIConWrapper,
    ResultWrapper,
    VerifyProcess,
    ResultText
} from './BuyGiftItem.styles';
import { UserHistoryBuyGift } from '../../../../../../models/UserHistory';
import { RightSquareOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatNumber } from '../../../../../../utils/FormatUtils';

interface Props {
    data: UserHistoryBuyGift;
}

export const BuyGiftItem: ComponentType<Props> = pure(({ data }) => {
    return (
        <BuyGiftItemWrapper>
            <BuyGiftItemContainer>
                <ItemIConWrapper>
                    <RightSquareOutlined
                        style={{ fontSize: 30, color: 'red' }}
                    />
                </ItemIConWrapper>
                <InfoContainer>
                    <VisitTitle>{data.giftName}</VisitTitle>
                    <VisitDescription>
                        Ngày mua:{' '}
                        {moment(data.date).format('MM/DD/YYYY HH:mm:ss')}
                    </VisitDescription>
                    <VisitDescription>
                        Điểm: {formatNumber(data.point)}
                    </VisitDescription>
                    {data.info && data.info.length > 0 ? (
                        <ResultWrapper>
                            {/* <VerifyProcess
                                type="circle"
                                percent={100}
                                size="small"></VerifyProcess> */}
                            <ResultText>{data.info}</ResultText>
                        </ResultWrapper>
                    ) : null}
                </InfoContainer>
            </BuyGiftItemContainer>
        </BuyGiftItemWrapper>
    );
});
