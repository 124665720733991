import GlobalStore from './GlobalStore';
import UserStore from './UserStore';
import AuthStore from './AuthStore';
import JSonStore from './JSonStore';
import CommonStore from './CommonStore';
import TournamentStore from './TournamentStore';
import VideoStore from './VideoStore';
import MiniGameStore from './MiniGameStore';
import GiftStore from './GiftStore';
import MissionStore from './MissionStore';
import HistoryStore from './HistoryStore';
import GiftBagStore from './GiftBagStore';
class RootStore {
    globalStore: GlobalStore;
    userStore: UserStore;
    authStore: AuthStore;
    jsonStore: JSonStore;
    commonStore: CommonStore;
    tournamentStore: TournamentStore;
    videoStore: VideoStore;
    miniGameStore: MiniGameStore;
    giftStore: GiftStore;
    missionStore: MissionStore;
    historyStore: HistoryStore;
    giftBagStore: GiftBagStore;

    constructor() {
        this.globalStore = new GlobalStore(this);
        this.userStore = new UserStore(this);
        this.authStore = new AuthStore(this);
        this.jsonStore = new JSonStore(this);
        this.commonStore = new CommonStore(this);
        this.tournamentStore = new TournamentStore(this);
        this.videoStore = new VideoStore(this);
        this.miniGameStore = new MiniGameStore(this);
        this.giftStore = new GiftStore(this);
        this.missionStore = new MissionStore(this);
        this.historyStore = new HistoryStore(this);
        this.giftBagStore = new GiftBagStore(this);
    }
}
export default RootStore;
