import { IRequestBase } from './ITypeBase';

export enum MISSION_CONTENT_KEY {
    QR_ANDROID = '[QR_ANDROID]',
    URL_ANDROID = '[URL_ANDROID]',
    QR_IOS = '[QR_IOS]',
    URL_IOS = '[URL_IOS]'
}
export interface IMission {
    mid: string;
    mission: string;
    img: string;
    desc: string;
    point: number;
    url: string;
    appId: number;
}

export interface IGetListMissionRequest extends IRequestBase {}

export interface IInstallAppFieldDynamic {
    id: number;
    value: string;
}
export interface IInstallAppJoinRequest extends IRequestBase {
    data: {
        extra: Array<IInstallAppFieldDynamic>;
    };
}
