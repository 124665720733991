import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import {
    IGetListBannerRequest,
    IJoinInstallAppMissionRequest
} from '../types/IBanner';
import BaseAuthService from './BaseAuthService';

export class MissionService extends BaseAuthService {
    private static instance: MissionService;

    public static getInstance(): MissionService {
        if (!MissionService.instance) {
            MissionService.instance = new MissionService();
        }
        return MissionService.instance;
    }

    getListMission = async (params: IGetListBannerRequest) => {
        try {
            const response = await this.postData(API.MISSION.LIST, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    joinInstallApp = async (params: IJoinInstallAppMissionRequest) => {
        try {
            const response = await this.postData(
                API.MISSION.JOIN_INSTALL_APP,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}
