import * as Yup from 'yup';

export const ChangePassSchema = Yup.object().shape({
    oldPass: Yup.string()
        .trim()
        .required('Mật khẩu cũ không được trống')
        .min(6, 'Mật khẩu cũ phải có ít nhất 6 ký tự')
        .max(40, 'Mật khẩu cũ không được vượt quá 40 ký tự'),
    newPass: Yup.string()
        .trim()
        .required('Mật khẩu mới không được trống')
        .min(6, 'Mật khẩu mới phải có ít nhất 6 ký tự')
        .max(40, 'Mật khẩu mới không được vượt quá 40 ký tự'),
    confirmPassword: Yup.string()
        .trim()
        .required('Xác nhận mật khẩu không được trống')
        .oneOf([Yup.ref('newPass'), null], 'Xác nhận mật khẩu không trùng khớp')
});
