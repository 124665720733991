import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';
import { animateScroll as scroll, scrollSpy } from 'react-scroll';
//components
import { LeftMenu } from './LeftMenu/LeftMenu';
import { RightContent } from './RightContent/RightContent';
//styles
import {
    AppContentWrapper,
    AppContentContainer,
    AppTitle
} from '../../../styles/App.styles';
import { TitleWrapper, LineDivider } from './ActivityView.styles';
import { Col, Row } from 'antd';
import { PROFILE_ACTIVITY_SCREEN } from '../../../configs/ScreenType';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { delay } from '../../../utils';

interface Props {}

export const ActivityView: ComponentType<Props> = pure(({}) => {
    const store = useRootStore();
    const { profileInfoScreen, changeProfileActivityScreen } =
        store.globalStore;

    useEffect(() => {
        autoScroll();
    }, []);

    const autoScroll = async () => {
        await delay(200);
        scroll.scrollTo(400);
    };

    const onChangeMenuProfileActivity = (menu: PROFILE_ACTIVITY_SCREEN) => {
        changeProfileActivityScreen(menu);
    };

    return (
        <AppContentWrapper>
            <AppContentContainer>
                <TitleWrapper>
                    <LineDivider />
                    <AppTitle>LỊCH SỬ HOẠT ĐỘNG</AppTitle>
                </TitleWrapper>

                <Row>
                    <Col
                        xl={7}
                        md={7}
                        sm={24}
                        xs={24}
                        style={{ marginRight: 40 }}>
                        <LeftMenu
                            onChangeMenuItem={onChangeMenuProfileActivity}
                        />
                    </Col>
                    <Col xl={16} md={16} sm={24} xs={24}>
                        <RightContent />
                    </Col>
                </Row>
            </AppContentContainer>
        </AppContentWrapper>
    );
});
