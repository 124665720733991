import styled from 'styled-components';
import { AppCard } from '../../../../../styles/App.styles';

export const SpinHistoryWrapper = styled.div``;

export const SpinHistoryContainer = styled(AppCard)`
    .ant-card-body {
        padding: 20px;
    }
`;

export const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
`;
