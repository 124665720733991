import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';

export const MissionContentWrapper = styled.div``;

export const MissionContentContainer = styled.div`
    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptop} {
        width: 1100px;
        margin: 0 auto;
    }
`;

export const MissionText = styled.p`
    margin: 0;
    color: #fff;
    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
        font-size: 16px;
        font-weight: 400;
    }
`;

export const FormWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const FormContainer = styled.div`
    width: 1100px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const RuleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const RuleText = styled.p`
    color: #fff;
    margin-left: 10px;
`;

export const FormTitle = styled.p`
    color: #fff;
    margin: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: left;
`;

export const FormItem = styled.div`
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
`;

export const FormLabel = styled.p`
    margin: 0;
    margin-right: 10px;
    color: #fff;
    min-width: 100px;
    text-align: left;
`;

export const InputContainer = styled.div`
    /* display: flex;
    max-width: 500px;
    flex-direction: column;
    justify-content: start; */
`;

interface InputWrapperProps {
    isBorder: boolean;
}
export const InputWrapper = styled.div.attrs((props: InputWrapperProps) => ({
    className: props.isBorder
}))`
    background-color: #000;
    &.bordered {
        border: solid 1px #cfc7c6;
        border-radius: 10px;
        padding: 30px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
`;
