import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';

export const ListMatchWrapper = styled.div`
    min-height: 200px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @media ${device.mobileS} {
        flex-direction: column;
    }
    @media ${device.laptop} {
        flex-direction: row;
    }
`;

export const MatchStatusWrapper = styled.div`
    display: flex;
    @media ${device.mobileS} {
        justify-content: start;
    }
    @media ${device.laptop} {
        justify-content: end;
    }
`;

export const StatusItemWrapper = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    :not(:last-child) {
        border-right: solid 1px #eee;
    }
    cursor: pointer;
`;

export const StatusItemTitle = styled.p`
    color: #fff;
    padding: 0;
    margin: 0;
    cursor: pointer;
    @media ${device.mobileS} {
        font-size: 16px;
        font-weight: 500;
    }
    @media ${device.laptop} {
        font-size: 18px;
        font-weight: 500;
    }

    :hover {
        color: #ff2423;
    }
`;
