import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';

export const TourTopWrapper = styled.div`
    background-color: transparent;
`;

export const TourTopContainer = styled.div`
    //width: 1100px;
    margin: 0 auto;
`;

export const TitleWrapper = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.mobileS} {
        width: 100%;
        padding: 10px;
    }

    @media ${device.tablet} {
        width: 1100px;
        padding: 20px;
    }
`;

export const TitleText = styled.h1`
    font-weight: 700;
    color: #fff;
    text-align: center;
    @media ${device.mobileS} {
        font-size: 32px;
    }

    @media ${device.tablet} {
        font-size: 40px;
    }
`;
