import styled from 'styled-components';
import { device } from '../../configs/MediaQuery';

interface SocialIconItemProps {
    isHeader: boolean;
    isFooter: boolean;
}

export const SocialIconWrapper = styled.div.attrs(
    (props: SocialIconItemProps) => ({
        isHeader: props.isHeader,
        isFooter: props.isFooter
    })
)`
    display: flex;
    width: 100%;
    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
        padding-top: ${(props) => (props.isHeader ? '0px' : '40px')};
    }
`;

export const SocialIconContainer = styled.div.attrs(
    (props: SocialIconItemProps) => ({
        isHeader: props.isHeader,
        isFooter: props.isFooter
    })
)`
    display: flex;
    flex-direction: row;

    @media ${device.mobileS} {
        padding-left: 24px;
    }
    @media ${device.laptop} {
        padding-right: 21px;
        padding-left: 0px;
        margin: ${(props) => (props.isHeader ? 'left' : '0 auto')};
    }
`;

export const SocialIconItem = styled.div.attrs(
    (props: SocialIconItemProps) => ({
        isHeader: props.isHeader,
        isFooter: props.isFooter
    })
)`
    @media ${device.mobileS} {
        width: 28px;
        margin-right: ${(props) => (props.isFooter ? '30px' : '30px')};
    }
    @media ${device.laptop} {
        width: 22px;
        margin-right: ${(props) => (props.isFooter ? '21px' : '30px')};
    }
`;
