import styled from 'styled-components';
import { Col } from 'antd';
import { device } from '../../../configs/MediaQuery';
import { AppButton } from '../../../styles/AppButton.styles';
import TextTruncate from 'react-text-truncate';

export const VideoItemWrapper = styled(Col)`
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 20px;
    min-height: 280px;
    cursor: pointer;
    @media ${device.mobileS} {
        padding-bottom: 25px;
        padding-left: 20px;
    }
    @media ${device.laptop} {
        //padding-bottom: 35px;
        padding-left: 0px;
    }

    :not(:last-child) {
        //padding-right: 30px;
    }
`;

export const VideoItemContainer = styled.div`
    position: relative;
`;

export const InfoContainer = styled.div`
    position: absolute;
    height: 120px;
    width: 100%;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    opacity: 0.9;
    bottom: 0;
    left: 0;
    background-color: #000;
    justify-content: center;
    display: flex;
    flex-direction: column;
`;

export const VideoNameWrapper = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
    //background-color: #000;
`;

export const VideoName = styled(TextTruncate)`
    color: #fff;
    margin: 0;
    font-weight: 700;

    @media ${device.mobileS} {
        font-size: 14px;
    }

    @media ${device.tablet} {
        font-size: 16px;
    }
`;

export const VideoDesc = styled.h1`
    color: #fff;
    margin: 0;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    @media ${device.mobileS} {
        font-size: 12px;
    }

    @media ${device.tablet} {
        font-size: 14px;
    }
`;

export const VideoBannerWrapper = styled.div`
    padding: 0;
    min-height: 280px;
    @media ${device.mobileS} {
        max-width: 100%;
    }
    @media ${device.laptop} {
        max-width: 100%;
    }
`;

export const VideoBannerImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 260px;
`;

export const FooterWrapper = styled.div`
    display: flex;
    height: 50px;
    justify-content: space-between;
    padding-right: 10px;
    //padding-bottom: 10px;
`;

export const PointWrapper = styled.div``;

export const PointText = styled.p`
    margin: 0;
`;

export const PointVal = styled.p`
    margin: 0;
    font-size: 14px;
    color: #fc1c03;
    font-weight: bold;
`;

export const ViewButton = styled(AppButton)`
    height: 38px;
    min-width: 100px;
`;
