import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';
import { Row } from 'antd';

export const MatchWrapper = styled.div`
    width: 100%;
`;

export const MatchContainer = styled(Row)`
    width: 100%;
    background-color: #120000;
    border-bottom: solid 1px #707070;
    cursor: pointer;
    :hover {
        background-image: linear-gradient(to right, #420000, #7c0100, #b10100);
    }
    @media ${device.mobileS} {
        height: 310px;
    }
    @media ${device.laptop} {
        height: 200px;
    }
`;

export const TeamWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media ${device.mobileS} {
        //height: 310px;
        margin-top: 16px;
    }
    @media ${device.laptop} {
        margin-top: 0px;
        height: 200px;
    }
`;

export const TeamContainer = styled.div`
    background-color: #1a0000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${device.mobileS} {
        width: 111px;
        height: 151px;
    }
    @media ${device.laptop} {
        width: 120px;
        height: 165px;
    }
`;

export const LogoContainer = styled.div`
    @media ${device.mobileS} {
        max-width: 77px;
        max-height: 77px;
    }
    @media ${device.laptop} {
        min-width: 84px;
        min-height: 84px;
    }
`;

export const LogoImage = styled.img`
    object-fit: contain;
    width: 84px;
    height: 84px;
`;

export const TeamName = styled.p`
    color: #fff;
    margin: 0;
    display: block;

    @media ${device.mobileS} {
        font-size: 16px;
        font-weight: 700;
    }
    @media ${device.laptop} {
        font-size: 20px;
        font-weight: 700;
        padding-top: 20px;
    }
`;

export const VsIconWrapper = styled.div`
    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
    }
`;

export const VsIconImage = styled.img`
    @media ${device.mobileS} {
        width: 43px;
    }
    @media ${device.laptop} {
        width: 48px;
    }
`;

export const MatchInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.mobileS} {
        align-items: center;
        margin-top: 20px;
    }
    @media ${device.laptop} {
        margin-top: 0px;
        margin-left: 20px;
        align-items: start;
        height: 100%;
    }
`;

export const MatchTitle = styled.h1`
    color: #fff;
    margin: 0;
    @media ${device.mobileS} {
        font-size: 16px;
        font-weight: 700;
    }
    @media ${device.laptop} {
        font-size: 20px;
        font-weight: 700;
        padding-bottom: 10px;
    }
`;

export const MatchTime = styled.h2`
    color: #fff;
    margin: 0;
    @media ${device.mobileS} {
        font-size: 14px;
        font-weight: 500;
    }
    @media ${device.laptop} {
        font-size: 16px;
        font-weight: 500;
    }
`;

export const ViewMoreWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media ${device.mobileS} {
        margin-top: 0px;
    }
    @media ${device.laptop} {
        margin-top: 0px;
        margin-left: 20px;
        height: 100%;
    }
`;

export const ViewMoreTitle = styled.h1`
    color: #fff;
    margin: 0;
    margin-right: 10px;
    @media ${device.mobileS} {
        font-size: 14px;
        font-weight: 700;
    }
    @media ${device.laptop} {
        font-size: 16px;
        font-weight: 700;
    }
`;

export const ViewMoreArrow = styled.div`
    @media ${device.mobileS} {
        width: 12px;
        height: 16px;
    }
    @media ${device.laptop} {
        width: 14px;
        height: 19px;
    }
`;
