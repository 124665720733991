import { ComponentType, useEffect, useState } from 'react';
import pure from 'recompose/pure';

//components
import { GiftNew } from './GiftNew/GiftNew';
import { GiftTop } from './GiftTop/GiftTop';
import { ShopView } from './ShopView/ShopView';
import { FConfirmDialog } from '../../components/FConfirmDialog/FConfirmDialog';
//styles
import {
    GiftExchangeContent,
    GiftExchangeWrapper
} from './GiftExchange.styles';
import { Col } from 'antd';
import { AppContentContainer } from '../../styles/App.styles';
import { useRootStore } from '../../providers/RootStoreProvider';
import { observer } from 'mobx-react-lite';
import { MAIN_MENU } from '../../types/IGlobal';
import { GiftCategoryView } from './GiftCategoryView/GiftCategoryView';

interface Props {}

export const GiftExchange: ComponentType<Props> = pure(({}) => {
    const store = useRootStore();
    const { changeMainMenuIndex } = store.globalStore;

    useEffect(() => {
        changeMainMenuIndex(MAIN_MENU.GIFT);
    }, []);

    return (
        <GiftExchangeWrapper>
            <GiftNew />

            <AppContentContainer>
                <GiftExchangeContent>
                    <Col
                        xl={16}
                        md={16}
                        sm={24}
                        xs={24}
                        style={{ marginRight: 30 }}>
                        <ShopView></ShopView>
                    </Col>
                    <Col xl={7} md={7} sm={24} xs={24}>
                        {/* <GiftTop></GiftTop> */}
                        <GiftCategoryView />
                    </Col>
                </GiftExchangeContent>
            </AppContentContainer>
        </GiftExchangeWrapper>
    );
});
