import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFlip, EffectFade, Navigation, Pagination } from 'swiper';
import { Row, Col, Spin } from 'antd';
//styles
import {
    BannerWrapper,
    BannerContainer,
    SwiperSlideImage,
    TopBannerWrapper,
    TopBannerContainer,
    TopBannerItem,
    TopLeftBannerItem,
    TopRightBannerItem,
    TopMidBannerItem
} from './BannerView.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { BANNER_TYPE } from '../../../types/IBanner';
import Banner from '../../../models/Banner';
import { Link } from 'react-router-dom';

interface Props {}

export const BannerView: ComponentType<Props> = pure(
    observer(({}) => {
        const store = useRootStore();
        const { listBanner } = store.commonStore;

        const onRenderLargeBanner = (banner: Banner) => {
            if (banner.isLinkExternal())
                return (
                    <a href={banner.url} target="_blank">
                        <SwiperSlideImage src={banner.image} />
                    </a>
                );
            else {
                return (
                    <Link to={banner.url}>
                        <SwiperSlideImage src={banner.image} />
                    </Link>
                );
            }
        };

        const onRenderSmallBanner = (banner: Banner) => {
            if (banner.order == 2) {
                if (banner.isLinkExternal())
                    return (
                        <a href={banner.url} target="_blank">
                            <TopMidBannerItem src={banner.image} />
                        </a>
                    );
                else
                    return (
                        <Link to={banner.url}>
                            <TopMidBannerItem src={banner.image} />
                        </Link>
                    );
            } else {
                if (banner.isLinkExternal()) {
                    return (
                        <a href={banner.url} target="_blank">
                            <TopLeftBannerItem src={banner.image} />
                        </a>
                    );
                } else
                    return (
                        <Link to={banner.url}>
                            <TopLeftBannerItem src={banner.image} />
                        </Link>
                    );
            }
        };

        // if (listBanner?.isLoading) {
        //     return (
        //         <BannerWrapper>
        //             <BannerContainer>
        //                 <Spin />
        //             </BannerContainer>
        //         </BannerWrapper>
        //     );
        // }

        if (listBanner && listBanner.list.length > 0)
            return (
                <BannerWrapper>
                    <BannerContainer>
                        <Swiper
                            key={Date.now()}
                            spaceBetween={0}
                            effect={'flip'}
                            navigation={true}
                            autoplay={true}
                            pagination={{
                                clickable: true
                            }}
                            modules={[EffectFade, Navigation, Pagination]}
                            className="mySwiper">
                            {listBanner &&
                                listBanner?.list
                                    .filter(
                                        (item) => item.type == BANNER_TYPE.LARGE
                                    )
                                    .map((item) => {
                                        return (
                                            <SwiperSlide key={item.bannerId}>
                                                {onRenderLargeBanner(item)}
                                            </SwiperSlide>
                                        );
                                    })}
                        </Swiper>
                        <TopBannerWrapper>
                            {listBanner &&
                                listBanner?.list
                                    .filter(
                                        (item) => item.type == BANNER_TYPE.SMALL
                                    )
                                    .slice(0, 3)
                                    .map((item) => {
                                        return (
                                            <TopBannerContainer
                                                key={item.bannerId}
                                                xl={8}
                                                md={8}
                                                sm={24}
                                                xs={24}>
                                                {onRenderSmallBanner(item)}
                                            </TopBannerContainer>
                                        );
                                    })}
                        </TopBannerWrapper>
                    </BannerContainer>
                </BannerWrapper>
            );
        else return null;
    })
);
