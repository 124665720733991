import React, { FC, ReactNode, useEffect, useState } from 'react';

import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { Outlet } from 'react-router-dom';
import { TopBoardContainer, TopBoardImage } from '../../views/Home/Home.styles';
import { LeaderboardDrawer } from '../../views/Home/LeaderboardDrawer/LeaderboardDrawer';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../providers/RootStoreProvider';
import { IGetConfigRequest } from '../../types/IConfig';
import { HelpDialog } from '../../views/CommonView/HelpDialog/HelpDialog';
import { JsonDialog } from '../../views/CommonView/JsonDialog/JsonDialog';

// define interface to represent component props
interface Props {}

const MainLayout: FC<Props> = observer(({}) => {
    const store = useRootStore();
    const { appConfig, getConfigApp } = store.commonStore;
    const { showDialogHelp, showDialogJson, showDialogJsonNested } =
        store.globalStore;
    const { appBanner, getAppBanner } = store.jsonStore;

    const [open, setOpen] = useState(false);
    const [showRank, setShowRank] = useState(false);

    useEffect(() => {
        if (!appConfig) onGetConfigApp();
        if (!appBanner) onGetAppBanner();
    }, []);

    const onGetAppBanner = () => {
        getAppBanner();
    };

    const onGetConfigApp = async () => {
        const params: IGetConfigRequest = {};
        const result = await getConfigApp(params);
        setShowRank(result.data?.showRank!);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Header></Header>
            <Outlet />
            {showRank && (
                <>
                    <TopBoardContainer onClick={() => showDrawer()}>
                        <TopBoardImage src="../../../../images/top_bxh.png" />
                    </TopBoardContainer>
                    <LeaderboardDrawer open={open} onClose={() => onClose()} />
                </>
            )}
            {showDialogHelp ? <HelpDialog /> : null}
            {showDialogJson ? <JsonDialog /> : null}
            <Footer />
        </div>
    );
});

export default MainLayout;
