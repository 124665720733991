import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';

export const FuDrawer = styled(Drawer)`
    .ant-drawer-header {
        background-color: #19181c;
        //height: 300px !important;
        //flex: 20% !important;
        border: 0 !important;
        //padding: 40px 20px 40px 20px !important;
    }

    .ant-drawer-close {
        color: #fff;
        font-weight: 500;
        @media ${device.mobileS} {
            font-size: 24px;
        }
        @media ${device.laptop} {
            font-size: 28px;
        }
    }

    .ant-drawer-body {
        background-color: #252525;
        padding: 0;
        margin: 0;
    }

    .ant-drawer-title {
        font-weight: 700;
        color: #fff;
        @media ${device.mobileS} {
            font-size: 28px;
        }
        @media ${device.laptop} {
            font-size: 32px;
        }
    }
`;

export const MenuContainer = styled.ul`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;
    padding-left: 20px;
    list-style-type: none;
    justify-content: space-between;
`;

interface MenuItemProps {
    isActive: boolean;
}

export const MenuItem = styled.li.attrs((props: MenuItemProps) => ({
    className: props.isActive
}))`
    padding-top: 17px;
    //margin-right: 30px;
    padding-bottom: 17px;

    &.active {
        border-bottom: solid 2px #ff2423;
    }

    &:hover {
        border-bottom: solid 2px #ff2423;
    }
    &:nth-child(4) {
        width: 200px;
    }
`;

export const MenuLink = styled(Link).attrs((props: MenuItemProps) => ({
    className: props.isActive
}))`
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    &:hover {
        color: #ff2423;
    }
    &:focus {
        //color: #ff2423;
    }
    &.active {
        color: #ff2423;
    }
`;
