import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';

export const GiftNewItemWrapper = styled.div`
    position: relative;
    cursor: pointer;
`;

export const GiftThumbnailContainer = styled.div`
    /* width: 500px; */
    max-height: 250px;
    border-radius: 10px;
`;

export const GiftThumbnail = styled.img`
    object-fit: cover;
    width: 100%;
    max-height: 250px;
    border-radius: 10px;
`;

export const InfoContainer = styled.div`
    position: absolute;
    height: 80px;
    width: 100%;
    padding-left: 20px;
    padding-top: 5px;
    opacity: 0.8;
    bottom: 0;
    left: 0;
    background-color: #252525;
`;

export const GiftName = styled.h1`
    color: #fff;
    margin: 0;
    font-weight: 700;
    @media ${device.mobileS} {
        font-size: 20px;
    }

    @media ${device.tablet} {
        font-size: 24px;
    }
`;

export const GiftDesc = styled.h1`
    color: #fff;
    margin: 0;
    font-weight: 500;
    @media ${device.mobileS} {
        font-size: 16px;
    }

    @media ${device.tablet} {
        font-size: 20px;
    }
`;
