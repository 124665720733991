import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';
import { AppButton } from '../../../styles/AppButton.styles';
import { Row } from 'antd';

export const ButtonWrapper = styled.div`
    width: 100%;
    background-color: #19181c;
`;

export const ButtonContainer = styled(Row)`
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.mobileS} {
        width: 100%;
        padding: 10px;
    }
    @media ${device.laptop} {
        width: 1000px;
        margin: 0 auto;
        padding: 40px;
    }
`;

export const SpinButtonBase = styled(AppButton)`
    @media ${device.mobileS} {
        width: 45%;
        margin: 5px;
    }
    @media ${device.laptop} {
        margin-right: 20px;
        width: 200px;
        margin: 0 auto;
    }
`;

export const ButtonAdd = styled(SpinButtonBase)``;

export const ButtonTotal = styled(SpinButtonBase)``;

export const ButtonStart = styled(SpinButtonBase)``;
