import { ComponentType, useEffect, useState } from 'react';

//components
import { BuyGiftItem } from './BuyGiftItem/BuyGiftItem';
//styles
import {
    BuyGiftHistoryWrapper,
    BuyGiftHistoryContainer
} from './BuyGiftHistory.styles';
import { useRootStore } from '../../../../../providers/RootStoreProvider';
import { IGetListUserHistoryBuyGift } from '../../../../../types/IUserHistory';
import { observer } from 'mobx-react-lite';
import { PaginationWrapper } from '../SpinHistory/SpinHistory.styles';
import { Pagination, PaginationProps } from 'antd';

interface Props {}

export const BuyGiftHistory: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const [pageIndex, setPageIndex] = useState(1);

    const { listUserHistoryBuyGift, getListUserHistoryBuyGift } =
        store.historyStore;

    useEffect(() => {
        onGetListUserHistory();
    }, []);

    const onChange: PaginationProps['onChange'] = (page, pageSize) => {
        console.log(pageSize);
        setPageIndex(page);
        onGetListUserHistory(true, page);
    };

    const onGetListUserHistory = async (
        isLoadMore?: boolean,
        pageIndex?: number
    ) => {
        const params: IGetListUserHistoryBuyGift = {
            data: {}
        };
        const result = await getListUserHistoryBuyGift(
            params,
            isLoadMore,
            pageIndex
        );
    };

    return (
        <BuyGiftHistoryWrapper>
            <BuyGiftHistoryContainer
                title={`Lịch sử đổi quà (${listUserHistoryBuyGift?.totalRecord})`}>
                {listUserHistoryBuyGift &&
                    listUserHistoryBuyGift.list.map((item) => {
                        return <BuyGiftItem data={item} key={item.giftId} />;
                    })}

                {listUserHistoryBuyGift?.totalPage! > 1 ? (
                    <PaginationWrapper>
                        <Pagination
                            showSizeChanger={false}
                            defaultCurrent={pageIndex}
                            total={listUserHistoryBuyGift?.totalPage}
                            onChange={onChange}
                        />
                    </PaginationWrapper>
                ) : null}
            </BuyGiftHistoryContainer>
        </BuyGiftHistoryWrapper>
    );
});
