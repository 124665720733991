import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { AppTitle } from '../../../styles/App.styles';
//styles
import {
    TopBannerWrapper,
    TopBannerContainer,
    TitleWrapper,
    BannerWrapper,
    BannerImage,
    RuleButton
} from './TopBanner.styles';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';

interface Props {}

export const TopBanner: ComponentType<Props> = pure(({}) => {
    return (
        <TopBannerWrapper>
            <TopBannerContainer>
                <TitleWrapper>
                    <AppTitle>DANH SÁCH NHIỆM VỤ</AppTitle>
                </TitleWrapper>
            </TopBannerContainer>
        </TopBannerWrapper>
    );
});
