export enum APP_BUTTON_TYPE {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    BORDER = 'border',
    LIGHT = 'light',
    DISABLED = 'disabled',
    TRANSPARENT = 'transparent'
}

export enum MAIN_MENU {
    HOME,
    INTRO,
    MISSION,
    SPIN,
    TOURNAMENT,
    GIFT,
    CONTACT,
    PROFILE,
    FAQ
}

export enum GENDER_TYPE {
    MALE,
    FEMALE
}

export enum RULES_CONTENT {
    INTRO,
    INVITED,
    VISIT,
    SPIN,
    VIDEO,
    INSTALL_APP
}

export enum HELP_CONTENT {
    INTRO,
    INVITED,
    VISIT,
    SPIN,
    VIDEO
}

export enum JSON_CONTENT_TYPE {
    POLICY,
    RESET_PASS
}
