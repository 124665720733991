import styled from 'styled-components';
import { Modal } from 'antd';
import { device } from '../../../../../../configs/MediaQuery';
import { AppButton } from '../../../../../../styles/AppButton.styles';
import { AppInputText } from '../../../../../../styles/AppInput.styles';

export const OTPDialogWrapper = styled.div`
    max-width: 460px;
`;

export const OTPDialogContainer = styled.div`
    width: 100%;
    text-align: center;
    color: #314154;
`;

export const OTPDialogModal = styled(Modal)`
    .ant-modal-body {
        text-align: center;
    }
`;

export const TitleContainer = styled.div`
    padding-top: 10px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const TitleText = styled.h1`
    font-weight: 700;
    margin: 0;
    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        font-size: 20px;
        line-height: 30px;
    }
`;

export const DescriptionText = styled.p`
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
`;

export const SendButton = styled(AppButton)`
    width: 100%;
`;

export const OTPWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const InputText = styled(AppInputText)`
    background: transparent;
    color: red;
    font-size: 40px;
    font-weight: 700;

    min-width: 50px;
    min-height: 70px;

    :not(:last-child) {
        margin-right: 20px;
    }
`;
