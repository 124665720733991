import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';
import { Drawer } from 'antd';

export const DrawerTopBoard = styled(Drawer)`
    .ant-drawer-header {
        background-color: #19181c;
        height: 100px !important;
        //flex: 20% !important;
        border: 0 !important;
        padding: 40px 20px 40px 20px !important;
    }

    .ant-drawer-close {
        color: #fff;
        font-weight: 500;
        @media ${device.mobileS} {
            font-size: 24px;
        }
        @media ${device.laptop} {
            font-size: 28px;
        }
    }

    .ant-drawer-body {
        background-color: #252525;
        padding: 0;
        margin: 0;
        padding-bottom: 100px;
    }

    .ant-drawer-title {
        font-weight: 700;
        color: #fff;
        @media ${device.mobileS} {
            font-size: 28px;
        }
        @media ${device.laptop} {
            font-size: 32px;
        }
    }
`;

export const MeRankWrapper = styled.div`
    position: absolute;
    bottom: 0;
    height: 64px;
    width: 100%;
    border-top: solid 0px #252525;
    background-color: #19181c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    padding-right: 16px;
`;

export const MeScoreWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ScoreText = styled.p`
    margin: 0;
    font-weight: 500;
    color: #fff;
    font-size: 20px;
    margin-right: 10px;
`;

export const ScoreValue = styled.p`
    margin: 0;
    font-weight: 700;
    color: #ff2423;
    font-size: 20px;
`;
