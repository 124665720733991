import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
`;

export const LineDivider = styled.div`
    width: 100%;
    background-color: #707070;

    @media ${device.mobileS} {
        height: 0px;
    }
    @media ${device.laptop} {
        height: 1px;
        margin-bottom: 20px;
    }
`;
