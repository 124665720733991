import styled from 'styled-components';
import { AppButton } from '../../styles/AppButton.styles';
import { device } from '../../configs/MediaQuery';

export const MainWrapper = styled.div`
    width: 100%;
    background-color: #19181c;
    padding-bottom: 20px;
    min-height: 400px;
`;

export const MainContainer = styled.div`
    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptop} {
        width: 1100px;
        margin: 0 auto;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 10px;
    justify-content: center;
`;

export const JoinButton = styled(AppButton)`
    //margin-right: 20px;
    @media ${device.mobileS} {
        width: 200px;
    }
    @media ${device.laptop} {
        //width: 100%;
        min-width: 200px;
    }
`;
