import { ComponentType, useEffect, useState } from 'react';
import { pure } from 'recompose';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

//components
import { SocialIcon } from '../../../../components/SocialIcon/SocialIcon';

//import styles
import {
    HeaderWrapper,
    HeaderContainer,
    AppLogoContainer,
    AppLogoImage,
    Row1Wrapper,
    RowContainer1,
    RowContainer2,
    MenuItem,
    MenuContainer,
    MenuLink,
    LoginButton,
    LoginButtonText,
    FAQLink
} from './HeaderWeb.styles';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { AuthHeader } from '../../../../helpers/AuthHeader';
import { Link } from 'react-router-dom';

import { MAIN_MENU } from '../../../../types/IGlobal';
import { UserLogin } from './UserLogin';
import _ from 'lodash';

const queryString = require('query-string');

export const HeaderWeb: ComponentType = pure(
    observer(({}) => {
        const store = useRootStore();
        const { currentUser } = store.authStore;
        const { toggleShowDialogLogin, indexMainMenu, changeMainMenuIndex } =
            store.globalStore;

        const isLogin = AuthHeader() || currentUser;

        let paramsQuery = queryString.parse(window.location.search);
        useEffect(() => {
            //check show login if token expired
            if (!_.isEmpty(paramsQuery) && paramsQuery.login && !isLogin) {
                toggleShowDialogLogin(true);
            }
        }, []);

        const onChangeMainMenuIndex = (index: MAIN_MENU) => {
            changeMainMenuIndex(index);
        };

        return (
            <HeaderWrapper>
                <HeaderContainer>
                    <Link
                        to={'/'}
                        onClick={() => onChangeMainMenuIndex(MAIN_MENU.HOME)}>
                        <AppLogoContainer title="1.1.4">
                            <AppLogoImage src="../../../images/app_logo.png"></AppLogoImage>
                        </AppLogoContainer>
                    </Link>
                    <Row1Wrapper>
                        <RowContainer1>
                            <SocialIcon isHeader={true} />
                            {!isLogin ? (
                                <LoginButton
                                    onClick={() => toggleShowDialogLogin(true)}>
                                    <LoginButtonText>Đăng nhập</LoginButtonText>
                                </LoginButton>
                            ) : (
                                <UserLogin />
                            )}
                        </RowContainer1>
                    </Row1Wrapper>
                    <RowContainer2>
                        <MenuContainer>
                            <MenuLink
                                onClick={() =>
                                    onChangeMainMenuIndex(MAIN_MENU.INTRO)
                                }
                                isActive={
                                    indexMainMenu == MAIN_MENU.INTRO
                                        ? 'active'
                                        : ''
                                }
                                to="/intro">
                                <MenuItem
                                    isActive={
                                        indexMainMenu == MAIN_MENU.INTRO
                                            ? 'active'
                                            : ''
                                    }>
                                    GIỚI THIỆU
                                </MenuItem>
                            </MenuLink>

                            <MenuLink
                                onClick={() =>
                                    onChangeMainMenuIndex(MAIN_MENU.MISSION)
                                }
                                isActive={
                                    indexMainMenu == MAIN_MENU.MISSION
                                        ? 'active'
                                        : ''
                                }
                                to="/mission">
                                <MenuItem
                                    isActive={
                                        indexMainMenu == MAIN_MENU.MISSION
                                            ? 'active'
                                            : ''
                                    }>
                                    NHIỆM VỤ
                                    {/* <DropdownMission
                                    dropdownRender={renderMenuMission}
                                    open={showMenuMission}
                                    onOpenChange={onOpenChangeMenuMission}
                                    trigger={['click', 'hover']}>
                                    <Space>
                                        NHIỆM VỤ
                                        <DownOutlined />
                                    </Space>
                                </DropdownMission> */}
                                </MenuItem>
                            </MenuLink>

                            <MenuLink
                                onClick={() =>
                                    onChangeMainMenuIndex(MAIN_MENU.SPIN)
                                }
                                isActive={
                                    indexMainMenu == MAIN_MENU.SPIN
                                        ? 'active'
                                        : ''
                                }
                                to="/spin">
                                <MenuItem
                                    isActive={
                                        indexMainMenu == MAIN_MENU.SPIN
                                            ? 'active'
                                            : ''
                                    }>
                                    VÒNG QUAY MAY MẮN
                                </MenuItem>
                            </MenuLink>

                            <MenuItem>
                                <MenuLink to="/about"></MenuLink>
                            </MenuItem>

                            {/* <MenuLink
                                onClick={() =>
                                    onChangeMainMenuIndex(MAIN_MENU.TOURNAMENT)
                                }
                                isActive={
                                    indexMainMenu == MAIN_MENU.TOURNAMENT
                                        ? 'active'
                                        : ''
                                }
                                to="/tournament">
                                <MenuItem
                                    isActive={
                                        indexMainMenu == MAIN_MENU.TOURNAMENT
                                            ? 'active'
                                            : ''
                                    }>
                                    GIẢI ĐẤU
                                </MenuItem>
                            </MenuLink> */}

                            <FAQLink href="data/faq.pdf" target="_blank">
                                <MenuItem
                                    isActive={
                                        indexMainMenu == MAIN_MENU.FAQ
                                            ? 'active'
                                            : ''
                                    }>
                                    FAQ
                                </MenuItem>
                            </FAQLink>

                            <MenuLink
                                onClick={() =>
                                    onChangeMainMenuIndex(MAIN_MENU.GIFT)
                                }
                                isActive={
                                    indexMainMenu == MAIN_MENU.GIFT
                                        ? 'active'
                                        : ''
                                }
                                to="/gift">
                                <MenuItem
                                    isActive={
                                        indexMainMenu == MAIN_MENU.GIFT
                                            ? 'active'
                                            : ''
                                    }>
                                    ĐỔI QUÀ
                                </MenuItem>
                            </MenuLink>

                            <MenuLink
                                onClick={() =>
                                    onChangeMainMenuIndex(MAIN_MENU.CONTACT)
                                }
                                isActive={
                                    indexMainMenu == MAIN_MENU.CONTACT
                                        ? 'active'
                                        : ''
                                }
                                to="/contact">
                                <MenuItem
                                    isActive={
                                        indexMainMenu == MAIN_MENU.CONTACT
                                            ? 'active'
                                            : ''
                                    }>
                                    LIÊN HỆ
                                </MenuItem>
                            </MenuLink>

                            <MenuLink
                                onClick={() =>
                                    onChangeMainMenuIndex(MAIN_MENU.PROFILE)
                                }
                                isActive={
                                    indexMainMenu == MAIN_MENU.PROFILE
                                        ? 'active'
                                        : ''
                                }
                                to="/profile">
                                <MenuItem
                                    isActive={
                                        indexMainMenu == MAIN_MENU.PROFILE
                                            ? 'active'
                                            : ''
                                    }>
                                    TRANG CÁ NHÂN
                                </MenuItem>
                            </MenuLink>
                        </MenuContainer>
                    </RowContainer2>
                </HeaderContainer>
                {/* <LoginView
                    visible={showDialogLogin}
                    onClose={() => toggleShowDialogLogin(false)}
                    onForgotPass={() => setShowForgotPass(true)}
                    onLoginSuccess={() => toggleShowDialogLogin(false)}
                    onRegisterAccount={() => setShowRegister(true)}
                />
                <ForgotPass
                    visible={showForgotPass}
                    onClose={() => setShowForgotPass(false)}
                />
                <Register
                    visible={showRegister}
                    refId={refId}
                    onClose={() => setShowRegister(false)}
                /> */}
            </HeaderWrapper>
        );
    })
);
