import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//components
import { MatchItem } from '../Match/MatchItem';
//styles
import {
    TournamentWrapper,
    TournamentContainer,
    CategoryItemWrapper,
    CategoryTitle,
    TournamentCategoryWrapper,
    TournamentTitle,
    LineDivider,
    MatchContainer
} from './TournamentView.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';
import Match from '../../../models/Match';
import { IGetListMatchRequest, IMatch } from '../../../types/IMatch';
import { APIResponseList } from '../../../models/APIResponse';
import Tournament from '../../../models/Tournament';
import { Spin } from 'antd';
import { NoData } from '../../CommonView/NoData/NoData';

interface Props {
    onGetListMatch: (tournamentId: any) => void;
}

export const TournamentView: ComponentType<Props> = pure(
    observer(({ onGetListMatch }) => {
        const store = useRootStore();
        const { listTournament, listMatch } = store.tournamentStore;

        if (listTournament && listTournament.list.length > 0)
            return (
                <TournamentWrapper>
                    <TournamentContainer>
                        <LineDivider />
                        <TournamentTitle>GIẢI ĐẤU</TournamentTitle>
                        <TournamentCategoryWrapper>
                            {listTournament?.list.map((item) => {
                                return (
                                    <CategoryItemWrapper
                                        key={item.tournamentId}
                                        onClick={() =>
                                            onGetListMatch(item.tournamentId)
                                        }>
                                        <CategoryTitle>
                                            {item.tournamentName}
                                        </CategoryTitle>
                                    </CategoryItemWrapper>
                                );
                            })}
                        </TournamentCategoryWrapper>
                        <MatchContainer>
                            {listMatch?.list.map((item) => {
                                return (
                                    <MatchItem data={item} key={item.matchId} />
                                );
                            })}
                            {listMatch?.isLoading ? <Spin /> : null}
                            {!listMatch?.hasData() ? (
                                <NoData description="Giải đấu này chưa có trận đấu nào!" />
                            ) : null}
                        </MatchContainer>
                    </TournamentContainer>
                </TournamentWrapper>
            );
        else return null;
    })
);
