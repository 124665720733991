import styled from 'styled-components';
import { AppCard } from '../../../../../styles/App.styles';

export const InstallAppHistoryWrapper = styled.div``;

export const InstallAppHistoryContainer = styled(AppCard)`
    .ant-card-body {
        padding: 20px;
    }
`;

export const TotalInstallApp = styled.h1`
    font-size: 20px;
    color: #fff;
    font-weight: 700;
`;
