import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    SuccessDialogWrapper,
    SuccessDialogContainer,
    SuccessDialogModal,
    TitleContainer,
    TitleText,
    PrizeNameWrapper,
    DescriptionText,
    PrizeName,
    PrizeImageWrapper,
    PrizeImage,
    ButtonWrapper,
    ButtonContinue,
    ButtonBag
} from './SuccessDialog.styles';
import { AppButton } from '../../../styles/AppButton.styles';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import SpinGift from '../../../models/SpinGift';
import { useNavigate } from 'react-router-dom';
import { PROFILE_SCREEN } from '../../../configs/ScreenType';
import { useRootStore } from '../../../providers/RootStoreProvider';
import Gift from '../../../models/Gift';

interface Props {
    visible: boolean;
    onClose: () => void;
    giftWin: Gift;
}

export const SuccessDialog: ComponentType<Props> = pure(
    ({ visible, onClose, giftWin }) => {
        const store = useRootStore();
        const navigation = useNavigate();
        const { changeProfileScreen } = store.globalStore;
        const { toggleScrollToList } = store.giftBagStore;

        const onGoToBag = () => {
            toggleScrollToList(true);
            onClose();
            changeProfileScreen(PROFILE_SCREEN.BAG);
            navigation('/profile');
        };

        return (
            <SuccessDialogWrapper>
                <SuccessDialogContainer>
                    <SuccessDialogModal
                        centered
                        onCancel={onClose}
                        open={visible}
                        footer={null}>
                        <TitleContainer>
                            <TitleText>Đổi quà thành công</TitleText>
                        </TitleContainer>
                        <PrizeImageWrapper>
                            <PrizeImage src={giftWin?.image}></PrizeImage>
                        </PrizeImageWrapper>
                        <PrizeNameWrapper>
                            <DescriptionText>
                                Chúc mừng bạn đã đổi quà thành công:
                            </DescriptionText>
                            <PrizeName>{giftWin?.giftName}</PrizeName>

                            <ButtonWrapper>
                                <ButtonContinue
                                    typeButton={APP_BUTTON_TYPE.PRIMARY}
                                    onClick={() => onClose()}>
                                    Tiếp tục đổi quà
                                </ButtonContinue>

                                <ButtonBag
                                    typeButton={APP_BUTTON_TYPE.PRIMARY}
                                    onClick={() => onGoToBag()}>
                                    Túi đồ
                                </ButtonBag>
                            </ButtonWrapper>
                        </PrizeNameWrapper>
                    </SuccessDialogModal>
                </SuccessDialogContainer>
            </SuccessDialogWrapper>
        );
    }
);
