import { observer } from 'mobx-react-lite';
import { ComponentType, useEffect, useState } from 'react';
import pure from 'recompose/pure';
import { useRootStore } from '../../providers/RootStoreProvider';
import {
    ButtonWrapper,
    JoinButton,
    MainContainer,
    MainWrapper
} from './InstallApp.styles';

//components
import { TopBanner } from './TopBanner/TopBanner';
import { RuleDescription } from './RuleDescription/RuleDescription';
import { MissionContent } from './MissionContent/MissionContent';
import { FormContent } from './MissionContent/FormContent';
import { APP_BUTTON_TYPE, RULES_CONTENT } from '../../types/IGlobal';
import { Spin } from 'antd';
import {
    IJoinInstallAppMissionRequest,
    JOIN_MISSION_ACTION
} from '../../types/IBanner';
import { showMessage } from '../../utils';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { IGetUserProfileDetailRequest, IInstallApp } from '../../types/IUser';
import { IMissionInstallApp } from '../../types/IJson';
import { RuleDialog } from '../CommonView/RuleDialog/RuleDialog';
import { JsonDialogNested } from '../CommonView/JsonDialogNested/JsonDialogNested';
import { TokenInvalid } from '../CommonView/TokenInvalid/TokenInvalid';

const queryString = require('query-string');

//styles

interface Props {}

export const InstallApp: ComponentType<Props> = observer(({}) => {
    let paramsQuery = queryString.parse(window.location.search);

    const store = useRootStore();
    const navigation = useNavigate();

    const { currentMission, loadingJoinMission, joinInstallAppMission } =
        store.missionStore;
    const { missionInstallApp, getMissionInstallAppInfo } = store.jsonStore;
    const { showDialogRule, showDialogJsonNested, toggleShowDialogJsonNested } =
        store.globalStore;
    const { currentUser, getProfileDetail } = store.authStore;

    const [missionJoined, setMissionJoined] = useState(false);
    const [missionUrls, setMissionUrls] = useState({});

    useEffect(() => {
        onGetMissionDetail();
    }, []);

    useEffect(() => {
        if (currentUser && currentUser.installApp != undefined)
            onCheckJoinedMission();
        else if (currentUser) doGetProfileUserLogin();
    }, [currentUser]);

    const doGetProfileUserLogin = () => {
        let params: IGetUserProfileDetailRequest = {};
        getProfileDetail(params);
    };

    const onGetMissionDetail = async () => {
        //if (!_.isEmpty(paramsQuery) && paramsQuery.appId) {
        if (currentMission) {
            const result = await getMissionInstallAppInfo(currentMission.appId);
        } else navigation('/mission');
    };

    const onCheckJoinedMission = () => {
        const appInfo = _.find(currentUser?.installApp, (item: IInstallApp) => {
            return item.appId.toString() == currentMission?.appId.toString(); //paramsQuery.appId.toString();
        });
        if (appInfo && appInfo.status === JOIN_MISSION_ACTION.JOINED) {
            const urls: any = {
                urlAndroid: appInfo.androidurl,
                urlIos: appInfo.iosurl
            };
            setMissionUrls(urls);
            //setMissionJoined(true);
        }
    };

    const onJoinInstallAppMission = async (extra: any) => {
        const params: IJoinInstallAppMissionRequest = {
            data: {
                appId: currentMission?.appId!,
                action: JOIN_MISSION_ACTION.JOINED,
                extra: extra
            }
        };
        console.log(params);
        const result = await joinInstallAppMission(params);
        if (result.isSuccess()) {
            const urls: any = {
                urlAndroid: result.data.androidurl,
                urlIos: result.data.iosurl
            };
            setMissionUrls(urls);
            setMissionJoined(true);
        } else showMessage(result);
    };

    const onSubmitForm = (data: any) => {
        onJoinInstallAppMission(data);
    };

    const renderContentMission = (data: IMissionInstallApp) => {
        if (!currentUser) return <TokenInvalid />;
        return <MissionContent data={data.description} urls={missionUrls} />;
    };

    const renderContent = () => {};

    return (
        <MainWrapper>
            <MainContainer>
                {missionInstallApp != null ? (
                    <>
                        <TopBanner
                            isJoined={missionJoined}
                            mission={missionInstallApp}
                            onClickRules={() =>
                                toggleShowDialogJsonNested(true)
                            }
                        />
                        <RuleDescription mission={missionInstallApp} />

                        {!missionJoined ? (
                            <FormContent
                                loading={loadingJoinMission}
                                mission={missionInstallApp}
                                onSubmitForm={onSubmitForm}
                            />
                        ) : (
                            renderContentMission(missionInstallApp)
                        )}

                        {showDialogJsonNested && missionInstallApp.rules ? (
                            <JsonDialogNested
                                jsonContent={missionInstallApp.rules}
                            />
                        ) : null}
                    </>
                ) : null}
            </MainContainer>
            {showDialogRule ? (
                <RuleDialog type={RULES_CONTENT.INSTALL_APP} />
            ) : null}
        </MainWrapper>
    );
});
