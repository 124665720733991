import { ISpinGift } from '../types/IGift';
import { DataList } from './DataList';
import _ from 'lodash';

export default class SpinGift {
    public giftKey: number;
    public giftId: string;
    public giftName: string;
    public giftCode: string;
    public image: string;
    public order: number;
    public quantity: number;
    public selected: boolean;
    public serial: string;

    constructor(
        giftKey: number,
        giftId: string,
        giftName: string,
        giftCode: string,
        image: string,
        order: number,
        quantity: number,
        selected: boolean,
        serial: string
    ) {
        this.giftKey = giftKey;
        this.giftId = giftId;
        this.giftName = giftName;
        this.giftCode = giftCode;
        this.image = image;
        this.order = order;
        this.quantity = quantity;
        this.selected = selected;
        this.serial = serial;
    }
    static fromJson = (json: ISpinGift): SpinGift => {
        return new SpinGift(
            0,
            json.sid,
            json.title,
            json.code,
            json.img,
            json.order,
            json.qty,
            false,
            json.serial
        );
    };

    updateSelected = (value: boolean) => {
        this.selected = value;
    };

    updateKey = (value: number) => {
        this.giftKey = value;
    };

    isWin = (): boolean => {
        return this.giftCode != 'A';
    };
}

export class SpinConfig {
    public spinPoint: number;

    constructor(spinPoint: number) {
        this.spinPoint = spinPoint;
    }

    static fromJson = (json: any): SpinConfig => {
        return new SpinConfig(json.spinPoint);
    };
}

export class ListSpinGift extends DataList<SpinGift> {
    private listGiftCode: string = '';
    static fromJson = (jsonArray: Array<ISpinGift>): Array<SpinGift> => {
        if (!jsonArray) return [];
        let data = new Array<SpinGift>();
        jsonArray.forEach((item) => {
            data.push(SpinGift.fromJson(item));
        });
        return data;
    };

    getListGiftCode(): string {
        if (this.listGiftCode.length > 0) {
            return this.listGiftCode;
        }

        _.forEach(this.list, (item) => {
            this.listGiftCode += item.giftCode;
        });
        return this.listGiftCode;
    }

    hasData(): boolean {
        return this.list.length > 0;
    }
}
