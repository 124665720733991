import { IGiftBagType } from '../types/IGiftBag';
import { DataList } from './DataList';

export class GiftBagType {
    public giftTypeId: number;
    public giftTypeName: string;

    constructor(giftTypeId: number, giftTypeName: string) {
        this.giftTypeId = giftTypeId;
        this.giftTypeName = giftTypeName;
    }
    static fromJson = (json: IGiftBagType): GiftBagType => {
        return new GiftBagType(json.type, json.name);
    };
}

export class ListGiftBagType extends DataList<GiftBagType> {
    static fromJson = (jsonArray: Array<IGiftBagType>): Array<GiftBagType> => {
        if (!jsonArray) return [];
        let data = new Array<GiftBagType>();
        jsonArray.forEach((item) => {
            data.push(GiftBagType.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
