import { ComponentType } from 'react';
import pure from 'recompose/pure';
//styles
import {
    ItemWrapper,
    ItemContainer,
    ItemImageWrapper,
    ItemImage,
    ItemTitle,
    CheckImageWrapper
} from './DailyItem.styles';
import { getDayIndex } from '../../../../utils/DateUtils';
import { IUserDailyVisitRequest } from '../../../../types/IUser';
import { CheckIcon } from '../../../../components/SvgIcon';

interface Props {
    dayIndex: number;
    isSunday?: boolean;
    isVisit?: boolean;
    onDailyVisit: (dayIndex: number, active: boolean) => void;
}

export const DailyItem: ComponentType<Props> = pure(
    ({ dayIndex, isSunday, isVisit, onDailyVisit }) => {
        const title = dayIndex != 0 ? `Thứ ${dayIndex + 1}` : 'Chủ nhật';
        const active = dayIndex == getDayIndex();

        /*const renderImage = () => {
            if (active)
                return (
                    <ItemImage
                        src={`../../../../images/visit/visit_${dayIndex}.jpg`}></ItemImage>
                );
            else
                return (
                    <ItemImage
                        src={`../../../../images/visit/visit_${dayIndex}.jpg`}></ItemImage>
                );
        };*/

        const renderImage = () => {
            return (
                <ItemImage
                    isActive={active ? 'active' : 'inactive'}
                    src={`../../../../images/visit/visit_${dayIndex}.jpg`}></ItemImage>
            );
        };

        return (
            <ItemWrapper onClick={() => onDailyVisit(dayIndex, active)}>
                <ItemContainer isSunday={isSunday}>
                    {/* <ItemTitle>{title}</ItemTitle> */}
                    <ItemImageWrapper>{renderImage()}</ItemImageWrapper>
                </ItemContainer>
                {active && isVisit ? (
                    <CheckImageWrapper>
                        <CheckIcon />
                    </CheckImageWrapper>
                ) : null}
            </ItemWrapper>
        );
    }
);
