import * as Yup from 'yup';

export const UpdateProfileSchema = Yup.object().shape({
    fullName: Yup.string().trim().required('Họ tên không được trống'),
    sex: Yup.string().trim().required('Giới tính không được trống'),
    birthDay: Yup.string().trim().required('Ngày sinh không được trống'),
    email: Yup.string()
        .trim()
        .required('Email không được trống')
        .email('Email không hợp lệ'),
    phone: Yup.string()
        .trim()
        .required('Số điện thoại không được trống')
        .min(9, 'Số điện thoại không được nhỏ hơn 9 ký tự')
        .max(12, 'Số điện thoại không được lớn hơn 12 ký tự'),
    cityId: Yup.string().trim().required('Tỉnh thành không được trống'),
    districtId: Yup.string().trim().required('Quận huyện không được trống')
});
