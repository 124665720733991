import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';
import { Col } from 'antd';

export const MissionItemWrapper = styled(Col)`
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 20px;
    height: 280px;
    position: relative;
    cursor: pointer;
    @media ${device.mobileS} {
        padding-bottom: 25px;
        padding-left: 20px;
    }
    @media ${device.laptop} {
        //padding-bottom: 35px;
        padding-left: 0px;
    }

    :not(:last-child) {
        //padding-right: 30px;
    }
`;

export const MissionItemContainer = styled.div`
    position: relative;
`;

export const InfoContainer = styled.div`
    position: absolute;
    height: 100px;
    width: 100%;
    padding: 10px;
    padding-top: 5px;
    opacity: 0.8;
    bottom: 0;
    left: 0;
    background-color: #000;
    justify-content: center;
    display: flex;
    flex-direction: column;
`;

export const MissionName = styled.h1`
    color: #fff;
    margin: 0;
    font-weight: 700;
    @media ${device.mobileS} {
        font-size: 16px;
    }

    @media ${device.tablet} {
        font-size: 18px;
    }
`;

export const MissionDesc = styled.h1`
    color: #fff;
    margin: 0;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    @media ${device.mobileS} {
        font-size: 12px;
    }

    @media ${device.tablet} {
        font-size: 14px;
    }
`;

export const MissionBannerWrapper = styled.div`
    padding: 0;
    height: 260px;
    @media ${device.mobileS} {
        max-width: 100%;
    }
    @media ${device.laptop} {
        max-width: 100%;
    }
`;

export const MissionBannerImage = styled.img`
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 280px;
`;
