import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFlip, EffectFade, Navigation, Pagination } from 'swiper';

//components
import { VideoItem } from '../VideoItem/VideoItem';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { observer } from 'mobx-react-lite';
//styles

interface Props {}

export const VideoSlider: ComponentType<Props> = pure(
    observer(({}) => {
        const store = useRootStore();
        const { listVideo } = store.videoStore;
        return (
            <Swiper
                //slidesPerView={2.5}
                lazy={true}
                observer={true}
                key={Date.now()}
                effect={'flip'}
                navigation={true}
                autoplay={true}
                centeredSlides={true}
                loop={true}
                spaceBetween={15}
                breakpoints={{
                    640: {
                        //width: 640,
                        slidesPerView: 1
                    },
                    768: {
                        //width: 768,
                        slidesPerView: 1.5
                    },
                    992: {
                        //width: 992,
                        slidesPerView: 2.5,
                        spaceBetween: 24
                    },
                    1200: {
                        //width: 1200,
                        slidesPerView: 3.5,
                        spaceBetween: 32
                    }
                }}
                grabCursor={true}
                // pagination={{
                //     clickable: true
                // }}
                modules={[EffectFade, Navigation, Pagination]}
                className="mySwiper">
                {listVideo &&
                    listVideo.list.map((item) => {
                        return (
                            <SwiperSlide key={item.videoId}>
                                <VideoItem videoInfo={item} />
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
        );
    })
);
