import { action, makeObservable, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { APIResponse, APIResponseList } from '../models/APIResponse';
import SpinGift, { ListSpinGift, SpinConfig } from '../models/SpinGift';
import {
    IAddSpinGiftRequest,
    IBuyGiftRequest,
    IGetListGiftRequest,
    IGetListSpinGiftRequest,
    IGetSpinGiftRequest,
    IGift,
    ISpinGift
} from '../types/IGift';
import { randomGiftChar, swap } from '../utils';
import { GiftService } from '../services/GiftService';
import { IResponseBase } from '../types/ITypeBase';
import GiftCategory, { ListGiftCategory } from '../models/GiftCategory';
import { IGetListGiftCategory, IGiftCategory } from '../types/IGiftCategory';
import Gift, { ListGift } from '../models/Gift';
import { ListGiftBagType } from '../models/GiftBagType';
import _ from 'lodash';

class GiftStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable giftKey: number = 0;
    @observable lastGiftKey: number = 0;
    @observable giftIdChar: string = ''; //'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    @observable currentGiftExchange: Gift | null = null;
    @observable showSuccessExchangeDialog = false;
    @observable listSpinGift: ListSpinGift | null = null;
    @observable giftCategory: GiftCategory | null = null;
    @observable listGiftCategory: ListGiftCategory | null = null;
    @observable giftRandom: SpinGift | null = null;
    @observable spinConfig: SpinConfig | null = null;

    //object gift for view gift exchange
    @observable listGift: ListGift | null = null;
    @observable listGiftTopBuy: ListGift | null = null;
    @observable listGiftTopNew: ListGift | null = null;

    @action randomGift = () => {
        const val = Math.floor(Math.random() * 12) + 1;
        runInAction(() => {
            this.giftKey = val;
        });
    };

    @action setCurrentGiftExchange = (data: Gift) => {
        runInAction(() => {
            this.currentGiftExchange = data;
        });
    };

    @action toggleShowSuccessExchangeDialog = (val: boolean) => {
        runInAction(() => {
            this.showSuccessExchangeDialog = val;
        });
    };

    @action setGiftCategory = (val: GiftCategory) => {
        runInAction(() => {
            this.giftCategory = val;
        });
    };

    @action randomGiftChar = () => {
        runInAction(() => {
            this.giftIdChar = randomGiftChar(
                1,
                this.listSpinGift?.getListGiftCode()!
            );
        });
    };

    @action addSpinGift = async (params: IAddSpinGiftRequest) => {
        const result = (await GiftService.getInstance().addSpinGift(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };

    @action buyGift = async (params: IBuyGiftRequest) => {
        const result = (await GiftService.getInstance().buyGift(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };

    @action shuffledGift = () => {
        runInAction(() => {
            if (this.listSpinGift)
                this.listSpinGift.list = _.shuffle(this.listSpinGift?.list);
        });
    };

    @action getSpinGiftRandom = async (params: IGetSpinGiftRequest) => {
        const result = (await GiftService.getInstance().getSpinGiftRandom(
            params
        )) as APIResponse<ISpinGift>;

        if (result && result.isSuccess())
            runInAction(() => {
                // this.giftRandom = this.listSpinGift?.list.find((item) => {
                //     return item.giftCode === result.data?.code!;
                // })!;
                this.giftIdChar = result.data?.code!;
                const data = _.filter(this.listSpinGift?.list, (item) => {
                    return item.giftCode === result.data?.code;
                });

                let shuffleData = _.clone(data);
                while (shuffleData[0].giftKey === this.lastGiftKey) {
                    shuffleData = _.shuffle(data);
                }
                this.lastGiftKey = shuffleData[0].giftKey;
                this.giftKey = shuffleData[0].giftKey;
            });
        else {
            runInAction(() => {
                this.giftIdChar = 'A'; //default error or exception
            });
        }
        return result;
    };

    @action addDefaultLostGift = () => {
        runInAction(() => {
            const lostGift = _.find(this.listSpinGift, (item: SpinGift) => {
                return item.giftCode === 'A';
            });
            console.log(lostGift);
        });
    };

    @action initListSpinGift = (data: Array<SpinGift>) => {
        runInAction(() => {
            const listGiftFull: Array<SpinGift> = [];
            let key = 0;
            _.forEach(data, (item: SpinGift) => {
                if (item.quantity === 1) {
                    key = key + 1;
                    item.updateKey(key);
                    listGiftFull.push(_.clone(item));
                } else {
                    let index = 0;
                    while (index < item.quantity) {
                        key = key + 1;
                        item.updateKey(key);
                        listGiftFull.push(_.clone(item));
                        index++;
                    }
                }
            });
            // if (this.listSpinGift) {
            //     this.listSpinGift.list = listGiftFull;
            // }

            //shuffle list
            const shuffledArray = _.orderBy(
                [...listGiftFull],
                (item) => {
                    return item.quantity;
                },
                'desc'
            );

            for (let i = 1; i < shuffledArray.length; i++) {
                if (
                    shuffledArray[i].giftCode === shuffledArray[i - 1].giftCode
                ) {
                    let j = i + 1;
                    while (
                        j < shuffledArray.length &&
                        shuffledArray[j].giftCode === shuffledArray[i].giftCode
                    ) {
                        j++;
                    }
                    swap(shuffledArray, i, j);
                }
            }

            if (this.listSpinGift) {
                this.listSpinGift.list = shuffledArray;
            }
            //console.log(this.listSpinGift);
        });
    };

    isDuplicate = (list: Array<SpinGift>) => {
        for (let i = 0; i < list.length; i++) {
            if (list[i].giftCode === list[i + 1].giftCode) return true;
        }
        return false;
    };

    @action getListSpinGift = async (
        params: IGetListSpinGiftRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listSpinGift)
            runInAction(() => {
                this.listSpinGift = new ListSpinGift();
            });
        else if (loadMore) {
            this.listSpinGift.pageIndex += 1;
            this.listSpinGift.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listSpinGift.pageIndex
                : this.listSpinGift.pageSize * this.listSpinGift.pageIndex;
        }

        //request api service
        const result = (await GiftService.getInstance().getListSpinGift(
            params
        )) as APIResponseList<Array<ISpinGift>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listSpinGift) {
                    // this.listSpinGift.list = this.listSpinGift.list?.concat(
                    //     ListSpinGift.fromJson(result.data?.list!)
                    // )!;
                    this.listSpinGift.totalRecord = result.totalRecord();
                    this.listSpinGift.isLoading = false;
                    this.spinConfig = SpinConfig.fromJson(result.data?.extra);
                    this.initListSpinGift(
                        ListSpinGift.fromJson(result.data?.list!)
                    );
                }
            });
        }
        return result;
    };

    @action getListGiftCategory = async (
        params: IGetListGiftCategory,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listGiftCategory)
            runInAction(() => {
                this.listGiftCategory = new ListGiftCategory();
            });
        else if (loadMore) {
            this.listGiftCategory.pageIndex += 1;
            this.listGiftCategory.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listGiftCategory.pageIndex
                : this.listGiftCategory.pageSize *
                  this.listGiftCategory.pageIndex;
        }

        //request api service
        const result = (await GiftService.getInstance().getListGiftCategory(
            params
        )) as APIResponseList<Array<IGiftCategory>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listGiftCategory) {
                    this.listGiftCategory.list =
                        this.listGiftCategory.list?.concat(
                            ListGiftCategory.fromJson(result.data?.list!)
                        )!;
                    this.listGiftCategory.totalRecord = result.totalRecord();
                    this.listGiftCategory.isLoading = false;
                }
            });
        }

        return result;
    };

    @action getListGiftTopBuy = async (
        params: IGetListGiftRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listGiftTopBuy)
            runInAction(() => {
                this.listGiftTopBuy = new ListGift();
            });
        else if (loadMore) {
            this.listGiftTopBuy.pageIndex += 1;
            this.listGiftTopBuy.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listGiftTopBuy.pageIndex
                : this.listGiftTopBuy.pageSize * this.listGiftTopBuy.pageIndex;
        }

        //request api service
        const result = (await GiftService.getInstance().getListGift(
            params
        )) as APIResponseList<Array<IGift>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listGiftTopBuy) {
                    this.listGiftTopBuy.list = this.listGiftTopBuy.list?.concat(
                        ListGift.fromJson(result.data?.list!)
                    )!;
                    this.listGiftTopBuy.totalRecord = result.totalRecord();
                    this.listGiftTopBuy.isLoading = false;
                }
            });
        }
        return result;
    };

    @action getListGiftTopNew = async (
        params: IGetListGiftRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listGiftTopNew)
            runInAction(() => {
                this.listGiftTopNew = new ListGift();
            });
        else if (loadMore) {
            this.listGiftTopNew.pageIndex += 1;
            this.listGiftTopNew.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listGiftTopNew.pageIndex
                : this.listGiftTopNew.pageSize * this.listGiftTopNew.pageIndex;
        }

        //request api service
        const result = (await GiftService.getInstance().getListGift(
            params
        )) as APIResponseList<Array<IGift>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listGiftTopNew) {
                    this.listGiftTopNew.list = this.listGiftTopNew.list?.concat(
                        ListGift.fromJson(result.data?.list!)
                    )!;
                    this.listGiftTopNew.totalRecord = result.totalRecord();
                    this.listGiftTopNew.isLoading = false;
                }
            });
        }
        return result;
    };

    @action getListGift = async (
        params: IGetListGiftRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listGift)
            runInAction(() => {
                this.listGift = new ListGift();
            });
        else if (loadMore) {
            this.listGift.pageIndex += 1;
            this.listGift.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listGift.pageIndex
                : this.listGift.pageSize * this.listGift.pageIndex;
        }

        //request api service
        const result = (await GiftService.getInstance().getListGift(
            params
        )) as APIResponseList<Array<IGift>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listGift) {
                    this.listGift.list = this.listGift.list?.concat(
                        ListGift.fromJson(result.data?.list!)
                    )!;
                    this.listGift.totalRecord = result.totalRecord();
                    this.listGift.isLoading = false;
                }
            });
        } else {
            if (this.listGift) {
                this.listGift.isLoading = false;
            }
        }
        return result;
    };
}

export default GiftStore;
