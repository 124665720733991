import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//components
import { TopBanner } from './TopBanner/TopBanner';
import { MissionItem } from '../Home/Missions/MissionItem/MissionItem';
//styles
import {
    AppContentWrapper,
    AppContentContainer
} from '../../styles/App.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../providers/RootStoreProvider';
import { Row } from 'antd';
import { IGetListMissionRequest, IMission } from '../../types/IMission';
import { APIResponseList } from '../../models/APIResponse';
import { MissionViewWrapper } from './MissionView.styles';
import { MAIN_MENU } from '../../types/IGlobal';

interface Props {}

export const MissionView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { listMission, getListMission } = store.missionStore;
    const { changeMainMenuIndex } = store.globalStore;

    useEffect(() => {
        if (!listMission || !listMission.hasData()) onGetListMission();
        changeMainMenuIndex(MAIN_MENU.MISSION);
    }, []);

    //get list mission in home
    const onGetListMission = async () => {
        const params: IGetListMissionRequest = {};
        let result: APIResponseList<Array<IMission>> = await getListMission(
            params
        );
    };

    return (
        <AppContentWrapper>
            <AppContentContainer>
                <MissionViewWrapper>
                    <TopBanner></TopBanner>
                    <Row>
                        {listMission &&
                            listMission.list.map((item) => {
                                return (
                                    <MissionItem
                                        key={item.missionId}
                                        data={item}
                                    />
                                );
                            })}
                    </Row>
                </MissionViewWrapper>
            </AppContentContainer>
        </AppContentWrapper>
    );
});
