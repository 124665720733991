import styled from 'styled-components';
import { AppCard } from '../../../styles/App.styles';

export const GiftCategoryWrapper = styled.div``;

export const GiftCategoryContainer = styled(AppCard)`
    .ant-card-body {
        padding: 0;
    }
`;
