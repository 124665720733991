import styled from 'styled-components';
import { AppCard } from '../../../../../styles/App.styles';

export const InvitedHistoryWrapper = styled.div``;

export const InvitedHistoryContainer = styled(AppCard)`
    .ant-card-body {
        padding: 20px;
    }
`;

export const TotalInvited = styled.h1`
    font-size: 20px;
    color: #fff;
    font-weight: 700;
`;
