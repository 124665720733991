import { ComponentType, useEffect, useState } from 'react';
import pure from 'recompose/pure';
import QRCode from 'qrcode';

import {
    MissionContentContainer,
    MissionContentWrapper,
    MissionText
} from './MissionContent.styles';
import { MISSION_CONTENT_KEY } from '../../../types/IMission';
import User from '../../../models/User';
import { observer } from 'mobx-react-lite';

//components

//styles

interface Props {
    data: string;
    urls: any;
}

export const MissionContent: ComponentType<Props> = observer(
    ({ data, urls }) => {
        const [htmlResult, setHtmlResult] = useState('');

        useEffect(() => {
            renderContent();
        }, []);

        const renderContent = async () => {
            const qrAndroid = await QRCode.toDataURL(urls.urlAndroid);
            const qrIos = await QRCode.toDataURL(urls.urlIos);
            let result: any = data.replace(
                MISSION_CONTENT_KEY.QR_ANDROID,
                `<image src='${qrAndroid}' />`
            );
            result = result.replace(
                MISSION_CONTENT_KEY.QR_IOS,
                `<image src='${qrIos}' />`
            );

            result = result.replace(
                MISSION_CONTENT_KEY.URL_ANDROID,
                `<image src='${urls.urlAndroid}' />`
            );

            result = result.replace(
                MISSION_CONTENT_KEY.URL_IOS,
                `<image src='${urls.urlIos}' />`
            );
            setHtmlResult(result);
        };
        return (
            <MissionContentWrapper>
                <MissionContentContainer>
                    <MissionText
                        dangerouslySetInnerHTML={{
                            __html: htmlResult
                        }}></MissionText>
                </MissionContentContainer>
            </MissionContentWrapper>
        );
    }
);
