import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import { IGetListBannerRequest } from '../types/IBanner';
import { IGetConfigRequest } from '../types/IConfig';
import { IGetInfoHelpRequest, IGetInfoIntroRequest } from '../types/IIntro';
import {
    IGetListCityRequest,
    IGetListDistrictRequest
} from '../types/ILocation';
import BaseAuthService from './BaseAuthService';

export class CommonService extends BaseAuthService {
    private static instance: CommonService;

    public static getInstance(): CommonService {
        if (!CommonService.instance) {
            CommonService.instance = new CommonService();
        }
        return CommonService.instance;
    }

    getListBanner = async (params: IGetListBannerRequest) => {
        try {
            const response = await this.postData(API.BANNER.LIST, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListCity = async (params: IGetListCityRequest) => {
        try {
            const response = await this.postData(API.LOCATION.GET_LIST, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListDistrict = async (params: IGetListDistrictRequest) => {
        try {
            const response = await this.postData(API.LOCATION.GET_LIST, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getInfoIntro = async (params: IGetInfoIntroRequest) => {
        try {
            const response = await this.postData(API.GLOBAL.INTRO, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getInfoHelp = async (params: IGetInfoHelpRequest) => {
        try {
            const response = await this.postData(API.GLOBAL.HELP, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getConfigApp = async (params: IGetConfigRequest) => {
        try {
            const response = await this.postData(API.GLOBAL.CONFIG, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}
