import { ComponentType, useEffect, useState } from 'react';
import pure from 'recompose/pure';
import { Progress, Spin } from 'antd';
import { animateScroll as scroll, scrollSpy } from 'react-scroll';
//components

//styles
import {
    InputText,
    InputWrapper,
    SendButton,
    VerifyProcess,
    VerifyAccountContainer
} from './VerifyAccount.styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegistrationUserSchema } from '../../../../../validation/RegistrationUserSchema';
import { IRegisterUserInfo } from '../../../../../types/IUser';
import { APP_BUTTON_TYPE } from '../../../../../types/IGlobal';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../../../providers/RootStoreProvider';
import {
    IVerifyEmailRequest,
    IVerifyPhoneRequest,
    VERIFY_TYPE
} from '../../../../../types/ILogin';
import { ForgotPassSchema } from '../../../../../validation/ForgotPassSchema';
import { VerifyPhoneSchema } from '../../../../../validation/VerifyPhoneSchema';
import User from '../../../../../models/User';
import { IResponseBase } from '../../../../../types/ITypeBase';
import { OTPDialog } from './OTPDialog/OTPDialog';
import { delay } from '../../../../../utils';

interface Props {
    user: User;
}

export const VerifyAccount: ComponentType<Props> = observer(({ user }) => {
    const store = useRootStore();
    const {
        currentUser,
        loadingVerifyEmail,
        loadingVerifyPhone,
        verifyEmail,
        verifyPhone
    } = store.authStore;
    const { scrollVerify } = store.globalStore;
    const [openDialogOTP, setOpenDialogOTP] = useState(false);
    const [verifyType, setVerifyType] = useState(VERIFY_TYPE.EMAIL);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<IRegisterUserInfo>({
        resolver: yupResolver(ForgotPassSchema),
        defaultValues: {
            account: user.email
        }
    });

    useEffect(() => {
        if (scrollVerify) autoScroll();
    }, [scrollVerify]);

    const autoScroll = async () => {
        await delay(200);
        scroll.scrollTo(400);
    };

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 }
    } = useForm<IRegisterUserInfo>({
        resolver: yupResolver(VerifyPhoneSchema)
    });

    const onVerifyEmail = async (data: IRegisterUserInfo) => {
        const params: IVerifyEmailRequest = {
            data: {
                email: data.account
            }
        };
        const result: IResponseBase = await verifyEmail(params);
        if (result && result.isSuccess()) {
            console.log(result);
            setVerifyType(VERIFY_TYPE.EMAIL);
            setOpenDialogOTP(true);
        }
    };

    const onVerifyPhone = async (data: IRegisterUserInfo) => {
        const params: IVerifyPhoneRequest = {
            data: {
                phone: data.phone
            }
        };

        const result: IResponseBase = await verifyPhone(params);
        if (result && result.isSuccess()) {
            console.log(result);
            setOpenDialogOTP(true);
            setVerifyType(VERIFY_TYPE.PHONE);
        }
    };

    const onOpenDialogOTP = () => {
        setOpenDialogOTP(true);
    };

    const onVerifySuccess = (type: VERIFY_TYPE) => {
        if (type === VERIFY_TYPE.EMAIL) {
            currentUser?.updateVerifyEmailStatus(true);
        } else if (type === VERIFY_TYPE.PHONE) {
            currentUser?.updateVerifyPhoneStatus(true);
        }
    };

    return (
        <VerifyAccountContainer title="XÁC THỰC TÀI KHOẢN">
            <form
                key={1}
                onSubmit={handleSubmit(onVerifyEmail)}
                autoComplete="off">
                <InputWrapper>
                    <InputText
                        {...register('account')}
                        isError={errors.account ? 'error' : ''}
                        //defaultValue={currentUser?.email}
                        readOnly={true}
                        placeholder="Email"
                    />
                    {!currentUser?.verifyEmail ? (
                        !loadingVerifyEmail ? (
                            <SendButton
                                type="submit"
                                typeButton={APP_BUTTON_TYPE.PRIMARY}>
                                Xác thực email
                            </SendButton>
                        ) : (
                            <SendButton
                                typeButton={APP_BUTTON_TYPE.PRIMARY}
                                style={{ pointerEvents: 'none' }}>
                                <Spin></Spin>
                            </SendButton>
                        )
                    ) : (
                        <VerifyProcess
                            type="circle"
                            percent={100}
                            size="small"
                        />
                    )}
                </InputWrapper>
            </form>
            <form
                key={2}
                onSubmit={handleSubmit2(onVerifyPhone)}
                autoComplete="off">
                <InputWrapper>
                    <InputText
                        {...register2('phone')}
                        isError={errors2.phone ? 'error' : ''}
                        value={currentUser?.phone}
                        readOnly={true}
                        placeholder="Email"
                    />

                    {!currentUser?.verifyPhone ? (
                        !loadingVerifyPhone ? (
                            <SendButton
                                type="submit"
                                typeButton={APP_BUTTON_TYPE.PRIMARY}>
                                Xác thực điện thoại
                            </SendButton>
                        ) : (
                            <SendButton
                                typeButton={APP_BUTTON_TYPE.PRIMARY}
                                style={{ pointerEvents: 'none' }}>
                                <Spin></Spin>
                            </SendButton>
                        )
                    ) : (
                        <VerifyProcess
                            type="circle"
                            percent={100}
                            size="small"
                        />
                    )}
                </InputWrapper>
            </form>

            {openDialogOTP ? (
                <OTPDialog
                    visible={openDialogOTP}
                    verifyType={verifyType}
                    onVerifySuccess={onVerifySuccess}
                    email={currentUser?.email!}
                    phone={currentUser?.phone!}
                    onClose={() => setOpenDialogOTP(false)}
                />
            ) : null}
        </VerifyAccountContainer>
    );
});
