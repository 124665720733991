import { ComponentType, useState } from 'react';
import pure from 'recompose/pure';

//components
import { PlayDialog } from '../PlayDialog/PlayDialog';
//styles
import {
    VideoItemWrapper,
    VideoThumbnailContainer,
    VideoThumbnail,
    PlayContainer
} from './VideoItem.styles';
import { PlayVideoIcon } from '../../../../components/SvgIcon';
import Video from '../../../../models/Video';
import { VideoPlayer } from '../../../../components/VideoPlayer/VideoPlayer';

interface Props {
    videoInfo: Video;
}

export const VideoItem: ComponentType<Props> = pure(({ videoInfo }) => {
    const [openDialogPlayVideo, setOpenDialogPlayVideo] = useState(false);

    return (
        <VideoItemWrapper>
            <VideoThumbnailContainer>
                <PlayContainer onClick={() => setOpenDialogPlayVideo(true)}>
                    <PlayVideoIcon />
                </PlayContainer>
                <VideoThumbnail src={videoInfo.thumbPath}></VideoThumbnail>
            </VideoThumbnailContainer>
            {openDialogPlayVideo ? (
                <VideoPlayer
                    open={openDialogPlayVideo}
                    video={videoInfo}
                    onClose={() => setOpenDialogPlayVideo(false)}></VideoPlayer>
            ) : null}
        </VideoItemWrapper>
    );
});
