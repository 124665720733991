import { Drawer } from 'antd';
import styled from 'styled-components';
import { device } from '../../configs/MediaQuery';

export const HomeWrapper = styled.div`
    width: 100%;
    background-color: #19181c;
    min-height: 600px;
`;

export const HomeContainer = styled.div`
    width: 1100px;
    margin: 0 auto;
`;

export const TopBoardContainer = styled.div`
    position: fixed;
    right: 0;
    top: 150px;
    z-index: 100;
    background-color: #19181c;
    margin-right: -100px;
    transition: all 100ms;
    cursor: pointer;

    &:hover {
        margin-right: 0px;
        transition: all 100ms;
    }
`;

export const TopBoardImage = styled.img`
    height: 59px;
    width: 192px;
`;
