import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { useNavigate } from 'react-router-dom';
//components

//styles
import {
    MissionItemWrapper,
    MissionItemContainer,
    MissionBannerWrapper,
    MissionBannerImage,
    InfoContainer,
    MissionName,
    MissionDesc
} from './MissionItem.styles';
import Mission from '../../../../models/Mission';
import { useRootStore } from '../../../../providers/RootStoreProvider';

interface Props {
    data?: Mission;
}

export const MissionItem: ComponentType<Props> = pure(({ data }) => {
    const store = useRootStore();
    const navigation = useNavigate();

    const { setMissionData } = store.missionStore;

    const onGoMission = () => {
        if (data?.isLinkExternal()) window.open(data.url, '_blank');
        else if (data?.isInstallApp()) {
            setMissionData(data);
            const url = data?.url! + `?appId=${data.appId}`;
            navigation(data?.url!);
        } else navigation(data?.url!);
    };

    return (
        <MissionItemWrapper
            xl={8}
            md={8}
            sm={24}
            xs={24}
            onClick={() => onGoMission()}>
            <MissionItemContainer>
                <MissionBannerWrapper>
                    <MissionBannerImage src={data?.image} />
                </MissionBannerWrapper>
                <InfoContainer>
                    <MissionName>{data?.missionName}</MissionName>
                    <MissionDesc>{data?.description}</MissionDesc>
                </InfoContainer>
            </MissionItemContainer>
        </MissionItemWrapper>
    );
});
