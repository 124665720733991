import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    WinDialogWrapper,
    WinDialogContainer,
    WinDialogModal,
    TitleContainer,
    TitleText,
    PrizeNameWrapper,
    DescriptionText,
    PrizeName,
    PrizeImageWrapper,
    PrizeImage,
    ButtonWrapper,
    ButtonContinue,
    ButtonBag,
    GiftSerial
} from './WinDialog.styles';
import { AppButton } from '../../../styles/AppButton.styles';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import SpinGift from '../../../models/SpinGift';
import { useNavigate } from 'react-router-dom';
import { PROFILE_SCREEN } from '../../../configs/ScreenType';
import { useRootStore } from '../../../providers/RootStoreProvider';

interface Props {
    visible: boolean;
    onClose: () => void;
    giftWin: SpinGift;
}

export const WinDialog: ComponentType<Props> = pure(
    ({ visible, onClose, giftWin }) => {
        const store = useRootStore();
        const navigation = useNavigate();
        const { changeProfileScreen } = store.globalStore;
        const { toggleScrollToList } = store.giftBagStore;

        const onGoToBag = () => {
            toggleScrollToList(true);
            changeProfileScreen(PROFILE_SCREEN.BAG);
            navigation('/profile');
        };

        return (
            <WinDialogWrapper>
                <WinDialogContainer>
                    <WinDialogModal
                        centered
                        onCancel={onClose}
                        open={visible}
                        footer={null}>
                        <TitleContainer>
                            <TitleText>Chúc mừng trúng giải</TitleText>
                        </TitleContainer>
                        <PrizeImageWrapper>
                            <PrizeImage src={giftWin?.image}></PrizeImage>
                        </PrizeImageWrapper>
                        <PrizeNameWrapper>
                            <DescriptionText>
                                Chúc mừng bạn đã quay trúng giải thưởng:
                            </DescriptionText>
                            <PrizeName>{giftWin?.giftName}</PrizeName>
                            <GiftSerial>
                                Mã giải thưởng: {giftWin.serial.toUpperCase()}
                            </GiftSerial>

                            <ButtonWrapper>
                                <ButtonContinue
                                    typeButton={APP_BUTTON_TYPE.PRIMARY}
                                    onClick={() => onClose()}>
                                    Tiếp tục quay số
                                </ButtonContinue>

                                <ButtonBag
                                    typeButton={APP_BUTTON_TYPE.PRIMARY}
                                    onClick={() => onGoToBag()}>
                                    Túi đồ
                                </ButtonBag>
                            </ButtonWrapper>
                        </PrizeNameWrapper>
                    </WinDialogModal>
                </WinDialogContainer>
            </WinDialogWrapper>
        );
    }
);
