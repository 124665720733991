import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    VisitItemWrapper,
    VisitItemContainer,
    InfoContainer,
    VisitTitle,
    VisitDescription,
    ItemIConWrapper
} from './VisitItem.styles';
import UserHistoryVisit from '../../../../../../models/UserHistory';
import { RightSquareOutlined } from '@ant-design/icons';
import { formatNumber } from '../../../../../../utils/FormatUtils';
import moment from 'moment';

interface Props {
    data: UserHistoryVisit;
}

export const VisitItem: ComponentType<Props> = pure(({ data }) => {
    return (
        <VisitItemWrapper>
            <VisitItemContainer>
                <ItemIConWrapper>
                    <RightSquareOutlined
                        style={{ fontSize: 30, color: 'red' }}
                    />
                </ItemIConWrapper>
                <InfoContainer>
                    <VisitTitle>{data.title}</VisitTitle>
                    <VisitDescription>
                        Điểm thưởng: {formatNumber(data.point)}
                    </VisitDescription>
                    <VisitDescription>
                        {moment(data.date).format('MM/DD/YYYY HH:mm:ss')}
                    </VisitDescription>
                </InfoContainer>
            </VisitItemContainer>
        </VisitItemWrapper>
    );
});
