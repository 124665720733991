import * as Yup from 'yup';

export const RegistrationUserSchema = Yup.object().shape({
    account: Yup.string()
        .trim()
        .required('Email không được trống')
        .email('Email không hợp lệ'),
    passwd: Yup.string()
        .trim()
        .required('Mật khẩu không được trống')
        .min(6, 'Mật khẩu phải có ít nhất 6 ký tự')
        .max(40, 'Mật khẩu không được vượt quá 40 ký tự'),
    confirmPassword: Yup.string()
        .trim()
        .required('Xác nhận mật khẩu không được trống')
        .oneOf([Yup.ref('passwd'), null], 'Xác nhận mật khẩu không trùng khớp'),
    phone: Yup.string()
        .trim()
        .required('Số điện thoại không được trống')
        .min(9, 'Số điện thoại không được nhỏ hơn 9 ký tự')
        .max(12, 'Số điện thoại không được lớn hơn 12 ký tự'),
    refid: Yup.string().trim(),
    acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required')
});
