import { Row } from 'antd';
import styled from 'styled-components';

export const GiftExchangeWrapper = styled.div`
    min-height: 1300px;
    //background-image: url('../images/bg-mission.png');
    background-color: #19181c;
    background-position: center;
    background-size: cover;
`;

export const GiftExchangeContent = styled(Row)``;
