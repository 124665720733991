import { ComponentType, useEffect, useState } from 'react';
import pure from 'recompose/pure';
import { AppCard } from '../../../styles/App.styles';
import { Modal, Select, Space } from 'antd';

//components
import { ShopItem } from './ShopItem/ShopItem';
import { Col, Row, Spin } from 'antd';
import { AppButton } from '../../../styles/AppButton.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { IGetListGiftCategory } from '../../../types/IGiftCategory';
import React from 'react';

//styles
import {
    GiftCategoryText,
    GiftCategoryWrapper,
    ShopWrapper
} from './ShopView.styles';
import {
    GIFT_TYPE,
    IBuyGiftRequest,
    IGetListGiftRequest
} from '../../../types/IGift';
import { FConfirmDialog } from '../../../components/FConfirmDialog/FConfirmDialog';
import Gift from '../../../models/Gift';
import { showMessage } from '../../../utils';
import { formatNumber } from '../../../utils/FormatUtils';
import { NoData } from '../../CommonView/NoData/NoData';
import GiftCategory from '../../../models/GiftCategory';
import { SuccessDialog } from '../SuccessDialog/SuccessDialog';

interface Props {}

const { Option } = Select;

export const ShopView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const {
        listGift,
        buyGift,
        currentGiftExchange,
        setCurrentGiftExchange,
        showSuccessExchangeDialog,
        toggleShowSuccessExchangeDialog,
        listGiftCategory,
        getListGiftCategory,
        getListGift,
        giftCategory,
        setGiftCategory
    } = store.giftStore;

    const { currentUser } = store.authStore;
    const [showDialogConfirm, setShowDialogConfirm] = useState(false);
    const [giftData, setGiftData] = useState<Gift>();
    const [giftCatId, setGiftCatId] = React.useState('');

    useEffect(() => {
        //if (!listGiftCategory || !listGiftCategory.hasData())
        onGetListGiftCategory();
    }, []);

    const onGetListGiftCategory = async () => {
        const params: IGetListGiftCategory = {};
        const result = await getListGiftCategory(params);
        if (result && result.isSuccess()) {
            if (result.data?.list && result.data?.list.length > 0) {
                setGiftCatId(result.data?.list[0].catId!);
                setGiftCategory(GiftCategory.fromJson(result.data?.list[0]));
            }
            onGetListGift(Number(result.data?.list[0].catId!));
        }
    };

    const onGetListGift = async (catId: number) => {
        const params: IGetListGiftRequest = {
            data: {
                type: GIFT_TYPE.ALL,
                catId: catId
            }
        };
        const result = await getListGift(params);
    };

    const onConfirm = async () => {
        const params: IBuyGiftRequest = {
            data: {
                giftId: giftData?.giftId!,
                point: giftData?.point!
            }
        };
        const result = await buyGift(params);
        const msgSuccess =
            'Chúc mừng bạn đã đổi quà thành công. Vui lòng kiểm tra túi đồ để sử dụng';
        setShowDialogConfirm(false);

        if (result.isSuccess()) {
            currentUser?.updateTotalPoint(giftData?.point!, false, true);
            setCurrentGiftExchange(giftData!);
            toggleShowSuccessExchangeDialog(true);
            return;
        } else showMessage(result, msgSuccess);
    };

    const onChangeGiftCategory = (value: any) => {
        setGiftCatId(value);
        onGetListGift(value);
    };

    const onBuyGift = async (gift: Gift) => {
        setGiftData(gift);
        setShowDialogConfirm(true);
    };

    const renderExtra = () => {
        return (
            <>
                {listGiftCategory && listGiftCategory?.list.length > 0 ? (
                    <GiftCategoryWrapper>
                        <GiftCategoryText>Chọn loại quà </GiftCategoryText>
                        <Select
                            defaultValue={giftCatId}
                            value={giftCatId}
                            style={{ width: 200 }}
                            onChange={onChangeGiftCategory}>
                            {listGiftCategory?.list.map((item) => {
                                return (
                                    <Option key={item.catId} value={item.catId}>
                                        {item.catName}
                                    </Option>
                                );
                            })}
                        </Select>
                    </GiftCategoryWrapper>
                ) : null}
            </>
        );
    };

    const buildDescription = () => {
        const description = `Bạn có chắc muốn dùng <b> ${formatNumber(
            giftData?.point!
        )} </b> để đổi quà <b>${giftData?.giftName}</b>?`;
        return description;
    };

    return (
        <ShopWrapper>
            <AppCard
                title={`${giftCategory?.catName} (${listGift?.totalRecord
                    .toString()
                    .padStart(2, '0')})`}
                extra={null}>
                <Row>
                    {listGift &&
                        listGift.list.map((item) => {
                            return (
                                <Col
                                    key={item.giftId}
                                    xl={12}
                                    md={12}
                                    sm={24}
                                    xs={24}>
                                    <ShopItem
                                        onBuyGift={onBuyGift}
                                        key={item.giftId}
                                        data={item}
                                    />
                                </Col>
                            );
                        })}
                </Row>
                {listGift && listGift.isLoading ? <Spin /> : null}
                {!listGift?.hasData() && !listGift?.isLoading ? (
                    <NoData description="Không có quà nào được tìm thấy !" />
                ) : null}
            </AppCard>
            {showDialogConfirm && giftData ? (
                <FConfirmDialog
                    visible={showDialogConfirm}
                    title="Xác nhận đổi quà"
                    description={buildDescription()}
                    onConfirm={onConfirm}
                    image={giftData.image}
                    onClose={() =>
                        setShowDialogConfirm(false)
                    }></FConfirmDialog>
            ) : null}
            {showSuccessExchangeDialog && currentGiftExchange ? (
                <SuccessDialog
                    giftWin={currentGiftExchange}
                    visible={showSuccessExchangeDialog}
                    onClose={() => toggleShowSuccessExchangeDialog(false)}
                />
            ) : null}
        </ShopWrapper>
    );
});
