import styled from 'styled-components';
import { AppButton } from '../../../../styles/AppButton.styles';
import Image from 'rc-image';

export const UserLoginWrapper = styled.div`
    height: 150px;
    background-color: #19181c;
`;

export const AvatarWrapper = styled.div`
    position: relative;
    padding-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AvatarImage = styled(Image)`
    width: 80px;
    height: 80px;
    margin-right: 40px;
    border-radius: 40px;
    border: solid 1px #fff;
    object-fit: cover;
    opacity: 1;
`;

export const UserLoginContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const UserNameText = styled.p`
    margin-top: 10px;
    margin-bottom: 1px;
    color: red;
    font-size: 18px;
    font-weight: 600;
`;

export const UserPoint = styled.p`
    margin: 0px;
    margin-bottom: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
`;

export const LoginButton = styled(AppButton)``;

export const LogoutButton = styled(AppButton)``;

export const ProfileButton = styled(AppButton)``;

export const BagButton = styled(AppButton)``;
