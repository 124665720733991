import { IIntro } from '../types/IIntro';

export default class Intro {
    public title: string;
    public content: string;
    constructor(title: string, content: string) {
        this.title = title;
        this.content = content;
    }

    static fromJson = (json: IIntro): Intro => {
        return new Intro(json.title, json.content);
    };
}
