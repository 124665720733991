import { ComponentType, useState } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    GiftDesc,
    GiftName,
    GiftNewItemWrapper,
    GiftThumbnail,
    GiftThumbnailContainer,
    InfoContainer
} from './GiftNewItem.styles';
import Gift from '../../../../models/Gift';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { FConfirmDialog } from '../../../../components/FConfirmDialog/FConfirmDialog';
import { Modal } from 'antd';
import { IBuyGiftRequest } from '../../../../types/IGift';
import { observer } from 'mobx-react-lite';
import { formatNumber } from '../../../../utils/FormatUtils';
import { showMessage } from '../../../../utils';

interface Props {
    data: Gift;
}

export const GiftNewItem: ComponentType<Props> = observer(({ data }) => {
    const store = useRootStore();
    const { currentUser } = store.authStore;
    const { buyGift, setCurrentGiftExchange, toggleShowSuccessExchangeDialog } =
        store.giftStore;

    const [showDialogConfirm, setShowDialogConfirm] = useState(false);

    const handleBuyGift = () => {
        setShowDialogConfirm(true);
    };

    const buildDescription = () => {
        const description = `Bạn có chắc muốn dùng <b> ${formatNumber(
            data?.point
        )} </b> để đổi quà <b>${data?.giftName}?</b>`;
        return description;
    };

    const onConfirm = async () => {
        const params: IBuyGiftRequest = {
            data: {
                giftId: data?.giftId!,
                point: data?.point!
            }
        };
        const result = await buyGift(params);
        setShowDialogConfirm(false);
        const msgSuccess =
            'Chúc mừng bạn đã đổi quà thành công. Vui lòng kiểm tra túi đồ để sử dụng';

        if (result.isSuccess()) {
            currentUser?.updateTotalPoint(data?.point!, false, true);
            setCurrentGiftExchange(data);
            toggleShowSuccessExchangeDialog(true);
            return;
        } else showMessage(result, msgSuccess);
    };

    return (
        <GiftNewItemWrapper>
            <GiftThumbnailContainer onClick={() => handleBuyGift()}>
                <GiftThumbnail src={data.image}></GiftThumbnail>
                <InfoContainer>
                    <GiftName>{data.giftName}</GiftName>
                    <GiftDesc>Điểm đổi: {formatNumber(data.point)}</GiftDesc>
                </InfoContainer>
            </GiftThumbnailContainer>

            {showDialogConfirm ? (
                <FConfirmDialog
                    visible={showDialogConfirm}
                    title="Xác nhận đổi quà"
                    description={buildDescription()}
                    onConfirm={onConfirm}
                    image={data.image}
                    onClose={() =>
                        setShowDialogConfirm(false)
                    }></FConfirmDialog>
            ) : null}
        </GiftNewItemWrapper>
    );
});
