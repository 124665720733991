import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';

export const ItemWrapper = styled.div`
    height: 110px;
    background-color: #252525;

    :not(:last-child) {
        border-bottom: solid 1px #313435;
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    padding: 15px;
`;

export const AvatarContainer = styled.div``;

export const AvatarImage = styled.img`
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 40px;
    border: solid 1px #fff;
`;

export const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    justify-content: center;
`;

export const UserName = styled.h1`
    margin: 0;
    color: #ff2423;
    font-weight: 700;
    @media ${device.mobileS} {
        font-size: 18px;
    }
    @media ${device.laptop} {
        font-size: 22px;
    }
`;

export const UserPoint = styled.p`
    margin: 0;
    color: #fff;
    font-weight: 500;
    @media ${device.mobileS} {
        font-size: 16px;
    }
    @media ${device.laptop} {
        font-size: 18px;
    }
`;
