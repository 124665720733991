import { IGiftBag } from '../types/IGiftBag';
import { DataList } from './DataList';

export default class GiftBag {
    public catId: number;
    public giftId: string;
    public giftName: string;
    public image: string;
    public giftCode: string;
    public giftBagType: number;
    public value: number;
    public order: number;
    public bagId: number;

    constructor(
        catId: number,
        giftId: string,
        giftName: string,
        image: string,
        giftCode: string,
        giftBagType: number,
        value: number,
        order: number,
        bagId: number
    ) {
        this.catId = catId;
        this.giftId = giftId;
        this.giftName = giftName;
        this.image = image;
        this.giftCode = giftCode;
        this.giftBagType = giftBagType;
        this.value = value;
        this.order = order;
        this.bagId = bagId;
    }

    static fromJson = (json: IGiftBag): GiftBag => {
        return new GiftBag(
            json.catId,
            json.giftId,
            json.giftName,
            json.giftImg,
            json.code,
            json.type,
            json.value,
            json.order,
            json.bagId
        );
    };
}

export class ListGiftBag extends DataList<GiftBag> {
    static fromJson = (jsonArray: Array<IGiftBag>): Array<GiftBag> => {
        if (!jsonArray) return [];
        let data = new Array<GiftBag>();
        jsonArray.forEach((item) => {
            data.push(GiftBag.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
