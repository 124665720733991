import { Col } from 'antd';
import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';
import { AppButton } from '../../../styles/AppButton.styles';

export const MissionWrapper = styled.div`
    width: 100%;
    @media ${device.mobileS} {
        padding-top: 0px;
        background-color: #19181c;
        padding-top: 42px;
    }
    @media ${device.laptop} {
        //padding-top: 160px;
        background-image: url('../../../images/bg-mission.png');
        background-color: #19181c;
        background-position: center;
        background-size: cover;
        padding-bottom: 20px;
    }
`;

export const LineDivider = styled.div`
    width: 100%;
    background-color: #707070;

    @media ${device.mobileS} {
        height: 0px;
    }
    @media ${device.laptop} {
        height: 1px;
        margin-bottom: 40px;
    }
`;

export const MissionContainer = styled.div`
    max-width: 1100px;
    margin: 0 auto;
`;

export const MissionTitle = styled.h1`
    font-weight: 700;
    color: #fff;

    @media ${device.mobileS} {
        font-size: 32px;
    }
    @media ${device.laptop} {
        font-size: 40px;
    }
`;

export const MissionDescription = styled.p`
    font-weight: 400;
    @media ${device.mobileS} {
        font-size: 14px;
    }
    @media ${device.laptop} {
        font-size: 16px;
    }
`;

export const ButtonMore = styled(AppButton)``;
