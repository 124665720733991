import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//components
import { InfoView } from './InfoView/InfoView';
import { StatsView } from './StatsView/StatsView';
import { PartnerView } from './PartnerView/PartnerView';
//styles
import { IntroWrapper, IntroContainer } from './IntroView.styles';
import { useRootStore } from '../../providers/RootStoreProvider';
import { MAIN_MENU, RULES_CONTENT } from '../../types/IGlobal';
import { IGetInfoIntroRequest, IIntro } from '../../types/IIntro';
import { APIResponse } from '../../models/APIResponse';
import { observer } from 'mobx-react-lite';

interface Props {}

export const IntroView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { changeMainMenuIndex } = store.globalStore;
    const { introInfo, getInfoIntro } = store.commonStore;

    useEffect(() => {
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        changeMainMenuIndex(MAIN_MENU.INTRO);
        onGetIntroInfo();
    }, []);

    const onGetIntroInfo = async () => {
        const params: IGetInfoIntroRequest = {
            data: {
                type: RULES_CONTENT.INTRO
            }
        };
        let result: APIResponse<IIntro> = await getInfoIntro(params);
    };

    return (
        <IntroWrapper>
            <IntroContainer>
                {introInfo ? <InfoView data={introInfo} /> : null}
                {/* <StatsView />
                <PartnerView /> */}
            </IntroContainer>
        </IntroWrapper>
    );
});
