import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';
import { Col, Row } from 'antd';

export const BannerWrapper = styled.div`
    @media ${device.mobileS} {
        min-height: 242px;
    }
    @media ${device.laptop} {
        min-height: 530px;
        margin-bottom: 160px;
    }
`;

export const BannerContainer = styled.div`
    max-width: 100%;
    min-height: 530px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #19181c;
`;

export const SwiperSlideImage = styled.img`
    width: 100%;
    object-fit: cover;
    @media ${device.mobileS} {
        height: 242px;
    }
    @media ${device.laptop} {
        height: 530px;
    }
`;

export const TopBannerWrapper = styled(Row)`
    height: auto;
    z-index: 1;

    @media ${device.mobileS} {
        margin-top: -50px;
        position: relative;
        background-color: transparent;
    }
    @media ${device.laptop} {
        width: 1100px;
        bottom: -110px;
        left: 50%;
        transform: translate(-50%, 0%);
        position: absolute;
        background-color: transparent;
    }
`;

export const TopBannerContainer = styled(Col)`
    padding: 15px;
    display: flex;
    justify-content: center;
`;

export const TopBannerItem = styled.img`
    width: 308px;
    object-fit: cover;
    border: solid 1px #fff;

    @media ${device.mobileS} {
        height: 249px;
    }
    @media ${device.laptop} {
        height: 216px;
    }
`;

export const TopLeftBannerItem = styled(TopBannerItem)`
    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptop} {
        order: 1;
        width: 100%;
    }
`;

export const TopRightBannerItem = styled(TopBannerItem)`
    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptop} {
        order: 3;
        width: 100%;
    }
`;

export const TopMidBannerItem = styled(TopBannerItem)`
    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptop} {
        transform: scale(1.3);
        width: 100%;
    }
`;
