import { Modal } from 'antd';
import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';

export const BuyDialogWrapper = styled.section`
    max-width: 460px;
`;

export const BuyDialogContainer = styled.div`
    width: 100%;
    text-align: center;
    color: #314154;
`;

export const BuyDialogModal = styled(Modal)`
    .ant-modal-body {
        text-align: center;
    }
`;

export const TitleContainer = styled.div`
    padding-top: 10px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const TitleText = styled.h1`
    font-weight: 700;
    margin: 0;
    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        font-size: 20px;
        line-height: 30px;
    }
`;

export const DescriptionText = styled.p`
    font-weight: 500;
    margin: 0;
    margin-right: 10px;
    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        font-size: 16px;
        line-height: 30px;
    }
`;
