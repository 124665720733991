import {
    IUserFeedbackRequest,
    IGetUserProfileDetailRequest,
    IUserRegisterRequest,
    IGetListUserRankRequest,
    IUserDailyVisitRequest,
    IUploadAvatarRequest,
    IUpdateProfileRequest
} from '../types/IUser';
import { handleError } from '../helpers/APIHelper';
import BaseAuthService from './BaseAuthService';
import { API } from '../configs/APIEndPoint';

class UserService extends BaseAuthService {
    private static instance: UserService;

    public static getInstance(): UserService {
        if (!UserService.instance) {
            UserService.instance = new UserService();
        }
        return UserService.instance;
    }

    //methods api
    sendFeedback = async (params: IUserFeedbackRequest) => {
        try {
            const response = await this.postData(API.USER.CONTACT, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    registerUser = async (params: IUserRegisterRequest) => {
        try {
            const response = await this.postData(API.USER.REGISTER, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    //get user profile details
    getProfileDetail = async (params: IGetUserProfileDetailRequest) => {
        try {
            const response = await this.postData(
                API.USER.PROFILE_DETAIL,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListUserRank = async (params: IGetListUserRankRequest) => {
        try {
            const response = await this.postData(API.USER.RANK, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    dailyVisit = async (params: IUserDailyVisitRequest) => {
        try {
            const response = await this.postData(API.USER.VISIT, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    uploadAvatar = async (params: IUploadAvatarRequest) => {
        try {
            const response = await this.postData(API.USER.UPLOAD_IMAGE, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    updateProfile = async (params: IUpdateProfileRequest) => {
        try {
            const response = await this.postData(
                API.USER.UPDATE_PROFILE,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}
export default UserService;
