import { makeObservable, action, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import JSonService from '../services/JSonService';
import {
    ListUserHistoryBuyGift,
    ListUserHistoryInvited,
    ListUserHistorySpin,
    ListUserHistoryVideo,
    ListUserHistoryVisit,
    UserHistoryInvitedConfig
} from '../models/UserHistory';
import { HistoryService } from '../services/HistoryService';
import {
    IGetListUserHistoryBuyGift,
    IGetListUserHistoryInvited,
    IGetListUserHistorySpin,
    IGetListUserHistoryVideo,
    IGetListUserHistoryVisit,
    IUserHistoryBuyGift,
    IUserHistoryInvited,
    IUserHistorySpin,
    IUserHistoryVisit
} from '../types/IUserHistory';
import { APIResponseList } from '../models/APIResponse';

class HistoryStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    //declare variables
    @observable listUserHistoryVisit: ListUserHistoryVisit | null = null;
    @observable listUserHistoryInvited: ListUserHistoryInvited | null = null;
    @observable userHistoryInvitedConfig: UserHistoryInvitedConfig | null =
        null;
    @observable listUserHistoryVideo: ListUserHistoryVideo | null = null;
    @observable listUserHistorySpin: ListUserHistorySpin | null = null;
    @observable listUserHistoryBuyGift: ListUserHistoryBuyGift | null = null;

    //declare action
    @action getListUserHistoryVisit = async (
        params: IGetListUserHistoryVisit,
        loadMore?: boolean,
        pageIndex?: number
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listUserHistoryVisit)
            runInAction(() => {
                this.listUserHistoryVisit = new ListUserHistoryVisit();
                this.listUserHistoryVisit.pageIndex = 0;
            });
        else if (loadMore && !pageIndex) {
            this.listUserHistoryVisit.pageIndex += 1; //load more
        } else if (loadMore && pageIndex != 0) {
            this.listUserHistoryVisit.pageIndex = pageIndex! - 1; //paging
        }
        if (this.listUserHistoryVisit) {
            this.listUserHistoryVisit.isLoading = true; // determine if we're loading data is show process in UI
            params.data.size = params.data.size
                ? params.data.size
                : this.listUserHistoryVisit.pageSize;
            params.data.from = params.data.size
                ? params.data.size * this.listUserHistoryVisit.pageIndex
                : this.listUserHistoryVisit.pageSize *
                  this.listUserHistoryVisit.pageIndex;
        }

        //request api service
        const result = (await HistoryService.getInstance().getListHistoryVisit(
            params
        )) as APIResponseList<Array<IUserHistoryVisit>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listUserHistoryVisit) {
                    if (loadMore && !pageIndex)
                        this.listUserHistoryVisit.list =
                            this.listUserHistoryVisit.list?.concat(
                                ListUserHistoryVisit.fromJson(
                                    result.data?.list!
                                )
                            )!;
                    else
                        this.listUserHistoryVisit.list =
                            ListUserHistoryVisit.fromJson(result.data?.list!);
                    this.listUserHistoryVisit.totalRecord =
                        result.totalRecord();
                    this.listUserHistoryVisit.isLoading = false;
                }
            });
        } else this.listUserHistoryVisit!.isLoading = false;
        return result;
    };

    @action getListUserHistoryInvited = async (
        params: IGetListUserHistoryInvited,
        loadMore?: boolean,
        pageIndex?: number
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listUserHistoryInvited)
            runInAction(() => {
                this.listUserHistoryInvited = new ListUserHistoryVisit();
                this.listUserHistoryInvited.pageIndex = 0;
            });
        else if (loadMore && !pageIndex) {
            this.listUserHistoryInvited.pageIndex += 1; //load more
        } else if (loadMore && pageIndex != 0) {
            this.listUserHistoryInvited.pageIndex = pageIndex! - 1; //paging
        }
        if (this.listUserHistoryInvited) {
            this.listUserHistoryInvited.isLoading = true; // determine if we're loading data is show process in UI
            params.data.size = params.data.size
                ? params.data.size
                : this.listUserHistoryInvited.pageSize;
            params.data.from = params.data.size
                ? params.data.size * this.listUserHistoryInvited.pageIndex
                : this.listUserHistoryInvited.pageSize *
                  this.listUserHistoryInvited.pageIndex;
        }

        //request api service
        const result =
            (await HistoryService.getInstance().getListHistoryInvited(
                params
            )) as APIResponseList<Array<IUserHistoryInvited>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listUserHistoryInvited) {
                    if (loadMore && !pageIndex)
                        this.listUserHistoryInvited.list =
                            this.listUserHistoryInvited.list?.concat(
                                ListUserHistoryVisit.fromJson(
                                    result.data?.list!
                                )
                            )!;
                    else
                        this.listUserHistoryInvited.list =
                            ListUserHistoryVisit.fromJson(result.data?.list!);
                    this.listUserHistoryInvited.totalRecord =
                        result.totalRecord();
                    this.listUserHistoryInvited.isLoading = false;
                    this.userHistoryInvitedConfig =
                        UserHistoryInvitedConfig.fromJson(result.data?.extra);
                }
            });
        } else this.listUserHistoryInvited!.isLoading = false;
        return result;
    };

    @action getListUserHistoryVideo = async (
        params: IGetListUserHistoryVideo,
        loadMore?: boolean,
        pageIndex?: number
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listUserHistoryVideo)
            runInAction(() => {
                this.listUserHistoryVideo = new ListUserHistoryVideo();
                this.listUserHistoryVideo.pageIndex = 0;
            });
        else if (loadMore && !pageIndex) {
            this.listUserHistoryVideo.pageIndex += 1; //load more
        } else if (loadMore && pageIndex != 0) {
            this.listUserHistoryVideo.pageIndex = pageIndex! - 1; //paging
        }
        if (this.listUserHistoryVideo) {
            this.listUserHistoryVideo.isLoading = true; // determine if we're loading data is show process in UI
            params.data.size = params.data.size
                ? params.data.size
                : this.listUserHistoryVideo.pageSize;
            params.data.from = params.data.size
                ? params.data.size * this.listUserHistoryVideo.pageIndex
                : this.listUserHistoryVideo.pageSize *
                  this.listUserHistoryVideo.pageIndex;
        }

        //request api service
        const result = (await HistoryService.getInstance().getListHistoryVideo(
            params
        )) as APIResponseList<Array<IUserHistoryInvited>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listUserHistoryVideo) {
                    if (loadMore && !pageIndex)
                        this.listUserHistoryVideo.list =
                            this.listUserHistoryVideo.list?.concat(
                                ListUserHistoryVideo.fromJson(
                                    result.data?.list!
                                )
                            )!;
                    else
                        this.listUserHistoryVideo.list =
                            ListUserHistoryVideo.fromJson(result.data?.list!);
                    this.listUserHistoryVideo.totalRecord =
                        result.totalRecord();
                    this.listUserHistoryVideo.isLoading = false;
                }
            });
        } else this.listUserHistoryVideo!.isLoading = false;
        return result;
    };

    @action getListUserHistorySpin = async (
        params: IGetListUserHistorySpin,
        loadMore?: boolean,
        pageIndex?: number
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listUserHistorySpin)
            runInAction(() => {
                this.listUserHistorySpin = new ListUserHistorySpin();
                this.listUserHistorySpin.pageIndex = 0;
            });
        else if (loadMore && !pageIndex) {
            this.listUserHistorySpin.pageIndex += 1; //load more
        } else if (loadMore && pageIndex != 0) {
            this.listUserHistorySpin.pageIndex = pageIndex! - 1; //paging
        }

        if (this.listUserHistorySpin) {
            this.listUserHistorySpin.isLoading = true; // determine if we're loading data is show process in UI
            params.data.size = params.data.size
                ? params.data.size
                : this.listUserHistorySpin.pageSize;
            params.data.from = params.data.size
                ? params.data.size * this.listUserHistorySpin.pageIndex
                : this.listUserHistorySpin.pageSize *
                  this.listUserHistorySpin.pageIndex;
        }

        //request api service
        const result = (await HistoryService.getInstance().getListHistorySpin(
            params
        )) as APIResponseList<Array<IUserHistorySpin>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listUserHistorySpin) {
                    if (loadMore && !pageIndex)
                        this.listUserHistorySpin.list =
                            this.listUserHistorySpin.list?.concat(
                                ListUserHistorySpin.fromJson(result.data?.list!)
                            )!;
                    else
                        this.listUserHistorySpin.list =
                            ListUserHistorySpin.fromJson(result.data?.list!);
                    this.listUserHistorySpin.totalRecord = result.totalRecord();
                    this.listUserHistorySpin.isLoading = false;
                }
            });
        } else this.listUserHistorySpin!.isLoading = false;
        return result;
    };

    @action getListUserHistoryBuyGift = async (
        params: IGetListUserHistoryBuyGift,
        loadMore?: boolean,
        pageIndex?: number
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listUserHistoryBuyGift)
            runInAction(() => {
                this.listUserHistoryBuyGift = new ListUserHistoryBuyGift();
                this.listUserHistoryBuyGift.pageIndex = 0;
            });
        else if (loadMore && !pageIndex) {
            this.listUserHistoryBuyGift.pageIndex += 1; //load more
        } else if (loadMore && pageIndex != 0) {
            this.listUserHistoryBuyGift.pageIndex = pageIndex! - 1; //paging
        }
        if (this.listUserHistoryBuyGift) {
            this.listUserHistoryBuyGift.isLoading = true;
            params.data.size = params.data.size
                ? params.data.size
                : this.listUserHistoryBuyGift.pageSize; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listUserHistoryBuyGift.pageIndex
                : this.listUserHistoryBuyGift.pageSize *
                  this.listUserHistoryBuyGift.pageIndex;
        }

        //request api service
        const result =
            (await HistoryService.getInstance().getListHistoryBuyGift(
                params
            )) as APIResponseList<Array<IUserHistoryBuyGift>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listUserHistoryBuyGift) {
                    if (loadMore && !pageIndex)
                        this.listUserHistoryBuyGift.list =
                            this.listUserHistoryBuyGift.list?.concat(
                                ListUserHistoryBuyGift.fromJson(
                                    result.data?.list!
                                )
                            )!;
                    else
                        this.listUserHistoryBuyGift.list =
                            ListUserHistoryBuyGift.fromJson(result.data?.list!);

                    this.listUserHistoryBuyGift.totalRecord =
                        result.totalRecord();
                    this.listUserHistoryBuyGift.isLoading = false;
                } else this.listUserHistoryBuyGift!.isLoading = false;
            });
        }
        return result;
    };
}
export default HistoryStore;
