import { ComponentType, useEffect, useState } from 'react';
import pure from 'recompose/pure';

//components
import { BannerView } from './Banner/BannerView';
import { Missions } from './Missions/Missions';
import { TournamentView } from './Tournament/TournamentView';
import { VideoView } from './Video/VideoView';
import { MiniGame } from './MiniGame/MiniGame';

//styles
import { HomeWrapper } from './Home.styles';
import { useRootStore } from '../../providers/RootStoreProvider';
import { IBanner, IGetListBannerRequest } from '../../types/IBanner';
import { APIResponseList } from '../../models/APIResponse';
import {
    IGetListTournamentRequest,
    ITournament
} from '../../types/ITournament';
import { IGetListMatchRequest, IMatch } from '../../types/IMatch';
import { IGetListVideoRequest, IVideo } from '../../types/IVideo';
import { IGetListMiniGameRequest, IMiniGame } from '../../types/IMiniGame';
import { MAIN_MENU } from '../../types/IGlobal';
import { IGetListMissionRequest, IMission } from '../../types/IMission';
import { observer } from 'mobx-react-lite';

interface Props {}

export const Home: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { getListBanner } = store.commonStore;
    const { getListTournament, getListMatch } = store.tournamentStore;
    const { getListVideo } = store.videoStore;
    const { getListMiniGame } = store.miniGameStore;
    const { getListMission } = store.missionStore;
    const { changeMainMenuIndex } = store.globalStore;
    const { getHomeDataInfo } = store.jsonStore;
    const { appConfig, getConfigApp } = store.commonStore;

    useEffect(() => {
        onGetListBanner();
        onGetListMiniGame();
        onGetListMission();
        changeMainMenuIndex(MAIN_MENU.HOME);
        getHomeDataInfo();
    }, []);

    useEffect(() => {
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        if (appConfig && appConfig.showVideo) onGetListVideo();
        if (appConfig && appConfig.showTour) onGetListTournament();
    }, [appConfig]);

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    //get list banner in home
    const onGetListBanner = async () => {
        const params: IGetListBannerRequest = {};
        let result: APIResponseList<Array<IBanner>> = await getListBanner(
            params,
            false
        );
    };

    //get list video in home
    const onGetListVideo = async () => {
        const params: IGetListVideoRequest = {};
        let result: APIResponseList<Array<IVideo>> = await getListVideo(
            params,
            false
        );
    };

    //get list minigame in home
    const onGetListMiniGame = async () => {
        const params: IGetListMiniGameRequest = {};
        let result: APIResponseList<Array<IMiniGame>> = await getListMiniGame(
            params,
            false
        );
    };

    //get list mission in home
    const onGetListMission = async () => {
        const params: IGetListMissionRequest = {};
        let result: APIResponseList<Array<IMission>> = await getListMission(
            params
        );
    };

    //get list tournament in home
    const onGetListTournament = async () => {
        const params: IGetListTournamentRequest = {};
        let result: APIResponseList<Array<ITournament>> =
            await getListTournament(params, false);

        //get all match of first tournament
        if (result.isSuccess() && result.data?.list.length! > 0) {
            onGetListMatch(result.data?.list[0].tournamentId!);
        }
    };

    const onGetListMatch = async (tournamentId: any) => {
        const params: IGetListMatchRequest = {
            data: {
                tourid: tournamentId
            }
        };
        let result: APIResponseList<Array<IMatch>> = await getListMatch(
            params,
            false
        );
    };

    return (
        <HomeWrapper>
            <BannerView />
            <Missions />
            {appConfig && appConfig.showTour ? (
                <TournamentView onGetListMatch={onGetListMatch} />
            ) : null}
            {appConfig && appConfig.showVideo ? <VideoView /> : null}
            <MiniGame />
        </HomeWrapper>
    );
});
