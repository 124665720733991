import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { Row } from 'antd';

//components
import { GameItem } from './GameItem/GameItem';
//styles
import {
    GameWrapper,
    GameContainer,
    TitleWrapper,
    ViewMoreWrapper,
    ViewMoreTitle,
    ViewMoreArrow
} from './MiniGame.styles';
import { AppTitle } from '../../../styles/App.styles';
import { ArrowNextIcon } from '../../../components/SvgIcon';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';

interface Props {}

export const MiniGame: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { listMiniGame } = store.miniGameStore;
    const navigate = useNavigate();

    if (listMiniGame && listMiniGame.list.length > 0)
        return (
            <GameWrapper>
                <GameContainer>
                    <TitleWrapper>
                        <AppTitle>MINI GAMES</AppTitle>
                        <ViewMoreWrapper>
                            <ViewMoreTitle onClick={() => navigate('/game')}>
                                XEM TẤT CẢ
                            </ViewMoreTitle>
                            <ViewMoreArrow>
                                <ArrowNextIcon />
                            </ViewMoreArrow>
                        </ViewMoreWrapper>
                    </TitleWrapper>
                    <Row style={{ marginTop: 20 }}>
                        {listMiniGame &&
                            listMiniGame.list.slice(0, 3).map((item) => {
                                return (
                                    <GameItem game={item} key={item.gameId} />
                                );
                            })}
                    </Row>
                </GameContainer>
            </GameWrapper>
        );
    else return null;
});
