import { IMatch } from '../types/IMatch';
import { DataList } from './DataList';

export default class Match {
    public nameTeam1: string;
    public logoTeam1: string;
    public nameTeam2: string;
    public logoTeam2: string;
    public videoPath: string;
    public tournamentId: string;
    public description: string;
    public thumbPath: string;
    public title: string;
    public matchId: string;
    public status: number;

    constructor(
        nameTeam1: string,
        logoTeam1: string,
        nameTeam2: string,
        logoTeam2: string,
        videoPath: string,
        tournamentId: string,
        description: string,
        thumbPath: string,
        title: string,
        matchId: string,
        status: number
    ) {
        this.nameTeam1 = nameTeam1;
        this.logoTeam1 = logoTeam1;
        this.nameTeam2 = nameTeam2;
        this.logoTeam2 = logoTeam2;
        this.videoPath = videoPath;
        this.tournamentId = tournamentId;
        this.description = description;
        this.thumbPath = thumbPath;
        this.title = title;
        this.matchId = matchId;
        this.status = status;
    }

    static fromJson = (json: IMatch): Match => {
        return new Match(
            json.nameTeam1,
            json.logoTeam1,
            json.nameTeam2,
            json.logoTeam2,
            json.videoPath,
            json.tournamentId,
            json.description,
            json.thumbPath,
            json.title,
            json.matchId,
            json.status
        );
    };
}

export class ListMatch extends DataList<Match> {
    static fromJson = (jsonArray: Array<IMatch>): Array<Match> => {
        let data = new Array<Match>();
        jsonArray.forEach((item) => {
            data.push(Match.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
