import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';
import { AppButton } from '../../../styles/AppButton.styles';

export const TopBannerWrapper = styled.div`
    width: 100%;
    @media ${device.mobileS} {
        padding-top: 0px;
        background-color: #19181c;
    }
    @media ${device.laptop} {
        background-image: url('../../../images/bg-mission.png');
        background-color: #19181c;
        background-position: center;
        background-size: cover;
    }
`;

export const TopBannerContainer = styled.div`
    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptop} {
        width: 1100px;
        margin: 0 auto;
    }
`;

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    @media ${device.mobileS} {
        padding-top: 20px;
        padding-bottom: 5px;
    }
    @media ${device.laptop} {
        padding-top: 30px;
        padding-bottom: 24px;
    }
`;

export const BannerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;

    @media ${device.mobileS} {
        margin-left: 20px;
        margin-right: 20px;
    }
    @media ${device.laptop} {
        margin: 0 auto;
    }
`;

export const BannerImage = styled.img`
    object-fit: cover;
    width: 100%;
    @media ${device.mobileS} {
        max-height: 200px;
    }
    @media ${device.laptop} {
        max-height: 460px;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 20px;
`;

export const HelpButton = styled(AppButton)`
    margin-right: 20px;
    @media ${device.mobileS} {
        width: 200px;
    }
    @media ${device.laptop} {
        width: 200px;
    }
`;

export const JoinedButton = styled(AppButton)`
    display: flex;
    margin-right: 20px;
    @media ${device.mobileS} {
        width: 200px;
    }
    @media ${device.laptop} {
        width: 200px;
    }
`;

export const JoinedText = styled.h1`
    margin: 0;
    margin-left: 10px;
    font-size: 20px;
    color: #2cc748;
`;

export const RuleButton = styled(AppButton)`
    @media ${device.mobileS} {
        width: 200px;
    }
    @media ${device.laptop} {
        width: 200px;
    }
`;
