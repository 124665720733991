import styled, { css } from 'styled-components';
import { device } from '../configs/MediaQuery';

export const InputTextBase = css`
    //background: transparent;
    border-radius: 8px;
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    height: 40px;
    padding: 10px;
    outline: none;
    border: 1px solid #dbdfe2;
    display: flex;
    margin-bottom: 20px;
`;

interface AppInputTextProps {
    isError: boolean;
}

export const AppInputText = styled.input.attrs((props: AppInputTextProps) => ({
    className: props.isError
}))`
    ${InputTextBase}

    &.error {
        border: solid 1px red;
        outline: 1px solid red;
    }

    @media ${device.mobileS} {
        width: 100%;
    }

    @media ${device.laptop} {
        width: 100%;
    }
`;

export const AppInputTextMultiline = styled.textarea.attrs(
    (props: AppInputTextProps) => ({
        className: props.isError
    })
)`
    ${InputTextBase}

    &.error {
        border: solid 1px red;
    }

    height: 100px;
    padding-left: 10px;
    padding-right: 10px;
    resize: none;
`;
