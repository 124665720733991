import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { Col, Row } from 'antd';
import { RightContent } from './RightContent/RightContent';
//components
import { LeftMenu } from './LeftMenu/LeftMenu';
//styles
import {
    LineDivider,
    ProfileInfoContainer,
    ProfileInfoWrapper,
    TitleWrapper
} from './ProfileInfo.styles';
import {
    AppContentContainer,
    AppContentWrapper,
    AppTitle
} from '../../../styles/App.styles';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { PROFILE_INFO_SCREEN } from '../../../configs/ScreenType';
import { observer } from 'mobx-react-lite';

interface Props {}

export const ProfileInfo: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { changeProfileInfoScreen } = store.globalStore;
    const { currentUser } = store.authStore;

    const onChangeMenuProfileInfo = (menu: PROFILE_INFO_SCREEN) => {
        changeProfileInfoScreen(menu);
    };

    return (
        <AppContentWrapper>
            <ProfileInfoContainer>
                <TitleWrapper>
                    <LineDivider />
                    <AppTitle>
                        XIN CHÀO :{' '}
                        {currentUser?.name && currentUser?.name.length > 0
                            ? currentUser?.name.toUpperCase()
                            : currentUser?.email.toUpperCase()}
                    </AppTitle>
                </TitleWrapper>

                <Row>
                    <Col
                        xl={7}
                        md={7}
                        sm={24}
                        xs={24}
                        style={{ marginRight: 40 }}>
                        <LeftMenu onChangeMenuItem={onChangeMenuProfileInfo} />
                    </Col>
                    <Col xl={16} md={16} sm={24} xs={24}>
                        <RightContent />
                    </Col>
                </Row>
            </ProfileInfoContainer>
        </AppContentWrapper>
    );
});
