import { v4 } from 'uuid';
import { Buffer } from 'buffer';
import { Modal, message } from 'antd';
import { IResponseBase } from '../types/ITypeBase';

export function getUUID() {
    return v4().toString();
}

export function getTimestamp() {
    return Math.floor(Date.now() / 1000).toString();
}

export function stripedHtml(val: any) {
    if (!val) return null;
    val = val.replace(/<[^>]+>/g, '');
    //val = val.replace(/[^a-zA-Z0-9,/ ]/g, '');
    return val;
}

export function randomGiftChar(length: number, chars: string) {
    let result = '';
    const charactersLength = chars.length;
    let counter = 0;
    while (counter < length) {
        result += chars.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function delay(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
}

export function swap(arr: any, indexA: number, indexB: number) {
    [arr[indexA], arr[indexB]] = [arr[indexB], arr[indexA]];
}

export function showMessage(
    result: IResponseBase,
    defaultSuccessMsg?: string,
    defaultFailureMsg?: string
) {
    if (result && result.isSuccess()) {
        return Modal.success({
            title:
                result.getTitle() && result.getTitle().length > 0
                    ? result.getTitle()
                    : 'Thành công',
            content:
                result.getDescription() && result.getDescription().length > 0
                    ? result.getDescription()
                    : defaultSuccessMsg && defaultSuccessMsg.length > 0
                    ? defaultSuccessMsg
                    : 'Thao tác thành công',
            centered: true
        });
    } else {
        return Modal.error({
            title:
                result.getTitle() && result.getTitle().length > 0
                    ? result.getTitle()
                    : `Thất bại. Mã lỗi ${result.responseCode()}`,
            content:
                result.getDescription() && result.getDescription().length > 0
                    ? result.getDescription()
                    : defaultFailureMsg && defaultFailureMsg.length > 0
                    ? defaultFailureMsg
                    : 'Có lỗi xảy ra. Vui lòng thử lại hoặc liên hệ với bộ phận chăm sóc khách hàng của chúng tôi',
            centered: true
        });
    }
}
