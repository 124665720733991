import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import { IGetListVideoRequest, IViewVideoRequest } from '../types/IVideo';
import BaseAuthService from './BaseAuthService';

export class VideoService extends BaseAuthService {
    private static instance: VideoService;

    public static getInstance(): VideoService {
        if (!VideoService.instance) {
            VideoService.instance = new VideoService();
        }
        return VideoService.instance;
    }

    getListVideo = async (params: IGetListVideoRequest) => {
        try {
            const response = await this.postData(API.VIDEO.LIST, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    viewVideo = async (params: IViewVideoRequest) => {
        try {
            const response = await this.postData(API.VIDEO.VIEW, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}
