import { ComponentType, useEffect } from 'react';
import { useRootStore } from '../../../providers/RootStoreProvider';

import { APIResponse } from '../../../models/APIResponse';
import { Spin } from 'antd';

//components

//styles
import {
    JsonDialogContainer,
    JsonDialogTitle,
    JsonDialogTitleWrapper,
    JsonDialogWrapper,
    JsonModal
} from './JsonDialogNested.styles';
import { observer } from 'mobx-react-lite';
import { JSON_CONTENT_TYPE } from '../../../types/IGlobal';
import { API } from '../../../configs/APIEndPoint';
import { IJsonBase } from '../../../types/IJson';

interface Props {
    jsonContent: IJsonBase | null;
}

export const JsonDialogNested: ComponentType<Props> = observer(
    ({ jsonContent }) => {
        const store = useRootStore();
        const { showDialogJsonNested, toggleShowDialogJsonNested } =
            store.globalStore;

        const onClose = () => {
            toggleShowDialogJsonNested(false);
            jsonContent = null;
        };

        if (showDialogJsonNested && jsonContent != null)
            return (
                <JsonDialogWrapper>
                    <JsonModal
                        title={jsonContent?.title}
                        open={showDialogJsonNested}
                        centered={true}
                        onCancel={() => onClose()}
                        width={800}
                        footer={null}>
                        {!jsonContent ? (
                            <JsonDialogContainer>
                                <Spin />
                            </JsonDialogContainer>
                        ) : (
                            <>
                                {/* <JsonDialogTitleWrapper>
                            <JsonDialogTitle>
                                {jsonContent?.title}
                            </JsonDialogTitle>
                        </JsonDialogTitleWrapper> */}

                                <JsonDialogContainer
                                    dangerouslySetInnerHTML={{
                                        __html: jsonContent?.content!
                                    }}></JsonDialogContainer>
                            </>
                        )}
                    </JsonModal>
                </JsonDialogWrapper>
            );
        else return null;
    }
);
