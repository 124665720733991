import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';
import { AppButton } from '../../../../styles/AppButton.styles';

export const ShopItemWrapper = styled.div`
    position: relative;
    margin: 10px;
`;

export const ShopItemContainer = styled.div``;

export const GiftThumbnailContainer = styled.div`
    /* width: 500px; */
    //max-height: 200px;
    border-radius: 10px;
    border: solid 1px #fff;
    padding: 20px;
    background-color: #000;
`;

export const GiftThumbnail = styled.img`
    object-fit: cover;
    width: 100%;
    max-height: 160px;
    border-radius: 10px;
`;

export const InfoContainer = styled.div`
    width: 100%;
    padding-top: 5px;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    //background-color: #252525;
`;

export const GiftName = styled.h1`
    color: #fff;
    margin: 0;
    font-weight: 700;
    @media ${device.mobileS} {
        font-size: 26px;
    }

    @media ${device.tablet} {
        font-size: 20px;
    }
`;

export const GiftDesc = styled.h1`
    color: #fff;
    margin: 0;
    font-weight: 500;
    @media ${device.mobileS} {
        font-size: 14px;
    }

    @media ${device.tablet} {
        font-size: 16px;
    }
`;

export const ExchangeButton = styled(AppButton)`
    height: 40px;
    margin-top: 10px;
    width: 50%;
`;
