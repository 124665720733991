import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';
import { Modal } from 'antd';

export const JsonDialogWrapper = styled.div``;

export const JsonModal = styled(Modal)`
    .ant-modal-header {
        background-color: red;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .ant-modal-title {
        color: #fff !important;
        font-weight: 700 !important;
    }
    .ant-modal-close-x {
        color: #fff !important;
        font-weight: 700 !important;
    }
`;

export const JsonDialogTitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`;

export const JsonDialogTitle = styled.h1`
    margin: 0;
    font-size: 20px;
    font-weight: 700;
`;

export const JsonDialogContainer = styled.div`
    color: #000;
    font-size: 16px;
    font-weight: 500;
    min-height: 400px;
    overflow: hidden;

    li {
        margin-left: 30px;
        padding-left: 10px;
    }
    @media ${device.mobileS} {
        width: 100%;
        margin-top: 40px;
    }
    @media ${device.laptop} {
        margin-top: 0px;
        margin-left: 0px;
    }
`;
