import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//components
import { TopBanner } from './TopBanner/TopBanner';
import { RuleDescription } from './RuleDescription/RuleDescription';
import { ListGift } from './ListGift/ListGift';
import { ControlButton } from './ControlButton/ControlButton';
import { TokenInvalid } from '../CommonView/TokenInvalid/TokenInvalid';
//styles
import { SpinWrapper, SpinContainer } from './SpinView.styles';
import { useRootStore } from '../../providers/RootStoreProvider';
import { HELP_CONTENT, MAIN_MENU, RULES_CONTENT } from '../../types/IGlobal';
import sleep from 'sleep-promise';
import { observer } from 'mobx-react-lite';
import { NotVerify } from '../CommonView/NotVerify/NotVerìfy';
import { RuleDialog } from '../CommonView/RuleDialog/RuleDialog';

interface Props {}

export const SpinView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { currentUser } = store.authStore;
    const {
        changeMainMenuIndex,
        showDialogRule,
        toggleShowDialogRule,
        setHelpType,
        toggleShowDialogHelp
    } = store.globalStore;
    const { randomGiftChar } = store.giftStore;

    useEffect(() => {
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        changeMainMenuIndex(MAIN_MENU.SPIN);
    }, []);

    const renderContent = () => {
        if (!currentUser?.isLogin()) {
            return <TokenInvalid />;
        } else if (!currentUser.isVerified()) {
            return <NotVerify />;
        } else
            return (
                <>
                    <ControlButton /> <ListGift />
                </>
            );
    };

    const onShowDialogHelp = () => {
        setHelpType(HELP_CONTENT.SPIN);
        toggleShowDialogHelp(true);
    };

    return (
        <SpinWrapper>
            <SpinContainer>
                <TopBanner
                    onClickRules={() => toggleShowDialogRule(true)}
                    onClickHelp={() => {
                        onShowDialogHelp();
                    }}
                />
                <RuleDescription />

                {renderContent()}
            </SpinContainer>
            {showDialogRule ? <RuleDialog type={RULES_CONTENT.SPIN} /> : null}
        </SpinWrapper>
    );
});
