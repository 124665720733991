import { ComponentType } from 'react';

//components

//styles
import {
    AppContentWrapper,
    AppContentContainer
} from '../../../styles/App.styles';
import {
    TokenInvalidWrapper,
    TokenInvalidContainer,
    ContentTitle,
    TextRegister
} from './TokenInvalid.styles';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { observer } from 'mobx-react-lite';

interface Props {}

export const TokenInvalid: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { toggleShowDialogLogin, toggleShowDialogRegister } =
        store.globalStore;
    return (
        <AppContentWrapper>
            <AppContentContainer>
                <TokenInvalidWrapper>
                    <TokenInvalidContainer title="BẠN CHƯA ĐĂNG NHẬP">
                        <ContentTitle
                            onClick={() => toggleShowDialogLogin(true)}
                            dangerouslySetInnerHTML={{
                                __html: "Vui lòng <p style='color: red; display: inline-block; cursor: pointer; font-weight:600'> đăng nhập</p> tài khoản của bạn để tiếp tục."
                            }}></ContentTitle>
                        <TextRegister
                            onClick={() => toggleShowDialogRegister(true)}
                            dangerouslySetInnerHTML={{
                                __html: "Nếu chưa có tài khoản vui lòng đăng ký <p style='color: red; display: inline-block; cursor: pointer; font-weight:600'> tại đây.</p>"
                            }}></TextRegister>
                        {/* <ContentDescription>
                            Vui lòng đăng nhập lại để sử dụng chức năng này.
                        </ContentDescription> */}
                    </TokenInvalidContainer>
                </TokenInvalidWrapper>
            </AppContentContainer>
        </AppContentWrapper>
    );
});
