import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    BuyDialogWrapper,
    BuyDialogContainer,
    BuyDialogModal,
    TitleContainer,
    TitleText,
    DescriptionText
} from './BuyDialog.styles';
import { formatNumber } from '../../../utils/FormatUtils';

interface Props {
    visible: boolean;
    point: number;
    onClose: () => void;
    onConfirm: () => void;
}

export const BuyDialog: ComponentType<Props> = pure(
    ({ visible, point, onConfirm, onClose }) => {
        return (
            <BuyDialogWrapper>
                <BuyDialogContainer>
                    <BuyDialogModal
                        centered
                        onCancel={onClose}
                        open={visible}
                        okText="Đồng ý"
                        cancelText="Huỷ bỏ"
                        onOk={onConfirm}>
                        <TitleContainer>
                            <TitleText>Xác nhận đổi lượt quay</TitleText>
                        </TitleContainer>
                        <DescriptionText
                            dangerouslySetInnerHTML={{
                                __html: `Bạn có chắc muốn đổi <b><color='red'>${formatNumber(
                                    point
                                )}</color></b> điểm để lấy thêm 1 lần
                            quay?`
                            }}></DescriptionText>
                    </BuyDialogModal>
                </BuyDialogContainer>
            </BuyDialogWrapper>
        );
    }
);
