import { IGift } from './IGift';
import { IRequestBase } from './ITypeBase';

export enum GiftBagActionType {
    RECEIVE = 2
}

export interface IGiftBagType {
    type: number;
    name: string;
}

export interface IGiftBag extends IGift {
    type: number;
    value: number;
    bagId: number;
}

export interface IGetListGiftBagType extends IRequestBase {}

export interface IGetListGiftBag extends IRequestBase {
    data: {
        type: number;
        from?: number;
        size?: number;
    };
}

export interface IBagReceiveItemRequest extends IRequestBase {
    data: {
        giftId: any;
        action: GiftBagActionType;
        bagId: number;
    };
}
