import { IRequestBase } from './ITypeBase';

export enum BANNER_TYPE {
    LARGE,
    SMALL
}

export enum JOIN_MISSION_ACTION {
    JOINED = 1
}

export interface IBanner {
    image: string;
    bannerId: string;
    type: BANNER_TYPE;
    url: string;
    order: number;
    status: number;
}

export interface IGetListBannerRequest extends IRequestBase {}

export interface IJoinInstallAppMissionRequest extends IRequestBase {
    data: {
        appId: number;
        action: JOIN_MISSION_ACTION;
        extra?: any;
    };
}
