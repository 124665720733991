import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//components
import { TournamentTopSlider } from './TournamentTopSlider';
//styles
import {
    TourTopContainer,
    TourTopWrapper,
    TitleText,
    TitleWrapper
} from './TournamentTop.styles';
import { AppContentContainer, AppTitle } from '../../../styles/App.styles';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { GIFT_TYPE, IGetListGiftRequest } from '../../../types/IGift';
import { observer } from 'mobx-react-lite';
import {
    IGetListTournamentRequest,
    ITournament
} from '../../../types/ITournament';
import { APIResponseList } from '../../../models/APIResponse';
import { IGetListMatchRequest, IMatch } from '../../../types/IMatch';
import { Spin } from 'antd';
import Tournament from '../../../models/Tournament';
import { ListMatch } from '../ListMatch/ListMatch';

interface Props {}

export const TournamentTop: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const {
        listTournament,
        getListTournament,
        getListMatch,
        currentTournament,
        setTournamentData
    } = store.tournamentStore;

    useEffect(() => {
        onGetListTournament();
    }, []);

    const onGetListTournament = async () => {
        const params: IGetListTournamentRequest = {};
        let result: APIResponseList<Array<ITournament>> =
            await getListTournament(params, false);

        //get all match of first tournament
        if (result.isSuccess() && result.data?.list.length! > 0) {
            onGetListMatch(Tournament.fromJson(result.data?.list[0]!));
        }
    };

    const onGetListMatch = async (tournament: Tournament) => {
        if (currentTournament?.tournamentId === tournament.tournamentId) return;
        setTournamentData(tournament);
        const params: IGetListMatchRequest = {
            data: {
                tourid: tournament.tournamentId
            }
        };
        let result: APIResponseList<Array<IMatch>> = await getListMatch(params);
    };

    return (
        <TourTopWrapper>
            <TourTopContainer>
                <TitleWrapper>
                    <AppTitle>GIẢI ĐẤU NỔI BẬT</AppTitle>
                </TitleWrapper>
                {listTournament?.isLoading ? (
                    <Spin></Spin>
                ) : (
                    <TournamentTopSlider
                        onGetListMatch={onGetListMatch}
                        tours={listTournament!}
                    />
                )}
            </TourTopContainer>
        </TourTopWrapper>
    );
});
