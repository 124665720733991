import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    CategoryItemWrapper,
    CategoryItemContainer,
    CategoryThumbnailContainer,
    CategoryThumbnail,
    InfoContainer,
    CategoryName
} from './CategoryItem.styles';

import { IGiftCategory } from '../../../../types/IGiftCategory';
import GiftCategory from '../../../../models/GiftCategory';

interface Props {
    data: GiftCategory;
    onChangeCategory: (category: GiftCategory) => void;
}

export const CategoryItem: ComponentType<Props> = pure(
    ({ data, onChangeCategory }) => {
        const handleChangeCategory = () => {
            onChangeCategory(data);
        };

        return (
            <CategoryItemWrapper>
                <CategoryItemContainer onClick={() => handleChangeCategory()}>
                    <CategoryThumbnailContainer>
                        <CategoryThumbnail
                            src={data.catImage}></CategoryThumbnail>
                    </CategoryThumbnailContainer>
                    <InfoContainer>
                        <CategoryName>{data.catName}</CategoryName>
                    </InfoContainer>
                </CategoryItemContainer>
            </CategoryItemWrapper>
        );
    }
);
