import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';
import { Link, NavLink } from 'react-router-dom';
import { Badge, Dropdown } from 'antd';
import { AppButton } from '../../../../styles/AppButton.styles';
import Image from 'rc-image';

export const HeaderWrapper = styled.div`
    position: sticky;
    width: 100%;
    //max-width: 1920px;
    z-index: 1020;
    top: 0;
`;

export const HeaderContainer = styled.div`
    background-color: #000;
    position: relative;
`;

export const Row1Wrapper = styled.div`
    width: 100%;
    border-bottom: 0.5px solid #707070;
`;

export const RowContainer1 = styled.div`
    height: 50px;
    width: 1100px;
    display: flex;
    margin: 0 auto;
    justify-content: space-around;
`;

export const RowContainer2 = styled.div`
    height: 50px;
    display: flex;
    width: 1100px;
    margin: 0 auto;
`;

export const MenuContainer = styled.ul`
    display: flex;
    flex-grow: 1;
    margin: 0;
    padding-left: 0px;
    list-style-type: none;
    justify-content: space-between;
`;

export const LogoutButton = styled(AppButton)`
    height: 40px;
    width: 100%;
`;

export const UserNameWrapper = styled.div`
    display: flex;
    justify-content: end;
`;

export const UserNameContainer = styled.div`
    display: flex;
    justify-content: end;
    margin-right: 10px;
    align-items: center;
`;

export const UserNameText = styled.p`
    margin: 0;
`;

export const DropdownMenu = styled.div`
    background-color: #f9f9f9;
    display: none;
    padding: 12px 16px;
    position: absolute;
    right: 0;
    z-index: 1;
`;

export const AvatarWrapper = styled.div`
    position: relative;
    padding-top: 5px;
`;

export const AvatarImage = styled(Image)`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: solid 1px #fff;
    object-fit: cover;
    opacity: 1;
`;

export const BadgePoint = styled(Badge)`
    position: absolute;
    color: #336699;
    right: -60px;
    top: 5px;
`;

interface MenuItemProps {
    isActive: boolean;
}

export const MenuItem = styled.li.attrs((props: MenuItemProps) => ({
    className: props.isActive
}))`
    padding-top: 12px;
    //margin-right: 30px;
    padding-bottom: 12px;

    &.active {
        border-bottom: solid 5px #ff2423;
    }

    &:hover {
        border-bottom: solid 5px #ff2423;
    }
    &:nth-child(4) {
        width: 200px;
    }
    &:nth-child(2) {
        display: inline-block;
        position: relative;
        :hover ${DropdownMenu} {
            display: block;
        }
    }
`;

export const MenuLink = styled(Link).attrs((props: MenuItemProps) => ({
    className: props.isActive
}))`
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    &:hover {
        color: #ff2423;
    }
    &:focus {
        //color: #ff2423;
    }
    &.active {
        color: #ff2423;
    }
`;

export const FAQLink = styled.a`
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    &:hover {
        color: #ff2423;
    }
`;

export const AppLogoContainer = styled.div`
    width: 217px;
    height: 106px;
    position: absolute;
    left: 50%;
    transform: translate(-42%, 0%);
`;

export const AppLogoImage = styled.img`
    object-fit: cover;
    max-width: 100%;
`;

export const LoginButton = styled.div`
    display: flex;
    background-color: #ff2423;
    cursor: pointer;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 7px;

    @media ${device.mobileS} {
        height: 249px;
    }
    @media ${device.laptop} {
        height: 27px;
        width: 100px;
    }
`;

export const LoginButtonText = styled.p`
    margin: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
`;

export const DropdownMission = styled(Dropdown)`
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    :hover {
        color: #ff2423;
    }
`;

export const DropdownProfile = styled(Dropdown)`
    color: #fff;
    width: 300px;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    color: #ff2423;
    display: flex;
    justify-content: end;

    :hover {
        color: #ff2423;
    }

    .anticon-down {
        font-weight: 700;
        font-size: 14px;
    }
`;

export const MissionMenuWrapper = styled.div`
    color: #fff;
    background-color: #000;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
`;

export const MissionMenuItem = styled.div`
    padding: 10px 20px 10px 20px;
    border-bottom: solid 2px #252525;
    width: 100%;
`;

export const MissionMenuText = styled.p`
    margin: 0;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;

    :hover {
        color: #ff2423;
    }
`;
