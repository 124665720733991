import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    AppContentWrapper,
    AppContentContainer,
    AppCard
} from '../../../styles/App.styles';
import {
    CopyButton,
    InvitedInput,
    InvitedLinkWrapper
} from './InvitedForm.styles';
import { AppInputText } from '../../../styles/AppInput.styles';
import { AppButton } from '../../../styles/AppButton.styles';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { message } from 'antd';
import { observer } from 'mobx-react-lite';
import User from '../../../models/User';

interface Props {
    userInfo: User;
}

export const InvitedForm: ComponentType<Props> = observer(({ userInfo }) => {
    const link =
        process.env.REACT_APP_SITE_DOMAIN + '?refId=' + userInfo?.refId;
    return (
        <AppContentWrapper>
            <AppContentContainer>
                <AppCard title="GỬI LIÊN KẾT CHO BẠN BÈ">
                    <p>
                        Gửi bạn bè liên kết (link) dưới đây của bạn để nhận thêm
                        điểm thưởng
                    </p>
                    <InvitedLinkWrapper>
                        <InvitedInput
                            type="text"
                            readOnly={true}
                            value={link}
                        />
                        <CopyToClipboard
                            text={link}
                            onCopy={() =>
                                message.success(
                                    'Sao chép liên kết thành công !'
                                )
                            }>
                            <CopyButton typeButton={APP_BUTTON_TYPE.PRIMARY}>
                                Sao chép
                            </CopyButton>
                        </CopyToClipboard>
                    </InvitedLinkWrapper>
                </AppCard>
            </AppContentContainer>
        </AppContentWrapper>
    );
});
