import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';
import {
    AppContentContainer,
    AppContentWrapper
} from '../../styles/App.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../providers/RootStoreProvider';
import { API } from '../../configs/APIEndPoint';
import {
    ContentDescription,
    ContentTitle,
    ResetPassContainer
} from './ResetPass.styles';

//components

//styles

interface Props {}

export const ResetPass: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { jsonContent, getJsonContentInfo } = store.jsonStore;

    useEffect(() => {
        getJsonContentInfo(API.JSON.RESET_PASS);
    }, []);

    return (
        <AppContentWrapper>
            <AppContentContainer>
                <ResetPassContainer>
                    <ContentTitle>{jsonContent?.title}</ContentTitle>
                    <ContentDescription>
                        {jsonContent?.content}
                    </ContentDescription>
                </ResetPassContainer>
            </AppContentContainer>
        </AppContentWrapper>
    );
});
