import styled from 'styled-components';
import { device } from '../../configs/MediaQuery';
import { AppTitle } from '../../styles/App.styles';
import { Col } from 'antd';

export const MainWrapper = styled.div`
    width: 100%;
    background-color: #19181c;
    min-height: 600px;
`;

export const MainContainer = styled.div`
    @media ${device.mobileS} {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media ${device.laptop} {
        width: 1100px;
        margin: 0 auto;
        padding: 0;
    }
`;

export const GameItemWrapper = styled(Col)`
    //margin: 10px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.mobileS} {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    @media ${device.laptop} {
        padding: 30px;
    }
`;

export const TitleText = styled(AppTitle)`
    text-align: center;
`;
