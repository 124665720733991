import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    InstallAppItemWrapper,
    InstallAppItemContainer,
    InfoContainer,
    VisitTitle,
    VisitDescription,
    ItemIConWrapper,
    WinDescription
} from './InstallAppItem.styles';
import { RightSquareOutlined } from '@ant-design/icons';
import { formatNumber } from '../../../../../../utils/FormatUtils';
import moment from 'moment';
import { IInstallApp } from '../../../../../../types/IUser';

interface Props {
    data: IInstallApp;
}

export const InstallAppItem: ComponentType<Props> = pure(({ data }) => {
    return (
        <InstallAppItemWrapper>
            <InstallAppItemContainer>
                <ItemIConWrapper>
                    <RightSquareOutlined
                        style={{ fontSize: 30, color: 'red' }}
                    />
                </ItemIConWrapper>
                <InfoContainer>
                    <VisitTitle>{data.title}</VisitTitle>
                    <VisitDescription>
                        Ngày tham gia:{' '}
                        {moment(data.joinDate).format('MM/DD/YYYY HH:mm:ss')}
                    </VisitDescription>
                    <VisitDescription>
                        Kết quả: {data.statusName}
                    </VisitDescription>
                    {data.serial && data.serial.length > 0 ? (
                        <WinDescription>
                            Mã giải thưởng: {data.serial}
                        </WinDescription>
                    ) : null}
                </InfoContainer>
            </InstallAppItemContainer>
        </InstallAppItemWrapper>
    );
});
