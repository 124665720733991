import { ComponentType, useCallback, useState } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    ActionWrapper,
    CropImageModal,
    CropImageWrapper,
    CropWrapper,
    OKButton,
    PreviewImage
} from './CropImageDialog.styles';
import { observer } from 'mobx-react-lite';
import Cropper, { Area, Point } from 'react-easy-crop';
import { APP_BUTTON_TYPE } from '../../types/IGlobal';
import getCroppedImg, { getCroppedImg1 } from '../../utils/CropImage';
import { CropImageType } from '../../configs/AppEnum';

interface Props {
    image: string;
    dialogTitle?: string;
    showDialog: boolean;
    onClose: () => void;
    cropType: CropImageType;
    onComplete: (cropImage: string) => void;
}

export const CropImageDialog: ComponentType<Props> = observer(
    ({ image, dialogTitle, showDialog, onClose, cropType, onComplete }) => {
        //crop image
        const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
        const [zoom, setZoom] = useState(1);
        const [rotation, setRotation] = useState(0);
        const [croppedImage, setCroppedImage] = useState(null);
        const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
            height: 0,
            width: 0,
            x: 0,
            y: 0
        });

        const onCropComplete = useCallback(
            (croppedArea: Area, croppedAreaPixels: Area) => {
                console.log(croppedArea, croppedAreaPixels);
                setCroppedAreaPixels(croppedAreaPixels);
            },
            []
        );

        const showCroppedImage = useCallback(async () => {
            try {
                const croppedImage = await getCroppedImg1(
                    image,
                    croppedAreaPixels,
                    rotation
                );
                console.log('donee', { croppedImage });
                onComplete(croppedImage);
                onClose();
                //setCroppedImage(croppedImage);
            } catch (e) {
                console.error(e);
            }
        }, [image, croppedAreaPixels, rotation]);

        return (
            <CropImageWrapper>
                <CropImageModal
                    title={dialogTitle ? dialogTitle : 'Chỉnh sửa hình ảnh'}
                    open={showDialog}
                    centered={true}
                    onCancel={() => onClose()}
                    width={600}
                    footer={null}>
                    {/* {croppedImage ? (
                        <PreviewImage src={croppedImage} />
                    ) : ( */}
                    <CropWrapper>
                        <Cropper
                            image={image!}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            cropShape={cropType}
                            aspect={
                                cropType === CropImageType.RECTANGLE
                                    ? 16 / 9
                                    : 1 / 1
                            }
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </CropWrapper>

                    <ActionWrapper>
                        <OKButton
                            typeButton={APP_BUTTON_TYPE.PRIMARY}
                            onClick={() => showCroppedImage()}>
                            Đồng ý
                        </OKButton>
                    </ActionWrapper>
                </CropImageModal>
            </CropImageWrapper>
        );
    }
);
