import { action, makeObservable, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { IBanner } from '../types/IBanner';
import { APIResponse, APIResponseList } from '../models/APIResponse';
import { CommonService } from '../services/CommonService';
import { ListVideo } from '../models/Video';
import {
    IGetListVideoRequest,
    IVideo,
    IViewVideoRequest,
    VIDEO_VIEW_STATUS
} from '../types/IVideo';
import { VideoService } from '../services/VideoService';
import { IResponseBase } from '../types/ITypeBase';
import _ from 'lodash';

class VideoStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable listVideo: ListVideo | null = null;

    @action viewVideo = async (params: IViewVideoRequest) => {
        const result = (await VideoService.getInstance().viewVideo(
            params
        )) as APIResponse<IResponseBase>;
        if (result.isSuccess()) {
            if (params.data.status === VIDEO_VIEW_STATUS.FINISH)
                _.remove(this.listVideo?.list!, (item) => {
                    return item.videoId === params.data.videoid;
                });
        }
        return result;
    };

    @action getListVideo = async (
        params: IGetListVideoRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listVideo)
            runInAction(() => {
                this.listVideo = new ListVideo();
            });
        else if (loadMore) {
            this.listVideo.pageIndex += 1;
            this.listVideo.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listVideo.pageIndex
                : this.listVideo.pageSize * this.listVideo.pageIndex;
        }

        //request api service
        const result = (await VideoService.getInstance().getListVideo(
            params
        )) as APIResponseList<Array<IVideo>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listVideo) {
                    this.listVideo.list = this.listVideo.list?.concat(
                        ListVideo.fromJson(result.data?.list!)
                    )!;
                    this.listVideo.totalRecord = result.totalRecord();
                    this.listVideo.isLoading = false;
                }
            });
        }
        return result;
    };
}

export default VideoStore;
