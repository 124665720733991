import * as Yup from 'yup';

export const ContactFormSchema = Yup.object().shape({
    name: Yup.string().trim().required('fullName is required'),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    phone: Yup.string()
        .trim()
        .required('Phone is required')
        .min(9, 'Password must be at least 9 characters')
        .max(12, 'Password must not exceed 12 characters'),
    message: Yup.string().trim().required('Message is required')
});
