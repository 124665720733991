import { ComponentType } from 'react';
import pure from 'recompose/pure';
//styles
import {
    GameItemWrapper,
    GameItemContainer,
    GameThumbnailWrapper,
    GameThumbnailImage,
    GameName
} from './GameItem.styles';
import MiniGame from '../../../../models/MiniGame';

interface Props {
    game?: MiniGame;
}

export const GameItem: ComponentType<Props> = pure(({ game }) => {
    return (
        <GameItemWrapper xl={8} md={12} sm={24} xs={24}>
            <a href={game?.gameUrl} target="_blank">
                <GameItemContainer>
                    <GameThumbnailWrapper>
                        <GameThumbnailImage src={game?.gameImg} />
                    </GameThumbnailWrapper>
                    <GameName>{game?.gameName}</GameName>
                </GameItemContainer>
            </a>
        </GameItemWrapper>
    );
});
