import { ComponentType, useEffect, useState } from 'react';
import pure from 'recompose/pure';
import sleep from 'sleep-promise';

//components
import { GiftItem } from './GiftItem/GiftItem';
//styles
import { ListGiftWrapper, ListGiftContainer } from './ListGift.styles';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { IGetListSpinGiftRequest, ISpinGift } from '../../../types/IGift';
import { APIResponseList } from '../../../models/APIResponse';

interface Props {}

export const ListGift: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { randomGift, randomGiftChar, listSpinGift, getListSpinGift } =
        store.giftStore;

    useEffect(() => {
        onGetListSpinGift();
    }, []);

    const onGetListSpinGift = async () => {
        const params: IGetListSpinGiftRequest = {};
        let result: APIResponseList<Array<ISpinGift>> = await getListSpinGift(
            params
        );
    };

    const onRandomGift = async () => {
        let count = 0;
        while (count < 30) {
            await sleep(200);
            randomGift();
            count++;
        }
    };

    const onRandomGiftChar = async () => {
        let count = 0;
        while (count < 30) {
            await sleep(200);
            randomGiftChar();
            count++;
        }
    };

    return (
        <ListGiftWrapper>
            <ListGiftContainer>
                <Row>
                    {listSpinGift &&
                        listSpinGift.list.map((item) => {
                            return <GiftItem data={item} key={item.giftKey} />;
                        })}
                </Row>
            </ListGiftContainer>
        </ListGiftWrapper>
    );
});
