import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';
import { useNavigate } from 'react-router-dom';
//components
import { MissionItem } from './MissionItem/MissionItem';

//styles
import {
    MissionWrapper,
    MissionContainer,
    LineDivider,
    MissionTitle,
    MissionDescription,
    ButtonMore
} from './Missions.styles';
import { Col, Row } from 'antd';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { MissionItemWrapper } from './MissionItem/MissionItem.styles';
import { APIResponseList } from '../../../models/APIResponse';

interface Props {}

export const Missions: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { listMission } = store.missionStore;
    const { homeInfo } = store.jsonStore;
    const navigate = useNavigate();

    if (listMission && listMission.list.length > 0)
        return (
            <MissionWrapper>
                <MissionContainer>
                    <LineDivider></LineDivider>
                    <Row>
                        <MissionItemWrapper xl={8} md={8} sm={24} xs={24}>
                            <MissionTitle>NHIỆM VỤ MỚI</MissionTitle>
                            <MissionDescription
                                dangerouslySetInnerHTML={{
                                    __html: homeInfo?.mission!
                                }}></MissionDescription>
                            <ButtonMore
                                typeButton={APP_BUTTON_TYPE.BORDER}
                                onClick={() => navigate('/mission')}>
                                Xem thêm
                            </ButtonMore>
                        </MissionItemWrapper>
                        {listMission &&
                            listMission.list.slice(0, 2).map((item) => {
                                return (
                                    <MissionItem
                                        key={item.missionId}
                                        data={item}
                                    />
                                );
                            })}
                    </Row>
                    {/* <Row>
                    <MissionItem xl={8} md={8} sm={24} xs={24}>
                        <MissionBannerWrapper>
                            <MissionBannerImage src="https://swiperjs.com/demos/images/nature-4.jpg" />
                        </MissionBannerWrapper>
                    </MissionItem>
                    <MissionItem xl={8} md={8} sm={24} xs={24}>
                        <MissionBannerWrapper>
                            <MissionBannerImage src="https://swiperjs.com/demos/images/nature-4.jpg" />
                        </MissionBannerWrapper>
                    </MissionItem>
                    <MissionItem xl={8} md={8} sm={24} xs={24}>
                        <MissionTitle>NHIỆM VỤ MỚI</MissionTitle>
                        <MissionDescription>
                            Nâng trình săn nhiệm vụ "pro" bằng cách hoàn tất các
                            nhiệm vụ nâng cao với phần thưởng "xịn xò" hơn những
                            nhiệm vụ thông thường. Đừng bỏ lỡ, let's go!
                        </MissionDescription>
                        <ButtonMore typeButton={APP_BUTTON_TYPE.BORDER}>
                            Xem thêm
                        </ButtonMore>
                    </MissionItem>
                </Row> */}
                </MissionContainer>
            </MissionWrapper>
        );
    else return null;
});
