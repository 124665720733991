import { IRequestBase } from './ITypeBase';

// login type user
export enum LOGIN_TYPE {
    FACEBOOK,
    GOOGLE,
    EMAIL
}

export enum VERIFY_TYPE {
    EMAIL,
    PHONE
}
export interface ILoginResponse {
    accessToken: string;
    userId: string;
    ssId: string;
}

export interface ILoginToken {
    id: number;
    name: string;
    email: string;
}

export interface IFacebookResponse {
    id: string;
    email: string;
    accessToken: string;
    userID: string;
    name: string;
}

export interface IGoogleProfile {
    googleId: string;
    email: string;
    name: string;
    imageUrl: string;
}

export interface IGoogleResponse {
    accessToken: string;
    profileObj: IGoogleProfile;
}

export interface ILoginBase {
    type: LOGIN_TYPE;
    account: string;
    passwd: string;
    lang: string;
}

export interface ILoginEmailRequest extends IRequestBase {
    data: {
        type: LOGIN_TYPE;
        account: string;
        passwd: string;
        lang: string;
    };
}

export interface IChangePassRequest extends IRequestBase {
    data: {
        opwd: string;
        npwd: string;
        cpwd: string;
    };
}

export interface ILoginGoogleRequest extends IRequestBase {}
export interface ILoginFacebookRequest extends IRequestBase {}

export interface IForgetPassRequest extends IRequestBase {
    data: {
        email: string;
    };
}

export interface IVerifyEmailRequest extends IRequestBase {
    data: {
        email: string;
        otp?: string;
    };
}

export interface IVerifyPhoneRequest extends IRequestBase {
    data: {
        phone: string;
        otp?: string;
    };
}
