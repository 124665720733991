import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';

export const VideoWrapper = styled.div`
    width: 100%;
`;

export const VideoHeaderContainer = styled.div`
    @media ${device.mobileS} {
        width: 100%;
        padding-bottom: 25px;
        margin-left: 20px;
        margin-right: 20px;
    }
    @media ${device.laptop} {
        width: 1100px;
        margin: 0 auto;
        padding-bottom: 32px;
    }
`;

export const VideoContentContainer = styled.div`
    width: 100%;
    padding-bottom: 20px;
`;
