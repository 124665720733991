import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    TourDesc,
    TourName,
    TourItemWrapper,
    TourThumbnail,
    TourThumbnailContainer,
    InfoContainer
} from './TournamentTopItem.styles';
import Tournament from '../../../../models/Tournament';
import { getStatusName } from '../../../../types/ITournament';

interface Props {
    data: Tournament;
    onGetListMatch: (tour: Tournament) => void;
}

export const TournamentTopItem: ComponentType<Props> = pure(
    ({ data, onGetListMatch }) => {
        return (
            <TourItemWrapper>
                <TourThumbnailContainer onClick={() => onGetListMatch(data)}>
                    <TourThumbnail src={data.image}></TourThumbnail>
                    <InfoContainer>
                        <TourName>{data.tournamentName}</TourName>
                        <TourDesc>
                            Trạng thái: {getStatusName(data.status)}
                        </TourDesc>
                    </InfoContainer>
                </TourThumbnailContainer>
            </TourItemWrapper>
        );
    }
);
