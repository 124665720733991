import { LOCAL_KEY } from './../configs/AppKey';
import { makeObservable, action, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { HELP_CONTENT, JSON_CONTENT_TYPE, MAIN_MENU } from '../types/IGlobal';
import {
    PROFILE_ACTIVITY_SCREEN,
    PROFILE_INFO_SCREEN,
    PROFILE_SCREEN
} from '../configs/ScreenType';

class GlobalStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    //declare variables
    @observable language: string | null = null;
    @observable indexMainMenu: MAIN_MENU | null = null;

    // flag dialog
    @observable showDialogRule: boolean = false;
    @observable showDialogHelp: boolean = false;
    @observable showDialogJson: boolean = false;
    @observable showDialogJsonNested: boolean = false;
    @observable showDialogLogin: boolean = false;
    @observable showDialogRegister: boolean = false;

    @observable helpType: HELP_CONTENT = HELP_CONTENT.INTRO;
    @observable jsonContentType: JSON_CONTENT_TYPE = JSON_CONTENT_TYPE.POLICY;

    // flag scroll
    @observable scrollVerify: boolean = false;

    @observable profileScreen: PROFILE_SCREEN = PROFILE_SCREEN.INFO;
    @observable profileInfoScreen: PROFILE_INFO_SCREEN =
        PROFILE_INFO_SCREEN.INFO;
    @observable profileActivityScreen: PROFILE_ACTIVITY_SCREEN =
        PROFILE_ACTIVITY_SCREEN.HISTORY_VISIT;

    @action toggleScrollVerify = async (value: boolean) => {
        runInAction(() => {
            this.scrollVerify = value;
        });
    };

    @action toggleShowDialogLogin = async (value: boolean) => {
        runInAction(() => {
            this.showDialogLogin = value;
        });
    };

    @action toggleShowDialogRegister = async (value: boolean) => {
        runInAction(() => {
            this.showDialogRegister = value;
        });
    };

    @action toggleShowDialogRule = async (value: boolean) => {
        runInAction(() => {
            this.showDialogRule = value;
        });
    };

    @action toggleShowDialogJsonContent = async (value: boolean) => {
        runInAction(() => {
            this.showDialogJson = value;
        });
    };

    @action toggleShowDialogJsonNested = async (value: boolean) => {
        runInAction(() => {
            this.showDialogJsonNested = value;
        });
    };

    @action toggleShowDialogHelp = async (value: boolean) => {
        runInAction(() => {
            this.showDialogHelp = value;
        });
    };

    @action setHelpType = async (value: HELP_CONTENT) => {
        runInAction(() => {
            this.helpType = value;
        });
    };

    @action setJsonContent = async (value: JSON_CONTENT_TYPE) => {
        runInAction(() => {
            this.jsonContentType = value;
        });
    };

    @action changeLanguage = async (language: string) => {
        runInAction(() => {
            this.language = language;
        });
        localStorage.setItem(LOCAL_KEY.USER_LANGUAGE, language);
    };

    @action changeProfileScreen = async (profileScreen: PROFILE_SCREEN) => {
        runInAction(() => {
            this.profileScreen = profileScreen;
        });
    };

    @action changeProfileActivityScreen = async (
        screen: PROFILE_ACTIVITY_SCREEN
    ) => {
        runInAction(() => {
            this.profileActivityScreen = screen;
        });
    };

    @action changeProfileInfoScreen = async (screen: PROFILE_INFO_SCREEN) => {
        runInAction(() => {
            this.profileInfoScreen = screen;
        });
    };

    @action changeMainMenuIndex = async (index: MAIN_MENU) => {
        runInAction(() => {
            this.indexMainMenu = index;
        });
        //localStorage.setItem(LOCAL_KEY.USER_LANGUAGE, language);
    };
}
export default GlobalStore;
