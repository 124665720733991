import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    DialogWrapper,
    DialogContainer,
    DialogModal,
    TitleContainer,
    TitleText,
    DescriptionText,
    ImageWrapper,
    ImageItem
} from './FConfirmDialog.styles';
import { observer } from 'mobx-react-lite';

interface Props {
    visible: boolean;
    title: string;
    description: string;
    onClose: () => void;
    onConfirm: () => void;
    okText?: string;
    cancelText?: string;
    image?: string;
}

export const FConfirmDialog: ComponentType<Props> = pure(
    ({
        visible,
        title,
        description,
        onConfirm,
        onClose,
        okText,
        cancelText,
        image
    }) => {
        return (
            <DialogWrapper>
                <DialogContainer>
                    <DialogModal
                        centered
                        onCancel={onClose}
                        open={visible}
                        okText={okText ? okText : 'Đồng ý'}
                        cancelText={cancelText ? cancelText : 'Huỷ bỏ'}
                        onOk={onConfirm}>
                        <TitleContainer>
                            <TitleText>{title}</TitleText>
                        </TitleContainer>
                        {image ? (
                            <>
                                <ImageWrapper>
                                    <ImageItem src={image} />
                                </ImageWrapper>
                            </>
                        ) : null}
                        <DescriptionText
                            dangerouslySetInnerHTML={{
                                __html: description
                            }}></DescriptionText>
                    </DialogModal>
                </DialogContainer>
            </DialogWrapper>
        );
    }
);
