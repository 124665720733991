import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';

export const TournamentWrapper = styled.div`
    width: 100%;
    @media ${device.mobileS} {
        padding-bottom: 30px;
    }
    @media ${device.laptop} {
        padding-bottom: 50px;
    }
`;

export const TournamentContainer = styled.div`
    @media ${device.mobileS} {
        margin-left: 20px;
        margin-right: 20px;
    }
    @media ${device.laptop} {
        width: 1100px;
        margin: 0 auto;
    }
`;

export const TournamentTitle = styled.h1`
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    padding: 0;
    margin: 0;
`;

export const LineDivider = styled.div`
    width: 100%;
    background-color: #707070;

    @media ${device.mobileS} {
        height: 0px;
    }
    @media ${device.laptop} {
        height: 1px;
        margin-bottom: 35px;
    }
`;

export const TournamentCategoryWrapper = styled.div`
    display: flex;
    @media ${device.mobileS} {
        justify-content: start;
        margin-bottom: 29px;
    }
    @media ${device.laptop} {
        justify-content: end;
        margin-bottom: 35px;
    }
`;

export const CategoryItemWrapper = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    :not(:last-child) {
        border-right: solid 1px #eee;
    }
    cursor: pointer;
`;

export const CategoryTitle = styled.p`
    color: #fff;
    padding: 0;
    margin: 0;
    cursor: pointer;
    @media ${device.mobileS} {
        font-size: 16px;
        font-weight: 500;
    }
    @media ${device.laptop} {
        font-size: 18px;
        font-weight: 500;
    }

    :hover {
        color: #ff2423;
    }
`;

export const MatchContainer = styled.div``;
