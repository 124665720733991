import { IMiniGame } from '../types/IMiniGame';
import { DataList } from './DataList';

export default class MiniGame {
    public gameId: string;
    public gameName: string;
    public gameCode: string;
    public gameImg: string;
    public gameUrl: string;
    public status: number;
    public order: number;

    constructor(
        gameId: string,
        gameName: string,
        gameCode: string,
        gameImg: string,
        gameUrl: string,
        status: number,
        order: number
    ) {
        this.gameId = gameId;
        this.gameName = gameName;
        this.gameCode = gameCode;
        this.gameImg = gameImg;
        this.gameUrl = gameUrl;
        this.status = status;
        this.order = order;
    }

    static fromJson = (json: IMiniGame): MiniGame => {
        return new MiniGame(
            json.gameId,
            json.gameName,
            json.gameCode,
            json.gameImg,
            json.gameUrl,
            json.status,
            json.order
        );
    };
}

export class ListMiniGame extends DataList<MiniGame> {
    static fromJson = (jsonArray: Array<IMiniGame>): Array<MiniGame> => {
        if (!jsonArray) return [];
        let data = new Array<MiniGame>();
        jsonArray.forEach((item) => {
            data.push(MiniGame.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
