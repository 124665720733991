import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';

export const MonthlyWrapper = styled.div`
    width: 100%;
    background-color: #19181c;
`;

export const MonthlyContainer = styled.div`
    @media ${device.mobileS} {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media ${device.laptop} {
        width: 1100px;
        margin: 0 auto;
        padding: 0;
    }
`;

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 24px;

    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
    }
`;

export const TextCount = styled.p`
    margin: 0;
    font-weight: 700;
    color: #fff;

    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
        font-size: 16px;
    }
`;
