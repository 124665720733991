import { Modal } from 'antd';
import styled from 'styled-components';
import { device } from '../../configs/MediaQuery';
import { AppButton } from '../../styles/AppButton.styles';
import { AppInputText } from '../../styles/AppInput.styles';

export const RegisterWrapper = styled.div`
    max-width: 600px;
`;

export const RegisterContainer = styled.div`
    width: 100%;
    text-align: center;
    color: #314154;
`;

export const RegisterModal = styled(Modal)`
    .ant-modal-body {
        text-align: center;
    }
    .ant-modal-content {
        width: 500px;
    }
`;

export const TitleContainer = styled.div`
    padding-top: 5px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const RegisterTitle = styled.h1`
    font-weight: 700;
    margin: 0;
    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        font-size: 20px;
        line-height: 30px;
    }
`;

export const RegisterButton = styled(AppButton)`
    width: 100%;
    margin-top: 20px;
`;

export const LogoApp = styled.img`
    width: 170px;
    height: auto;
    object-fit: cover;
`;

export const RuleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
`;

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
`;

export const InputText = styled(AppInputText)`
    &.error {
        margin: 0;
    }
`;

export const ErrorText = styled.p`
    font-size: 12px;
    color: red;
    margin: 5px;
`;

export const RuleText = styled.p`
    margin: 0;
    padding-left: 10px;
    font-size: 14;
    font-weight: 400;
    text-align: left;

    a {
        font-weight: 700;
        color: #ff2423;
    }
`;
