import styled from 'styled-components';
import { AppInputText } from '../../../styles/AppInput.styles';
import { AppButton } from '../../../styles/AppButton.styles';
import { device } from '../../../configs/MediaQuery';

export const InvitedFormWrapper = styled.div``;

export const InvitedLinkWrapper = styled.div`
    display: flex;
    @media ${device.mobileS} {
        flex-direction: column;
    }
    @media ${device.laptop} {
        flex-direction: row;
    }
`;

export const InvitedText = styled.p`
    font-size: 18px;
    font-weight: 700;
`;

export const InvitedInput = styled(AppInputText)`
    font-size: 18px;
    font-weight: 700;
    color: #000;

    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptop} {
        width: 80%;
        margin-right: 20px;
    }
`;

export const CopyButton = styled(AppButton)`
    height: 40px;

    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptop} {
        width: 20%;
    }
`;
