import { observable, action, runInAction, makeObservable } from 'mobx';
import RootStore from './RootStore';
import UserService from '../services/UserService';
import User, { ListUser } from '../models/User';
import { APIResponse, APIResponseList } from '../models/APIResponse';
import { UserProfile } from '../models/UserProfile';
import {
    IGetListUserRankRequest,
    IGetUserProfileDetailRequest,
    IUpdateProfileRequest,
    IUploadAvatarRequest,
    IUser,
    IUserDailyVisitRequest,
    IUserFeedbackRequest,
    IUserRegisterRequest
} from '../types/IUser';
import { IResponseBase } from '../types/ITypeBase';
import { IUserProfile } from '../types/IUserProfile';

class UserStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    //declare variables
    @observable listUserRank: ListUser | null = null;
    @observable loadingUpdateProfile: boolean = false;

    @action registerUser = async (params: IUserRegisterRequest) => {
        const result = (await UserService.getInstance().registerUser(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };

    @action sendFeedback = async (params: IUserFeedbackRequest) => {
        const result = (await UserService.getInstance().sendFeedback(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };

    @action dailyVisit = async (params: IUserDailyVisitRequest) => {
        const result = (await UserService.getInstance().dailyVisit(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };

    @action getListUserRank = async (
        params: IGetListUserRankRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listUserRank)
            runInAction(() => {
                this.listUserRank = new ListUser();
            });
        else if (loadMore) {
            this.listUserRank.pageIndex += 1;
            this.listUserRank.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listUserRank.pageIndex
                : this.listUserRank.pageSize * this.listUserRank.pageIndex;
        }

        //request api service
        const result = (await UserService.getInstance().getListUserRank(
            params
        )) as APIResponseList<Array<IUser>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listUserRank) {
                    this.listUserRank.list = this.listUserRank.list?.concat(
                        ListUser.fromJson(result.data?.list!)
                    )!;
                    this.listUserRank.totalRecord = result.totalRecord();
                    this.listUserRank.isLoading = false;
                }
            });
        }
        return result;
    };

    @action uploadAvatar = async (params: IUploadAvatarRequest) => {
        const result = (await UserService.getInstance().uploadAvatar(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };

    @action updateProfile = async (params: IUpdateProfileRequest) => {
        this.loadingUpdateProfile = true;
        const result = (await UserService.getInstance().updateProfile(
            params
        )) as APIResponse<IResponseBase>;
        this.loadingUpdateProfile = false;
        return result;
    };
}
export default UserStore;
