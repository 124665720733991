import { IVideo } from '../types/IVideo';
import { DataList } from './DataList';

export default class Video {
    public videoId: string;
    public videoName: string;
    public videoPath: string;
    public thumbPath: string;
    public status: number;
    public order: number;
    public point: number;
    public videoType: number;
    public playTime: number;

    constructor(
        videoId: string,
        videoName: string,
        videoPath: string,
        thumbPath: string,
        status: number,
        order: number,
        point: number,
        videoType: number,
        playTime: number
    ) {
        this.videoId = videoId;
        this.videoName = videoName;
        this.videoPath = videoPath;
        this.thumbPath = thumbPath;
        this.status = status;
        this.order = order;
        this.point = point;
        this.videoType = videoType;
        this.playTime = playTime;
    }

    static fromJson = (json: IVideo): Video => {
        return new Video(
            json.videoId,
            json.title,
            json.videoPath,
            json.thumbPath,
            json.status,
            json.order,
            json.point,
            json.video_type,
            json.playtime
        );
    };
}

export class VideoBasic {
    public videoId: string;
    public videoPath: string;
    public videoTitle: string;

    constructor(videoId: string, videoPath: string, videoTitle: string) {
        this.videoId = videoId;
        this.videoPath = videoPath;
        this.videoTitle = videoTitle;
    }
}
export class ListVideo extends DataList<Video> {
    static fromJson = (jsonArray: Array<IVideo>): Array<Video> => {
        if (!jsonArray) return [];
        let data = new Array<Video>();
        jsonArray.forEach((item) => {
            data.push(Video.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
