import { Modal } from 'antd';
import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';
import { AppButton } from '../../../styles/AppButton.styles';

export const WinDialogWrapper = styled.section`
    max-width: 460px;
`;

export const WinDialogContainer = styled.div`
    width: 100%;
    text-align: center;
    color: #314154;
`;

export const WinDialogModal = styled(Modal)`
    .ant-modal-body {
        text-align: center;
    }
`;

export const TitleContainer = styled.div`
    padding-top: 10px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const TitleText = styled.h1`
    font-weight: 700;
    margin: 0;
    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        font-size: 20px;
        line-height: 30px;
    }
`;

export const PrizeImageWrapper = styled.div`
    width: 150px;
    height: 150px;
    margin: 0px auto;
`;

export const PrizeImage = styled.img`
    object-fit: cover;
    width: 100%;
`;

export const PrizeNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

export const PrizeName = styled.p`
    font-weight: 700;
    margin: 0;
    color: red;
    margin-bottom: 10px;

    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        font-size: 16px;
        line-height: 30px;
    }
`;

export const GiftSerial = styled.p`
    font-weight: 800;
    margin: 0;
    color: #314154;
    margin-bottom: 20px;

    @media ${device.mobileS} {
        font-size: 12px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        font-size: 14px;
        line-height: 30px;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
`;

export const ButtonContinue = styled(AppButton)`
    width: 60%;
`;

export const ButtonBag = styled(AppButton)`
    margin-left: 10px;
    width: 40%;
`;

export const DescriptionText = styled.p`
    font-weight: 500;
    margin: 0;
    margin-right: 10px;
    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        font-size: 16px;
        line-height: 30px;
    }
`;
