import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    InvitedItemWrapper,
    InvitedItemContainer,
    InfoContainer,
    VisitTitle,
    VisitDescription,
    ItemIConWrapper
} from './InvitedItem.styles';
import { UserHistoryInvited } from '../../../../../../models/UserHistory';
import { RightSquareOutlined } from '@ant-design/icons';
import { formatNumber } from '../../../../../../utils/FormatUtils';
import moment from 'moment';

interface Props {
    data: UserHistoryInvited;
}

export const InvitedItem: ComponentType<Props> = pure(({ data }) => {
    return (
        <InvitedItemWrapper>
            <InvitedItemContainer>
                <ItemIConWrapper>
                    <RightSquareOutlined
                        style={{ fontSize: 30, color: 'red' }}
                    />
                </ItemIConWrapper>
                <InfoContainer>
                    <VisitTitle>{data.title}</VisitTitle>
                    <VisitDescription>
                        Điểm thưởng: {formatNumber(data.point)}
                    </VisitDescription>
                    <VisitDescription>
                        {moment(data.date).format('MM/DD/YYYY HH:mm:ss')}
                    </VisitDescription>
                </InfoContainer>
            </InvitedItemContainer>
        </InvitedItemWrapper>
    );
});
