import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFlip, EffectFade, Navigation, Pagination } from 'swiper';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';

//components
import { TournamentTopItem } from './TournamentTopItem/TournamentTopItem';
import { ListGift } from '../../../models/Gift';
import Tournament, { ListTournament } from '../../../models/Tournament';
//styles

interface Props {
    tours: ListTournament;
    onGetListMatch: (tour: Tournament) => void;
}

export const TournamentTopSlider: ComponentType<Props> = observer(
    ({ tours, onGetListMatch }) => {
        const store = useRootStore();
        return (
            <Swiper
                //slidesPerView={2.5}
                lazy={true}
                observer={true}
                key={Date.now()}
                effect={'flip'}
                navigation={true}
                autoplay={true}
                centeredSlides={true}
                loop={true}
                spaceBetween={15}
                breakpoints={{
                    640: {
                        //width: 640,
                        slidesPerView: 1
                    },
                    768: {
                        //width: 768,
                        slidesPerView: 1.5
                    },
                    992: {
                        //width: 992,
                        slidesPerView: 2.5,
                        spaceBetween: 24
                    },
                    1200: {
                        //width: 1200,
                        slidesPerView: 3.5,
                        spaceBetween: 32
                    }
                }}
                grabCursor={true}
                // pagination={{
                //     clickable: true
                // }}
                modules={[EffectFade, Navigation, Pagination]}
                className="mySwiper">
                {tours &&
                    tours.list.map((item) => {
                        return (
                            <SwiperSlide key={item.tournamentId}>
                                <TournamentTopItem
                                    onGetListMatch={onGetListMatch}
                                    key={item.tournamentId}
                                    data={item}
                                />
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
        );
    }
);
