import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { MatchItem } from '../../Home/Match/MatchItem';
import {
    HeaderWrapper,
    ListMatchWrapper,
    MatchStatusWrapper,
    StatusItemTitle,
    StatusItemWrapper
} from './ListMatch.styles';
import { AppTitle } from '../../../styles/App.styles';
import { Spin } from 'antd';
import { NoData } from '../../CommonView/NoData/NoData';
import Tournament from '../../../models/Tournament';
import { IGetListMatchRequest, IMatch } from '../../../types/IMatch';
import { APIResponseList } from '../../../models/APIResponse';
import { TOUR_STATUS_TYPE } from '../../../types/ITournament';

//components

//styles

interface Props {}

export const ListMatch: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { listMatch, currentTournament, getListMatch } =
        store.tournamentStore;

    const onGetListMatch = async (status: number) => {
        const params: IGetListMatchRequest = {
            data: {
                tourid: currentTournament?.tournamentId!,
                status: status
            }
        };
        let result: APIResponseList<Array<IMatch>> = await getListMatch(params);
    };

    return (
        <>
            <HeaderWrapper>
                <AppTitle>
                    GIẢI ĐẤU : {currentTournament?.tournamentName}
                </AppTitle>
                <MatchStatusWrapper>
                    <StatusItemWrapper
                        onClick={() => onGetListMatch(TOUR_STATUS_TYPE.ALL)}>
                        <StatusItemTitle>Tất cả</StatusItemTitle>
                    </StatusItemWrapper>
                    <StatusItemWrapper
                        onClick={() =>
                            onGetListMatch(TOUR_STATUS_TYPE.RUNNING)
                        }>
                        <StatusItemTitle>Đang diễn ra</StatusItemTitle>
                    </StatusItemWrapper>
                    <StatusItemWrapper
                        onClick={() => onGetListMatch(TOUR_STATUS_TYPE.READY)}>
                        <StatusItemTitle>Sắp diễn ra</StatusItemTitle>
                    </StatusItemWrapper>
                </MatchStatusWrapper>
            </HeaderWrapper>
            <ListMatchWrapper>
                {listMatch?.list.map((item) => {
                    return <MatchItem data={item} key={item.matchId} />;
                })}
                {listMatch?.isLoading ? <Spin /> : null}
                {!listMatch?.hasData() && !listMatch?.isLoading ? (
                    <NoData description="Giải đấu này chưa có trận đấu nào!" />
                ) : null}
            </ListMatchWrapper>
        </>
    );
});
