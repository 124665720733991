import { ComponentType, useState } from 'react';
import pure from 'recompose/pure';
import OtpInput from 'react-otp-input';

//styles
import {
    OTPDialogWrapper,
    OTPDialogContainer,
    OTPDialogModal,
    TitleText,
    TitleContainer,
    DescriptionText,
    SendButton,
    InputText,
    OTPWrapper
} from './OTPDialog.styles';

import { Modal } from 'antd';
import { useRootStore } from '../../../../../../providers/RootStoreProvider';
import { IResponseBase } from '../../../../../../types/ITypeBase';
import {
    IVerifyEmailRequest,
    IVerifyPhoneRequest,
    VERIFY_TYPE
} from '../../../../../../types/ILogin';
import { APP_BUTTON_TYPE } from '../../../../../../types/IGlobal';
import { showMessage } from '../../../../../../utils';

interface Props {
    visible: boolean;
    onClose: () => void;
    onVerifySuccess: (verifyType: VERIFY_TYPE) => void;
    verifyType: VERIFY_TYPE;
    email: string;
    phone: string;
}

export const OTPDialog: ComponentType<Props> = pure(
    ({ visible, verifyType, email, phone, onClose, onVerifySuccess }) => {
        const store = useRootStore();
        const { verifyEmail, verifyPhone } = store.authStore;

        const [otp, setOtp] = useState('');
        const isValid = otp.length === 6;

        const onVerifyAccount = () => {
            if (verifyType === VERIFY_TYPE.EMAIL) onVerifyEmail();
            else onVerifyPhone();
        };

        const onVerifyEmail = async () => {
            const params: IVerifyEmailRequest = {
                data: {
                    email: email,
                    otp: otp
                }
            };
            const result: IResponseBase = await verifyEmail(params);
            const msgSuccess = 'Chúc mừng bạn đã xác thực email thành công.';
            showMessage(result, msgSuccess);
            if (result && result.isSuccess()) {
                onClose();
                onVerifySuccess(VERIFY_TYPE.EMAIL);
            } else {
                setOtp('');
            }
        };

        const onVerifyPhone = async () => {
            const params: IVerifyPhoneRequest = {
                data: {
                    phone: phone,
                    otp: otp
                }
            };
            const result: IResponseBase = await verifyPhone(params);
            const msgSuccess =
                'Chúc mừng bạn đã xác thực điện thoại thành công.';
            showMessage(result, msgSuccess);
            if (result && result.isSuccess()) {
                onClose();
                onVerifySuccess(VERIFY_TYPE.PHONE);
            } else {
                setOtp('');
            }
        };

        const msgType =
            verifyType === VERIFY_TYPE.EMAIL
                ? 'Mã xác thực đã được gửi về email đăng ký của bạn. Vui lòng kiểm tra email và nhập mã xác thực '
                : 'Mã xác thực đã được gửi về số điện thoại đăng ký Zalo của bạn. Vui lòng kiểm tra Zalo trên điện thoại và nhập mã xác thực.';

        return (
            <OTPDialogWrapper>
                <OTPDialogContainer>
                    <OTPDialogModal
                        centered
                        onCancel={onClose}
                        open={visible}
                        footer={null}>
                        <TitleContainer>
                            <TitleText>Xác thực tài khoản</TitleText>
                        </TitleContainer>
                        <DescriptionText>{msgType}</DescriptionText>
                        <OTPWrapper>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span></span>}
                                renderInput={(props) => (
                                    <InputText {...props} />
                                )}
                            />
                        </OTPWrapper>
                        <SendButton
                            onClick={() => onVerifyAccount()}
                            typeButton={
                                isValid
                                    ? APP_BUTTON_TYPE.PRIMARY
                                    : APP_BUTTON_TYPE.DISABLED
                            }>
                            Xác thực tài khoản
                        </SendButton>
                    </OTPDialogModal>
                </OTPDialogContainer>
            </OTPDialogWrapper>
        );
    }
);
