import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//components
import { TopBanner } from './TopBanner/TopBanner';
import { RuleDescription } from './RuleDescription/RuleDescription';
import { DailySection } from './DailySection/DailySection';
import { MonthlySection } from './MonthlySection/MonthlySection';
//styles
import { MainWrapper, MainContainer } from './DailyAttendance.styles';
import { useRootStore } from '../../providers/RootStoreProvider';
import { HELP_CONTENT, MAIN_MENU, RULES_CONTENT } from '../../types/IGlobal';
import { observer } from 'mobx-react-lite';
import { TokenInvalid } from '../CommonView/TokenInvalid/TokenInvalid';
import { NotVerify } from '../CommonView/NotVerify/NotVerìfy';
import { RuleDialog } from '../CommonView/RuleDialog/RuleDialog';

interface Props {}

export const DailyAttendance: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { currentUser } = store.authStore;
    const {
        changeMainMenuIndex,
        showDialogRule,
        toggleShowDialogRule,
        setHelpType,
        toggleShowDialogHelp
    } = store.globalStore;

    useEffect(() => {
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        changeMainMenuIndex(MAIN_MENU.MISSION);
    }, []);

    const renderContent = () => {
        if (!currentUser?.isLogin()) {
            return <TokenInvalid />;
        } else if (!currentUser.isVerified()) {
            return <NotVerify />;
        } else
            return (
                <>
                    <DailySection />
                    <MonthlySection />
                </>
            );
    };

    const onShowDialogHelp = () => {
        setHelpType(HELP_CONTENT.VISIT);
        toggleShowDialogHelp(true);
    };

    return (
        <MainWrapper>
            <MainContainer>
                <TopBanner
                    onClickRules={() => toggleShowDialogRule(true)}
                    onClickHelp={() => {
                        onShowDialogHelp();
                    }}
                />
                <RuleDescription />
                {renderContent()}
            </MainContainer>
            {showDialogRule ? <RuleDialog type={RULES_CONTENT.VISIT} /> : null}
        </MainWrapper>
    );
});
