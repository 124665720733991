import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';
import { AppButton } from '../../../../styles/AppButton.styles';
import LinesEllipsis from 'react-lines-ellipsis';

export const BagItemWrapper = styled.div`
    position: relative;
    margin: 10px;
    border: solid 1px #fff;
    border-radius: 10px;
`;

export const BagItemContainer = styled.div``;

export const GiftThumbnailContainer = styled.div`
    /* width: 500px; */
    //max-height: 200px;
    border-radius: 10px;

    padding: 20px;
    background-color: #000;
`;

export const GiftThumbnail = styled.img`
    object-fit: cover;
    width: 100%;
    height: 160px;
    border-radius: 10px;
`;

export const InfoContainer = styled.div`
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: #252525;
`;

export const GiftName = styled(LinesEllipsis)`
    color: #fff;
    margin: 0;
    width: 100%;
    font-weight: 600;
    @media ${device.mobileS} {
        font-size: 16px;
    }

    @media ${device.tablet} {
        font-size: 18px;
    }
`;

export const GiftDesc = styled.h1`
    color: #fff;
    margin: 0;
    font-weight: 500;
    @media ${device.mobileS} {
        font-size: 14px;
    }

    @media ${device.tablet} {
        font-size: 16px;
    }
`;

export const ExchangeButton = styled(AppButton)`
    height: 40px;
    margin-top: 10px;
    width: 50%;
`;
