import styled from 'styled-components';
import { AppCard } from '../../../../../styles/App.styles';
import { AppButton } from '../../../../../styles/AppButton.styles';
import { AppInputText } from '../../../../../styles/AppInput.styles';
import { device } from '../../../../../configs/MediaQuery';
import { Progress } from 'antd';

export const VerifyAccountContainer = styled(AppCard)`
    .ant-card-body {
        padding: 20px;
    }
`;

export const InputWrapper = styled.div`
    display: flex;
    @media ${device.mobileS} {
        flex-direction: column;
    }
    @media ${device.laptop} {
        flex-direction: row;
    }
`;

export const SendButton = styled(AppButton)`
    height: 40px;

    @media ${device.mobileS} {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 20px;
    }
    @media ${device.laptop} {
        width: 300px;
        margin-left: 20px;
    }
`;

export const InputText = styled(AppInputText)`
    background: transparent;
    color: #fff;
`;

export const VerifyProcess = styled(Progress)`
    .ant-progress-inner {
        margin-left: 20px;
        width: 40px !important;
        height: 40px !important;
    }
`;
