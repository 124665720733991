import { ITournament } from '../types/ITournament';
import { DataList } from './DataList';

export default class Tournament {
    public tournamentId: string;
    public tournamentName: string;
    public image: string;
    public order: number;
    public status: number;

    constructor(
        tournamentId: string,
        tournamentName: string,
        image: string,
        order: number,
        status: number
    ) {
        this.tournamentId = tournamentId;
        this.tournamentName = tournamentName;
        this.image = image;
        this.order = order;
        this.status = status;
    }

    static fromJson = (json: ITournament): Tournament => {
        return new Tournament(
            json.tournamentId,
            json.tournamentName,
            json.tourImg,
            json.order,
            json.status
        );
    };
}

export class ListTournament extends DataList<Tournament> {
    static fromJson = (jsonArray: Array<ITournament>): Array<Tournament> => {
        if (!jsonArray) return [];
        let data = new Array<Tournament>();
        jsonArray.forEach((item) => {
            data.push(Tournament.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
