import styled from 'styled-components';
import { device } from '../../configs/MediaQuery';

export const MaintenanceWrapper = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #000;
`;

export const MaintenanceContainer = styled.div`
    max-width: 1100px;
    height: 100vh;
    background-color: #19181c;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const MaintenanceTitle = styled.h1`
    margin: 20px;
    font-weight: bold;
    color: #fff;

    @media ${device.mobileS} {
        font-size: 20px;
    }
    @media ${device.laptop} {
        font-size: 30px;
    }
`;

export const MaintenanceImage = styled.img`
    width: 200px;
`;

export const MaintenanceDescription = styled.p`
    margin: 0;
    font-weight: 500;
    color: #707070;
    @media ${device.mobileS} {
        font-size: 16px;
    }
    @media ${device.laptop} {
        font-size: 22px;
    }
`;
