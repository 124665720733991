import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//components
import { TopBanner } from './TopBanner/TopBanner';
import { VideoItem } from './VideoItem/VideoItem';
//styles
import {
    AppContentWrapper,
    AppContentContainer
} from '../../styles/App.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../providers/RootStoreProvider';
import { IGetListVideoRequest, IVideo } from '../../types/IVideo';
import { APIResponseList } from '../../models/APIResponse';
import { Row } from 'antd';
import { TokenInvalid } from '../CommonView/TokenInvalid/TokenInvalid';
import { NotVerify } from '../CommonView/NotVerify/NotVerìfy';

interface Props {}

export const VideoView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { currentUser } = store.authStore;
    const { listVideo, getListVideo } = store.videoStore;

    useEffect(() => {
        if (!listVideo || !listVideo.hasData()) onGetListVideo();
    }, []);

    const onGetListVideo = async () => {
        const params: IGetListVideoRequest = {};
        let result: APIResponseList<Array<IVideo>> = await getListVideo(params);
    };

    const renderContent = () => {
        if (!currentUser?.isLogin()) {
            return <TokenInvalid />;
        } else if (!currentUser.isVerified()) {
            return <NotVerify />;
        } else
            return (
                <>
                    <Row>
                        {listVideo &&
                            listVideo.list.map((item) => {
                                return (
                                    <VideoItem key={item.videoId} data={item} />
                                );
                            })}
                    </Row>
                </>
            );
    };

    return (
        <AppContentWrapper>
            <AppContentContainer>
                <TopBanner></TopBanner>
                {renderContent()}
            </AppContentContainer>
        </AppContentWrapper>
    );
});
