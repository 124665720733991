import { action, makeObservable, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { ListBanner } from '../models/Banner';
import { IBanner, IGetListBannerRequest } from '../types/IBanner';
import { APIResponse, APIResponseList } from '../models/APIResponse';
import { CommonService } from '../services/CommonService';
import Intro from '../models/Intro';
import {
    IGetInfoHelpRequest,
    IGetInfoIntroRequest,
    IIntro
} from '../types/IIntro';
import { ListCity, ListDistrict } from '../models/Location';
import {
    ICity,
    IDistrict,
    IGetListCityRequest,
    IGetListDistrictRequest
} from '../types/ILocation';
import { IConfig, IGetConfigRequest } from '../types/IConfig';
import { IResponseBase } from '../types/ITypeBase';

class BannerStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable listBanner: ListBanner | null = null;
    @observable introInfo: Intro | null = null;
    @observable helpInfo: Intro | null = null;
    @observable listCity: ListCity | null = null;
    @observable listDistrict: ListDistrict | null = null;
    @observable appConfig: IConfig | null = null;

    @action getConfigApp = async (params: IGetConfigRequest) => {
        const result = (await CommonService.getInstance().getConfigApp(
            params
        )) as APIResponse<IConfig>;
        if (result && result.isSuccess())
            runInAction(() => {
                this.appConfig = result.data!;
            });
        return result;
    };

    @action getInfoIntro = async (params: IGetInfoIntroRequest) => {
        const result = (await CommonService.getInstance().getInfoIntro(
            params
        )) as APIResponse<IIntro>;

        //if (result && result.isSuccess())
        if (result)
            runInAction(() => {
                this.introInfo = Intro.fromJson(result.data!);
            });
        return result;
    };

    @action getInfoHelp = async (params: IGetInfoHelpRequest) => {
        const result = (await CommonService.getInstance().getInfoHelp(
            params
        )) as APIResponse<IIntro>;

        if (result && result.isSuccess())
            runInAction(() => {
                this.helpInfo = Intro.fromJson(result.data!);
            });
        return result;
    };

    @action getListBanner = async (
        params: IGetListBannerRequest,
        loadMore: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listBanner)
            runInAction(() => {
                this.listBanner = new ListBanner();
            });
        else if (loadMore) {
            this.listBanner.pageIndex += 1;
            this.listBanner.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listBanner.pageIndex
                : this.listBanner.pageSize * this.listBanner.pageIndex;
        }

        //request api service
        const result = (await CommonService.getInstance().getListBanner(
            params
        )) as APIResponseList<Array<IBanner>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listBanner) {
                    this.listBanner.list = this.listBanner.list?.concat(
                        ListBanner.fromJson(result.data?.list!)
                    )!;
                    this.listBanner.totalRecord = result.totalRecord();
                    this.listBanner.isLoading = false;
                }
            });
        } else this.listBanner!.isLoading = false;
        return result;
    };

    @action getListCity = async (
        params: IGetListCityRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listCity)
            runInAction(() => {
                this.listCity = new ListCity();
            });
        else if (loadMore) {
            this.listCity.pageIndex += 1;
            this.listCity.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listCity.pageIndex
                : this.listCity.pageSize * this.listCity.pageIndex;
        }

        //request api service
        const result = (await CommonService.getInstance().getListCity(
            params
        )) as APIResponseList<Array<ICity>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listCity) {
                    this.listCity.list = this.listCity.list?.concat(
                        ListCity.fromJson(result.data?.list!)
                    )!;
                    this.listCity.totalRecord = result.totalRecord();
                    this.listCity.isLoading = false;
                }
            });
        }
        return result;
    };

    @action getListDistrict = async (
        params: IGetListDistrictRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listDistrict)
            runInAction(() => {
                this.listDistrict = new ListDistrict();
            });
        else if (loadMore) {
            this.listDistrict.pageIndex += 1;
            this.listDistrict.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listDistrict.pageIndex
                : this.listDistrict.pageSize * this.listDistrict.pageIndex;
        }

        //request api service
        const result = (await CommonService.getInstance().getListDistrict(
            params
        )) as APIResponseList<Array<IDistrict>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listDistrict) {
                    this.listDistrict.list = this.listDistrict.list?.concat(
                        ListDistrict.fromJson(result.data?.list!)
                    )!;
                    this.listDistrict.totalRecord = result.totalRecord();
                    this.listDistrict.isLoading = false;
                }
            });
        }
        return result;
    };
}

export default BannerStore;
