import { BANNER_TYPE, IBanner } from '../types/IBanner';
import { DataList } from './DataList';

export default class Banner {
    public image: string;
    public bannerId: string;
    public type: BANNER_TYPE;
    public url: string;
    public order: number;
    public status: number;

    constructor(
        image: string,
        bannerId: string,
        type: BANNER_TYPE,
        url: string,
        order: number,
        status: number
    ) {
        this.image = image;
        this.bannerId = bannerId;
        this.type = type;
        this.url = url;
        this.order = order;
        this.status = status;
    }

    static fromJson = (json: IBanner): Banner => {
        return new Banner(
            json.image,
            json.bannerId,
            json.type,
            json.url,
            json.order,
            json.status
        );
    };

    isLinkExternal = (): boolean => {
        return (
            this.url.indexOf('http://') !== -1 ||
            this.url.indexOf('https://') !== -1
        );
    };
}

export class ListBanner extends DataList<Banner> {
    static fromJson = (jsonArray: Array<IBanner>): Array<Banner> => {
        if (!jsonArray) return [];
        let data = new Array<Banner>();
        jsonArray.forEach((item) => {
            data.push(Banner.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
