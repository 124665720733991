import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';
import { RULES_CONTENT } from '../../../types/IGlobal';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { IGetInfoIntroRequest, IIntro } from '../../../types/IIntro';
import { APIResponse } from '../../../models/APIResponse';
import { Modal, Spin } from 'antd';

//components

//styles
import {
    RuleDialogContainer,
    RuleDialogTitle,
    RuleDialogTitleWrapper,
    RuleDialogWrapper,
    RuleModal
} from './RuleDialog.styles';
import { observer } from 'mobx-react-lite';
interface Props {
    type: RULES_CONTENT;
}

export const RuleDialog: ComponentType<Props> = observer(({ type }) => {
    const store = useRootStore();
    const { showDialogRule, toggleShowDialogRule } = store.globalStore;
    const { introInfo, getInfoIntro } = store.commonStore;

    useEffect(() => {
        onGetIntroInfo();
    }, []);

    const onGetIntroInfo = async () => {
        const params: IGetInfoIntroRequest = {
            data: {
                type: type
            }
        };
        let result: APIResponse<IIntro> = await getInfoIntro(params);
    };

    return (
        <RuleDialogWrapper>
            <RuleModal
                title={introInfo?.title}
                open={showDialogRule}
                centered={true}
                onCancel={() => toggleShowDialogRule(false)}
                width={800}
                footer={null}>
                {!introInfo ? (
                    <RuleDialogContainer>
                        <Spin />
                    </RuleDialogContainer>
                ) : (
                    <>
                        {/* <RuleDialogTitleWrapper>
                            <RuleDialogTitle>
                                {introInfo?.title}
                            </RuleDialogTitle>
                        </RuleDialogTitleWrapper> */}

                        <RuleDialogContainer
                            dangerouslySetInnerHTML={{
                                __html: introInfo?.content!
                            }}></RuleDialogContainer>
                    </>
                )}
            </RuleModal>
        </RuleDialogWrapper>
    );
});
