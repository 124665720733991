import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { AppTitle } from '../../../styles/App.styles';
//styles
import {
    TopBannerWrapper,
    TopBannerContainer,
    TitleWrapper,
    BannerWrapper,
    BannerImage,
    RuleButton,
    ButtonWrapper,
    HelpButton
} from './TopBanner.styles';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';

interface Props {
    onClickRules: () => void;
    onClickHelp: () => void;
}

export const TopBanner: ComponentType<Props> = observer(
    ({ onClickRules, onClickHelp }) => {
        const store = useRootStore();
        const { appBanner } = store.jsonStore;

        return (
            <TopBannerWrapper>
                <TopBannerContainer>
                    <TitleWrapper>
                        <AppTitle>ĐIỂM DANH HÀNG NGÀY</AppTitle>
                    </TitleWrapper>
                    <BannerWrapper>
                        <BannerImage src={appBanner?.visit}></BannerImage>
                        <ButtonWrapper>
                            <HelpButton
                                typeButton={APP_BUTTON_TYPE.PRIMARY}
                                onClick={onClickHelp}>
                                Hướng dẫn
                            </HelpButton>
                            <RuleButton
                                typeButton={APP_BUTTON_TYPE.PRIMARY}
                                onClick={onClickRules}>
                                Thể lệ
                            </RuleButton>
                        </ButtonWrapper>
                    </BannerWrapper>
                </TopBannerContainer>
            </TopBannerWrapper>
        );
    }
);
