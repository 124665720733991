import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    SpinItemWrapper,
    SpinItemContainer,
    InfoContainer,
    VisitTitle,
    VisitDescription,
    ItemIConWrapper,
    WinDescription
} from './SpinItem.styles';
import { UserHistorySpin } from '../../../../../../models/UserHistory';
import { RightSquareOutlined } from '@ant-design/icons';
import moment from 'moment';

interface Props {
    data: UserHistorySpin;
}

export const SpinItem: ComponentType<Props> = pure(({ data }) => {
    return (
        <SpinItemWrapper>
            <SpinItemContainer>
                <ItemIConWrapper>
                    <RightSquareOutlined
                        style={{ fontSize: 30, color: 'red' }}
                    />
                </ItemIConWrapper>
                <InfoContainer>
                    <VisitTitle>
                        Ngày quay số:{' '}
                        {moment(data.date).format('MM/DD/YYYY HH:mm:ss')}
                    </VisitTitle>
                    {data.serial && data.serial.length > 0 ? (
                        <WinDescription>Kết quả: {data.result}</WinDescription>
                    ) : (
                        <VisitDescription>
                            Kết quả: {data.result}
                        </VisitDescription>
                    )}
                    {data.serial && data.serial.length > 0 ? (
                        <WinDescription>
                            Mã giải thưởng: {data.serial}
                        </WinDescription>
                    ) : null}
                </InfoContainer>
            </SpinItemContainer>
        </SpinItemWrapper>
    );
});
