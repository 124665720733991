import { Col, Row } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { device } from '../../../configs/MediaQuery';

export const FooterWrapper = styled.footer`
    width: 100%;
    background: #000000;
    min-height: 240px;
`;

export const LogoWrapper = styled.div`
    padding-top: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LogoImage = styled.img`
    @media ${device.mobileS} {
        margin-top: 22px;
        margin-bottom: 20px;
        max-width: 112px;
        max-height: 39px;
    }
    @media ${device.laptop} {
        max-width: 184px;
        max-height: 65px;
        margin-top: 24px;
        margin-bottom: 25px;
    }
`;

export const FooterContainer = styled.div`
    max-width: 1140px;
    margin: 0 auto;
`;

export const MenuContainer = styled.ul`
    display: flex;
    flex-grow: 1;
    margin: 0;

    list-style-type: none;
    justify-content: center;

    @media ${device.mobileS} {
        flex-direction: column;
        padding-left: 24px;
    }
    @media ${device.laptop} {
        flex-direction: row;
        padding-left: 0px;
    }
`;

export const MenuItem = styled.li`
    padding-right: 55px;
    @media ${device.mobileS} {
        padding-bottom: 17px;
    }
    @media ${device.laptop} {
        padding-bottom: 0px;
    }
`;

export const MenuLink = styled.a`
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    &:hover,
    &:focus {
    }
    &:active {
        color: red;
    }
`;

export const AddressWrapper = styled.div`
    display: flex;
    @media ${device.mobileS} {
        padding-top: 0px;
    }
    @media ${device.laptop} {
        padding-top: 0px;
    }
`;

export const AddressContainer = styled(Row)`
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @media ${device.mobileS} {
        width: 100%;
        padding: 14px;
    }
    @media ${device.laptop} {
        width: 900px;
    }
`;

export const AddressItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    @media ${device.mobileS} {
        padding-top: 12px;
    }
    @media ${device.laptop} {
        padding-right: 40px;
        padding-top: 0px;
    }
`;

export const AddressIconWrapper = styled.div`
    width: 15px;
    margin-right: 10px;
`;
export const AddressText = styled.p`
    color: #fff;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
`;
