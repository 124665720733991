import {
    IUserHistoryBuyGift,
    IUserHistoryInvited,
    IUserHistorySpin,
    IUserHistoryVideo,
    IUserHistoryVisit
} from '../types/IUserHistory';
import { DataList } from './DataList';

export default class UserHistoryVisit {
    public id: number;
    public title: string;
    public date: string;
    public point: number;

    constructor(id: number, title: string, date: string, point: number) {
        this.id = id;
        this.title = title;
        this.date = date;
        this.point = point;
    }

    static fromJson = (json: IUserHistoryVisit): UserHistoryVisit => {
        return new UserHistoryVisit(json.id, json.title, json.date, json.point);
    };
}

export class ListUserHistoryVisit extends DataList<UserHistoryVisit> {
    static fromJson = (
        jsonArray: Array<IUserHistoryVisit>
    ): Array<UserHistoryVisit> => {
        if (!jsonArray) return [];
        let data = new Array<UserHistoryVisit>();
        jsonArray.forEach((item) => {
            data.push(UserHistoryVisit.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}

// history invited
export class UserHistoryInvited {
    public id: number;
    public title: string;
    public date: string;
    public point: number;

    constructor(id: number, title: string, date: string, point: number) {
        this.id = id;
        this.title = title;
        this.date = date;
        this.point = point;
    }

    static fromJson = (json: IUserHistoryInvited): UserHistoryInvited => {
        return new UserHistoryInvited(
            json.id,
            json.title,
            json.date,
            json.point
        );
    };
}

export class ListUserHistoryInvited extends DataList<UserHistoryInvited> {
    static fromJson = (
        jsonArray: Array<IUserHistoryInvited>
    ): Array<UserHistoryInvited> => {
        if (!jsonArray) return [];
        let data = new Array<UserHistoryInvited>();
        jsonArray.forEach((item) => {
            data.push(UserHistoryInvited.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}

export class UserHistoryInvitedConfig {
    public totalinvite: number;

    constructor(totalinvite: number) {
        this.totalinvite = totalinvite;
    }

    static fromJson = (json: any): UserHistoryInvitedConfig => {
        return new UserHistoryInvitedConfig(json.totalinvite);
    };
}

// video
export class UserHistoryVideo {
    public id: number;
    public title: string;
    public date: string;
    public point: number;

    constructor(id: number, title: string, date: string, point: number) {
        this.id = id;
        this.title = title;
        this.date = date;
        this.point = point;
    }

    static fromJson = (json: IUserHistoryVideo): UserHistoryVideo => {
        return new UserHistoryVideo(json.id, json.title, json.date, json.point);
    };
}

export class ListUserHistoryVideo extends DataList<UserHistoryVideo> {
    static fromJson = (
        jsonArray: Array<IUserHistoryVideo>
    ): Array<UserHistoryVideo> => {
        if (!jsonArray) return [];
        let data = new Array<UserHistoryVideo>();
        jsonArray.forEach((item) => {
            data.push(UserHistoryVideo.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}

// spin
export class UserHistorySpin {
    public id: number;
    public result: string;
    public date: string;
    public serial: string;

    constructor(id: number, result: string, date: string, serial: string) {
        this.id = id;
        this.result = result;
        this.date = date;
        this.serial = serial;
    }

    static fromJson = (json: IUserHistorySpin): UserHistorySpin => {
        return new UserHistorySpin(
            json.id,
            json.result,
            json.date,
            json.serial
        );
    };
}

export class ListUserHistorySpin extends DataList<UserHistorySpin> {
    static fromJson = (
        jsonArray: Array<IUserHistorySpin>
    ): Array<UserHistorySpin> => {
        if (!jsonArray) return [];
        let data = new Array<UserHistorySpin>();
        jsonArray.forEach((item) => {
            data.push(UserHistorySpin.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}

//gift
export class UserHistoryBuyGift {
    public giftId: number;
    public giftName: string;
    public date: string;
    public point: number;
    public info: string;

    constructor(
        giftId: number,
        giftName: string,
        date: string,
        point: number,
        info: string
    ) {
        this.giftId = giftId;
        this.giftName = giftName;
        this.date = date;
        this.point = point;
        this.info = info;
    }

    static fromJson = (json: IUserHistoryBuyGift): UserHistoryBuyGift => {
        return new UserHistoryBuyGift(
            json.giftId,
            json.giftName,
            json.date,
            json.point,
            json.info
        );
    };
}

export class ListUserHistoryBuyGift extends DataList<UserHistoryBuyGift> {
    static fromJson = (
        jsonArray: Array<IUserHistoryBuyGift>
    ): Array<UserHistoryBuyGift> => {
        if (!jsonArray) return [];
        let data = new Array<UserHistoryBuyGift>();
        jsonArray.forEach((item) => {
            data.push(UserHistoryBuyGift.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
