import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    GiftCategoryContainer,
    GiftCategoryWrapper
} from './GiftCategoryView.styles';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { observer } from 'mobx-react-lite';
import { IGetListGiftCategory } from '../../../types/IGiftCategory';
import { CategoryItem } from './CategoryItem/CategoryItem';
import { GIFT_TYPE, IGetListGiftRequest } from '../../../types/IGift';
import GiftCategory from '../../../models/GiftCategory';

interface Props {}

export const GiftCategoryView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { listGiftCategory, getListGift, setGiftCategory } = store.giftStore;

    useEffect(() => {
        //onGetListGiftCategory();
    }, []);

    const onGetListGift = async (category: GiftCategory) => {
        const params: IGetListGiftRequest = {
            data: {
                type: GIFT_TYPE.ALL,
                catId: Number(category.catId)
            }
        };
        const result = await getListGift(params);
    };

    const onChangeCategory = async (category: GiftCategory) => {
        setGiftCategory(category);
        onGetListGift(category);
    };

    return (
        <GiftCategoryWrapper>
            <GiftCategoryContainer title="CHỌN LOẠI QUÀ">
                {listGiftCategory &&
                    listGiftCategory.list.map((item) => {
                        return (
                            <CategoryItem
                                data={item}
                                key={item.catId}
                                onChangeCategory={onChangeCategory}
                            />
                        );
                    })}
            </GiftCategoryContainer>
        </GiftCategoryWrapper>
    );
});
