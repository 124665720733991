import { ComponentType, useEffect } from 'react';
import { Modal, Radio, Select, Spin } from 'antd';
import { animateScroll as scroll, scrollSpy } from 'react-scroll';
//components

//styles
import {
    UserInfoWrapper,
    UserInfoContainer,
    UpdateButton,
    InputWrapper,
    InputText,
    ErrorText,
    FieldWrapper,
    FieldLabel,
    VerifyProcess,
    VerifyFieldWrapper,
    BirthdayInput,
    CitySelect,
    DistrictSelect
} from './UserInfo.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../../../providers/RootStoreProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { UpdateProfileSchema } from '../../../../../validation/UpdateProfileSchema';
import {
    IUpdateProfileInfo,
    IUpdateProfileRequest
} from '../../../../../types/IUser';
import User from '../../../../../models/User';
import { APP_BUTTON_TYPE } from '../../../../../types/IGlobal';
import type { DatePickerProps } from 'antd';
import moment from 'moment';
import {
    IGetListCityRequest,
    IGetListDistrictRequest
} from '../../../../../types/ILocation';
import { delay, showMessage } from '../../../../../utils';

interface Props {
    user: User;
}

const { Option } = Select;

export const UserInfo: ComponentType<Props> = observer(({ user }) => {
    const store = useRootStore();
    const { currentUser } = store.authStore;
    const { updateProfile, loadingUpdateProfile } = store.userStore;
    const { listCity, listDistrict, getListCity, getListDistrict } =
        store.commonStore;

    const {
        register,
        handleSubmit,
        control,
        reset,
        resetField,
        formState: { errors }
    } = useForm<IUpdateProfileInfo>({
        resolver: yupResolver(UpdateProfileSchema),
        defaultValues: {
            fullName: currentUser?.name,
            sex: currentUser?.sex,
            birthDay: currentUser?.birthday,
            email: currentUser?.email,
            phone: currentUser?.phone,
            cityId: currentUser?.cityId,
            districtId: currentUser?.districtId,
            verifyNumber: currentUser?.verifyNumber
        }
    });

    useEffect(() => {
        if (!listCity) onGetListCity();
        autoScroll();
    }, []);

    useEffect(() => {
        if (currentUser?.cityId != 0) {
            onGetListDistrict(currentUser?.cityId);
        }
    }, []);

    const autoScroll = async () => {
        await delay(200);
        scroll.scrollTo(400);
    };

    const onUpdateProfile = async (data: IUpdateProfileInfo) => {
        console.log(data);
        const params: IUpdateProfileRequest = {
            data: {
                fn: data.fullName,
                email: data.email,
                phone: data.phone,
                sex: data.sex,
                locid: data.districtId,
                verifyNumber: data.verifyNumber,
                dob: moment(data.birthDay).format('YYYY/MM/DD')
            }
        };

        const result = await updateProfile(params);
        const msgSuccess =
            'Chúc mừng bạn đã cập nhật thông tin tài khoản thành công';
        showMessage(result, msgSuccess);
    };

    const onGetListCity = async () => {
        const params: IGetListCityRequest = {
            data: {}
        };
        const result = await getListCity(params);
    };

    const onGetListDistrict = async (cityId: any) => {
        const params: IGetListDistrictRequest = {
            data: {
                cityid: cityId
            }
        };
        const result = await getListDistrict(params);
    };

    const onChangeBirthday: DatePickerProps['onChange'] = (
        date,
        dateString
    ) => {
        console.log(date, dateString);
    };

    const getBirthday = () => {
        if (!currentUser || !currentUser.birthday) return moment(new Date());
        else return moment(currentUser.birthday);
    };

    return (
        <UserInfoWrapper>
            <UserInfoContainer title="THÔNG TIN TÀI KHOẢN">
                <form
                    key={1}
                    onSubmit={handleSubmit(onUpdateProfile)}
                    autoComplete="off">
                    <FieldWrapper>
                        <FieldLabel>Họ và tên</FieldLabel>
                        <InputWrapper>
                            <InputText
                                placeholder="Họ và tên"
                                {...register('fullName')}
                                isError={errors.fullName ? 'error' : ''}
                            />
                            {errors.fullName ? (
                                <ErrorText>{errors.fullName.message}</ErrorText>
                            ) : null}
                        </InputWrapper>
                    </FieldWrapper>

                    <FieldWrapper>
                        <FieldLabel>Giới tính</FieldLabel>
                        <Controller
                            control={control}
                            name="sex"
                            //defaultValue={gender}
                            render={({
                                field: { onChange, value },
                                fieldState: { invalid, error },
                                formState
                            }) => (
                                <Radio.Group
                                    value={value}
                                    style={{ marginTop: 10, marginBottom: 20 }}
                                    onChange={(e) => onChange(e.target.value)}>
                                    <Radio value={0} style={{ color: '#fff' }}>
                                        Nam
                                    </Radio>
                                    <Radio value={1} style={{ color: '#fff' }}>
                                        Nữ
                                    </Radio>
                                </Radio.Group>
                            )}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <FieldLabel>Ngày sinh</FieldLabel>
                        <Controller
                            control={control}
                            name="birthDay"
                            //defaultValue={gender}
                            render={({
                                field: { onChange, value },
                                fieldState: { invalid, error },
                                formState
                            }) => (
                                <BirthdayInput
                                    defaultValue={getBirthday()}
                                    onChange={onChange}
                                    format={'DD-MM-yyyy'}
                                    allowClear={false}
                                />
                            )}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <FieldLabel>CMND / CCCD</FieldLabel>
                        <InputWrapper>
                            <InputText
                                placeholder="CMND / CCCD"
                                {...register('verifyNumber')}
                            />
                        </InputWrapper>
                    </FieldWrapper>
                    <FieldWrapper>
                        <FieldLabel>Email</FieldLabel>
                        <InputWrapper>
                            <VerifyFieldWrapper>
                                <InputText
                                    placeholder="Email"
                                    style={{ width: '90%' }}
                                    {...register('email')}
                                    isError={errors.email ? 'error' : ''}
                                />
                                {currentUser?.verifyEmail ? (
                                    <VerifyProcess
                                        type="circle"
                                        percent={100}
                                        size="small"
                                    />
                                ) : (
                                    <VerifyProcess
                                        type="circle"
                                        percent={100}
                                        size="small"
                                        status="exception"
                                    />
                                )}
                            </VerifyFieldWrapper>
                            {errors.email ? (
                                <ErrorText>{errors.email.message}</ErrorText>
                            ) : null}
                        </InputWrapper>
                    </FieldWrapper>
                    <FieldWrapper>
                        <FieldLabel>Số điện thoại</FieldLabel>
                        <InputWrapper>
                            <VerifyFieldWrapper>
                                <InputText
                                    placeholder="Số điện thoại"
                                    {...register('phone')}
                                    isError={errors.phone ? 'error' : ''}
                                />
                                {currentUser?.verifyPhone ? (
                                    <VerifyProcess
                                        type="circle"
                                        percent={100}
                                        size="small"
                                    />
                                ) : (
                                    <VerifyProcess
                                        type="circle"
                                        percent={100}
                                        size="small"
                                        status="exception"
                                    />
                                )}
                            </VerifyFieldWrapper>
                            {errors.phone ? (
                                <ErrorText>{errors.phone.message}</ErrorText>
                            ) : null}
                        </InputWrapper>
                    </FieldWrapper>

                    <FieldWrapper style={{ marginBottom: 20 }}>
                        <FieldLabel>Tỉnh thành</FieldLabel>
                        {listCity && listCity.hasData() ? (
                            <Controller
                                control={control}
                                name="cityId"
                                render={({
                                    field: { onChange, value },
                                    fieldState: { invalid, error },
                                    formState
                                }) => (
                                    <InputWrapper>
                                        <CitySelect
                                            defaultValue={currentUser?.cityId}
                                            style={{ width: '100%' }}
                                            value={value}
                                            onChange={(e) => {
                                                onGetListDistrict(e);
                                                onChange(e);
                                                currentUser?.resetDistrict();
                                                resetField('districtId', {
                                                    defaultValue: ''
                                                });
                                            }}>
                                            {listCity?.list.map((item) => {
                                                return (
                                                    <Option
                                                        key={item.cityId}
                                                        value={item.cityId}>
                                                        {item.cityName}
                                                    </Option>
                                                );
                                            })}
                                        </CitySelect>
                                        {errors.cityId ? (
                                            <ErrorText>
                                                {errors.cityId.message}
                                            </ErrorText>
                                        ) : null}
                                    </InputWrapper>
                                )}
                            />
                        ) : null}
                    </FieldWrapper>

                    <FieldWrapper>
                        <FieldLabel>Quận huyện</FieldLabel>
                        {listDistrict && listDistrict.hasData() ? (
                            <Controller
                                control={control}
                                name="districtId"
                                render={({
                                    field: { onChange, value },
                                    fieldState: { invalid, error },
                                    formState
                                }) => (
                                    <InputWrapper>
                                        <DistrictSelect
                                            defaultValue={
                                                currentUser?.districtId
                                            }
                                            style={{ width: '100%' }}
                                            value={currentUser?.districtId}
                                            onChange={(e) => {
                                                onChange(e);
                                                currentUser?.updateDistrict(e);
                                            }}>
                                            {listDistrict?.list.map((item) => {
                                                return (
                                                    <Option
                                                        key={item.districtId}
                                                        value={item.districtId}>
                                                        {item.districtName}
                                                    </Option>
                                                );
                                            })}
                                        </DistrictSelect>
                                        {errors.districtId ? (
                                            <ErrorText>
                                                {errors.districtId.message}
                                            </ErrorText>
                                        ) : null}
                                    </InputWrapper>
                                )}
                            />
                        ) : (
                            <InputWrapper>
                                <DistrictSelect></DistrictSelect>
                                {errors.districtId ? (
                                    <ErrorText>
                                        {errors.districtId.message}
                                    </ErrorText>
                                ) : null}
                            </InputWrapper>
                        )}
                    </FieldWrapper>

                    {!loadingUpdateProfile ? (
                        <UpdateButton
                            type="submit"
                            typeButton={APP_BUTTON_TYPE.PRIMARY}>
                            Cập nhật thông tin
                        </UpdateButton>
                    ) : (
                        <UpdateButton
                            style={{ pointerEvents: 'none' }}
                            typeButton={APP_BUTTON_TYPE.PRIMARY}>
                            <Spin />
                        </UpdateButton>
                    )}
                </form>
            </UserInfoContainer>
        </UserInfoWrapper>
    );
});
