import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//styles
import {
    MainWrapper,
    MainContainer,
    TitleWrapper,
    TitleText,
    GameItemWrapper
} from './MiniGameView.styles';
import { Col, Row } from 'antd';
import { GameItem } from '../Home/MiniGame/GameItem/GameItem';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../providers/RootStoreProvider';
import { IGetListMiniGameRequest, IMiniGame } from '../../types/IMiniGame';
import { APIResponseList } from '../../models/APIResponse';

interface Props {}

export const MiniGameView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { listMiniGame, getListMiniGame } = store.miniGameStore;

    useEffect(() => {
        onGetListMiniGame();
    }, []);

    const onGetListMiniGame = async () => {
        const params: IGetListMiniGameRequest = {};
        let result: APIResponseList<Array<IMiniGame>> = await getListMiniGame(
            params,
            false
        );
    };

    return (
        <MainWrapper>
            <MainContainer>
                <TitleWrapper>
                    <TitleText>DANH SÁCH MINIGAMES</TitleText>
                </TitleWrapper>

                <Row>
                    {listMiniGame &&
                        listMiniGame.list.map((item) => {
                            return <GameItem game={item} key={item.gameId} />;
                        })}
                </Row>
            </MainContainer>
        </MainWrapper>
    );
});
