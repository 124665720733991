import styled from 'styled-components';
import { AppCard } from '../../../styles/App.styles';
import { AppButton } from '../../../styles/AppButton.styles';

export const NotVerifyWrapper = styled.div``;

export const NotVerifyContainer = styled(AppCard)`
    .ant-card-body {
        padding: 20px;
    }
`;

export const ContentTitle = styled.h2`
    font-weight: 700;
    color: #fff;
    font-size: 18px;
`;

export const ContentDescription = styled.h2`
    font-weight: 500;
    color: #fff;
    font-size: 16px;
`;

export const VerifyButton = styled(AppButton)`
    margin-top: 20px;
    padding: 200px;
    height: 40px;
`;
