import { ComponentType, useState } from 'react';
import pure from 'recompose/pure';
//styles
import {
    MatchWrapper,
    MatchContainer,
    TeamWrapper,
    TeamContainer,
    LogoContainer,
    LogoImage,
    TeamName,
    VsIconWrapper,
    VsIconImage,
    MatchInfoWrapper,
    MatchTitle,
    MatchTime,
    ViewMoreWrapper,
    ViewMoreTitle,
    ViewMoreArrow
} from './MatchItem.styles';
import { Col } from 'antd';
import { ArrowNextIcon } from '../../../components/SvgIcon';
import Match from '../../../models/Match';
import { VideoPlayer } from '../../../components/VideoPlayer/VideoPlayer';
import Video, { VideoBasic } from '../../../models/Video';

interface Props {
    data: Match;
}

export const MatchItem: ComponentType<Props> = pure(({ data }) => {
    const [openVideo, setOpenVideo] = useState(false);
    const [videoData, setVideoData] = useState<any>(null);

    const onViewMatch = () => {
        const video = new Video(
            data.matchId,
            data.title,
            data.videoPath,
            '',
            0,
            0,
            0,
            0,
            0
        );
        setVideoData(video);
        setOpenVideo(true);
    };

    return (
        <MatchWrapper>
            <MatchContainer>
                <Col xl={10} md={24} sm={24} xs={24}>
                    <TeamWrapper>
                        <TeamContainer>
                            <LogoContainer>
                                <LogoImage src={data.logoTeam1}></LogoImage>
                            </LogoContainer>
                            <TeamName>{data.nameTeam1}</TeamName>
                        </TeamContainer>
                        <VsIconWrapper>
                            <VsIconImage src="../../../images/ic_vs.png"></VsIconImage>
                        </VsIconWrapper>
                        <TeamContainer>
                            <LogoContainer>
                                <LogoImage src={data.logoTeam2}></LogoImage>
                            </LogoContainer>
                            <TeamName>{data.nameTeam2}</TeamName>
                        </TeamContainer>
                    </TeamWrapper>
                </Col>
                <Col xl={9} md={24} sm={24} xs={24}>
                    <MatchInfoWrapper>
                        <MatchTitle>{data.title}</MatchTitle>
                        <MatchTime>{data.description}</MatchTime>
                    </MatchInfoWrapper>
                </Col>
                <Col xl={5} md={24} sm={24} xs={24}>
                    <ViewMoreWrapper onClick={() => onViewMatch()}>
                        <ViewMoreTitle>XEM TRẬN ĐẤU</ViewMoreTitle>
                        <ViewMoreArrow>
                            <ArrowNextIcon />
                        </ViewMoreArrow>
                    </ViewMoreWrapper>
                </Col>
            </MatchContainer>
            {openVideo ? (
                <VideoPlayer
                    open={openVideo}
                    video={videoData}
                    onClose={() => setOpenVideo(false)}></VideoPlayer>
            ) : null}
        </MatchWrapper>
    );
});
