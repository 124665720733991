import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    ShopItemWrapper,
    ShopItemContainer,
    GiftThumbnailContainer,
    GiftThumbnail,
    InfoContainer,
    GiftName,
    GiftDesc,
    ExchangeButton
} from './ShopItem.styles';
import { APP_BUTTON_TYPE } from '../../../../types/IGlobal';
import Gift from '../../../../models/Gift';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { formatNumber } from '../../../../utils/FormatUtils';

interface Props {
    data: Gift;
    onBuyGift: (data: Gift) => void;
}

export const ShopItem: ComponentType<Props> = pure(({ data, onBuyGift }) => {
    const handleBuyGift = () => {
        onBuyGift(data);
    };
    return (
        <ShopItemWrapper>
            <ShopItemContainer>
                <GiftThumbnailContainer>
                    <GiftThumbnail src={data.image}></GiftThumbnail>
                </GiftThumbnailContainer>
                <InfoContainer>
                    <GiftName>{data.giftName}</GiftName>
                    <GiftDesc>Điểm đổi: {formatNumber(data.point)}</GiftDesc>
                    <ExchangeButton
                        typeButton={APP_BUTTON_TYPE.BORDER}
                        onClick={() => handleBuyGift()}>
                        Đổi quà
                    </ExchangeButton>
                </InfoContainer>
            </ShopItemContainer>
        </ShopItemWrapper>
    );
});
