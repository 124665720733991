import { Col } from 'antd';
import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';

export const GameItemWrapper = styled(Col)`
    margin-bottom: 20px;
    border-radius: 20px;
`;

export const GameItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
        max-width: 350px;
        padding: 20px;
        margin: 0;
    }
`;

export const GameThumbnailWrapper = styled.div`
    @media ${device.mobileS} {
        width: 100%;
        max-height: 350px;
    }
    @media ${device.laptop} {
        width: 350px;
        max-height: 350px;
    }
`;

export const GameThumbnailImage = styled.img`
    object-fit: cover;
    border-radius: 10px;

    @media ${device.mobileS} {
        width: 100%;
        height: 250px;
    }
    @media ${device.laptop} {
        width: 330px;
        height: 250px;
    }
`;

export const GameName = styled.h1`
    margin: 0;
    color: white;
    margin-top: 12px;
    @media ${device.mobileS} {
        font-size: 18px;
        font-weight: 700;
    }
    @media ${device.laptop} {
        font-size: 20px;
        font-weight: 700;
    }
`;
