import { ComponentType, useEffect } from 'react';
import { useRootStore } from '../../../providers/RootStoreProvider';

import { APIResponse } from '../../../models/APIResponse';
import { Spin } from 'antd';

//components

//styles
import {
    JsonDialogContainer,
    JsonDialogTitle,
    JsonDialogTitleWrapper,
    JsonDialogWrapper,
    JsonModal
} from './JsonDialog.styles';
import { observer } from 'mobx-react-lite';
import { JSON_CONTENT_TYPE } from '../../../types/IGlobal';
import { API } from '../../../configs/APIEndPoint';

interface Props {}

export const JsonDialog: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { jsonContentType, showDialogJson, toggleShowDialogJsonContent } =
        store.globalStore;
    const { jsonContent, getJsonContentInfo } = store.jsonStore;

    useEffect(() => {
        getJsonContentInfo(getJsonUrl());
    }, []);

    const getJsonUrl = () => {
        switch (jsonContentType) {
            case JSON_CONTENT_TYPE.POLICY:
                return API.JSON.POLICY;
            default:
                return API.JSON.POLICY;
        }
    };

    return (
        <JsonDialogWrapper>
            <JsonModal
                title={jsonContent?.title}
                open={showDialogJson}
                centered={true}
                onCancel={() => toggleShowDialogJsonContent(false)}
                width={800}
                footer={null}>
                {!jsonContent ? (
                    <JsonDialogContainer>
                        <Spin />
                    </JsonDialogContainer>
                ) : (
                    <>
                        {/* <JsonDialogTitleWrapper>
                            <JsonDialogTitle>
                                {jsonContent?.title}
                            </JsonDialogTitle>
                        </JsonDialogTitleWrapper> */}

                        <JsonDialogContainer
                            dangerouslySetInnerHTML={{
                                __html: jsonContent?.content!
                            }}></JsonDialogContainer>
                    </>
                )}
            </JsonModal>
        </JsonDialogWrapper>
    );
});
