import { ReactElement, FC, useState, ComponentType, useEffect } from 'react';
import { HeaderWeb, HeaderMobile } from './index';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { LoginView } from '../../../views/LoginView/LoginView';
import { ForgotPass } from '../../../views/ForgotPass/ForgotPass';
import { Register } from '../../../views/Register/Register';
import { observer } from 'mobx-react-lite';
import { pure } from 'recompose';
import { useRootStore } from '../../../providers/RootStoreProvider';
import _ from 'lodash';
import { AuthHeader } from '../../../helpers/AuthHeader';

const queryString = require('query-string');

export const Header: ComponentType = pure(
    observer(({}) => {
        const store = useRootStore();
        const { currentUser } = store.authStore;
        const isDesktop = useMediaQuery('(min-width: 960px)');

        const { showDialogLogin, showDialogRegister, toggleShowDialogLogin } =
            store.globalStore;

        const [refId, setRefId] = useState('');
        const [showRegister, setShowRegister] = useState(false);
        const [showForgotPass, setShowForgotPass] = useState(false);

        let paramsQuery = queryString.parse(window.location.search);

        const isLogin = AuthHeader() || currentUser;

        useEffect(() => {
            if (!_.isEmpty(paramsQuery) && paramsQuery.refId) {
                setShowRegister(true);
                //logout(); // auto logout
                setRefId(paramsQuery.refId);
            }
            if (!_.isEmpty(paramsQuery) && paramsQuery.login && !isLogin) {
                toggleShowDialogLogin(true);
            }
        }, []);

        return (
            <>
                {isDesktop ? <HeaderWeb /> : <HeaderMobile />}
                <LoginView
                    visible={showDialogLogin}
                    onClose={() => toggleShowDialogLogin(false)}
                    onForgotPass={() => setShowForgotPass(true)}
                    onLoginSuccess={() => toggleShowDialogLogin(false)}
                    onRegisterAccount={() => setShowRegister(true)}
                />
                <ForgotPass
                    visible={showForgotPass}
                    onClose={() => setShowForgotPass(false)}
                />
                <Register
                    visible={showRegister || showDialogRegister}
                    refId={refId}
                    onClose={() => setShowRegister(false)}
                />
            </>
        );
    })
);
