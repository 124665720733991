import { AxiosResponse } from 'axios';
import {
    ILoginResponseBase,
    IRequestBase,
    IResponseBase
} from '../types/ITypeBase';

abstract class BaseService {
    //abstract methods
    protected abstract postData(api: string, params: IRequestBase): any;
    protected abstract handleResponse(response: AxiosResponse): IResponseBase;
    protected abstract handleLoginResponse(
        response: AxiosResponse
    ): ILoginResponseBase;
}

export default BaseService;
