export const API = {
    AUTHENTICATION: {
        LOGIN: 'login/account',
        LOGOUT: 'logout/account',
        FORGOT_PASS: 'login/forgetpwd',
        CHANGE_PASS: 'login/changepwd',
        VERIFY: 'login/verify'
    },
    BANNER: {
        LIST: 'info/banners/list'
    },
    VIDEO: {
        LIST: 'info/videos/list',
        VIEW: 'video/view'
    },
    MINIGAME: {
        LIST: 'info/minigames/list'
    },
    MISSION: {
        LIST: 'mission/list',
        JOIN_INSTALL_APP: '/mission/install-app/join'
    },
    GIFT: {
        LIST_GIFT: 'gift/list',
        BUY_GIFT: 'gift/buy',
        GET_GIFT_CATEGORY: 'gift/cat/list',
        SPIN_LIST_GIFT: 'spin/list',
        GET_SPIN_GIFT: 'spin/get',
        ADD_SPIN: 'spin/add'
    },
    TOURNAMENT: {
        LIST: 'info/tournaments/list'
    },
    MATCH: {
        LIST: 'info/tournament/matchs/list'
    },
    USER: {
        RANK: 'user/rank',
        REGISTER: 'login/regis',
        PROFILE_DETAIL: 'user/detail',
        CONTACT: 'info/contact/receive',
        VISIT: 'mission/daily/visit',
        UPLOAD_IMAGE: 'user/upl/avatar',
        UPDATE_PROFILE: 'user/update'
    },
    BAG_GIFT: {
        GIFT_TYPE: 'bag/type/list',
        BAG_LIST: 'bag/list',
        BAG_RECEIVED: 'bag/receive'
    },
    HISTORY: {
        VISIT: 'history/visit',
        INVITED: 'history/invite',
        VIDEO: 'history/video/view',
        SPIN: 'history/spin',
        BUY_GIFT: 'history/gift/buy'
    },
    LOCATION: {
        GET_LIST: 'info/location/list'
    },
    GLOBAL: {
        UPLOAD_IMAGE: 'webhook/upload',
        INTRO: 'info/intro',
        HELP: 'info/help',
        CONFIG: 'info/config'
    },
    JSON: {
        MAINTENANCE: 'json/maintenance.json',
        HOME: 'json/home.json',
        POLICY: 'json/policy.json',
        TERMS: 'json/terms.json',
        IMAGES: 'json/images.json',
        RESET_PASS: 'json/reset_pass.json',
        INSTALL_APP: 'json/mission/'
    }
};
