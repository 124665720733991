import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';
import { Col } from 'antd';

export const ItemWrapper = styled(Col)`
    @media ${device.mobileS} {
        padding: 5px;
    }
    @media ${device.laptop} {
        padding: 10px;
    }
`;

interface ItemContainerProps {
    isReceive: boolean;
}

export const ItemContainer = styled.div.attrs((props: ItemContainerProps) => ({
    className: props.isReceive
}))`
    border: solid 1px #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.receive {
        background-image: linear-gradient(to bottom, #420000, #7c0100, #b10100);
    }

    @media ${device.mobileS} {
        width: 100%;
        height: 180px;
    }
    @media ${device.laptop} {
        width: 100%;
        height: 210px;
    }
    :hover {
        //background-image: linear-gradient(to bottom, #420000, #7c0100, #b10100);
    }
`;

export const ItemTitle = styled.p`
    margin: 0;
    font-weight: 700;
    color: #fff;
    padding: 10px;
    text-align: center;
    overflow: hidden;
    max-height: 3.6em;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;

    @media ${device.mobileS} {
        font-size: 13px;
    }
    @media ${device.laptop} {
        font-size: 14px;
    }
`;

export const ItemImageWrapper = styled.div`
    @media ${device.mobileS} {
        width: 100%;
        height: 70%;
    }
    @media ${device.laptop} {
        width: 100%;
        height: 70%;
    }
`;

export const ItemImage = styled.img`
    object-fit: cover;
    @media ${device.mobileS} {
        width: 100%;
        height: 100%;
    }
    @media ${device.laptop} {
        width: 100%;
        height: 100%;
    }
`;
