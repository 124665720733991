import { ComponentType, useEffect, useState } from 'react';
import pure from 'recompose/pure';

//components
import { VisitItem } from './VisitItem/VisitItem';
//styles
import {
    VisitHistoryWrapper,
    VisitHistoryContainer
} from './VisitHistory.styles';
import { useRootStore } from '../../../../../providers/RootStoreProvider';
import { IGetListUserHistoryVisit } from '../../../../../types/IUserHistory';
import { observer } from 'mobx-react-lite';
import { Pagination, PaginationProps } from 'antd';
import { PaginationWrapper } from '../SpinHistory/SpinHistory.styles';

interface Props {}

export const VisitHistory: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const [pageIndex, setPageIndex] = useState(1);

    const { listUserHistoryVisit, getListUserHistoryVisit } =
        store.historyStore;

    useEffect(() => {
        onGetListUserHistory();
    }, []);

    const onChange: PaginationProps['onChange'] = (page, pageSize) => {
        console.log(pageSize);
        setPageIndex(page);
        onGetListUserHistory(true, page);
    };

    const onGetListUserHistory = async (
        isLoadMore?: boolean,
        pageIndex?: number
    ) => {
        const params: IGetListUserHistoryVisit = {
            data: {}
        };
        const result = await getListUserHistoryVisit(
            params,
            isLoadMore,
            pageIndex
        );
    };

    return (
        <VisitHistoryWrapper>
            <VisitHistoryContainer
                title={`Lịch sử điểm danh (${listUserHistoryVisit?.totalRecord})`}>
                {listUserHistoryVisit &&
                    listUserHistoryVisit.list.map((item) => {
                        return <VisitItem data={item} key={item.id} />;
                    })}

                {listUserHistoryVisit?.totalPage! > 1 ? (
                    <PaginationWrapper>
                        <Pagination
                            showSizeChanger={false}
                            defaultCurrent={pageIndex}
                            total={listUserHistoryVisit?.totalPage}
                            onChange={onChange}
                        />
                    </PaginationWrapper>
                ) : null}
            </VisitHistoryContainer>
        </VisitHistoryWrapper>
    );
});
