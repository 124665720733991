import { ComponentType } from 'react';
import pure from 'recompose/pure';
import _ from 'lodash';
import { useRootStore } from '../../providers/RootStoreProvider';
import { HTTP_STATUS } from '../../configs/HttpStatus';

const queryString = require('query-string');

//components

//styles

interface Props {}

export const ErrorView: ComponentType<Props> = pure(({}) => {
    const store = useRootStore();
    const { logout } = store.authStore;
    let paramsQuery = queryString.parse(window.location.search);
    if (
        !_.isEmpty(paramsQuery) &&
        paramsQuery.code == HTTP_STATUS.TOKEN_INVALID
    ) {
        //logout();
    }
    return <>Error</>;
});
