import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components
import { MonthlyItem } from './MonthlyItem/MonthlyItem';
//styles
import {
    MonthlyWrapper,
    MonthlyContainer,
    TitleWrapper,
    TextCount
} from './MonthlySection.styles';
import { AppTitle } from '../../../styles/App.styles';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';

interface Props {}

export const MonthlySection: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { currentUser } = store.authStore;

    return (
        <MonthlyWrapper>
            <MonthlyContainer>
                <TitleWrapper>
                    <AppTitle>ĐIỂM DANH THÁNG</AppTitle>
                </TitleWrapper>

                <TextCount>
                    THÁNG NÀY ĐÃ ĐIỂM DANH TỔNG: {currentUser?.totalVisit} NGÀY
                </TextCount>
                <Row>
                    <Col xl={4} md={4} sm={8} xs={8}>
                        <MonthlyItem
                            key={1}
                            id={1}
                            total={currentUser ? currentUser.totalVisit : 0}
                        />
                    </Col>
                    <Col xl={4} md={4} sm={8} xs={8}>
                        <MonthlyItem
                            key={3}
                            id={3}
                            total={currentUser ? currentUser.totalVisit : 0}
                        />
                    </Col>
                    <Col xl={4} md={4} sm={8} xs={8}>
                        <MonthlyItem
                            key={5}
                            id={5}
                            total={currentUser ? currentUser.totalVisit : 0}
                        />
                    </Col>
                    <Col xl={4} md={4} sm={8} xs={8}>
                        <MonthlyItem
                            key={10}
                            id={10}
                            total={currentUser ? currentUser.totalVisit : 0}
                        />
                    </Col>
                    <Col xl={4} md={4} sm={8} xs={8}>
                        <MonthlyItem
                            key={20}
                            id={20}
                            total={currentUser ? currentUser.totalVisit : 0}
                        />
                    </Col>
                    <Col xl={4} md={4} sm={8} xs={8}>
                        <MonthlyItem
                            key={30}
                            id={30}
                            total={currentUser ? currentUser.totalVisit : 0}
                        />
                    </Col>
                </Row>
            </MonthlyContainer>
        </MonthlyWrapper>
    );
});
