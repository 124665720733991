import { ComponentType, useState } from 'react';
import pure from 'recompose/pure';

import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import sleep from 'sleep-promise';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { observer } from 'mobx-react-lite';
import { Modal, message } from 'antd';
import {
    IAddSpinGiftRequest,
    IGetSpinGiftRequest,
    ISpinGift
} from '../../../types/IGift';
import { APIResponse } from '../../../models/APIResponse';
import _ from 'lodash';

//components
import { WinDialog } from '../WinDialog/WinDialog';
import { BuyDialog } from '../BuyDialog/BuyDialog';
//styles
import {
    ButtonWrapper,
    ButtonContainer,
    ButtonAdd,
    ButtonStart,
    ButtonTotal
} from './ControlButton.styles';
import SpinGift from '../../../models/SpinGift';
import { IResponseBase } from '../../../types/ITypeBase';
import { formatNumber } from '../../../utils/FormatUtils';
import { useNavigate } from 'react-router-dom';
import {
    PROFILE_ACTIVITY_SCREEN,
    PROFILE_SCREEN
} from '../../../configs/ScreenType';

interface Props {}

export const ControlButton: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const navigation = useNavigate();
    const {
        randomGift,
        randomGiftChar,
        listSpinGift,
        spinConfig,
        getSpinGiftRandom,
        addSpinGift,
        shuffledGift
    } = store.giftStore;
    const { currentUser } = store.authStore;
    const { changeProfileScreen, changeProfileActivityScreen } =
        store.globalStore;

    const [spinning, setSpinning] = useState(false);
    const [openWinDialog, setOpenWinDialog] = useState(false);
    const [giftWin, setGiftWin] = useState<SpinGift | null>(null);
    const [openBuyDialog, setOpenBuyDialog] = useState(false);

    const onStartSpin = async () => {
        const params: IGetSpinGiftRequest = {
            data: {
                sid: '1'
            }
        };
        let result: APIResponse<ISpinGift> = await getSpinGiftRandom(params);
        if (result && result.isSuccess()) {
            const code = result.data?.code;
            const gift: SpinGift = _.find(listSpinGift?.list, (item) => {
                return item.giftCode === code;
            })!;
            if (gift && gift.isWin()) {
                gift.serial = result.data?.serial!;
                setGiftWin(gift);
                setOpenWinDialog(true);
            } else {
                //show message not win
                Modal.warning({
                    title: 'Thật tiếc!',
                    content: 'Chúc bạn may mắn lần sau!',
                    centered: true
                });
            }
        } else {
            Modal.error({
                title:
                    result.getTitle() && result.getTitle().length > 0
                        ? result.getTitle()
                        : `Quay số không thành công. Lỗi ${result.responseCode()}`,
                content:
                    result.getDescription() &&
                    result.getDescription().length > 0
                        ? result.getDescription()
                        : 'Có lỗi xảy ra. Vui lòng thử lại hoặc liên hệ với bộ phận chăm sóc khách hàng của chúng tôi',
                centered: true
            });
        }
    };

    const onShowConfirmBuy = () => {
        if (currentUser?.totalPoint! < spinConfig?.spinPoint!) {
            Modal.error({
                title: 'Đổi lượt quay không thành công!',
                content: 'Bạn không đủ điểm để thực hiện đổi lần quay.',
                centered: true
            });
            return;
        } else setOpenBuyDialog(true);
    };

    const onBuySpin = async () => {
        setOpenBuyDialog(false);
        const params: IAddSpinGiftRequest = {
            data: {
                qty: 1
            }
        };
        const result: IResponseBase = await addSpinGift(params);
        if (result && result.isSuccess()) {
            message.success('Đổi lượt quay thành công !');
            currentUser?.updateTotalPoint(spinConfig?.spinPoint!, false, true);
            currentUser?.updateTotalSpin(1, true);
        } else {
            Modal.error({
                title: `Đổi lượt quay không thành công. Lỗi ${result.responseCode()}`,
                content:
                    result.getDescription() &&
                    result.getDescription().length > 0
                        ? result.getDescription()
                        : 'Có lỗi xảy ra. Vui lòng thử lại hoặc liên hệ với bộ phận chăm sóc khách hàng của chúng tôi',
                centered: true
            });
        }
    };

    const onRandomGiftChar = async () => {
        if (currentUser?.totalSpin! > 0) {
            let count = 0;
            setSpinning(true);
            currentUser?.updateTotalSpin(1, false, true);

            while (count < 20) {
                await sleep(200);
                randomGiftChar();
                count++;
            }
            setSpinning(false);
            onStartSpin();
        } else {
            //currentUser?.updateTotalSpin(1, false, true);
            Modal.error({
                title: 'Bạn đã hết lượt quay',
                content:
                    'Vui lòng mua thêm lượt hoặc làm nhiệm vụ để kiếm thêm lượt quay',
                centered: true
            });
        }
    };

    const onRandomGift = async () => {
        await shuffledGift(); //random gift
        if (currentUser?.totalSpin! > 0) {
            let count = 0;
            setSpinning(true);
            currentUser?.updateTotalSpin(1, false, true);

            while (count < 20) {
                await sleep(200);
                randomGift();
                count++;
            }
            setSpinning(false);
            onStartSpin();
        } else {
            //currentUser?.updateTotalSpin(1, false, true);
            Modal.error({
                title: 'Bạn đã hết lượt quay',
                content:
                    'Vui lòng mua thêm lượt hoặc làm nhiệm vụ để kiếm thêm lượt quay',
                centered: true
            });
        }
    };

    const onGoToHistory = () => {
        changeProfileActivityScreen(PROFILE_ACTIVITY_SCREEN.HISTORY_SPIN);
        changeProfileScreen(PROFILE_SCREEN.ACTIVITY);
        navigation('/profile');
    };

    return (
        <ButtonWrapper>
            <ButtonContainer>
                <ButtonAdd
                    typeButton={APP_BUTTON_TYPE.BORDER}
                    onClick={() => onGoToHistory()}>
                    LỊCH SỬ
                </ButtonAdd>
                <ButtonAdd
                    typeButton={APP_BUTTON_TYPE.BORDER}
                    onClick={() => onShowConfirmBuy()}>
                    THÊM LƯỢT
                </ButtonAdd>
                <ButtonAdd typeButton={APP_BUTTON_TYPE.BORDER}>
                    TỔNG LƯỢT: {formatNumber(currentUser?.totalSpin!)}
                </ButtonAdd>
                <ButtonAdd
                    typeButton={
                        spinning
                            ? APP_BUTTON_TYPE.DISABLED
                            : APP_BUTTON_TYPE.BORDER
                    }
                    onClick={() => onRandomGift()}>
                    BẮT ĐẦU
                </ButtonAdd>
            </ButtonContainer>
            {openWinDialog === true ? (
                <WinDialog
                    giftWin={giftWin!}
                    visible={openWinDialog}
                    onClose={() => setOpenWinDialog(false)}></WinDialog>
            ) : null}

            {openBuyDialog === true ? (
                <BuyDialog
                    visible={openBuyDialog}
                    point={spinConfig?.spinPoint!}
                    onClose={() => setOpenBuyDialog(false)}
                    onConfirm={() => onBuySpin()}></BuyDialog>
            ) : null}
        </ButtonWrapper>
    );
});
