import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';
//styles
import { RuleWrapper, RuleContainer, RuleText } from './RuleDescription.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { IMissionInstallApp } from '../../../types/IJson';

interface Props {
    mission: IMissionInstallApp;
}

export const RuleDescription: ComponentType<Props> = observer(({ mission }) => {
    return (
        <RuleWrapper>
            <RuleContainer>
                <RuleText
                    dangerouslySetInnerHTML={{
                        __html: mission.shortDesc
                    }}></RuleText>
            </RuleContainer>
        </RuleWrapper>
    );
});
