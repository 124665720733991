import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

//components
import { SocialIcon } from '../../../components/SocialIcon/SocialIcon';

//styles
import {
    FooterWrapper,
    FooterContainer,
    MenuContainer,
    MenuItem,
    MenuLink,
    LogoWrapper,
    LogoImage,
    AddressWrapper,
    AddressContainer,
    AddressText,
    AddressIconWrapper,
    AddressItemContainer
} from './Footer.styles';
import { EmailIcon, MapIcon, PhoneIcon } from '../../../components/SvgIcon';

export const Footer: FC = () => {
    const { t } = useTranslation();

    return (
        <FooterWrapper>
            <FooterContainer>
                <LogoWrapper>
                    <LogoImage src="../../../images/app_logo_footer.png" />
                </LogoWrapper>
                {/* <MenuContainer>
                    <MenuItem>
                        <MenuLink>GIỚI THIỆU</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink>NHIỆM VỤ</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink>GIẢI ĐẤU</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink>ĐỔI QUÀ</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink>LIÊN HỆ</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink>HỘI VIÊN</MenuLink>
                    </MenuItem>
                </MenuContainer>

                <SocialIcon isFooter={true} /> */}

                {/* <AddressWrapper>
                    <AddressContainer>
                        <Col xl={13} md={13} sm={24} xs={24}>
                            <AddressItemContainer>
                                <AddressIconWrapper>
                                    <MapIcon />
                                </AddressIconWrapper>
                                <AddressText>
                                    Địa chỉ: 144-146 Nguyễn Thái Bình,
                                    <br />
                                    Phường Nguyễn Thái Bình, Quận 1, TP. Hồ Chí
                                    Minh
                                </AddressText>
                            </AddressItemContainer>
                        </Col>
                        <Col xl={11} md={11} sm={24} xs={24}>
                            <AddressItemContainer>
                                <AddressIconWrapper>
                                    <PhoneIcon />
                                </AddressIconWrapper>
                                <AddressText>
                                    Hotline: 028.2200.0113
                                </AddressText>
                            </AddressItemContainer>
                            <AddressItemContainer>
                                <AddressIconWrapper>
                                    <EmailIcon />
                                </AddressIconWrapper>
                                <AddressText>Email: info@fusoft.vn</AddressText>
                            </AddressItemContainer>
                        </Col>
                    </AddressContainer>
                </AddressWrapper> */}
            </FooterContainer>
        </FooterWrapper>
    );
};
