import { ComponentType } from 'react';
import pure from 'recompose/pure';
//styles
import {
    ItemContainer,
    ItemWrapper,
    AvatarContainer,
    AvatarImage,
    NameContainer,
    UserName,
    UserPoint
} from './LeaderboardItem.styles';
import User from '../../../../models/User';

interface Props {
    user: User;
}

export const LeaderboardItem: ComponentType<Props> = pure(({ user }) => {
    return (
        <ItemWrapper>
            <ItemContainer>
                <AvatarContainer>
                    <AvatarImage src={user.avatar} />
                </AvatarContainer>
                <NameContainer>
                    <UserName>{user.name}</UserName>
                    <UserPoint>{user.totalPoint}</UserPoint>
                </NameContainer>
            </ItemContainer>
        </ItemWrapper>
    );
});
