import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components
import { VerifyAccount } from './VerifyAccount/VerifyAccount';
import { ChangePass } from './ChangePass/ChangePass';
import { UserInfo } from './UserInfo/UserInfo';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { observer } from 'mobx-react-lite';
import { PROFILE_INFO_SCREEN } from '../../../../configs/ScreenType';

//styles

interface Props {}

export const RightContent: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { currentUser } = store.authStore;
    const { profileInfoScreen } = store.globalStore;

    const onRenderContent = () => {
        if (profileInfoScreen === PROFILE_INFO_SCREEN.VERIFY)
            return currentUser ? <VerifyAccount user={currentUser} /> : null;
        else if (profileInfoScreen === PROFILE_INFO_SCREEN.CHANGE_PASSWORD)
            return <ChangePass />;
        else if (profileInfoScreen === PROFILE_INFO_SCREEN.INFO)
            return currentUser ? <UserInfo user={currentUser} /> : null;
    };

    return <>{onRenderContent()}</>;
});
