export enum PROFILE_SCREEN {
    INFO,
    ACTIVITY,
    BAG
}

export enum PROFILE_INFO_SCREEN {
    INFO,
    VERIFY,
    CHANGE_PASSWORD
}

export enum PROFILE_ACTIVITY_SCREEN {
    HISTORY_VISIT,
    HISTORY_INVITED,
    HISTORY_VIDEO_VIEW,
    HISTORY_SPIN,
    HISTORY_BUY_GIFT,
    HISTORY_INSTALL_APP
}
