import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';

export const ItemWrapper = styled.div`
    cursor: pointer;
    @media ${device.mobileS} {
        padding: 10px;
    }
    @media ${device.laptop} {
        padding: 10px;
    }
`;

export const CheckImageWrapper = styled.div`
    position: absolute;
    top: 70px;
    left: 70px;
    @media ${device.mobileS} {
        width: 50%;
        height: 50%;
    }
    @media ${device.laptop} {
        width: 50%;
        height: 50%;
    }
`;

interface ItemContainerProps {
    isSunday: boolean;
}

export const ItemContainer = styled.div.attrs((props: ItemContainerProps) => ({
    isSunday: props.isSunday
}))`
    border: solid 1px #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${device.mobileS} {
        width: 100%;
        height: ${(props) => (props.isSunday ? '390px' : '232px')};
    }
    @media ${device.laptop} {
        width: 100%;
        height: ${(props) => (props.isSunday ? '484px' : '232px')};
    }
`;

export const ItemTitle = styled.p`
    margin: 0;
    font-weight: 700;
    color: #fff;
    padding: 10px;

    @media ${device.mobileS} {
        font-size: 16px;
    }
    @media ${device.laptop} {
        font-size: 20px;
    }
`;

export const ItemImageWrapper = styled.div`
    @media ${device.mobileS} {
        width: 70%;
        height: 70%;
    }
    @media ${device.laptop} {
        width: 70%;
        height: 70%;
    }
`;

interface ItemImageProps {
    isActive: boolean;
}
export const ItemImage = styled.img.attrs((props: ItemImageProps) => ({
    className: props.isActive
}))`
    object-fit: cover;
    &.inactive {
        filter: grayscale(100%);
    }
    @media ${device.mobileS} {
        width: 100%;
        height: 100%;
    }
    @media ${device.laptop} {
        width: 100%;
        height: 100%;
    }
`;
