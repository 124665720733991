import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    BagItemWrapper,
    BagItemContainer,
    GiftThumbnailContainer,
    GiftThumbnail,
    InfoContainer,
    GiftName,
    GiftDesc,
    ExchangeButton
} from './GiftBagItem.styles';
import { APP_BUTTON_TYPE } from '../../../../types/IGlobal';
import Gift from '../../../../models/Gift';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import GiftBag from '../../../../models/GiftBag';
import LinesEllipsis from 'react-lines-ellipsis';

interface Props {
    data: GiftBag;
    onReceiveGift: (data: GiftBag) => void;
}

export const GiftBagItem: ComponentType<Props> = pure(
    ({ data, onReceiveGift }) => {
        const handleReceiveGift = () => {
            onReceiveGift(data);
        };
        return (
            <BagItemWrapper>
                <BagItemContainer>
                    <GiftThumbnailContainer>
                        <GiftThumbnail src={data.image}></GiftThumbnail>
                    </GiftThumbnailContainer>
                    <InfoContainer>
                        <GiftName
                            title={data?.giftName}
                            text={data?.giftName}
                            maxLine="1"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                            style={{ paddingRight: 2 }}
                        />
                        {/* <GiftName>{data.giftName}</GiftName> */}
                        {/* <GiftDesc>Giá trị: {data.value}</GiftDesc> */}
                        <ExchangeButton
                            typeButton={APP_BUTTON_TYPE.BORDER}
                            onClick={() => handleReceiveGift()}>
                            Nhận quà
                        </ExchangeButton>
                    </InfoContainer>
                </BagItemContainer>
            </BagItemWrapper>
        );
    }
);
