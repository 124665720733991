import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components
import { VideoSlider } from './VideoSlider/VideoSlider';
import { PlayDialog } from './PlayDialog/PlayDialog';
//styles
import {
    VideoWrapper,
    VideoHeaderContainer,
    VideoContentContainer
} from './VideoView.styles';
import { AppTitle, AppDescription } from '../../../styles/App.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';

interface Props {}

export const VideoView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { homeInfo } = store.jsonStore;
    const { listVideo } = store.videoStore;

    if (listVideo && listVideo.list.length > 0)
        return (
            <VideoWrapper>
                <VideoHeaderContainer>
                    <AppTitle>VIDEO HOT</AppTitle>
                    <AppDescription
                        dangerouslySetInnerHTML={{
                            __html: homeInfo?.video!
                        }}></AppDescription>
                </VideoHeaderContainer>
                <VideoContentContainer>
                    <VideoSlider />
                </VideoContentContainer>
            </VideoWrapper>
        );
    else return null;
});
