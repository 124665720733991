import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components
import { DailyItem } from './DailyItem/DailyItem';
//styles
import {
    DailyWrapper,
    DailyContainer,
    DailyDividerWrapper
} from './DailySection.styles';
import { Col, Modal, Row } from 'antd';
import { AppLineDivider } from '../../../styles/App.styles';
import { IUserDailyVisitRequest } from '../../../types/IUser';
import { APIResponse } from '../../../models/APIResponse';
import { IResponseBase } from '../../../types/ITypeBase';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { formatDate } from '../../../utils/DateUtils';
import { observer } from 'mobx-react-lite';
import { showMessage } from '../../../utils';

interface Props {}

export const DailySection: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { dailyVisit } = store.userStore;
    const { currentUser } = store.authStore;

    const onDailyVisit = async (dayIndex: number, active: boolean) => {
        // currentUser?.updateVisitStatus(true);
        // currentUser?.updateTotalVisit();
        // console.log(currentUser?.totalVisit);
        // return;
        if (!active) return;
        if (currentUser?.isVisit) {
            Modal.error({
                title: 'Điểm danh không thành công',
                content: 'Hôm nay bạn đã điểm danh. Hãy quay lại sau',
                centered: true
            });
            return;
        }
        const params: IUserDailyVisitRequest = {
            data: {
                mid: '1',
                ymd: formatDate('YYYYMMDD', new Date())
            }
        };

        let result: APIResponse<IResponseBase> = await dailyVisit(params);
        const msgSuccess = 'Chúc mừng bạn đã điểm danh thành công';
        showMessage(result, msgSuccess);
        if (result && result.isSuccess()) {
            currentUser?.updateVisitStatus(true);
            currentUser?.updateTotalVisit();
            const data: any = result.data;

            if (data && data.point)
                currentUser?.updateTotalPoint(Number(data.point), true);
            if (data && data.spin)
                currentUser?.updateTotalSpin(Number(data.spin), true);
        }
    };
    return (
        <DailyWrapper>
            <DailyContainer>
                <Row>
                    <Col xl={16} md={16} sm={24} xs={24}>
                        <Row>
                            <Col xl={8} md={8} sm={12} xs={12}>
                                <DailyItem
                                    key={1}
                                    dayIndex={1}
                                    isVisit={currentUser?.isVisit}
                                    onDailyVisit={onDailyVisit}
                                />
                            </Col>
                            <Col xl={8} md={8} sm={12} xs={12}>
                                <DailyItem
                                    key={2}
                                    dayIndex={2}
                                    isVisit={currentUser?.isVisit}
                                    onDailyVisit={onDailyVisit}
                                />
                            </Col>
                            <Col xl={8} md={8} sm={12} xs={12}>
                                <DailyItem
                                    key={3}
                                    dayIndex={3}
                                    isVisit={currentUser?.isVisit}
                                    onDailyVisit={onDailyVisit}
                                />
                            </Col>

                            <Col xl={8} md={8} sm={12} xs={12}>
                                <DailyItem
                                    key={4}
                                    dayIndex={4}
                                    isVisit={currentUser?.isVisit}
                                    onDailyVisit={onDailyVisit}
                                />
                            </Col>
                            <Col xl={8} md={8} sm={12} xs={12}>
                                <DailyItem
                                    key={5}
                                    dayIndex={5}
                                    isVisit={currentUser?.isVisit}
                                    onDailyVisit={onDailyVisit}
                                />
                            </Col>
                            <Col xl={8} md={8} sm={12} xs={12}>
                                <DailyItem
                                    key={6}
                                    dayIndex={6}
                                    isVisit={currentUser?.isVisit}
                                    onDailyVisit={onDailyVisit}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={8} md={8} sm={24} xs={24}>
                        <DailyItem
                            key={0}
                            dayIndex={0}
                            isSunday={true}
                            isVisit={currentUser?.isVisit}
                            onDailyVisit={onDailyVisit}
                        />
                    </Col>
                </Row>
                <DailyDividerWrapper>
                    <AppLineDivider />
                </DailyDividerWrapper>
            </DailyContainer>
        </DailyWrapper>
    );
});
