import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//components
import { MapView } from './MapView/MapView';
import { ContactForm } from './ContactForm/ContactForm';
//styles
import { ContactWrapper, ContactContainer } from './ContactView.styles';
import { useRootStore } from '../../providers/RootStoreProvider';
import { MAIN_MENU } from '../../types/IGlobal';

interface Props {}

export const ContactView: ComponentType<Props> = pure(({}) => {
    const store = useRootStore();
    const { changeMainMenuIndex } = store.globalStore;

    useEffect(() => {
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        changeMainMenuIndex(MAIN_MENU.CONTACT);
    }, []);

    return (
        <ContactWrapper>
            <ContactContainer>
                {/* <MapView /> */}
                <ContactForm />
            </ContactContainer>
        </ContactWrapper>
    );
});
