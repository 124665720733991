import styled from 'styled-components';
import { AppCard } from '../../../../../styles/App.styles';

export const VisitHistoryWrapper = styled.div``;

export const VisitHistoryContainer = styled(AppCard)`
    .ant-card-body {
        padding: 20px;
    }
`;
