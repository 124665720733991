import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';
import { AppButton } from '../../../styles/AppButton.styles';
import {
    AppInputText,
    AppInputTextMultiline
} from '../../../styles/AppInput.styles';

export const ContactFormWrapper = styled.div`
    width: 100%;
    background-color: #19181c;
    padding-top: 40px;
    padding-bottom: 40px;
`;

export const ContactFormContainer = styled.div`
    @media ${device.mobileS} {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media ${device.laptop} {
        width: 1100px;
        margin: 0 auto;
        padding: 0;
    }
`;

export const ContactTitleWrapper = styled.div`
    padding-bottom: 30px;
`;

export const ContactInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
`;

export const ContactInfoContainer = styled.div`
    margin-top: -20px;
`;

export const AddressItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    @media ${device.mobileS} {
        padding-top: 12px;
    }
    @media ${device.laptop} {
        padding-right: 40px;
        padding-top: 12px;
    }
`;

export const AddressIconWrapper = styled.div`
    width: 15px;
    margin-right: 15px;
`;

export const AddressText = styled.p`
    color: #fff;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
`;

export const SendButton = styled(AppButton)`
    width: 100%;
`;

export const InputText = styled(AppInputText)`
    background: transparent;
    color: #fff;
`;

export const InputTextMultiline = styled(AppInputTextMultiline)`
    background: transparent;
    color: #fff;
`;

export const SocialWrapper = styled.div`
    padding-top: 20px;
`;
