import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { AuthHeader } from '../../../../helpers/AuthHeader';
import { IGetUserProfileDetailRequest } from '../../../../types/IUser';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { useNavigate } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import { Badge } from 'antd';
import {
    AvatarImage,
    AvatarWrapper,
    BadgePoint,
    DropdownProfile,
    LogoutButton,
    MissionMenuItem,
    MissionMenuText,
    MissionMenuWrapper,
    UserNameContainer,
    UserNameText,
    UserNameWrapper
} from './HeaderWeb.styles';
import { Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { APP_BUTTON_TYPE } from '../../../../types/IGlobal';
import { PROFILE_SCREEN } from '../../../../configs/ScreenType';
import { formatNumber } from '../../../../utils/FormatUtils';

export const UserLogin: React.FC = observer(() => {
    const store = useRootStore();
    const navigate = useNavigate();
    const { changeProfileScreen } = store.globalStore;
    const { toggleScrollToList } = store.giftBagStore;

    const { currentUser, getProfileDetail, logout } = store.authStore;

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (currentUser == null && AuthHeader() != null)
            doGetProfileUserLogin();
    }, []);

    const onUserLogout = () => {
        logout();
        navigate('/'); //go home
    };

    const goToBag = () => {
        setVisible(false);
        toggleScrollToList(true);
        changeProfileScreen(PROFILE_SCREEN.BAG);
        navigate('/profile');
    };

    const goToProfile = () => {
        setVisible(false);
        changeProfileScreen(PROFILE_SCREEN.INFO);
        navigate('/profile');
    };

    const doGetProfileUserLogin = () => {
        let params: IGetUserProfileDetailRequest = {};
        getProfileDetail(params);
    };

    const onOpenChange = (e: any) => {
        setVisible(e);
    };

    const renderMenuProfile = () => {
        return (
            <MissionMenuWrapper>
                <MissionMenuItem onClick={() => goToProfile()}>
                    <Link to={'/profile'}>
                        <MissionMenuText>Trang cá nhân</MissionMenuText>
                    </Link>
                </MissionMenuItem>
                <MissionMenuItem onClick={() => goToBag()}>
                    <Link to={'/profile'}>
                        <MissionMenuText>Túi đồ</MissionMenuText>
                    </Link>
                </MissionMenuItem>
                <MissionMenuItem>
                    <LogoutButton
                        typeButton={APP_BUTTON_TYPE.PRIMARY}
                        onClick={() => onUserLogout()}>
                        Đăng xuất
                    </LogoutButton>
                </MissionMenuItem>
            </MissionMenuWrapper>
        );
    };

    const onLoadAvatarError = (event: any) => {
        event.target.src = `../../../../images/default-avatar.png`;
        event.onerror = null;
    };

    return (
        <UserNameWrapper>
            <DropdownProfile
                onOpenChange={onOpenChange}
                dropdownRender={renderMenuProfile}
                trigger={['hover']}
                open={visible}>
                <UserNameContainer>
                    <UserNameText>{currentUser?.getUserName()}</UserNameText>
                    {/* <LinesEllipsis
                        text={currentUser?.getUserName()}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                        style={{ paddingRight: 2 }}
                    /> */}
                    <DownOutlined style={{ fontSize: 12, paddingLeft: 5 }} />
                </UserNameContainer>
            </DropdownProfile>
            <AvatarWrapper>
                <AvatarImage
                    src={currentUser?.avatar}
                    placeholder={true}
                    preview={false}
                    fallback="../../../../images/default-cover.jpeg"
                    onError={onLoadAvatarError}></AvatarImage>
                {currentUser?.totalPoint! > 0 ? (
                    <BadgePoint
                        count={formatNumber(currentUser?.totalPoint!)}
                        overflowCount={99999}
                        color="#336699"></BadgePoint>
                ) : null}
            </AvatarWrapper>
        </UserNameWrapper>
    );
});
