import { ComponentType } from 'react';
import pure from 'recompose/pure';
//styles
import {
    InfoWrapper,
    InfoContainer,
    TitleWrapper,
    TitleText,
    ContentWrapper,
    LogoWrapper,
    LogoImage,
    InfoTextWrapper
} from './InfoView.styles';
import { AppTitle } from '../../../styles/App.styles';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import Intro from '../../../models/Intro';
import { useRootStore } from '../../../providers/RootStoreProvider';

interface Props {
    data: Intro;
}

export const InfoView: ComponentType<Props> = observer(({ data }) => {
    const store = useRootStore();
    const { appBanner } = store.jsonStore;

    return (
        <InfoWrapper>
            <InfoContainer>
                <TitleWrapper>
                    <TitleText>{data.title.toUpperCase()}</TitleText>
                </TitleWrapper>
                <ContentWrapper>
                    <Row>
                        <Col xl={7} md={7} sm={25} xs={24}>
                            <LogoWrapper>
                                <LogoImage src={appBanner?.intro}></LogoImage>
                            </LogoWrapper>
                        </Col>
                        <Col xl={17} md={17} sm={24} xs={24}>
                            <InfoTextWrapper
                                dangerouslySetInnerHTML={{
                                    __html: data.content
                                }}></InfoTextWrapper>
                        </Col>
                    </Row>
                </ContentWrapper>
            </InfoContainer>
        </InfoWrapper>
    );
});
