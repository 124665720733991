import styled, { css } from 'styled-components';
import { device } from '../configs/MediaQuery';

export const BaseStyles = css`
    cursor: pointer;
    height: 40px;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    font-size: 20px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.mobileS} {
        //width: 100%;
    }

    @media ${device.tablet} {
        //width: auto;
    }
`;

export const PrimaryStyles = css`
    background-color: #ff2423;
    color: #fff;
`;

export const SecondaryStyles = css`
    background-color: #f0f0f0;
    color: #4f4f4f;
`;

export const BorderStyles = css`
    color: #fff;
    border: solid 1px #ff2423;
    border-bottom-width: 5px;
    background-color: transparent;
`;

export const DisabledStyle = css`
    color: #aab2bf;
    background-color: #f4f6f8;
    pointer-events: none;
`;

interface AppButtonProps {
    typeButton: boolean;
}
export const AppButton = styled.button.attrs((props: AppButtonProps) => ({
    className: props.typeButton
}))`
    &.primary {
        ${BaseStyles}
        ${PrimaryStyles}
    }
    &.secondary {
        ${BaseStyles}
        ${SecondaryStyles}
    }
    &.border {
        ${BaseStyles}
        ${BorderStyles}
    }
    &.disabled {
        ${BaseStyles}
        ${DisabledStyle}
    }
`;
