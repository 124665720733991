import { IGiftCategory } from '../types/IGiftCategory';
import { DataList } from './DataList';

export default class GiftCategory {
    public catId: string;
    public catName: string;
    public catImage: string;

    constructor(catId: string, catName: string, catImage: string) {
        this.catId = catId;
        this.catName = catName;
        this.catImage = catImage;
    }

    static fromJson = (json: IGiftCategory): GiftCategory => {
        return new GiftCategory(json.catId, json.catName, json.image);
    };
}

export class ListGiftCategory extends DataList<GiftCategory> {
    static fromJson = (
        jsonArray: Array<IGiftCategory>
    ): Array<GiftCategory> => {
        if (!jsonArray) return [];
        let data = new Array<GiftCategory>();
        jsonArray.forEach((item) => {
            data.push(GiftCategory.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
