import { ComponentType, useEffect, useState } from 'react';

//components
import { InvitedItem } from './InvitedItem/InvitedItem';
//styles
import {
    InvitedHistoryWrapper,
    InvitedHistoryContainer,
    TotalInvited
} from './InvitedHistory.styles';
import { useRootStore } from '../../../../../providers/RootStoreProvider';
import { IGetListUserHistoryInvited } from '../../../../../types/IUserHistory';
import { observer } from 'mobx-react-lite';
import { PaginationWrapper } from '../SpinHistory/SpinHistory.styles';
import { Pagination, PaginationProps } from 'antd';

interface Props {}

export const InvitedHistory: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const [pageIndex, setPageIndex] = useState(1);
    const {
        listUserHistoryInvited,
        getListUserHistoryInvited,
        userHistoryInvitedConfig
    } = store.historyStore;

    useEffect(() => {
        onGetListUserHistory();
    }, []);

    const onGetListUserHistory = async (
        isLoadMore?: boolean,
        pageIndex?: number
    ) => {
        const params: IGetListUserHistoryInvited = {
            data: {}
        };
        const result = await getListUserHistoryInvited(
            params,
            isLoadMore,
            pageIndex
        );
    };

    const onChange: PaginationProps['onChange'] = (page, pageSize) => {
        console.log(pageSize);
        setPageIndex(page);
        onGetListUserHistory(true, page);
    };

    const renderExtra = () => {
        return (
            <TotalInvited>
                Tổng số bạn đã mời: {userHistoryInvitedConfig?.totalinvite}
            </TotalInvited>
        );
    };

    return (
        <InvitedHistoryWrapper>
            <InvitedHistoryContainer
                title={`Lịch sử mời bạn (${listUserHistoryInvited?.totalRecord})`}
                extra={renderExtra()}>
                {listUserHistoryInvited &&
                    listUserHistoryInvited.list.map((item) => {
                        return <InvitedItem data={item} key={item.id} />;
                    })}

                {listUserHistoryInvited?.totalPage! > 1 ? (
                    <PaginationWrapper>
                        <Pagination
                            showSizeChanger={false}
                            defaultCurrent={pageIndex}
                            total={listUserHistoryInvited?.totalPage}
                            onChange={onChange}
                        />
                    </PaginationWrapper>
                ) : null}
            </InvitedHistoryContainer>
        </InvitedHistoryWrapper>
    );
});
