import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import { IGetListGiftCategory } from '../types/IGiftCategory';
import {
    IAddSpinGiftRequest,
    IBuyGiftRequest,
    IGetListGiftRequest,
    IGetListSpinGiftRequest,
    IGetSpinGiftRequest
} from '../types/IGift';
import BaseAuthService from './BaseAuthService';

export class GiftService extends BaseAuthService {
    private static instance: GiftService;

    public static getInstance(): GiftService {
        if (!GiftService.instance) {
            GiftService.instance = new GiftService();
        }
        return GiftService.instance;
    }

    getListSpinGift = async (params: IGetListSpinGiftRequest) => {
        try {
            const response = await this.postData(
                API.GIFT.SPIN_LIST_GIFT,
                params
            );
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListGiftCategory = async (params: IGetListGiftCategory) => {
        try {
            const response = await this.postData(
                API.GIFT.GET_GIFT_CATEGORY,
                params
            );
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListGift = async (params: IGetListGiftRequest) => {
        try {
            const response = await this.postData(API.GIFT.LIST_GIFT, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getSpinGiftRandom = async (params: IGetSpinGiftRequest) => {
        try {
            const response = await this.postData(
                API.GIFT.GET_SPIN_GIFT,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    addSpinGift = async (params: IAddSpinGiftRequest) => {
        try {
            const response = await this.postData(API.GIFT.ADD_SPIN, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    buyGift = async (params: IBuyGiftRequest) => {
        try {
            const response = await this.postData(API.GIFT.BUY_GIFT, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}
