import { ComponentType } from 'react';
import pure from 'recompose/pure';
import Video from '../../models/Video';
import { Modal } from 'antd';
import { PlayWrapper } from './VideoPlayer.styles';
import ReactPlayer from 'react-player';
//components

//styles

interface Props {
    open: boolean;
    video: Video;
    onClose: () => void;
}

export const VideoPlayer: ComponentType<Props> = pure(
    ({ video, open, onClose }) => {
        const onCloseDialog = () => {
            onClose();
        };

        const onPauseVideo = () => {};

        const onPlayVideo = () => {};

        const onErrorVideo = () => {};

        return (
            <PlayWrapper>
                <Modal
                    title={video.videoName}
                    open={open}
                    centered={true}
                    onCancel={onCloseDialog}
                    width={800}
                    footer={null}>
                    <ReactPlayer
                        url={video.videoPath}
                        controls={true}
                        width={'100%'}
                        onPause={onPauseVideo}
                        onPlay={onPauseVideo}
                        onError={onErrorVideo}
                    />
                </Modal>
            </PlayWrapper>
        );
    }
);
