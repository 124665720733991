import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import md5 from 'blueimp-md5';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, message } from 'antd';
import { ComponentType } from 'react';

import {
    IFacebookResponse,
    ILoginBase,
    ILoginEmailRequest,
    ILoginFacebookRequest,
    ILoginGoogleRequest,
    LOGIN_TYPE
} from '../../types/ILogin';
import { LoginSchema } from '../../validation/LoginSchema';
import { useRootStore } from '../../providers/RootStoreProvider';
//import GoogleLogin from 'react-google-login';
import { CredentialResponse } from '@react-oauth/google';
import _ from 'lodash';

//styles
import {
    LoginWrapper,
    LoginContainer,
    LoginModal,
    TitleContainer,
    LogoApp,
    LoginInputText,
    LoginButton,
    ForgetPass,
    NoAccountWrapper,
    NoAccountText,
    NoAccountLink,
    SpinLogin
} from './LoginView.styles';

import { APIResponse } from '../../models/APIResponse';
import { IResponseBase } from '../../types/ITypeBase';
import { APP_BUTTON_TYPE } from '../../types/IGlobal';

interface Props {
    visible: boolean;
    onClose: () => void;
    onLoginSuccess: () => void;
    onForgotPass: () => void;
    onRegisterAccount: () => void;
}

export const LoginView: ComponentType<Props> = observer(
    ({ visible, onClose, onLoginSuccess, onForgotPass, onRegisterAccount }) => {
        const store = useRootStore();
        const { t } = useTranslation();

        const { login, loadingLogin, getProfileGoogle } = store.authStore;

        const {
            register,
            handleSubmit,
            reset,
            setValue,
            formState: { errors }
        } = useForm<ILoginBase>({
            resolver: yupResolver(LoginSchema)
        });

        const handleForgotPass = () => {
            onClose();
            onForgotPass();
        };

        const handleRegisterAccount = () => {
            onClose();
            onRegisterAccount();
        };

        const onSubmit = async (data: ILoginBase) => {
            const params: ILoginEmailRequest = {
                data: {
                    type: LOGIN_TYPE.EMAIL,
                    account: data.account,
                    passwd: md5(data.passwd),
                    lang: 'vi'
                }
            };

            const result: APIResponse<IResponseBase> = await login(params);
            if (result && result.isSuccess()) handleLoginSuccess();
            else handleLoginFailure();
        };

        const onResponseFacebook = async (response: IFacebookResponse) => {
            const params: ILoginFacebookRequest = {
                data: {
                    type: LOGIN_TYPE.FACEBOOK,
                    account: response.id,
                    password: response.accessToken,
                    lang: 'vi'
                }
            };
            const result: APIResponse<IResponseBase> = await login(params);
            if (result && result.isSuccess()) handleLoginSuccess();
            else handleLoginFailure();
        };

        const onFailureGoogle = (response: any) => {
            console.log(response);
        };

        const onResponseGoogle = async (response: any) => {
            const params: ILoginGoogleRequest = {
                data: {
                    type: LOGIN_TYPE.GOOGLE,
                    email: response.profileObj.email,
                    password: response.accessToken,
                    lang: 'vi'
                }
            };
            const result: APIResponse<IResponseBase> = await login(params);
            if (result && result.isSuccess()) handleLoginSuccess();
            else handleLoginFailure();
        };

        const handleLoginSuccess = () => {
            message.success('Đăng nhập hệ thống thành công !');
            onLoginSuccess();
        };

        const handleLoginFailure = () => {
            Modal.error({
                title: 'Đăng nhập không thành công',
                content: 'Vui lòng kiểm tra lại thông tin và thử lại',
                centered: true
            });
        };

        const handleLoginGoogleSuccess = (
            credentialResponse: CredentialResponse
        ) => {
            console.log(credentialResponse.credential);
            getProfileGoogle(credentialResponse.credential!);
        };

        return (
            <LoginWrapper>
                <LoginContainer>
                    <LoginModal
                        centered
                        onCancel={onClose}
                        open={visible}
                        footer={null}>
                        <TitleContainer>
                            {/* <LoginTitle>Đăng nhập</LoginTitle> */}
                            <LogoApp src="../../../../images/app_logo_footer.png"></LogoApp>
                        </TitleContainer>
                        {/* <GoogleLogin
                                onSuccess={(
                                    credentialResponse: CredentialResponse
                                ) => {
                                    handleLoginGoogleSuccess(
                                        credentialResponse
                                    );
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            /> */}

                        {/* <GoogleLogin
                                clientId={
                                    process.env.REACT_APP_GOOGLE_CLIENT_ID!
                                }
                                buttonText="Login"
                                render={(renderProps) => (
                                    <LoginButtonWrapper
                                        onClick={renderProps.onClick}>
                                        <LoginButtonIcon src="../../../../images/ic_google.svg"></LoginButtonIcon>
                                        <LoginButtonText>
                                            Tiếp tục với google
                                        </LoginButtonText>
                                    </LoginButtonWrapper>
                                )}
                                onSuccess={onResponseGoogle}
                                onFailure={onFailureGoogle}
                                cookiePolicy={'single_host_origin'}
                            /> */}
                        {/* <FacebookLogin
                                appId={process.env.REACT_APP_FACEBOOK_APPID!}
                                callback={onResponseFacebook}
                                fields="email, name, picture"
                                autoLoad={false}
                                disableMobileRedirect={true}
                                scope="public_profile,email"
                                render={(renderProps) => (
                                    <LoginButtonWrapper
                                        onClick={renderProps.onClick}>
                                        <LoginButtonIcon src="../../../../images/ic_facebook.svg"></LoginButtonIcon>
                                        <LoginButtonText>
                                            Tiếp tục với facebook
                                        </LoginButtonText>
                                    </LoginButtonWrapper>
                                )}
                            /> */}
                        {/* <LoginEmailText>
                                hoặc đăng nhập với email
                            </LoginEmailText> */}
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <LoginInputText
                                {...register('account')}
                                isError={errors.account ? 'error' : ''}
                                placeholder="Số điện thoại / Email"></LoginInputText>
                            <LoginInputText
                                {...register('passwd')}
                                type="password"
                                isError={errors.passwd ? 'error' : ''}
                                placeholder="Mật khẩu"></LoginInputText>
                            {!loadingLogin ? (
                                <LoginButton
                                    type="submit"
                                    typeButton={APP_BUTTON_TYPE.PRIMARY}>
                                    Đăng nhập
                                </LoginButton>
                            ) : (
                                <LoginButton
                                    style={{ pointerEvents: 'none' }}
                                    typeButton={APP_BUTTON_TYPE.PRIMARY}>
                                    <SpinLogin />
                                </LoginButton>
                            )}
                        </form>
                        <ForgetPass onClick={() => handleForgotPass()} href="#">
                            Quên mật khẩu?
                        </ForgetPass>
                        <NoAccountWrapper>
                            <NoAccountText>Bạn chưa có tài khoản</NoAccountText>
                            <NoAccountLink
                                onClick={() => handleRegisterAccount()}
                                href="#">
                                Đăng ký
                            </NoAccountLink>
                        </NoAccountWrapper>
                    </LoginModal>
                </LoginContainer>
            </LoginWrapper>
        );
    }
);
