import { IRequestBase } from './ITypeBase';

export enum GIFT_TYPE {
    ALL,
    TOP_NEW,
    TOP_BUY
}

export enum GIFT_VALUE_TYPE {
    POINT = 1,
    SPIN = 2,
    CODE = 3
}

export interface ISpinGift {
    sid: string;
    title: string;
    code: string;
    img: string;
    order: number;
    qty: number;
    serial: string;
}

export interface IGift {
    catId: number;
    giftId: string;
    giftName: string;
    code: string;
    giftImg: string;
    order: number;
    totalBuy: number;
    point: number;
}

export interface IGetListSpinGiftRequest extends IRequestBase {}

export interface IGetSpinGiftRequest extends IRequestBase {
    data: {
        sid: string;
    };
}

export interface IAddSpinGiftRequest extends IRequestBase {
    data: {
        qty: number;
    };
}

export interface IGetListGiftRequest extends IRequestBase {
    data: {
        type: GIFT_TYPE;
        catId: number;
        from?: number;
        size?: number;
    };
}

export interface IBuyGiftRequest extends IRequestBase {
    data: {
        giftId: string;
        point: number;
    };
}
