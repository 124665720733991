import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components
import { FacebookIcon, OtherIcon, TwitterIcon, YoutubeIcon } from '../SvgIcon';
//styles
import {
    SocialIconWrapper,
    SocialIconContainer,
    SocialIconItem
} from './SocialIcon.styles';

interface Props {
    isHeader?: boolean;
    isFooter?: boolean;
}

export const SocialIcon: ComponentType<Props> = pure(
    ({ isHeader, isFooter }) => {
        return (
            <SocialIconWrapper isHeader={isHeader} isFooter={isFooter}>
                {/* <SocialIconContainer isHeader={isHeader} isFooter={isFooter}>
                    <SocialIconItem isHeader={isHeader} isFooter={isFooter}>
                        <FacebookIcon />
                    </SocialIconItem>
                    <SocialIconItem isHeader={isHeader} isFooter={isFooter}>
                        <YoutubeIcon />
                    </SocialIconItem>
                    <SocialIconItem isHeader={isHeader} isFooter={isFooter}>
                        <OtherIcon />
                    </SocialIconItem>
                    <SocialIconItem isHeader={isHeader} isFooter={isFooter}>
                        <TwitterIcon />
                    </SocialIconItem>
                </SocialIconContainer> */}
            </SocialIconWrapper>
        );
    }
);
