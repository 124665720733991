import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { Modal } from 'antd';
import YouTube from 'react-youtube';
import { Progress } from 'antd';
import ReactPlayer from 'react-player';
//styles
import {
    PlayWrapper,
    TimeWrapper,
    TimeProcess,
    BonusButton,
    TimeRemainText
} from './PlayDialog.styles';
import React from 'react';
import Video from '../../../models/Video';
import Countdown from 'react-countdown';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { IViewVideoRequest, VIDEO_VIEW_STATUS } from '../../../types/IVideo';
import { IResponseBase } from '../../../types/ITypeBase';
import { showMessage } from '../../../utils';
import { formatNumber } from '../../../utils/FormatUtils';

interface Props {
    open: boolean;
    video: Video;
    onClose: () => void;
}

export const PlayDialog: ComponentType<Props> = pure(
    ({ open, video, onClose }) => {
        const store = useRootStore();
        const { viewVideo } = store.videoStore;
        const { currentUser } = store.authStore;
        const videoRef = React.useRef<any>(null);
        const countRef = React.useRef<any>(null);

        const [isPlay, setIsPlay] = React.useState(false);
        const [isReceive, setIsReceive] = React.useState(false);
        const [sessionPlay, setSessionPlay] = React.useState('');
        const [timePercent, setTimePercent] = React.useState(0);

        const opts = {
            height: '390',
            width: '100%',
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1
            }
        };
        const onVideoReady = () => {
            onPlayVideo();
        };

        const onPauseVideo = () => {
            console.log('onPauseVideo');
            if (countRef.current) countRef.current?.pause();
        };

        const onPlayVideo = () => {
            console.log('onPlayVideo');
            if (countRef.current) countRef.current?.start();
            if (!isPlay) {
                setIsPlay(true);
                onRequestViewVideo(VIDEO_VIEW_STATUS.START);
            }
            // if (countRef.current) {
            //     countRef.current?.start();
            // }
        };

        const onRequestViewVideo = async (status: VIDEO_VIEW_STATUS) => {
            const params: IViewVideoRequest = {
                data: {
                    videoid: video.videoId,
                    status: status
                }
            };
            if (status === VIDEO_VIEW_STATUS.FINISH) {
                params.data.point = video.point;
                params.data.ssplay = sessionPlay;
            }
            const result: IResponseBase = await viewVideo(params);
            const msgSuccess = `Chúc mừng bạn đã xem video và nhận được ${formatNumber(
                video.point
            )} điểm thưởng`;
            if (status === VIDEO_VIEW_STATUS.FINISH)
                showMessage(result, msgSuccess);

            if (result && result.isSuccess()) {
                if (status === VIDEO_VIEW_STATUS.START) {
                    const session = result.data.ssplay;
                    setSessionPlay(session);
                    if (countRef.current) {
                        countRef.current?.start();
                    }
                } else {
                    //update total point of user local
                    //currentUser?.updateTotalPoint(video.point, true);
                    setIsReceive(true); //mark flag receive bonus
                }
            }
        };

        const onCloseDialog = () => {
            console.log(videoRef.current?.internalPlayer);
            if (videoRef.current?.internalPlayer) {
                //videoRef.current?.internalPlayer.stopVideo();
                //videoRef.current?.internalPlayer.pauseVideo();
                videoRef.current?.destroyPlayer();
            }
            onClose();
        };

        const onCompleteCountdown = () => {};

        const onTimeTick = (val: any) => {
            const timeRemain = val.seconds;
            const timePlay = video.playTime - timeRemain;
            const percent = Math.floor((timePlay * 100) / video.playTime);
            setTimePercent(percent);
        };

        // Renderer callback with condition
        const renderer = ({
            hours,
            minutes,
            seconds,
            completed
        }: {
            hours: any;
            minutes: any;
            seconds: any;
            completed: any;
        }) => {
            if (completed) {
                // Render a completed state
                return (
                    <>
                        <BonusButton
                            typeButton={
                                !isReceive
                                    ? APP_BUTTON_TYPE.PRIMARY
                                    : APP_BUTTON_TYPE.DISABLED
                            }
                            onClick={() =>
                                onRequestViewVideo(VIDEO_VIEW_STATUS.FINISH)
                            }>
                            Nhận thưởng
                        </BonusButton>
                        {/* <TimeProcess type="circle" percent={100} /> */}
                    </>
                );
            } else {
                const timePlay = video.playTime - seconds;
                const percent = Math.floor((timePlay * 100) / video.playTime);
                // Render a countdown
                return (
                    <>
                        {!isReceive ? (
                            <>
                                <TimeRemainText>
                                    {' '}
                                    Thời gian còn lại:
                                </TimeRemainText>
                                <TimeProcess
                                    type="circle"
                                    strokeWidth={10}
                                    strokeColor={'red'}
                                    percent={percent}
                                    format={(percent) => `${seconds}`}
                                />
                            </>
                        ) : (
                            <BonusButton typeButton={APP_BUTTON_TYPE.DISABLED}>
                                Đã nhận thưởng
                            </BonusButton>
                        )}
                    </>
                );
            }
        };

        return (
            <PlayWrapper>
                <Modal
                    title={video.videoName}
                    open={open}
                    centered={true}
                    onCancel={onCloseDialog}
                    width={800}
                    footer={null}>
                    <TimeWrapper>
                        <Countdown
                            ref={countRef}
                            autoStart={false}
                            date={Date.now() + video.playTime * 1000}
                            //onTick={onTimeTick}
                            renderer={renderer}
                            onComplete={onCompleteCountdown}
                        />
                    </TimeWrapper>
                    <ReactPlayer
                        url={video.videoPath}
                        width={'100%'}
                        onPause={onPauseVideo}
                        onPlay={onPlayVideo}
                        onStart={onPlayVideo}

                        //onError={onErrorVideo}
                    />
                    {/* <YouTube
                        ref={videoRef}
                        videoId="3PlEUIP7ngI" //{videoInfo.videoId}
                        opts={opts}
                        onPlay={onPlayVideo}
                        onPause={onPauseVideo}
                        onReady={onVideoReady}
                    /> */}
                </Modal>
            </PlayWrapper>
        );
    }
);
