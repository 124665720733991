import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    AppContentContainer,
    AppContentWrapper
} from '../../styles/App.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../providers/RootStoreProvider';
import {
    MaintenanceContainer,
    MaintenanceDescription,
    MaintenanceImage,
    MaintenanceTitle,
    MaintenanceWrapper
} from './Maintenance.styles';

interface Props {}

export const Maintenance: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { maintenanceInfo, getMaintenanceInfo } = store.jsonStore;

    useEffect(() => {
        onGetMaintenanceInfo();
    }, []);

    const onGetMaintenanceInfo = () => {
        if (!maintenanceInfo) getMaintenanceInfo();
    };

    return (
        <MaintenanceWrapper>
            <MaintenanceContainer>
                <MaintenanceImage src="../../../../images/maintenance.png"></MaintenanceImage>
                <MaintenanceTitle>{maintenanceInfo?.title}</MaintenanceTitle>
                <MaintenanceDescription>
                    {maintenanceInfo?.content}
                </MaintenanceDescription>
            </MaintenanceContainer>
        </MaintenanceWrapper>
    );
});
