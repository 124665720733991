import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//components
import { TopBanner } from './TopBanner/TopBanner';
import { InvitedForm } from './InvitedForm/InvitedForm';
import { useRootStore } from '../../providers/RootStoreProvider';
import { HELP_CONTENT, MAIN_MENU, RULES_CONTENT } from '../../types/IGlobal';
import { observer } from 'mobx-react-lite';
import { AuthHeader } from '../../helpers/AuthHeader';
import { AppContentWrapper } from '../../styles/App.styles';
import { TokenInvalid } from '../CommonView/TokenInvalid/TokenInvalid';
import { NotVerify } from '../CommonView/NotVerify/NotVerìfy';
import { RuleDialog } from '../CommonView/RuleDialog/RuleDialog';
import { RuleDescription } from './RuleDescription/RuleDescription';
//styles

interface Props {}

export const InvitedView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { currentUser } = store.authStore;
    const {
        changeMainMenuIndex,
        showDialogRule,
        toggleShowDialogRule,
        setHelpType,
        toggleShowDialogHelp
    } = store.globalStore;

    useEffect(() => {
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        changeMainMenuIndex(MAIN_MENU.MISSION);
    }, []);

    const renderContent = () => {
        if (!currentUser?.isLogin()) {
            return <TokenInvalid />;
        } else if (!currentUser.isVerified()) {
            return <NotVerify />;
        } else return <InvitedForm userInfo={currentUser!} />;
    };

    const onShowDialogHelp = () => {
        setHelpType(HELP_CONTENT.INVITED);
        toggleShowDialogHelp(true);
    };

    return (
        <AppContentWrapper>
            <TopBanner
                onClickRules={() => toggleShowDialogRule(true)}
                onClickHelp={() => {
                    onShowDialogHelp();
                }}
            />
            <RuleDescription />
            {renderContent()}
            {showDialogRule ? (
                <RuleDialog type={RULES_CONTENT.INVITED} />
            ) : null}
        </AppContentWrapper>
    );
});
