import { Modal } from 'antd';
import styled from 'styled-components';
import { device } from '../../configs/MediaQuery';
import { AppButton } from '../../styles/AppButton.styles';

export const ForgotPassWrapper = styled.div`
    max-width: 460px;
`;

export const ForgotPassContainer = styled.div`
    width: 100%;
    text-align: center;
    color: #314154;
`;

export const ForgotPassModal = styled(Modal)`
    .ant-modal-body {
        text-align: center;
    }
`;

export const TitleContainer = styled.div`
    padding-top: 10px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const TitleText = styled.h1`
    font-weight: 700;
    margin: 0;
    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        font-size: 20px;
        line-height: 30px;
    }
`;

export const DescriptionText = styled.p`
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
`;

export const SendButton = styled(AppButton)`
    width: 100%;
`;
