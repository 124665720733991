import numeral from 'numeral';

//format number ex 0,000 and get 1 number after decimal if round else get 2 number
export function formatNumber(value: number, isRound?: boolean): string {
    if (isRound) return numeral(value).format('0,0.[0]');
    else {
        let result: string = numeral(value).format('0,0.[00]');
        return result.replaceAll(',', '.');
    }
}

export function roundNumber(value: number, numberRound: number): string {
    let strRound = ''.padStart(numberRound, '0');
    const pattern = `0,0.[${strRound}]`;
    const data = numeral(value).format(pattern);
    return numeral(value).format(pattern);
}

export function formatDistance(value: number, numberRound: number): string {
    const valueKm = value / 1000;
    return roundNumber(valueKm, numberRound);
}
