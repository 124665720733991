import { action, makeObservable, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import Mission, { ListMission } from '../models/Mission';
import { MissionService } from '../services/MissionService';
import { IGetListMissionRequest, IMission } from '../types/IMission';
import { APIResponse, APIResponseList } from '../models/APIResponse';
import { IJoinInstallAppMissionRequest } from '../types/IBanner';
import { IResponseBase } from '../types/ITypeBase';

class MissionStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable listMission: ListMission | null = null;
    @observable currentMission: Mission | null = null;

    @observable loadingJoinMission: boolean = false;

    @action setMissionData = (mission: Mission) => {
        runInAction(() => {
            this.currentMission = mission;
        });
    };

    @action getListMission = async (
        params: IGetListMissionRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listMission)
            runInAction(() => {
                this.listMission = new ListMission();
            });
        else if (loadMore) {
            this.listMission.pageIndex += 1;
            this.listMission.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listMission.pageIndex
                : this.listMission.pageSize * this.listMission.pageIndex;
        }

        //request api service
        const result = (await MissionService.getInstance().getListMission(
            params
        )) as APIResponseList<Array<IMission>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listMission) {
                    this.listMission.list = this.listMission.list?.concat(
                        ListMission.fromJson(result.data?.list!)
                    )!;
                    this.listMission.totalRecord = result.totalRecord();
                    this.listMission.isLoading = false;
                }
            });
        }
        return result;
    };

    @action joinInstallAppMission = async (
        params: IJoinInstallAppMissionRequest
    ) => {
        this.loadingJoinMission = true;
        const result = (await MissionService.getInstance().joinInstallApp(
            params
        )) as APIResponse<any>;
        this.loadingJoinMission = false;
        return result;
    };
}

export default MissionStore;
