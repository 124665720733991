import { Progress } from 'antd';
import styled from 'styled-components';
import { AppButton } from '../../../styles/AppButton.styles';

export const PlayWrapper = styled.div``;

export const TimeWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
`;

export const TimeRemainText = styled.p`
    font-size: 18px;
    font-weight: 500;
    margin: 0px;
    margin-right: 20px;
`;

export const TimeProcess = styled(Progress)`
    .ant-progress-inner {
        width: 60px !important;
        height: 60px !important;
    }
`;

export const BonusButton = styled(AppButton)``;
