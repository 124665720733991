import styled from 'styled-components';
import { AppCard } from '../../../../../styles/App.styles';
import { AppInputText } from '../../../../../styles/AppInput.styles';
import { AppButton } from '../../../../../styles/AppButton.styles';
import { DatePicker, Progress, Select } from 'antd';

export const UserInfoWrapper = styled.div``;

export const UserInfoContainer = styled(AppCard)`
    .ant-card-body {
        padding: 20px;
    }
`;

export const FieldWrapper = styled.div`
    display: flex;
`;

export const FieldLabel = styled.p`
    margin: 0;
    margin-right: 20px;
    margin-top: 10px;
    min-width: 100px;
`;

export const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: start;
`;

export const VerifyFieldWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
`;

export const InputText = styled(AppInputText)`
    background: transparent;
    &.error {
        margin: 0;
    }
`;

export const ErrorText = styled.p`
    font-size: 14px;
    color: red;
    margin: 10px;
`;

export const CitySelect = styled(Select)`
    width: 300px !important;
    .ant-select-selector {
        background-color: transparent !important;
        height: 40px !important;
        border-radius: 10px !important;
    }
    .ant-select-selection-item {
        display: flex;
        align-items: center;
        color: #fff;
    }
    .ant-select-arrow {
        font-size: 16px;
        font-weight: bold;
        color: #fff !important;
    }
`;

export const DistrictSelect = styled(CitySelect)`
    margin-bottom: 0px;
`;

export const BirthdayInput = styled(DatePicker)`
    color: #fff;
    border-radius: 8px;
    background-color: transparent;
    height: 40px;
    width: 200px;
    margin-bottom: 20px;

    .ant-picker-input input {
        color: #fff !important;
    }
    .ant-picker-suffix {
        color: #fff !important;
    }
    .ant-picker-clear {
        background-color: transparent !important;
    }
`;

export const UpdateButton = styled(AppButton)`
    width: 100%;
    margin-top: 50px;
`;

export const VerifyProcess = styled(Progress)`
    .ant-progress-inner {
        margin-left: 20px;
        width: 40px !important;
        height: 40px !important;
    }
`;
