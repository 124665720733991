import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { useNavigate } from 'react-router-dom';
//components

//styles
import {
    AppContentWrapper,
    AppContentContainer
} from '../../../styles/App.styles';
import {
    NotVerifyWrapper,
    NotVerifyContainer,
    ContentDescription,
    ContentTitle,
    VerifyButton
} from './NotVerìfy.styles';
import { AppButton } from '../../../styles/AppButton.styles';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { useRootStore } from '../../../providers/RootStoreProvider';
import {
    PROFILE_INFO_SCREEN,
    PROFILE_SCREEN
} from '../../../configs/ScreenType';

interface Props {}

export const NotVerify: ComponentType<Props> = pure(({}) => {
    const store = useRootStore();
    const navigation = useNavigate();
    const { changeProfileScreen, changeProfileInfoScreen, toggleScrollVerify } =
        store.globalStore;

    const onGoVerifyView = () => {
        changeProfileScreen(PROFILE_SCREEN.INFO);
        changeProfileInfoScreen(PROFILE_INFO_SCREEN.VERIFY);
        toggleScrollVerify(true);
        navigation('/profile');
    };
    return (
        <AppContentWrapper>
            <AppContentContainer>
                <NotVerifyWrapper>
                    <NotVerifyContainer title="Tài khoản của bạn chưa được xác thực thông tin!">
                        <ContentTitle>
                            Vui lòng xác thực số điện thoại, email để tiếp tục!
                        </ContentTitle>
                        {/* <ContentDescription>
                            Vui lòng xác thực số điện thoại, email để tiếp tục!
                        </ContentDescription> */}
                        <VerifyButton
                            typeButton={APP_BUTTON_TYPE.PRIMARY}
                            onClick={() => onGoVerifyView()}>
                            Xác thực ngay
                        </VerifyButton>
                    </NotVerifyContainer>
                </NotVerifyWrapper>
            </AppContentContainer>
        </AppContentWrapper>
    );
});
