import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    LeftMenuWrapper,
    LeftMenuContainer,
    MenuItemWrapper,
    MenuItemText
} from './LeftMenu.styles';
import { UserAddOutlined } from '@ant-design/icons';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { PROFILE_ACTIVITY_SCREEN } from '../../../../configs/ScreenType';
import { observer } from 'mobx-react-lite';

interface Props {
    onChangeMenuItem: (item: PROFILE_ACTIVITY_SCREEN) => void;
}

export const LeftMenu: ComponentType<Props> = observer(
    ({ onChangeMenuItem }) => {
        const store = useRootStore();
        const { appConfig } = store.commonStore;
        const { profileActivityScreen } = store.globalStore;
        return (
            <LeftMenuWrapper>
                <LeftMenuContainer>
                    {/* <MenuItemWrapper>
                    <MenuItemText>Nhật ký hoạt động</MenuItemText>
                </MenuItemWrapper> */}
                    <MenuItemWrapper
                        onClick={() =>
                            onChangeMenuItem(
                                PROFILE_ACTIVITY_SCREEN.HISTORY_VISIT
                            )
                        }>
                        <MenuItemText
                            isActive={
                                profileActivityScreen ===
                                PROFILE_ACTIVITY_SCREEN.HISTORY_VISIT
                                    ? 'active'
                                    : ''
                            }>
                            Điểm danh
                        </MenuItemText>
                    </MenuItemWrapper>
                    <MenuItemWrapper
                        onClick={() =>
                            onChangeMenuItem(
                                PROFILE_ACTIVITY_SCREEN.HISTORY_INVITED
                            )
                        }>
                        <MenuItemText
                            isActive={
                                profileActivityScreen ===
                                PROFILE_ACTIVITY_SCREEN.HISTORY_INVITED
                                    ? 'active'
                                    : ''
                            }>
                            Mời bạn
                        </MenuItemText>
                    </MenuItemWrapper>
                    {appConfig?.showVideoHistory ? (
                        <MenuItemWrapper
                            onClick={() =>
                                onChangeMenuItem(
                                    PROFILE_ACTIVITY_SCREEN.HISTORY_VIDEO_VIEW
                                )
                            }>
                            <MenuItemText
                                isActive={
                                    profileActivityScreen ===
                                    PROFILE_ACTIVITY_SCREEN.HISTORY_VIDEO_VIEW
                                        ? 'active'
                                        : ''
                                }>
                                Xem video
                            </MenuItemText>
                        </MenuItemWrapper>
                    ) : null}
                    <MenuItemWrapper
                        onClick={() =>
                            onChangeMenuItem(
                                PROFILE_ACTIVITY_SCREEN.HISTORY_SPIN
                            )
                        }>
                        <MenuItemText
                            isActive={
                                profileActivityScreen ===
                                PROFILE_ACTIVITY_SCREEN.HISTORY_SPIN
                                    ? 'active'
                                    : ''
                            }>
                            Vòng quay may mắn
                        </MenuItemText>
                    </MenuItemWrapper>
                    <MenuItemWrapper
                        onClick={() =>
                            onChangeMenuItem(
                                PROFILE_ACTIVITY_SCREEN.HISTORY_BUY_GIFT
                            )
                        }>
                        <MenuItemText
                            isActive={
                                profileActivityScreen ===
                                PROFILE_ACTIVITY_SCREEN.HISTORY_BUY_GIFT
                                    ? 'active'
                                    : ''
                            }>
                            Đổi quà
                        </MenuItemText>
                    </MenuItemWrapper>

                    {/* <MenuItemWrapper
                        onClick={() =>
                            onChangeMenuItem(
                                PROFILE_ACTIVITY_SCREEN.HISTORY_INSTALL_APP
                            )
                        }>
                        <MenuItemText
                            isActive={
                                profileActivityScreen ===
                                PROFILE_ACTIVITY_SCREEN.HISTORY_INSTALL_APP
                                    ? 'active'
                                    : ''
                            }>
                            Cài đặt App
                        </MenuItemText>
                    </MenuItemWrapper> */}
                </LeftMenuContainer>
            </LeftMenuWrapper>
        );
    }
);
