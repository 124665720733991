import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';
import {
    AppContentContainer,
    AppContentWrapper
} from '../../styles/App.styles';

//components
import { TournamentTop } from './TournamentTop/TournamentTop';
import { ListMatch } from './ListMatch/ListMatch';
import { useRootStore } from '../../providers/RootStoreProvider';
import { MAIN_MENU } from '../../types/IGlobal';

//styles

interface Props {}

export const TournamentView: ComponentType<Props> = pure(({}) => {
    const store = useRootStore();
    const { changeMainMenuIndex } = store.globalStore;

    useEffect(() => {
        changeMainMenuIndex(MAIN_MENU.TOURNAMENT);
    }, []);

    return (
        <AppContentWrapper>
            <TournamentTop />
            <AppContentContainer>
                <ListMatch />
            </AppContentContainer>
        </AppContentWrapper>
    );
});
