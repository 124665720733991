import { action, makeObservable, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { APIResponse, APIResponseList } from '../models/APIResponse';
import { ListGiftBagType } from '../models/GiftBagType';
import { GiftBagService } from '../services/GiftBagService';
import {
    IBagReceiveItemRequest,
    IGetListGiftBag,
    IGetListGiftBagType,
    IGiftBag,
    IGiftBagType
} from '../types/IGiftBag';
import { ListGiftBag } from '../models/GiftBag';
import { IResponseBase } from '../types/ITypeBase';
import _ from 'lodash';

class GiftBagStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable listGiftBagType: ListGiftBagType | null = null;
    @observable listGiftBag: ListGiftBag | null = null;
    @observable scrollToList: boolean = false;

    @action toggleScrollToList = (value: boolean) => {
        runInAction(() => {
            this.scrollToList = !value;
        });
    };

    @action getListGiftBagType = async (
        params: IGetListGiftBagType,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listGiftBagType)
            runInAction(() => {
                this.listGiftBagType = new ListGiftBagType();
            });
        else if (loadMore) {
            this.listGiftBagType.pageIndex += 1;
            this.listGiftBagType.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listGiftBagType.pageIndex
                : this.listGiftBagType.pageSize *
                  this.listGiftBagType.pageIndex;
        }

        //request api service
        const result = (await GiftBagService.getInstance().getListGiftBagType(
            params
        )) as APIResponseList<Array<IGiftBagType>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listGiftBagType) {
                    this.listGiftBagType.list =
                        this.listGiftBagType.list?.concat(
                            ListGiftBagType.fromJson(result.data?.list!)
                        )!;
                    this.listGiftBagType.totalRecord = result.totalRecord();
                    this.listGiftBagType.isLoading = false;
                }
            });
        }

        return result;
    };

    @action getListGiftBag = async (
        params: IGetListGiftBag,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listGiftBag)
            runInAction(() => {
                this.listGiftBag = new ListGiftBag();
            });
        else if (loadMore) {
            this.listGiftBag.pageIndex += 1;
            this.listGiftBag.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listGiftBag.pageIndex
                : this.listGiftBag.pageSize * this.listGiftBag.pageIndex;
        }

        //request api service
        const result = (await GiftBagService.getInstance().getListGiftBag(
            params
        )) as APIResponseList<Array<IGiftBag>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listGiftBag) {
                    this.listGiftBag.list = this.listGiftBag.list?.concat(
                        ListGiftBag.fromJson(result.data?.list!)
                    )!;
                    this.listGiftBag.totalRecord = result.totalRecord();
                    this.listGiftBag.isLoading = false;
                }
            });
        }

        return result;
    };

    @action receiveBagItem = async (params: IBagReceiveItemRequest) => {
        const result = (await GiftBagService.getInstance().receiveBagItem(
            params
        )) as APIResponse<IResponseBase>;

        //remove the item from the list bag
        if (result.isSuccess()) {
            runInAction(() => {
                _.remove(this.listGiftBag?.list!, (item) => {
                    return item.bagId === params.data.bagId;
                });
            });
        }
        return result;
    };
}

export default GiftBagStore;
