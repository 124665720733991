import { IRequestBase } from './ITypeBase';

export enum VIDEO_VIEW_STATUS {
    START,
    FINISH
}
export interface IVideo {
    videoId: string;
    title: string;
    videoPath: string;
    thumbPath: string;
    status: number;
    order: number;
    point: number;
    playtime: number;
    video_type: number;
}

export interface IGetListVideoRequest extends IRequestBase {}

export interface IViewVideoRequest extends IRequestBase {
    data: {
        videoid: string;
        status: number;
        point?: number;
        ssplay?: string;
    };
}
