import { ComponentType, useMemo, useState } from 'react';
import pure from 'recompose/pure';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFlip, EffectFade, Navigation, Pagination } from 'swiper';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';

//components
import { GiftNewItem } from './GiftNewItem/GiftNewItem';
import Gift, { ListGift } from '../../../models/Gift';
import { FConfirmDialog } from '../../../components/FConfirmDialog/FConfirmDialog';
import { IBuyGiftRequest } from '../../../types/IGift';
import { Modal } from 'antd';
//styles

interface Props {
    gifts: ListGift;
}

export const GiftNewSlider: ComponentType<Props> = observer(({ gifts }) => {
    const store = useRootStore();
    const { showSuccessExchangeDialog } = store.giftStore;
    return (
        <>
            <Swiper
                //slidesPerView={2.5}
                lazy={true}
                observer={true}
                key={Date.now()}
                effect={'flip'}
                navigation={true}
                autoplay={true}
                centeredSlides={true}
                loop={true}
                spaceBetween={15}
                breakpoints={{
                    640: {
                        //width: 640,
                        slidesPerView: 1
                    },
                    768: {
                        //width: 768,
                        slidesPerView: 1.5
                    },
                    992: {
                        //width: 992,
                        slidesPerView: 2.5,
                        spaceBetween: 24
                    },
                    1200: {
                        //width: 1200,
                        slidesPerView: 3.5,
                        spaceBetween: 32
                    }
                }}
                grabCursor={true}
                // pagination={{
                //     clickable: true
                // }}
                modules={[EffectFade, Navigation, Pagination]}
                className="mySwiper">
                {gifts &&
                    gifts.list.map((item) => {
                        return (
                            <SwiperSlide key={item.giftId}>
                                <GiftNewItem key={item.giftId} data={item} />
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
        </>
    );
});
