import { ComponentType, useState } from 'react';
import pure from 'recompose/pure';
import { IMissionInstallApp } from '../../../types/IJson';
import {
    FormContainer,
    FormItem,
    FormLabel,
    FormTitle,
    FormWrapper,
    InputContainer,
    InputWrapper,
    RuleText,
    RuleWrapper
} from './MissionContent.styles';
import { ButtonWrapper, JoinButton } from '../InstallApp.styles';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { Checkbox, Modal, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { stripedHtml } from '../../../utils';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TokenInvalid } from '../../CommonView/TokenInvalid/TokenInvalid';
import { JsonDialogNested } from '../../CommonView/JsonDialogNested/JsonDialogNested';

interface Field {
    id: number;
    value: string;
}

interface Props {
    mission: IMissionInstallApp;
    loading: boolean;
    onSubmitForm: (data: Array<Field>) => void;
}

export const FormContent: ComponentType<Props> = observer(
    ({ mission, loading, onSubmitForm }) => {
        const store = useRootStore();
        const { currentUser } = store.authStore;
        const { showDialogJsonNested, toggleShowDialogJsonNested } =
            store.globalStore;

        const [acceptPolicyData, setAcceptPolicyData] = useState(false);

        let formFields: Field[] = [];
        mission.formFields.map((field, index) => {
            let obj: Field = {
                id: ++index,
                value: ''
            };
            formFields.push(obj);
        });

        const [fields, setFields] = useState<Field[]>(formFields);

        const handleFieldChange = (id: number, value: string) => {
            const updatedFields = fields.map((field) =>
                field.id === id ? { ...field, value } : field
            );
            setFields(updatedFields);
        };

        const handleSubmit = (event: React.FormEvent) => {
            event.preventDefault();
            if (!acceptPolicyData) {
                Modal.error({
                    title: 'Đăng ký không thành công',
                    content:
                        'Vui lòng đồng ý với chính sách bảo vệ dữ liệu cá nhân',
                    centered: true
                });
                return;
            }

            let params: Array<Field> = [];
            fields.map((field) => {
                let obj = {
                    id: field.id,
                    value: stripedHtml(field.value)
                };
                params.push(obj);
            });
            onSubmitForm(params);
        };

        const onAcceptPolicyData = (e: CheckboxChangeEvent) => {
            setAcceptPolicyData(e.target.checked);
        };

        if (currentUser)
            return (
                <FormWrapper>
                    <FormContainer>
                        <form onSubmit={handleSubmit}>
                            <InputWrapper
                                isBorder={
                                    fields && fields.length > 0
                                        ? 'bordered'
                                        : ''
                                }>
                                {mission.formTitle &&
                                mission.formTitle.length > 0 ? (
                                    <FormTitle>{mission.formTitle}</FormTitle>
                                ) : null}
                                <InputContainer>
                                    {fields &&
                                        fields.map((field, index) => (
                                            <FormItem key={field.id}>
                                                <FormLabel>
                                                    {mission.formFields[index]}
                                                </FormLabel>
                                                <input
                                                    style={{
                                                        minHeight: 35,
                                                        minWidth: 250,
                                                        paddingLeft: 10
                                                    }}
                                                    type="text"
                                                    value={field.value}
                                                    onChange={(e) =>
                                                        handleFieldChange(
                                                            field.id,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </FormItem>
                                        ))}
                                    {mission.policyTitle &&
                                    mission.policyTitle.length > 0 ? (
                                        <RuleWrapper>
                                            <Checkbox
                                                onChange={onAcceptPolicyData}
                                                style={{
                                                    marginRight: 10
                                                }}></Checkbox>
                                            <RuleText
                                                onClick={() =>
                                                    toggleShowDialogJsonNested(
                                                        true
                                                    )
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: mission.policyTitle
                                                }}></RuleText>
                                        </RuleWrapper>
                                    ) : null}
                                </InputContainer>
                                <ButtonWrapper>
                                    {!loading ? (
                                        <JoinButton
                                            type="submit"
                                            typeButton={
                                                APP_BUTTON_TYPE.PRIMARY
                                            }>
                                            Tham gia
                                        </JoinButton>
                                    ) : (
                                        <JoinButton
                                            style={{ pointerEvents: 'none' }}
                                            typeButton={
                                                APP_BUTTON_TYPE.PRIMARY
                                            }>
                                            <Spin />
                                        </JoinButton>
                                    )}
                                </ButtonWrapper>
                            </InputWrapper>
                        </form>
                    </FormContainer>
                    {showDialogJsonNested && mission.policyContent ? (
                        <JsonDialogNested jsonContent={mission.policyContent} />
                    ) : null}
                </FormWrapper>
            );
        else return <TokenInvalid />;
    }
);
