import { action, makeObservable, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import Tournament, { ListTournament } from '../models/Tournament';
import { ITournament, IGetListTournamentRequest } from '../types/ITournament';
import { APIResponseList } from '../models/APIResponse';
import { TournamentService } from '../services/TournamentService';
import { ListMatch } from '../models/Match';
import { IGetListMatchRequest, IMatch } from '../types/IMatch';

class TournamentStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable currentTournament: Tournament | null = null;
    @observable listTournament: ListTournament | null = null;
    @observable listTournamentTop: ListTournament | null = null;

    @observable listMatch: ListMatch | null = null;

    @action setTournamentData = (data: Tournament) => {
        runInAction(() => {
            this.currentTournament = data;
        });
    };

    @action getListTournamentTop = async (
        params: IGetListTournamentRequest,
        loadMore: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listTournamentTop)
            runInAction(() => {
                this.listTournamentTop = new ListTournament();
            });
        else if (loadMore) {
            this.listTournamentTop.pageIndex += 1;
            this.listTournamentTop.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listTournamentTop.pageIndex
                : this.listTournamentTop.pageSize *
                  this.listTournamentTop.pageIndex;
        }

        //request api service
        const result = (await TournamentService.getInstance().getListTournament(
            params
        )) as APIResponseList<Array<ITournament>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listTournamentTop) {
                    this.listTournamentTop.list =
                        this.listTournamentTop.list?.concat(
                            ListTournament.fromJson(result.data?.list!)
                        )!;
                    this.listTournamentTop.totalRecord = result.totalRecord();
                    this.listTournamentTop.isLoading = false;
                }
            });
        }
        return result;
    };

    @action getListTournament = async (
        params: IGetListTournamentRequest,
        loadMore: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listTournament)
            runInAction(() => {
                this.listTournament = new ListTournament();
            });
        else if (loadMore) {
            this.listTournament.pageIndex += 1;
            this.listTournament.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listTournament.pageIndex
                : this.listTournament.pageSize * this.listTournament.pageIndex;
        }

        //request api service
        const result = (await TournamentService.getInstance().getListTournament(
            params
        )) as APIResponseList<Array<ITournament>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listTournament) {
                    this.listTournament.list = this.listTournament.list?.concat(
                        ListTournament.fromJson(result.data?.list!)
                    )!;
                    this.listTournament.totalRecord = result.totalRecord();
                    this.listTournament.isLoading = false;

                    if (result.data?.list.length! > 0) {
                        this.currentTournament = Tournament.fromJson(
                            result.data?.list[0]!
                        );
                    }
                }
            });
        } else this.listTournament!.isLoading = false;
        return result;
    };

    @action getListMatch = async (
        params: IGetListMatchRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listMatch)
            runInAction(() => {
                this.listMatch = new ListMatch();
            });
        else if (loadMore) {
            this.listMatch.pageIndex += 1;
            this.listMatch.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listMatch.pageIndex
                : this.listMatch.pageSize * this.listMatch.pageIndex;
        }

        //request api service
        const result = (await TournamentService.getInstance().getListMatch(
            params
        )) as APIResponseList<Array<IMatch>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listMatch) {
                    this.listMatch.list = this.listMatch.list?.concat(
                        ListMatch.fromJson(result.data?.list!)
                    )!;
                    this.listMatch.totalRecord = result.totalRecord();
                    this.listMatch.isLoading = false;
                }
            });
        }
        return result;
    };
}

export default TournamentStore;
