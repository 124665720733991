import { action, makeObservable, observable, runInAction } from 'mobx';
import { AuthHeader } from '../helpers/AuthHeader';
import { IInstallApp, IUser } from '../types/IUser';
import { DataList } from './DataList';
export default class User {
    public userId: string;
    public name: string;
    public email: string;
    public ssId: string;
    @observable public avatar: string;
    @observable public cover: string;
    public sex: number;
    public phone: string;
    public birthday: string;
    @observable public totalSpin: number;
    @observable public isVisit: boolean;
    @observable public totalVisit: number;
    @observable public totalPoint: number;
    public verifyEmail: boolean;
    public verifyPhone: boolean;
    public refId: string;
    public cityId: any;
    @observable public districtId: any;
    public verifyNumber: string;
    public installApp: Array<IInstallApp>;

    constructor(
        userId: string,
        name: string,
        email: string,
        ssId: string,
        avatar: string,
        cover: string,
        sex: number,
        phone: string,
        birthday: string,
        totalSpin: number,
        isVisit: boolean,
        totalVisit: number,
        totalPoint: number,
        verifyEmail: boolean,
        verifyPhone: boolean,
        refId: string,
        cityId: any,
        districtId: any,
        verifyNumber: string,
        installApp: Array<IInstallApp>
    ) {
        makeObservable(this);
        this.userId = userId;
        this.name = name;
        this.email = email;
        this.ssId = ssId;
        this.avatar = avatar;
        this.cover = cover;
        this.sex = sex;
        this.phone = phone;
        this.birthday = birthday;
        this.totalSpin = totalSpin;
        this.isVisit = isVisit;
        this.totalVisit = totalVisit;
        this.totalPoint = totalPoint;
        this.verifyEmail = verifyEmail;
        this.verifyPhone = verifyPhone;
        this.refId = refId;
        this.cityId = cityId;
        this.districtId = districtId;
        this.verifyNumber = verifyNumber;
        this.installApp = installApp;
    }

    static fromJson = (json: IUser): User => {
        return new User(
            json.hvid,
            json.fullname,
            json.email,
            json.ssid,
            json.avatar,
            json.cover,
            json.sex,
            json.phone,
            json.dob != null && json.dob.length > 0
                ? json.dob
                : new Date().toString(),
            json.spin,
            Boolean(json.visit),
            json.totalvisit,
            json.point,
            Boolean(json.verifyEmail),
            Boolean(json.verifyPhone),
            json.refId,
            json.cityId != 0 ? json.cityId : '',
            json.locationId != 0 ? json.locationId : '',
            json.verifyNumber,
            json.installApp
        );
    };

    getUserName = (): string => {
        if (this.name && this.name.length > 0) return this.name;
        const index = this.email.indexOf('@');
        return this.email.substring(0, index);
    };

    isLogin = (): boolean => {
        return AuthHeader() != null || this != null;
    };

    isVerified = (): boolean => {
        return this.verifyEmail === true && this.verifyPhone === true;
    };

    @action updateAvatar = (image: string): void => {
        this.avatar = image;
    };

    @action updateCover = (image: string): void => {
        this.cover = image;
    };

    @action resetDistrict = (): void => {
        this.districtId = '';
    };

    @action updateDistrict = (id: any): void => {
        this.districtId = id;
    };

    @action updateVisitStatus = (status: boolean): void => {
        this.isVisit = status;
    };

    @action updateVerifyEmailStatus = (status: boolean): void => {
        this.verifyEmail = status;
    };

    @action updateVerifyPhoneStatus = (status: boolean): void => {
        this.verifyPhone = status;
    };

    @action updateTotalVisit = (): void => {
        runInAction(() => {
            this.totalVisit += 1;
        });
    };

    @action updateTotalSpin = (
        val: number,
        isAdd?: boolean,
        isSubtract?: boolean
    ): void => {
        runInAction(() => {
            if (isAdd) this.totalSpin += val;
            else if (isSubtract) this.totalSpin -= val;
        });
    };

    @action updateTotalPoint = (
        val: number,
        isAdd?: boolean,
        isSubtract?: boolean
    ): void => {
        if (isAdd) this.totalPoint += val;
        else if (isSubtract) this.totalPoint -= val;
    };
}

export class ListUser extends DataList<User> {
    static fromJson = (jsonArray: Array<IUser>): Array<User> => {
        let data = new Array<User>();
        jsonArray.forEach((item) => {
            data.push(User.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
