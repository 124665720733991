import { IRequestBase } from './ITypeBase';

export enum TOUR_STATUS {
    HOT
}

export enum TOUR_STATUS_TYPE {
    ALL,
    RUNNING,
    READY,
    FINISHED
}

export function getStatusName(status: number): string {
    switch (status) {
        case TOUR_STATUS_TYPE.RUNNING:
            return 'Đang diễn ra';
        case TOUR_STATUS_TYPE.READY:
            return 'Sắp diễn ra';
        case TOUR_STATUS_TYPE.FINISHED:
            return 'Đã kết thúc';
        default:
            return 'Sắp diễn ra';
    }
}

export interface ITournament {
    tournamentId: string;
    tournamentName: string;
    tourImg: string;
    order: number;
    status: number;
}

export interface IGetListTournamentRequest extends IRequestBase {}

export interface IGetListTournamentTopRequest extends IRequestBase {}
