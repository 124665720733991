var moment = require('moment');

export function formatDate(format: string, date: Date): string {
    const result = moment(new Date()).format(format);
    try {
        if (date == undefined || date == null) return result;
        if (!moment(date).isValid()) return result;
        return moment(new Date(date)).format(format);
    } catch (err) {
        return result;
    }
}

export function getDayIndex() {
    const now = new Date();
    return now.getDay();
}

//convert timestamp to date
export function convertTimestampToDate(
    timestamp: number,
    format: string
): string {
    var date = new Date(timestamp);
    return formatDate(format, date);
}

//convert date to timestamp
export function convertDateToTimestamp(date: Date, time: TimeRanges): number {
    const dateStr = moment(date).format('MM/DD/YYYY');
    const timeStr = moment(time).format('HH:mm');
    const fullDate = dateStr + ' ' + timeStr;

    const dateVal = new Date(fullDate);
    return moment(dateVal).format('x');
}

export function humanTime(timestamp: number, lang: string) {
    const yesterday = formatDate('DD-MM-YYYY', moment().subtract(1, 'days'));
    const today = formatDate('DD-MM-YYYY', new Date());
    const runDate = formatDate('DD-MM-YYYY', new Date(timestamp));

    //get hour in date
    const hour = formatDate('hh:mm A', new Date(timestamp));

    const strToday = lang == 'en' ? 'Today at ' : 'Hôm nay lúc ';
    const strYesterday = lang == 'en' ? 'Yesterday at ' : 'Hôm qua lúc ';
    const strOther = lang == 'en' ? ' at ' : ' lúc ';

    if (runDate == today) return strToday + hour;
    else if (runDate == yesterday) return strYesterday + hour;
    else return runDate + strOther + hour;
}
