import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IChangePass } from '../../../../../types/IUser';
import { ChangePassSchema } from '../../../../../validation/ChangePassSchema ';
//components

//styles
import {
    ChangePassWrapper,
    ChangePassContainer,
    InputWrapper,
    InputText,
    ErrorText,
    ChangePassButton
} from './ChangePass.styles';
import { APP_BUTTON_TYPE } from '../../../../../types/IGlobal';
import { IChangePassRequest } from '../../../../../types/ILogin';
import { IResponseBase } from '../../../../../types/ITypeBase';
import { useRootStore } from '../../../../../providers/RootStoreProvider';
import { Modal, Spin } from 'antd';
import { USER_ERROR } from '../../../../../configs/APIStatus';
import { showMessage } from '../../../../../utils';
import { observer } from 'mobx-react-lite';

interface Props {}

export const ChangePass: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { changePass, loadingChangePass, logout } = store.authStore;
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<IChangePass>({
        resolver: yupResolver(ChangePassSchema),
        defaultValues: {}
    });

    const onChangePass = async (data: IChangePass) => {
        const params: IChangePassRequest = {
            data: {
                opwd: data.oldPass,
                npwd: data.newPass,
                cpwd: data.confirmPassword
            }
        };
        const result: IResponseBase = await changePass(params);
        const msgSuccess =
            'Chúc mừng bạn đã thay đổi mật khẩu đăng nhập thành công. Bạn vui lòng đăng nhập lại để tiếp tục sử dụng';

        showMessage(result, msgSuccess);
        if (result && result.isSuccess()) {
            logout();
        }
    };

    return (
        <ChangePassWrapper>
            <ChangePassContainer title="ĐỔI MẬT KHẨU ĐĂNG NHẬP">
                <form
                    key={1}
                    onSubmit={handleSubmit(onChangePass)}
                    autoComplete="off">
                    <InputWrapper>
                        <InputText
                            placeholder="Mật khẩu cũ..."
                            type="password"
                            {...register('oldPass')}
                            isError={errors.oldPass ? 'error' : ''}
                        />
                        {errors.oldPass ? (
                            <ErrorText>{errors.oldPass.message}</ErrorText>
                        ) : null}
                    </InputWrapper>
                    <InputWrapper>
                        <InputText
                            placeholder="Mật khẩu mới"
                            type="password"
                            {...register('newPass')}
                            isError={errors.newPass ? 'error' : ''}
                        />
                        {errors.newPass ? (
                            <ErrorText>{errors.newPass.message}</ErrorText>
                        ) : null}
                    </InputWrapper>
                    <InputWrapper>
                        <InputText
                            placeholder="Xác nhận mật khẩu"
                            type="password"
                            {...register('confirmPassword')}
                            isError={errors.confirmPassword ? 'error' : ''}
                        />
                        {errors.confirmPassword ? (
                            <ErrorText>
                                {errors.confirmPassword.message}
                            </ErrorText>
                        ) : null}
                    </InputWrapper>
                    {!loadingChangePass ? (
                        <ChangePassButton
                            type="submit"
                            typeButton={APP_BUTTON_TYPE.PRIMARY}>
                            Thay đổi mật khẩu
                        </ChangePassButton>
                    ) : (
                        <ChangePassButton
                            style={{ pointerEvents: 'none' }}
                            typeButton={APP_BUTTON_TYPE.PRIMARY}>
                            <Spin />
                        </ChangePassButton>
                    )}
                </form>
            </ChangePassContainer>
        </ChangePassWrapper>
    );
});
