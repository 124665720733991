import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';
//styles
import { RuleWrapper, RuleContainer, RuleText } from './RuleDescription.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';

interface Props {}

export const RuleDescription: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { homeInfo, getHomeDataInfo } = store.jsonStore;

    useEffect(() => {
        if (!homeInfo) getHomeDataInfo();
    }, []);

    return (
        <RuleWrapper>
            <RuleContainer>
                <RuleText
                    dangerouslySetInnerHTML={{
                        __html: homeInfo?.visit!
                    }}></RuleText>
            </RuleContainer>
        </RuleWrapper>
    );
});
