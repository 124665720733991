import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//components
import { GiftNewSlider } from './GiftNewSlider';
//styles
import {
    GiftNewContainer,
    GiftNewWrapper,
    TitleText,
    TitleWrapper
} from './GiftNew.styles';
import { AppTitle } from '../../../styles/App.styles';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { GIFT_TYPE, IGetListGiftRequest } from '../../../types/IGift';
import { observer } from 'mobx-react-lite';

interface Props {}

export const GiftNew: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { listGiftTopNew, getListGiftTopNew } = store.giftStore;

    useEffect(() => {
        onGetListGiftTopBuy();
    }, []);

    const onGetListGiftTopBuy = async () => {
        const params: IGetListGiftRequest = {
            data: {
                type: GIFT_TYPE.TOP_NEW,
                catId: 0
            }
        };
        const result = await getListGiftTopNew(params);
    };
    return (
        <GiftNewWrapper>
            <GiftNewContainer>
                <TitleWrapper>
                    <AppTitle>QUÀ MỚI NHẤT</AppTitle>
                </TitleWrapper>
                {listGiftTopNew ? (
                    <GiftNewSlider gifts={listGiftTopNew} />
                ) : null}
            </GiftNewContainer>
        </GiftNewWrapper>
    );
});
