import { ComponentType } from 'react';
import pure from 'recompose/pure';
//styles
import {
    ContactFormWrapper,
    ContactFormContainer,
    ContactInfoWrapper,
    ContactTitleWrapper,
    AddressItemContainer,
    AddressIconWrapper,
    AddressText,
    SocialWrapper,
    InputText,
    InputTextMultiline,
    SendButton,
    ContactInfoContainer
} from './ContactForm.styles';
import { Col, Modal, Row } from 'antd';
import { AppTitle, CompanyTitle } from '../../../styles/App.styles';
import { EmailIcon, MapIcon, PhoneIcon } from '../../../components/SvgIcon';
import { SocialIcon } from '../../../components/SocialIcon/SocialIcon';

import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { useForm } from 'react-hook-form';
import { IFeedbackInfo, IUserFeedbackRequest } from '../../../types/IUser';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContactFormSchema } from '../../../validation/ContactFormSchema';
import { IResponseBase } from '../../../types/ITypeBase';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { showMessage } from '../../../utils';

interface Props {}

export const ContactForm: ComponentType<Props> = pure(({}) => {
    const store = useRootStore();
    const { sendFeedback } = store.userStore;

    const {
        register,
        handleSubmit,
        reset,
        resetField,
        formState: { errors }
    } = useForm<IFeedbackInfo>({
        resolver: yupResolver(ContactFormSchema)
    });

    const onSubmit = async (data: IFeedbackInfo) => {
        const params: IUserFeedbackRequest = {
            data: {
                name: data.name,
                email: data.email,
                phone: data.phone,
                content: data.message
            }
        };
        const result: IResponseBase = await sendFeedback(params);
        const msgSuccess =
            'Chúc mừng bạn đã gửi thông tin thành công. Chúng tôi sẽ liên hệ với bạn sớm nhất';
        showMessage(result, msgSuccess);
        if (result.isSuccess()) {
            reset();
            return;
        }
    };

    return (
        <ContactFormWrapper>
            <ContactFormContainer>
                <Row>
                    <Col xl={12} md={12} sm={24} xs={24}>
                        <ContactInfoWrapper>
                            <ContactTitleWrapper>
                                <AppTitle>THÔNG TIN LIÊN HỆ</AppTitle>
                            </ContactTitleWrapper>

                            <ContactInfoContainer>
                                <CompanyTitle>
                                    CÔNG ​TY CỔ PHẦN CÔNG NGHỆ FUS
                                </CompanyTitle>
                                <AddressItemContainer>
                                    <AddressIconWrapper>
                                        <MapIcon />
                                    </AddressIconWrapper>
                                    <AddressText>
                                        Địa chỉ: 144-146 Nguyễn Thái Bình,
                                        <br />
                                        Phường Nguyễn Thái Bình, Quận 1, TP. Hồ
                                        Chí Minh
                                    </AddressText>
                                </AddressItemContainer>
                                <AddressItemContainer>
                                    <AddressIconWrapper>
                                        <PhoneIcon />
                                    </AddressIconWrapper>
                                    <AddressText>
                                        Hotline: 028.2200.0113
                                    </AddressText>
                                </AddressItemContainer>
                                <AddressItemContainer>
                                    <AddressIconWrapper>
                                        <EmailIcon />
                                    </AddressIconWrapper>
                                    <AddressText>
                                        Email: fumeli@fusoftvn.com
                                    </AddressText>
                                </AddressItemContainer>

                                <SocialWrapper>
                                    <SocialIcon isHeader={true} />
                                </SocialWrapper>
                            </ContactInfoContainer>
                        </ContactInfoWrapper>
                    </Col>
                    <Col xl={12} md={12} sm={24} xs={24}>
                        <ContactInfoWrapper>
                            <ContactTitleWrapper>
                                <AppTitle>GỬI YÊU CẦU</AppTitle>
                            </ContactTitleWrapper>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                autoComplete="off">
                                <InputText
                                    {...register('name')}
                                    isError={errors.name ? 'error' : ''}
                                    placeholder="Họ và tên (*)"
                                />
                                <InputText
                                    {...register('email')}
                                    isError={errors.email ? 'error' : ''}
                                    placeholder="Email  (*)"
                                />
                                <InputText
                                    {...register('phone')}
                                    isError={errors.phone ? 'error' : ''}
                                    placeholder="Số điện thoại  (*)"
                                />
                                <InputTextMultiline
                                    {...register('message')}
                                    isError={errors.message ? 'error' : ''}
                                    placeholder="Nội dung  (*)"
                                />
                                <SendButton
                                    type="submit"
                                    typeButton={APP_BUTTON_TYPE.PRIMARY}>
                                    Gửi thông tin
                                </SendButton>
                            </form>
                        </ContactInfoWrapper>
                    </Col>
                </Row>
            </ContactFormContainer>
        </ContactFormWrapper>
    );
});
