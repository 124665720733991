import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';
import Image from 'rc-image';
import { AppButton } from '../../../styles/AppButton.styles';

export const TopHeaderWrapper = styled.div`
    width: 100%;
    position: relative;
`;

export const TopHeaderContainer = styled.div`
    width: 100%;
    height: 100%;
    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
    }
`;

export const CoverWrapper = styled.div`
    width: 100%;
    position: relative;
    @media ${device.mobileS} {
        height: 152px;
    }
    @media ${device.laptop} {
        height: 300px;
    }
`;

export const AddCoverButton = styled.div`
    height: 40px;
    background-color: red;
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
`;

export const CoverImage = styled(Image)`
    width: 100%;

    @media ${device.mobileS} {
        height: 152px;
    }
    @media ${device.laptop} {
        height: 300px;
    }
    object-fit: cover;
    opacity: 1;
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #19181c;
    @media ${device.mobileS} {
        background-image: url('../../../images/bg-mission.png');
        background-position: center;
        background-size: cover;
        margin-top: -45px;
    }
    @media ${device.laptop} {
        margin-top: -90px;
    }
`;

export const AvatarWrapper = styled.div`
    position: relative;
    @media ${device.mobileS} {
        margin-bottom: 20px;
    }
    @media ${device.laptop} {
        margin-bottom: 0px;
    }
`;

export const AvatarImage = styled(Image)`
    object-fit: cover;

    position: relative;

    @media ${device.mobileS} {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        border: solid 5px #fff;
    }
    @media ${device.laptop} {
        width: 180px;
        height: 180px;
        border-radius: 90px;
        border: solid 10px #fff;
    }
`;

export const CameraWrapper = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;

    background-color: #707070;

    @media ${device.mobileS} {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        padding: 6px;
    }
    @media ${device.laptop} {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        padding: 8px;
    }
`;

export const UserName = styled.h1`
    margin: 0;
    font-weight: 700;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    @media ${device.mobileS} {
        font-size: 30px;
    }
    @media ${device.laptop} {
        font-size: 40px;
    }
`;

export const UserEmail = styled.h1`
    margin: 0;
    font-weight: 500;
    color: #c7ccd4;
    text-shadow: 1px 1px 1px #000;
    @media ${device.mobileS} {
        font-size: 16px;
    }
    @media ${device.laptop} {
        font-size: 20px;
    }
`;

export const UserPoint = styled.h2`
    margin: 0;
    font-weight: 700;
    color: #c7ccd4;
    text-shadow: 1px 1px 1px #000;

    @media ${device.mobileS} {
        font-size: 16px;
    }
    @media ${device.laptop} {
        font-size: 20px;
    }
`;

export const ButtonListWrapper = styled.div`
    display: flex;
    margin-top: 20px;
    @media ${device.mobileS} {
        flex-direction: column;
        width: 70%;
    }
    @media ${device.laptop} {
        flex-direction: row;
        width: 600px;
    }
`;

export const ActionButton = styled.button`
    color: #fff;
    background-color: #ff2423;
    border: solid 1px #000;
    outline: none;
    cursor: pointer;
    border-radius: 10px;

    padding-left: 10px;
    padding-right: 10px;
    height: 50px;

    @media ${device.mobileS} {
        font-size: 14px;
        width: 100%;
        margin-bottom: 20px;
    }
    @media ${device.laptop} {
        font-size: 16px;
        margin-right: 20px;
    }
`;

export const InfoButton = styled(ActionButton)`
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 20px;
        margin-right: 10px;
    }
    p {
        margin: 0;
        font-weight: 700;
        @media ${device.mobileS} {
            font-size: 14px;
        }
        @media ${device.laptop} {
            font-size: 16px;
        }
    }
`;
