import styled from 'styled-components';

export const VideoItemWrapper = styled.div`
    /* max-width: 500px; */
    position: relative;
`;

export const VideoThumbnailContainer = styled.div`
    /* width: 500px; */
    max-height: 328px;
    border-radius: 10px;
    border: solid 1px #fff;
`;

export const VideoThumbnail = styled.img`
    object-fit: cover;
    width: 100%;
    height: 250px;
    border-radius: 10px;
`;

export const PlayContainer = styled.div`
    position: absolute;
    width: 62px;
    height: 62px;
    left: 42%;
    top: 42%;
    //transform: translate(-42%, 50%);
`;
