import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';

export const ListGiftWrapper = styled.div`
    width: 100%;
    background-color: #19181c;
    padding-bottom: 40px;
`;

export const ListGiftContainer = styled.div`
    @media ${device.mobileS} {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media ${device.laptop} {
        width: 1100px;
        margin: 0 auto;
        padding: 0;
    }
`;
