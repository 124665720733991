import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { AppTitle } from '../../../styles/App.styles';
//styles
import {
    TopBannerWrapper,
    TopBannerContainer,
    TitleWrapper,
    BannerWrapper,
    BannerImage,
    RuleButton,
    ButtonWrapper,
    HelpButton,
    JoinedButton,
    JoinedText
} from './TopBanner.styles';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { IMissionInstallApp } from '../../../types/IJson';
import { CheckCircleOutlined, CheckOutlined } from '@ant-design/icons';

interface Props {
    mission: IMissionInstallApp;
    isJoined: boolean;
    onClickRules: () => void;
}

export const TopBanner: ComponentType<Props> = observer(
    ({ isJoined, mission, onClickRules }) => {
        const store = useRootStore();
        const { appBanner } = store.jsonStore;

        return (
            <TopBannerWrapper>
                <TopBannerContainer>
                    <TitleWrapper>
                        <AppTitle>{mission.title}</AppTitle>
                    </TitleWrapper>
                    <BannerWrapper>
                        <BannerImage src={mission.banner}></BannerImage>
                        <ButtonWrapper>
                            {isJoined ? (
                                <JoinedButton
                                    typeButton={APP_BUTTON_TYPE.BORDER}>
                                    <CheckOutlined
                                        style={{ color: '#2cc748' }}
                                    />
                                    <JoinedText>Đã tham gia</JoinedText>
                                </JoinedButton>
                            ) : null}
                            <RuleButton
                                typeButton={APP_BUTTON_TYPE.PRIMARY}
                                onClick={onClickRules}>
                                Thể lệ
                            </RuleButton>
                        </ButtonWrapper>
                    </BannerWrapper>
                </TopBannerContainer>
            </TopBannerWrapper>
        );
    }
);
