import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import { IGetListBannerRequest } from '../types/IBanner';
import { IGetInfoIntroRequest } from '../types/IIntro';
import {
    IGetListUserHistoryBuyGift,
    IGetListUserHistoryInvited,
    IGetListUserHistorySpin,
    IGetListUserHistoryVideo,
    IGetListUserHistoryVisit
} from '../types/IUserHistory';
import BaseAuthService from './BaseAuthService';

export class HistoryService extends BaseAuthService {
    private static instance: HistoryService;

    public static getInstance(): HistoryService {
        if (!HistoryService.instance) {
            HistoryService.instance = new HistoryService();
        }
        return HistoryService.instance;
    }

    getListHistoryVisit = async (params: IGetListUserHistoryVisit) => {
        try {
            const response = await this.postData(API.HISTORY.VISIT, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListHistoryInvited = async (params: IGetListUserHistoryInvited) => {
        try {
            const response = await this.postData(API.HISTORY.INVITED, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListHistoryVideo = async (params: IGetListUserHistoryVideo) => {
        try {
            const response = await this.postData(API.HISTORY.VIDEO, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListHistorySpin = async (params: IGetListUserHistorySpin) => {
        try {
            const response = await this.postData(API.HISTORY.SPIN, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListHistoryBuyGift = async (params: IGetListUserHistoryBuyGift) => {
        try {
            const response = await this.postData(API.HISTORY.BUY_GIFT, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}
