import styled from 'styled-components';
import { AppCard } from '../../../../../styles/App.styles';
import { AppInputText } from '../../../../../styles/AppInput.styles';
import { AppButton } from '../../../../../styles/AppButton.styles';

export const ChangePassWrapper = styled.div``;

export const ChangePassContainer = styled(AppCard)`
    .ant-card-body {
        padding: 20px;
    }
`;

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
`;

export const InputText = styled(AppInputText)`
    background: transparent;
    &.error {
        margin: 0;
    }
`;

export const ErrorText = styled.p`
    font-size: 14px;
    color: red;
    margin: 10px;
`;

export const ChangePassButton = styled(AppButton)`
    width: 100%;
    margin-top: 20px;
`;
