import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';

export const RuleWrapper = styled.div`
    background-color: #19181c;
    @media ${device.mobileS} {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media ${device.laptop} {
        padding-bottom: 20px;
    }
`;

export const RuleContainer = styled.div`
    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptop} {
        width: 1100px;
        margin: 0 auto;
    }
`;

export const RuleText = styled.p`
    margin: 0;
    color: #fff;
    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
        font-size: 16px;
        font-weight: 400;
    }
`;
