import APIHelper, { JSonHelper } from '../helpers/APIHelper';
import { getTimestamp } from '../utils';

class JSonService {
    private static instance: JSonService;

    public static getInstance(): JSonService {
        if (!JSonService.instance) {
            JSonService.instance = new JSonService();
        }
        return JSonService.instance;
    }

    public constructor() {
        //override default baseURL and headers of API service
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8'
        };
        JSonHelper.defaults.headers.common = headers;
        JSonHelper.defaults.baseURL = process.env.REACT_APP_CDN_URL;
    }

    getData = async (api: string) => {
        const response: any = await JSonHelper.get(
            api + `?ts=${getTimestamp()}`
        );
        return response.data;
    };

    postData = async (api: string) => {
        const response: any = await JSonHelper.post(
            api + `?ts=${getTimestamp()}`
        );
        return response.data;
    };
}

export default JSonService;
