import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';
import { AppTitle } from '../../../styles/App.styles';

export const InfoWrapper = styled.div`
    @media ${device.mobileS} {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media ${device.laptop} {
        width: 1100px;
        margin: 0 auto;
        padding: 0;
    }
`;

export const InfoContainer = styled.div`
    padding-bottom: 40px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.mobileS} {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    @media ${device.laptop} {
        padding: 30px;
    }
`;

export const TitleText = styled(AppTitle)`
    text-align: center;
`;

export const ContentWrapper = styled.div``;

export const LogoWrapper = styled.div`
    @media ${device.mobileS} {
        width: 100%;
    }
    @media ${device.laptop} {
        width: 100%;
    }
`;

export const LogoImage = styled.img`
    width: 100%;
    object-fit: cover;
`;

export const InfoTextWrapper = styled.div`
    color: #fff;
    font-size: 16px;
    font-weight: 500;

    li {
        margin-left: 30px;
        padding-left: 10px;
    }
    @media ${device.mobileS} {
        width: 100%;
        margin-top: 40px;
    }
    @media ${device.laptop} {
        margin-top: 0px;
        margin-left: 40px;
    }
`;
