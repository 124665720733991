import { ComponentType } from 'react';
import pure from 'recompose/pure';
//styles
import {
    ItemWrapper,
    ItemContainer,
    ItemImageWrapper,
    ItemImage,
    ItemTitle
} from './GiftItem.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import SpinGift from '../../../../models/SpinGift';

interface Props {
    data: SpinGift;
}

export const GiftItem: ComponentType<Props> = observer(({ data }) => {
    const store = useRootStore();
    const { giftKey } = store.giftStore;

    return (
        <ItemWrapper xl={4} md={4} sm={8} xs={8}>
            <ItemContainer isReceive={data.giftKey == giftKey ? 'receive' : ''}>
                <ItemImageWrapper>
                    <ItemImage src={data.image}></ItemImage>
                </ItemImageWrapper>
                <ItemTitle>{data.giftName}</ItemTitle>
            </ItemContainer>
        </ItemWrapper>
    );
});
