import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//components
import { LeaderboardItem } from './LeaderboardItem/LeaderboardItem';
//styles
import {
    DrawerTopBoard,
    MeRankWrapper,
    MeScoreWrapper,
    ScoreText,
    ScoreValue
} from './LeaderboardDrawer.styles';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { IGetListUserRankRequest, IUser } from '../../../types/IUser';
import { APIResponseList } from '../../../models/APIResponse';
import { observer } from 'mobx-react-lite';
import { isLogin } from '../../../helpers/AuthHeader';

interface Props {
    open: boolean;
    onClose: () => void;
}

export const LeaderboardDrawer: ComponentType<Props> = observer(
    ({ open, onClose }) => {
        const store = useRootStore();
        const { listUserRank, getListUserRank } = store.userStore;
        const { currentUser } = store.authStore;

        useEffect(() => {
            onGetListUserRank();
        }, []);

        const onGetListUserRank = async () => {
            const params: IGetListUserRankRequest = {};
            let result: APIResponseList<Array<IUser>> = await getListUserRank(
                params,
                false
            );
        };

        return (
            <DrawerTopBoard
                title="BẢNG XẾP HẠNG"
                placement="right"
                onClose={onClose}
                open={open}>
                {listUserRank &&
                    listUserRank.list.map((user) => {
                        return (
                            <LeaderboardItem user={user} key={user.userId} />
                        );
                    })}

                {currentUser?.isLogin() ? (
                    <MeRankWrapper>
                        <MeScoreWrapper>
                            <ScoreText>Điểm của bạn: </ScoreText>
                            <ScoreValue>{currentUser?.totalPoint}</ScoreValue>
                        </MeScoreWrapper>
                    </MeRankWrapper>
                ) : null}
            </DrawerTopBoard>
        );
    }
);
