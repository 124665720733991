import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import { IGetListGiftCategory } from '../types/IGiftCategory';
import {
    IAddSpinGiftRequest,
    IBuyGiftRequest,
    IGetListGiftRequest,
    IGetListSpinGiftRequest,
    IGetSpinGiftRequest
} from '../types/IGift';
import BaseAuthService from './BaseAuthService';
import {
    IBagReceiveItemRequest,
    IGetListGiftBag,
    IGetListGiftBagType
} from '../types/IGiftBag';

export class GiftBagService extends BaseAuthService {
    private static instance: GiftBagService;

    public static getInstance(): GiftBagService {
        if (!GiftBagService.instance) {
            GiftBagService.instance = new GiftBagService();
        }
        return GiftBagService.instance;
    }

    getListGiftBagType = async (params: IGetListGiftBagType) => {
        try {
            const response = await this.postData(
                API.BAG_GIFT.GIFT_TYPE,
                params
            );
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListGiftBag = async (params: IGetListGiftBag) => {
        try {
            const response = await this.postData(API.BAG_GIFT.BAG_LIST, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    receiveBagItem = async (params: IBagReceiveItemRequest) => {
        try {
            const response = await this.postData(
                API.BAG_GIFT.BAG_RECEIVED,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}
