import { ComponentType, useEffect, useState } from 'react';

//components
import { SpinItem } from './SpinItem/SpinItem';
//styles
import {
    SpinHistoryWrapper,
    SpinHistoryContainer,
    PaginationWrapper
} from './SpinHistory.styles';
import { useRootStore } from '../../../../../providers/RootStoreProvider';
import { IGetListUserHistorySpin } from '../../../../../types/IUserHistory';
import { observer } from 'mobx-react-lite';
import { Pagination, PaginationProps } from 'antd';

interface Props {}

export const SpinHistory: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { listUserHistorySpin, getListUserHistorySpin } = store.historyStore;
    const [pageIndex, setPageIndex] = useState(1);

    useEffect(() => {
        onGetListUserHistory();
    }, []);

    const onGetListUserHistory = async (
        isLoadMore?: boolean,
        pageIndex?: number
    ) => {
        const params: IGetListUserHistorySpin = {
            data: {}
        };
        const result = await getListUserHistorySpin(
            params,
            isLoadMore,
            pageIndex
        );
    };

    const renderData = () => {
        if (listUserHistorySpin && listUserHistorySpin.hasData()) {
            return (
                <SpinHistoryContainer title="Lịch sử quay số">
                    {listUserHistorySpin &&
                        listUserHistorySpin.list.map((item) => {
                            return <SpinItem data={item} key={item.id} />;
                        })}
                </SpinHistoryContainer>
            );
        }
    };

    const onChange: PaginationProps['onChange'] = (page, pageSize) => {
        console.log(pageSize);
        setPageIndex(page);
        onGetListUserHistory(true, page);
    };

    return (
        <SpinHistoryWrapper>
            <SpinHistoryContainer
                title={`Lịch sử quay số (${listUserHistorySpin?.totalRecord})`}>
                {listUserHistorySpin &&
                    listUserHistorySpin.list.map((item) => {
                        return <SpinItem data={item} key={item.id} />;
                    })}

                {listUserHistorySpin?.totalPage! > 1 ? (
                    <PaginationWrapper>
                        <Pagination
                            showSizeChanger={false}
                            defaultCurrent={pageIndex}
                            total={listUserHistorySpin?.totalPage}
                            onChange={onChange}
                        />
                    </PaginationWrapper>
                ) : null}
            </SpinHistoryContainer>
        </SpinHistoryWrapper>
    );
});
