import { IMission } from '../types/IMission';
import { DataList } from './DataList';

export default class Mission {
    public missionId: string;
    public missionName: string;
    public description: string;
    public image: string;
    public point: number;
    public url: string;
    public appId: number;

    constructor(
        missionId: string,
        missionName: string,
        description: string,
        image: string,
        point: number,
        url: string,
        appId: number
    ) {
        this.missionId = missionId;
        this.missionName = missionName;
        this.description = description;
        this.image = image;
        this.point = point;
        this.url = url;
        this.appId = appId;
    }

    static fromJson = (json: IMission): Mission => {
        return new Mission(
            json.mid,
            json.mission,
            json.desc,
            json.img,
            json.point,
            json.url,
            json.appId
        );
    };

    isLinkExternal = (): boolean => {
        return (
            this.url.indexOf('http://') !== -1 ||
            this.url.indexOf('https://') !== -1
        );
    };

    isInstallApp = (): boolean => {
        return this.appId !== null && this.appId > 0;
    };
}

export class ListMission extends DataList<Mission> {
    static fromJson = (jsonArray: Array<IMission>): Array<Mission> => {
        let data = new Array<Mission>();
        jsonArray.forEach((item) => {
            data.push(Mission.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
