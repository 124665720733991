import { ComponentType, useCallback, useState } from 'react';
import pure from 'recompose/pure';
//styles
import {
    TopHeaderWrapper,
    TopHeaderContainer,
    CoverWrapper,
    CoverImage,
    InfoWrapper,
    AvatarImage,
    UserName,
    UserPoint,
    ButtonListWrapper,
    InfoButton,
    AvatarWrapper,
    CameraWrapper,
    UserEmail,
    AddCoverButton
} from './TopHeader.styles';
import {
    ActivityIcon,
    CameraIcon,
    GiftIcon,
    PeopleIcon
} from '../../../components/SvgIcon';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { PROFILE_SCREEN } from '../../../configs/ScreenType';
import { observer } from 'mobx-react-lite';
import { formatNumber } from '../../../utils/FormatUtils';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import getCroppedImg, {
    getRotatedImage,
    readImageFile
} from '../../../utils/CropImage';
import { dataURLtoFile } from '../../../utils/FileUtils';
import { getOrientation } from 'get-orientation/browser';
import { CropImageDialog } from '../../../components/CropImageDialog/CropImageDialog';
import { CropImageType } from '../../../configs/AppEnum';
import {
    IUploadAvatarRequest,
    IUploadCoverRequest
} from '../../../types/IUser';
import { Modal } from 'antd';
import { IResponseBase } from '../../../types/ITypeBase';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { getTimestamp, showMessage } from '../../../utils';

interface Props {}

export const TopHeader: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { currentUser } = store.authStore;
    const { changeProfileScreen } = store.globalStore;
    const { uploadAvatar } = store.userStore;

    const [imageSrc, setImageSrc] = useState(null);
    const [openDialogCropImage, setOpenDialogCropImage] = useState(false);
    const [cropType, setCropType] = useState<CropImageType>();
    const [dialogTitle, setDialogTitle] = useState('');

    const onFileAvatarChange = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl: any = readImageFile(file).then((data: any) => {
                setImageSrc(data);
                setCropType(CropImageType.ROUND);
                setDialogTitle('Chỉnh sửa ảnh đại diện');
                setOpenDialogCropImage(true);
                e.target.value = null;
            });
            /*try {
                const orientation = await getOrientation(file);
                const rotation = orientation.valueOf();
                if (rotation) {
                    imageDataUrl = await getRotatedImage(
                        imageDataUrl,
                        rotation
                    );
                }
            } catch (e) {
                console.warn('failed to detect the orientation');
            }
            setImageSrc(imageDataUrl);*/
        }
    };

    const onFileCoverChange = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl: any = readImageFile(file).then((data: any) => {
                setImageSrc(data);
                setCropType(CropImageType.RECTANGLE);
                setDialogTitle('Chỉnh sửa ảnh bìa');
                setOpenDialogCropImage(true);
                e.target.value = null;
            });
        }
    };

    const onCropImageComplete = async (image: string) => {
        let params: any;
        let msgSuccess, msgError;
        const isUploadAvatar = cropType === CropImageType.ROUND;
        if (isUploadAvatar) {
            params = {
                data: {
                    avatar: image
                }
            };
            msgSuccess = 'Cập nhật ảnh đại diện thành công';
            msgError = 'Cập nhật ảnh đại diện thất bại';
        } else {
            params = {
                data: {
                    cover: image
                }
            };
            msgSuccess = 'Cập nhật ảnh bìa thành công';
            msgError = 'Cập nhật ảnh bìa thất bại';
        }
        const result: IResponseBase = await uploadAvatar(params);
        showMessage(result, msgSuccess, msgError);
        if (result.isSuccess()) {
            if (isUploadAvatar)
                currentUser?.updateAvatar(
                    result.data.image + `?ts=${getTimestamp()}`
                );
            else
                currentUser?.updateCover(
                    result.data.image + `?ts=${getTimestamp()}`
                );
            return;
        }
    };

    const onChangeProfileScreen = (profileScreen: PROFILE_SCREEN) => {
        changeProfileScreen(profileScreen);
    };

    const onLoadAvatarError = (event: any) => {
        event.target.src = `../../../../images/default-avatar.png`;
        event.onerror = null;
    };

    const onLoadCoverError = (event: any) => {
        event.target.src = `../../../../images/default-cover.jpeg`;
        event.onerror = null;
    };

    return (
        <TopHeaderWrapper>
            <CoverWrapper>
                <CoverImage
                    src={currentUser?.cover}
                    placeholder={true}
                    preview={false}
                    fallback="../../../../images/default-cover.jpeg"
                    onError={onLoadCoverError}></CoverImage>
                <label className="custom-file-upload">
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={onFileCoverChange}
                    />
                    <AddCoverButton>Cập nhật ảnh bìa</AddCoverButton>
                </label>
            </CoverWrapper>
            <TopHeaderContainer>
                <InfoWrapper>
                    <AvatarWrapper>
                        <AvatarImage
                            src={currentUser?.avatar}
                            placeholder={true}
                            preview={false}
                            fallback="../../../../images/default-avatar.png"
                            onError={onLoadAvatarError}></AvatarImage>

                        <label className="custom-file-upload">
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={onFileAvatarChange}
                            />
                            <CameraWrapper>
                                <CameraIcon />
                            </CameraWrapper>
                        </label>
                    </AvatarWrapper>
                    <UserName>{currentUser?.name}</UserName>
                    <UserEmail>{currentUser?.email}</UserEmail>
                    <UserPoint>
                        Tổng điểm của bạn :{' '}
                        {formatNumber(currentUser?.totalPoint!)}
                    </UserPoint>
                    <ButtonListWrapper>
                        <InfoButton
                            onClick={() =>
                                onChangeProfileScreen(PROFILE_SCREEN.INFO)
                            }>
                            <PeopleIcon></PeopleIcon>
                            <p>THÔNG TIN</p>
                        </InfoButton>
                        <InfoButton
                            onClick={() =>
                                onChangeProfileScreen(PROFILE_SCREEN.ACTIVITY)
                            }>
                            <ActivityIcon></ActivityIcon>
                            <p>HOẠT ĐỘNG</p>
                        </InfoButton>
                        <InfoButton
                            onClick={() =>
                                onChangeProfileScreen(PROFILE_SCREEN.BAG)
                            }>
                            <GiftIcon></GiftIcon>
                            <p>TÚI ĐỒ</p>
                        </InfoButton>
                    </ButtonListWrapper>
                </InfoWrapper>
            </TopHeaderContainer>
            {imageSrc && openDialogCropImage ? (
                <CropImageDialog
                    image={imageSrc}
                    onComplete={onCropImageComplete}
                    cropType={cropType!}
                    dialogTitle={dialogTitle}
                    showDialog={openDialogCropImage}
                    onClose={() => setOpenDialogCropImage(false)}
                />
            ) : null}
        </TopHeaderWrapper>
    );
});
