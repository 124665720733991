import { ComponentType } from 'react';
import pure from 'recompose/pure';
//styles
import {
    ForgotPassWrapper,
    ForgotPassContainer,
    ForgotPassModal,
    TitleText,
    TitleContainer,
    DescriptionText,
    SendButton
} from './ForgotPass.styles';
import { AppInputText } from '../../styles/AppInput.styles';
import { AppButton } from '../../styles/AppButton.styles';
import { APP_BUTTON_TYPE } from '../../types/IGlobal';
import { useForm } from 'react-hook-form';
import { ForgotPassSchema } from '../../validation/ForgotPassSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { IRegisterUserInfo } from '../../types/IUser';
import { useRootStore } from '../../providers/RootStoreProvider';
import { IForgetPassRequest } from '../../types/ILogin';
import { IResponseBase } from '../../types/ITypeBase';
import { Modal } from 'antd';
import { showMessage } from '../../utils';

interface Props {
    visible: boolean;
    onClose: () => void;
}

export const ForgotPass: ComponentType<Props> = pure(({ visible, onClose }) => {
    const store = useRootStore();
    const { forgetPass } = store.authStore;

    const {
        register,
        handleSubmit,
        reset,
        resetField,
        formState: { errors }
    } = useForm<IRegisterUserInfo>({
        resolver: yupResolver(ForgotPassSchema)
    });

    const onSubmit = async (data: IRegisterUserInfo) => {
        const params: IForgetPassRequest = {
            data: {
                email: data.account
            }
        };
        const result: IResponseBase = await forgetPass(params);
        const msgSuccess =
            'Mật khẩu mới đã được gửi qua email của bạn. Vui lòng kiểm tra email';
        showMessage(result, msgSuccess);
    };

    return (
        <ForgotPassWrapper>
            <ForgotPassContainer>
                <ForgotPassModal
                    centered
                    onCancel={onClose}
                    open={visible}
                    footer={null}>
                    <TitleContainer>
                        <TitleText>Quên mật khẩu đăng nhập</TitleText>
                    </TitleContainer>
                    <DescriptionText>
                        Để lấy lại mật khẩu bạn vui lòng cung cấp Email đã đăng
                        ký / liên kết tài khoản
                    </DescriptionText>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <AppInputText
                            placeholder="Email..."
                            {...register('account')}
                            isError={errors.account ? 'error' : ''}
                        />

                        <SendButton
                            type="submit"
                            typeButton={APP_BUTTON_TYPE.PRIMARY}>
                            Gửi email xác nhận
                        </SendButton>
                    </form>
                </ForgotPassModal>
            </ForgotPassContainer>
        </ForgotPassWrapper>
    );
});
