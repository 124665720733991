import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    AvatarImage,
    AvatarWrapper,
    InfoContainer,
    LoginButton,
    UserLoginContainer,
    UserLoginWrapper,
    UserNameText,
    UserPoint
} from './UserLogin.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { APP_BUTTON_TYPE } from '../../../../types/IGlobal';
import { formatNumber } from '../../../../utils/FormatUtils';
import { useNavigate } from 'react-router-dom';

interface Props {}

export const UserLogin: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const navigate = useNavigate();

    const { currentUser, logout } = store.authStore;
    const { toggleShowDialogLogin } = store.globalStore;

    const onUserLogout = () => {
        logout();
        navigate('/'); //go home
    };

    const renderContent = () => {
        if (!currentUser?.isLogin()) {
            return <LoginView />;
        } else return <UserView />;
    };

    const LoginView = () => {
        return (
            <LoginButton
                typeButton={APP_BUTTON_TYPE.PRIMARY}
                onClick={() => toggleShowDialogLogin(true)}>
                Đăng nhập
            </LoginButton>
        );
    };

    const onLoadAvatarError = (event: any) => {
        event.target.src = `../../../../images/default-avatar.png`;
        event.onerror = null;
    };

    const UserView = () => {
        return (
            <>
                <AvatarWrapper>
                    <AvatarImage
                        src={currentUser?.cover}
                        placeholder={true}
                        preview={false}
                        fallback="../../../../images/default-cover.jpeg"
                        onError={onLoadAvatarError}></AvatarImage>
                    <InfoContainer>
                        <UserNameText>
                            {currentUser?.getUserName()}
                        </UserNameText>
                        <UserPoint>
                            Điểm: {formatNumber(currentUser?.totalPoint!)}
                        </UserPoint>
                        <LoginButton
                            typeButton={APP_BUTTON_TYPE.PRIMARY}
                            onClick={() => onUserLogout()}>
                            Đăng xuất
                        </LoginButton>
                    </InfoContainer>
                </AvatarWrapper>
            </>
        );
    };

    return (
        <UserLoginWrapper>
            <UserLoginContainer>{renderContent()}</UserLoginContainer>
        </UserLoginWrapper>
    );
});
