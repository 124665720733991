import {
    IAppBanner,
    IHomeData,
    IJsonBase,
    IMaintenance,
    IMissionInstallApp,
    IPolicy,
    ITerms
} from './../types/IJson';
import { makeObservable, action, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import JSonService from '../services/JSonService';
import { API } from '../configs/APIEndPoint';

class JSonStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    //declare variables
    @observable maintenanceInfo: IMaintenance | null = null;
    @observable homeInfo: IHomeData | null = null;
    @observable jsonContent: IJsonBase | null = null;
    @observable termsInfo: ITerms | null = null;
    @observable appBanner: IAppBanner | null = null;
    @observable missionInstallApp: IMissionInstallApp | null = null;

    //declare action
    @action getMaintenanceInfo = async () => {
        const result = (await JSonService.getInstance().getData(
            API.JSON.MAINTENANCE
        )) as IMaintenance;
        if (result) {
            runInAction(() => {
                this.maintenanceInfo = result;
            });
        }
    };

    @action getMissionInstallAppInfo = async (appId: any) => {
        const result = (await JSonService.getInstance().getData(
            API.JSON.INSTALL_APP + `${appId}.json`
        )) as IMissionInstallApp;
        if (result) {
            console.log(result);
            runInAction(() => {
                this.missionInstallApp = result;
            });
        }
        return result;
    };

    @action getAppBanner = async () => {
        const result = (await JSonService.getInstance().getData(
            API.JSON.IMAGES
        )) as IAppBanner;
        if (result) {
            runInAction(() => {
                this.appBanner = result;
            });
        }
    };

    @action getHomeDataInfo = async () => {
        const result = (await JSonService.getInstance().getData(
            API.JSON.HOME
        )) as IHomeData;
        if (result) {
            runInAction(() => {
                this.homeInfo = result;
            });
        }
    };

    @action getJsonContentInfo = async (jsonUrl: string) => {
        const result = (await JSonService.getInstance().getData(
            jsonUrl
        )) as IJsonBase;
        if (result) {
            runInAction(() => {
                this.jsonContent = result;
            });
        }
    };

    @action getTermsInfo = async () => {
        const result = (await JSonService.getInstance().getData(
            API.JSON.TERMS
        )) as ITerms;
        if (result) {
            runInAction(() => {
                this.termsInfo = result;
            });
        }
    };
}
export default JSonStore;
